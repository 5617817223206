/* [[ ESLINT BUG ENUM NOT USED ]] */
/* eslint-disable no-unused-vars */

import { AttributeType } from '@/utils/constants/examConstants'
import { IResponseAttributes, IResponseMappedExamFields } from '../../services/attributes/types'

export enum mutationTypes {
	SET_ATTRIBUTE_LOADING = 'SET_ATTRIBUTE_LOADING',
	SET_ATTRIBUTE_SAVING = 'SET_ATTRIBUTE_SAVING',

	CREATE_ATTRIBUTE_GROUP = 'CREATE_ATTRIBUTE_GROUP',
	UPDATE_ATTRIBUTE_GROUP = 'UPDATE_ATTRIBUTE_GROUP',
	DELETE_ATTRIBUTE_GROUP = 'DELETE_ATTRIBUTE_GROUP',

	SET_ATTRIBUTES = 'SET_ATTRIBUTES',

	CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE',
	UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE',
	DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE',
	UPDATE_SELECTED_ATTRIBUTE = 'UPDATE_SELECTED_ATTRIBUTE',

	SET_FORMULAS = 'SET_FORMULAS',

	SET_FORMULA = 'SET_FORMULA',
	CREATE_FORMULA = 'CREATE_FORMULA',
	UPDATE_FORMULA = 'UPDATE_FORMULA',
	DELETE_FORMULA = 'DELETE_FORMULA',

	UPDATE_ATTRIBUTE_POSITIONS = 'UPDATE_ATTRIBUTE_POSITIONS',

	SET_MAPPED_EXAM_FIELDS = 'SET_MAPPED_EXAM_FIELDS',
	CREATE_MAPPED_EXAM_FIELDS = 'CREATE_MAPPED_EXAM_FIELDS',
	UPDATE_MAPPED_EXAM_FIELDS = 'UPDATE_MAPPED_EXAM_FIELDS',
}

export enum actionTypes {
	CREATE_ATTRIBUTE_GROUP = 'CREATE_ATTRIBUTE_GROUP',
	UPDATE_ATTRIBUTE_GROUP = 'UPDATE_ATTRIBUTE_GROUP',
	DELETE_ATTRIBUTE_GROUP = 'DELETE_ATTRIBUTE_GROUP',

	GET_ATTRIBUTES = 'GET_ATTRIBUTES',
	CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE',
	UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE',
	DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE',

	GET_FORMULAS = 'GET_FORMULAS',

	SET_FORMULA = 'SET_FORMULA',
	SET_REMOVE_FORMULA = 'SET_REMOVE_FORMULA',
	CREATE_FORMULA = 'CREATE_FORMULA',
	UPDATE_FORMULA = 'UPDATE_FORMULA',
	DELETE_FORMULA = 'DELETE_FORMULA',

	UPDATE_ATTRIBUTE_POSITIONS = 'UPDATE_ATTRIBUTE_POSITIONS',
	GET_MAPPED_EXAM_FIELDS = 'GET_MAPPED_EXAM_FIELDS',
	CREATE_ATTRIBUTE_MAPPED_FIELD = 'CREATE_ATTRIBUTE_MAPPED_FIELD',
	UPDATE_ATTRIBUTE_MAPPED_FIELD = 'UPDATE_ATTRIBUTE_MAPPED_FIELD',
}

export interface IAttributeType {
	id: number
	type: AttributeType
	slug: string
	icon: string
}

export interface IAttributeFormula {
	id?: number
	attribute_id: number
	formula: string
	formula_base_id: number | null
	specie_id: number | null
	created_at?: string | null
	updated_at?: string | null
}

export interface IAttribute<T = any> {
	id: number
	name: string
	type: AttributeType
	unit: string
	method: null | string | number
	display_method: boolean
	reference_attribute_id: null | number
	key: string
	position: number
	default_value: null | string
	attribute_id: number
	formulas: IAttributeFormula[]
	options: T
	note: string | null
}

export interface IAttributeGroup {
	id: number
	attributes: IAttribute<unknown>[]
	name: string
	status: boolean
	show_title: boolean
	position: number
	exam_id: number
}

export interface ISelectedAttributeGroup {
	id: number
	position: number
}

export interface ISelectedAttribute {
	id: number
	position: number
	type: 'attribute' | 'attribute_group'
}

export interface IFormula {
	id: number
	name: string
	description: string
	specie_id: null | number
	formula: string
	created_at: string
	updated_at: string
}

export interface AttributeState {
	attributes: IResponseAttributes[]
	attributeIsLoading: boolean
	attributeIsSaving: boolean
	formulas: IFormula[]
	selectedAttributeGroup: ISelectedAttributeGroup
	selectedAttribute: ISelectedAttribute
	examMappedFields: IResponseMappedExamFields[]
}

export interface IMappedExamField {
	id: string
	organization_id: number
	created_by: string
	exam_id: number
	exam_field_id: string
	interfacing_field: string
	equipment_id: number
	created_at: string
	updated_at: string
}
