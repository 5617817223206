import API from '@/axios'
const Endpoint = '/models'
const EndpointInterfacing = '/interfacing'
const ExamsEndpoint = '/exams'
const AttributesEndpoint = '/attributes'
const FormulasEndpoint = '/formulas'
const AttributeGroupsEndpoint = '/groups'
const MappedExamFields = '/mapped-exam-fields'

import { AxiosResponse } from 'axios'
import {
	IResponseAttributeGroup,
	IResponseAttributes,
	IResponseAttribute,
	IUpdateAttributeGroupParams,
	ICreateAttributeGroupParams,
	ICreateAttributeParams,
	IUpdateAttributeParams,
	ICreateAttributeFormulaParams,
	IUpdateAttributeFormulaParams,
	IUpdateAttributePositionsParams,
	ICreateAttributeMappedFieldParams,
	IUpdateAttributeMappedFieldParams,
} from './types'
import { IMappedExamField } from '@/modules/exams/store/attributes/types'

export default {
	async getAttributes(examId: number): Promise<AxiosResponse<IResponseAttributes[]>> {
		return await API.get(`${Endpoint}${ExamsEndpoint}/${examId}${AttributesEndpoint}`)
	},

	async createAttributeGroup({
		payload,
		examId,
	}: ICreateAttributeGroupParams): Promise<AxiosResponse<IResponseAttributeGroup | boolean>> {
		return await API.post(
			`${Endpoint}${ExamsEndpoint}/${examId}${AttributesEndpoint}${AttributeGroupsEndpoint}`,
			payload
		)
	},

	async updateAttributeGroup({
		payload,
		attributeGroupId,
	}: IUpdateAttributeGroupParams): Promise<AxiosResponse<IResponseAttributeGroup | boolean>> {
		return await API.put(
			`${Endpoint}${AttributesEndpoint}${AttributeGroupsEndpoint}/${attributeGroupId}`,
			payload
		)
	},

	async deleteAttributeGroup(id: number): Promise<AxiosResponse<boolean>> {
		return await API.delete(`${Endpoint}${AttributesEndpoint}${AttributeGroupsEndpoint}/${id}`)
	},

	async createAttribute({
		groupId,
		payload,
	}: ICreateAttributeParams): Promise<AxiosResponse<IResponseAttribute | boolean>> {
		return await API.post(
			`${Endpoint}${AttributesEndpoint}${AttributeGroupsEndpoint}/${groupId}/fields`,
			payload
		)
	},

	async updateAttribute({
		attributeId,
		payload,
	}: IUpdateAttributeParams): Promise<AxiosResponse<IResponseAttribute | boolean>> {
		return await API.put(`${Endpoint}${AttributesEndpoint}/${attributeId}`, payload)
	},

	async deleteAttribute(attributeId: number): Promise<AxiosResponse<boolean>> {
		return await API.delete(`${Endpoint}${AttributesEndpoint}/${attributeId}`)
	},

	async getFormulas() {
		return await API.get(`${Endpoint}${FormulasEndpoint}`)
	},

	async createFormula({ attributeId, payload }: ICreateAttributeFormulaParams) {
		return await API.post(
			`${Endpoint}${AttributesEndpoint}/${attributeId}${FormulasEndpoint}`,
			payload
		)
	},

	async updateFormula({ formulaId, payload }: IUpdateAttributeFormulaParams) {
		return await API.put(`${Endpoint}${FormulasEndpoint}/${formulaId}`, payload)
	},

	async deleteFormula(formulaId: number) {
		return await API.delete(`${Endpoint}${FormulasEndpoint}/${formulaId}`)
	},

	async updateAttributePositions({
		examId,
		payload,
	}: IUpdateAttributePositionsParams): Promise<AxiosResponse<IResponseAttributes[]>> {
		return await API.patch(
			`${Endpoint}${ExamsEndpoint}/${examId}${AttributesEndpoint}/positions`,
			payload
		)
	},

	async createAttributeMappedField({
		examId,
		payload,
	}: ICreateAttributeMappedFieldParams): Promise<AxiosResponse<IMappedExamField | boolean>> {
		return await API.post(
			`${EndpointInterfacing}${ExamsEndpoint}/${examId}${MappedExamFields}`,
			payload
		)
	},

	async updateAttributeMappedField({
		mappedFieldId,
		payload,
	}: IUpdateAttributeMappedFieldParams): Promise<AxiosResponse<IMappedExamField | boolean>> {
		return await API.put(`${EndpointInterfacing}${MappedExamFields}/${mappedFieldId}`, payload)
	},
	async getMappedExamFields(id: number) {
		return await API.get(EndpointInterfacing + '/exams/' + id + '/mapped-exam-fields/all')
	},
}
