/* [[ ESLINT BUG ENUM NOT USED ]] */
/* eslint-disable no-unused-vars */

export enum mutationTypes {
	SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN',
	SET_UPDATE_LOGGED_STATE = 'SET_UPDATE_LOGGED_STATE',

	SET_UPDATE_TOKEN_TIMER_ID = 'SET_UPDATE_TOKEN_TIMER_ID',

	SET_RESET_LOGOUT = 'SET_RESET_LOGOUT',

	SET_PERMISSIONS = 'SET_PERMISSIONS',

	SET_LOGGED_USER = 'SET_LOGGED_USER',
}

export enum actionTypes {
	LOG_IN = 'LOG_IN',

	LOG_IN_BY_GOOGLE = 'LOG_IN_BY_GOOGLE',

	LOG_IN_BY_REFRESH_TOKEN = 'LOG_IN_BY_REFRESH_TOKEN',

	START_TOKEN_UPDATE = 'START_TOKEN_UPDATE',
	STOP_TOKEN_UPDATE = 'STOP_TOKEN_UPDATE',

	GET_PERMISSIONS = 'GET_PERMISSIONS',

	SET_PERMISSIONS_USER = 'SET_PERMISSIONS_USER',
}

export interface IUser {
	id: number
	uuid: string
	nome: string
	apelido: string | null
	assinatura: string | null
	avatar: string
	cpf: string
	email: string
	genero: 'M' | 'F' | 'O'
	nascimento: string | null
	confirmado: boolean
	idioma: string
	ocupacao: string
	atuacao: number
	created_at: string
	updated_at: string
	deleted_at: string | null
	tipo_de_usuario_id: number
	last_login_at: string
	medico: {
		id: number
		usuario_id: number
		registro: number
		uf: string
		medico_tipo_id: number
		mapa: string
		created_at: string
		updated_at: string
	}
	tipo_de_usuario: {
		id: number
		nome: string
	}
	contas: any[]
	interesses: IInterest[]
	default_organization_id: number
}

export interface IGoogleResponse {
	Fa: number
	Oc: {
		token_type: 'Bearer'
		access_token: string
		scope:
			| 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'
			| string
		login_hint: string
		expires_in: number
		id_token: string // JWT Token
		session_state: {
			extraQueryParams: {
				authuser: string
			}
		}
		first_issued_at: number
		expires_at: number
		idpId: 'google'
	}
	Xx: {
		h7: string // ID
		Zf: string // Full Name
		E8: string // First Name
		nU?: string | null // Avatar
		Sy: string // Email
	}
}

export interface IGoogleProfileParseByCluster {
	id: string
	name: string
	fullName: string
	avatar?: null | string
	email: string
}

export interface IInterest {
	id: number
	nome: string
	slug: string
}

export interface IPermissions {
	action: string
	resource: string
}

export interface IAuthPayload {
	user: string
	senha: string
}

export interface IAuthGooglePayload {
	google_id: string
	email: string
	photo: string
}

export interface IToken {
	token_type: 'Bearer'
	expires_in?: number
	access_token?: string
	refresh_token?: string
}

export interface ILogInResponse {
	token: {
		token_type: 'Bearer'
		expires_in: number
		access_token: string
		refresh_token: string
	}
	usuario: IUser
}

export interface ILogInResponseByGoogle {
	token?: {
		token_type: 'Bearer'
		expires_in: number
		access_token: string
		refresh_token: string
	}
	usuario?: IUser
	email: string
	redirect: string
}

export interface AuthenticationState {
	token: IToken
	isLoggedIn: boolean
	loggedUser?: IUser
	permissions: IPermissions[]
	tokenUpdateTimerId?: number
}
