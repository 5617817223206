import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { ExamsGroupState, IExamsGroupPayload, IExamsGroupResponse } from '../types'
import API from '../../service'
import ExamAPI from '@/modules/exams/services'
import { actionTypes, Actions } from './action-types'
import { mutationTypes } from '../mutations/mutation-types'
import { getterTypes } from '../getters/getter-types'
import { STATUS_SUCCESS, STATUS_CREATED } from '@/utils/constants/statusConstants'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IResponseExam } from '@/modules/exams/services/types'
import { IExamFilters } from '@/modules/exams/store/types'
import { ITypeSuccessfully as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export const actions: ActionTree<ExamsGroupState, RootState> & Actions = {
	async [actionTypes.GET_EXAMS_GROUPS]({
		state,
		commit,
	}): Promise<ITypeOrmPaginationTemplate<IExamsGroupResponse> | boolean> {
		commit(mutationTypes.SET_LOADING_EXAMS_GROUPS, ENABLE_LOADING)

		const { status, data: responseOrmPagination } = await API.getExamsGroups({
			page: state.examsGroups.meta.page,
			limit: state.examsGroups.meta.limit,
			search: state.examsGroups.meta.search,
		})

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.ADD_META_PAGE_EXAMS_GROUPS, responseOrmPagination)
		}

		commit(mutationTypes.SET_LOADING_EXAMS_GROUPS, DISABLE_LOADING)

		return status === STATUS_SUCCESS ? responseOrmPagination : false
	},

	async [actionTypes.GET_EXAMS_GROUPS_BY_SEARCH](
		{ state, commit },
		search
	): Promise<ITypeOrmPaginationTemplate<IExamsGroupResponse> | boolean> {
		commit(mutationTypes.SET_LOADING_EXAMS_GROUPS, ENABLE_LOADING)

		commit(mutationTypes.SET_SEARCH_EXAMS_GROUPS, { page: 1, search: search })

		const { status, data: responseOrmPagination } = await API.getExamsGroups({
			page: state.examsGroups.meta.page,
			limit: state.examsGroups.meta.limit,
			search: state.examsGroups.meta.search,
		})

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.SET_META_PAGE_EXAMS_GROUPS, responseOrmPagination)
		}

		commit(mutationTypes.SET_LOADING_EXAMS_GROUPS, DISABLE_LOADING)

		return status === STATUS_SUCCESS ? responseOrmPagination : false
	},

	async [actionTypes.CREATE_EXAMS_GROUP](
		{ state, getters, commit },
		payload: IExamsGroupPayload
	): Promise<IExamsGroupResponse | boolean> {
		const { status, data: examsGroup } = await API.createExamsGroup(payload)

		if (status === STATUS_CREATED) {
			const indexCurrentPage = getters[getterTypes.GET_INDEX_CURRENT_PAGE](
				state.examsGroups.meta.page
			)

			if (indexCurrentPage > -1) {
				commit(mutationTypes.ADD_EXAMS_GROUP, {
					indexCurrentPage: indexCurrentPage,
					examsGroup: examsGroup,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.examsGroup.name'),
						description: i18n.t(
							'modules.examsGroup.feedback.success.create.examsGroup'
						),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_CREATED ? examsGroup : false
	},

	async [actionTypes.UPDATE_EXAMS_GROUP](
		{ getters, commit },
		{ examsGroupId, payload }: { examsGroupId: number; payload: IExamsGroupPayload }
	): Promise<IExamsGroupResponse | boolean> {
		const { status, data: examsGroup } = await API.updateExamsGroup({
			examsGroupId: examsGroupId,
			payload: payload,
		})

		if (status === STATUS_SUCCESS) {
			const { indexCurrentPage, indexExamsGroup } =
				getters[getterTypes.GET_INDEX_EXAM_GROUP_REACTIVITY](examsGroupId)

			if (indexCurrentPage > -1 && indexExamsGroup > -1) {
				commit(mutationTypes.UPDATE_EXAMS_GROUP, {
					indexCurrentPage: indexCurrentPage,
					indexExamsGroup: indexExamsGroup,
					examsGroup: examsGroup,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.examsGroup.name'),
						description: i18n.t(
							'modules.examsGroup.feedback.success.update.examsGroup'
						),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS ? examsGroup : false
	},

	async [actionTypes.REMOVE_EXAMS_GROUP](
		{ getters, commit },
		examsGroupId: number
	): Promise<boolean> {
		const { status } = await API.removeExamsGroup(examsGroupId)

		if (status === STATUS_SUCCESS) {
			const { indexCurrentPage, indexExamsGroup } =
				getters[getterTypes.GET_INDEX_EXAM_GROUP_REACTIVITY](examsGroupId)

			if (indexCurrentPage > -1 && indexExamsGroup > -1) {
				commit(mutationTypes.REMOVE_EXAMS_GROUP, {
					indexCurrentPage: indexCurrentPage,
					indexExamsGroup: indexExamsGroup,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.examsGroup.name'),
						description: i18n.t(
							'modules.examsGroup.feedback.success.remove.examsGroup'
						),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS
	},

	async [actionTypes.GET_EXAMS](
		{ commit },
		filters: IExamFilters
	): Promise<ITypeOrmPaginationTemplate<IResponseExam> | boolean> {
		commit(mutationTypes.UPDATE_LOADING_EXAMS, ENABLE_LOADING)

		const { status, data: exams } = await ExamAPI.getExams(filters)

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.SET_EXAMS, exams)
		}

		commit(mutationTypes.UPDATE_LOADING_EXAMS, DISABLE_LOADING)

		return status === STATUS_SUCCESS ? exams : false
	},

	async [actionTypes.UNLINK_EXAM](
		{ getters, commit },
		{ examsGroupId, examId }
	): Promise<IExamsGroupResponse | boolean> {
		const { status, data: examsGroup } = await API.unlinkExam({ examsGroupId, examId })

		if (status === STATUS_SUCCESS) {
			const { indexCurrentPage, indexExamsGroup } =
				getters[getterTypes.GET_INDEX_EXAM_GROUP_REACTIVITY](examsGroupId)

			if (indexCurrentPage > -1 && indexExamsGroup > -1) {
				commit(mutationTypes.UPDATE_EXAMS_GROUP, {
					indexCurrentPage: indexCurrentPage,
					indexExamsGroup: indexExamsGroup,
					examsGroup: examsGroup,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.examsGroup.name'),
						description: i18n.t(
							'modules.examsGroup.feedback.success.remove.unlinkExam'
						),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS ? examsGroup : false
	},

	async [actionTypes.CHANGE_STATUS_EXAMS_GROUP](
		{ getters, commit },
		{ examsGroupId, status }
	): Promise<IExamsGroupResponse | boolean> {
		const { status: statusResponse, data: examsGroup } = await API.changeStatusExamsGroup({
			examsGroupId,
			status,
		})

		if (statusResponse === STATUS_SUCCESS) {
			const { indexCurrentPage, indexExamsGroup } =
				getters[getterTypes.GET_INDEX_EXAM_GROUP_REACTIVITY](examsGroupId)

			if (indexCurrentPage > -1 && indexExamsGroup > -1) {
				commit(mutationTypes.UPDATE_EXAMS_GROUP, {
					indexCurrentPage: indexCurrentPage,
					indexExamsGroup: indexExamsGroup,
					examsGroup: examsGroup,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.examsGroup.name'),
						description: i18n.t(
							'modules.examsGroup.feedback.success.update.examsGroupStatus'
						),
					},
					{ root: true }
				)
			}
		}

		return statusResponse === STATUS_SUCCESS ? examsGroup : false
	},
}

export default { actions }
