import API from '@/axios'
const Endpoint = '/animals/v1/'
const SpeciesEndpoint = 'species/'

export default {
	async getEspecies() {
		return await API.get(Endpoint + SpeciesEndpoint).then((response) => response.data)
	},

	async createSpecie(payload) {
		return await API.post(Endpoint + SpeciesEndpoint, payload).then((response) => response.data)
	},

	async updateSpecie(payload, id) {
		return await API.put(Endpoint + SpeciesEndpoint + id, payload).then(
			(response) => response.data
		)
	},

	async deleteSpecie(id) {
		return await API.delete(Endpoint + SpeciesEndpoint + id).then((response) => response)
	},
}
