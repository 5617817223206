const Login = () => import('@/modules/authentication/components/organism/Login.vue')
const Registro = () => import('./views/Registro')
const RecuperarSenha = () => import('./views/RecuperarSenha')
const ConfirmarToken = () => import('./views/ConfirmarToken')
const NovaSenha = () => import('./views/NovaSenha')
const Finalizado = () => import('./views/Finalizado')
const Setup = () => import('./views/SetupInicial')
const SobreVoce = () => import('./views/setup/SobreVoce')
const SeuTrabalho = () => import('./views/setup/SeuTrabalho')
const Owner = () => import('./views/setup/Owner')
const Medico = () => import('./views/setup/Medico')
const ConfirmarCadastro = () => import('./views/ConfirmarCadastro')
const ConfirmationInvite = () => import('../usuarios/components/Invites/ConfirmationInvite.vue')
const Convite = () => import('./views/setup/Convite')
const importCliente = () => import('./views/importCliente.vue')
const ConfirmationRecoveryEmail = () => import('./components/ConfirmationRecoveryEmail')

const configMeta = {
	register: true,
}

const routes = [
	{ path: '/login', component: Login, name: 'auth', meta: configMeta },
	{ path: '/registro', component: Registro, name: 'experimente', meta: configMeta },
	{
		path: '/forgotpassword/',
		component: RecuperarSenha,
		name: 'auth.recovery',
		meta: configMeta,
	},
	{
		path: '/forgotpassword/email',
		component: ConfirmationRecoveryEmail,
		name: 'auth.recovery.email',
		meta: configMeta,
		beforeEnter(to, from, next) {
			const regex = /\S+@\S+\.\S+/

			if (to.query.email && regex.test(to.query.email)) {
				return next()
			}

			return next({ name: 'auth.recovery' })
		},
	},
	{
		path: '/confirmar-email',
		component: ConfirmarToken,
		name: 'auth.confirmar',
		meta: configMeta,
	},
	{
		path: '/redefinir-senha/:token',
		component: NovaSenha,
		name: 'auth.novasenha',
		meta: configMeta,
	},
	{ path: '/finalizado', component: Finalizado, name: 'finalizado', meta: configMeta },
	{
		path: '/confirmar-cadastro/:token',
		component: ConfirmarCadastro,
		name: 'confirmar.cadastro',
		meta: configMeta,
	},
	{
		path: '/confirm-invite/ooid=:ooid',
		component: ConfirmationInvite,
		name: 'confirm.invite',
	},
	{ path: '/import', component: importCliente, name: 'teste', meta: configMeta },
	{
		path: '/setup',
		component: Setup /* redirect: { name: 'setup.nome' } , */,
		children: [
			{
				path: '/setup/sobre-voce',
				component: SobreVoce,
				name: 'setup.nome',
				meta: configMeta,
			},
			{
				path: '/setup/o-que-voce-faz',
				component: SeuTrabalho,
				name: 'setup.trabalho',
				meta: configMeta,
			},
			{
				path: '/setup/configurar-ambiente',
				component: Owner,
				name: 'setup.owner',
				meta: configMeta,
			},
			{
				path: '/setup/configurar-medico',
				component: Medico,
				name: 'setup.medico',
				meta: configMeta,
			},
			{
				path: '/setup/convidar-membros',
				component: Convite,
				name: 'setup.convite',
				meta: configMeta,
			},
		],
	},
]

export default routes
