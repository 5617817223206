import { RootState } from '@/store/types'
import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'
import { Actions } from './actions/action-types'
import { actions } from './actions/actions'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { mutations } from './mutations/mutations'
import { AnimalState } from './types'

export const state: AnimalState = {
	animals: {
		data: [],
		meta: {
			filters: {
				page: 1,
				limit: 8,
				search: undefined,
				orderBy: undefined,
				orderDirection: undefined,
				speciesId: undefined,
				breedId: undefined,
				customerId: undefined,
				hasRequests: undefined,
				hasRelationships: undefined,
				sex: undefined,
			},
			loading: false,
		},
	},
	openModalCreateAnimal: false,
	openModalUpdateAnimal: false,
	openStakeholderSelection: false,
	animalSelected: undefined,
	actorsSelected: [],
	isFiltersNotSaved: false,
}

export type Store<S = AnimalState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}

export const ANIMAL_NAMESPACE = 'Animals'

export const animal: Module<AnimalState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: actions,
	mutations: mutations,
}
