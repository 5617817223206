export default class ErrorService {
	constructor() {}

	static onAPI(error) {
		const response = error.response

		console.log(response)
	}

	static onSyntax(error, vm, info) {
		console.log(error, vm, info)
	}

	static onStore(error, vm, info) {
		console.log(error, vm, info)
	}

	static initHandler() {
		const scope = this

		window.onerror = (message, url, line, col, error) => {
			console.log(error)
		}
	}

	static displayErrorAlert(message) {
		console.log(message)
	}
}
