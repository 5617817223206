import Vue from 'vue'
import store from '../../store/store'
import { actionTypes as actionTypesOfAuth } from '@/modules/authentication/store/types'

import { AbilityBuilder, Ability } from '@casl/ability'

export async function updateAbility() {
	const { can, cannot, rules } = new AbilityBuilder(Ability)
	const organization = store.state.Organizacao.organizacao

	let isOwned = false

	if (organization && organization.vinculo) {
		isOwned = organization.vinculo.proprietario
	}

	if (isOwned) {
		can('create', 'all')
		can('read', 'all')
		can('update', 'all')
		can('delete', 'all')
	} else {
		const permissionsExists = await store.dispatch(`Auth/${actionTypesOfAuth.GET_PERMISSIONS}`)

		if (permissionsExists) {
			const { permissions } = store.state.Auth

			permissions.forEach((permission) => {
				const { resource, resource_id, permissions: scopes } = permission

				scopes.forEach((scope) => {
					if (scope.effect === 'allow') {
						can(scope.action, resource, `${resource_id}`)
					} else if (scope.effect === 'deny') {
						cannot(scope.action, resource, `${resource_id}`)
					} else {
						alert('Wrong permission effect.')
					}
				})
			})
		}
	}

	Vue.prototype.$ability.update(rules)
}
