/* eslint-disable @typescript-eslint/no-explicit-any */
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { RootState } from '@/store/types'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ActionTree } from 'vuex'
import API from '../service'
import {
	CustomersState,
	ICustomer,
	ICustomerFilters,
	ICustomerFiltersBelongsTo,
	actionTypes,
	mutationTypes,
} from './types'
import { ORDER_NAMESPACE } from '@/modules/orders/store'
import { actionTypes as actionTypesOrdersOfCustomers } from '@/modules/orders/store/actions/customers/action-types'
import { actionTypes as actionTypesReceivingOfCustomers } from '@/modules/receiving/store/actions/customers/action-types'
import { IAnimalResponse } from '@/modules/animals/store/types'
import { ANIMAL_NAMESPACE } from '@/modules/animals/store'
import { actionTypes as actionTypesOfAnimals } from '@/modules/animals/store/actions/action-types'
import { RECEIVING_NAMESPACE } from '@/modules/receiving/store'

export const actions: ActionTree<CustomersState, RootState> = {
	async [actionTypes.GET_CUSTOMERS](
		{ commit, state, getters },
		{
			resetPage = false,
			filters,
			isMergeArray,
		}: { resetPage?: boolean; filters?: ICustomerFilters; isMergeArray?: boolean } = {}
	): Promise<ITypeOrmPaginationTemplate<ICustomer>> {
		try {
			commit(mutationTypes.SET_CUSTOMER_LOADING, true)

			if (resetPage) {
				commit(mutationTypes.SET_FILTERS, { ...state.filters, page: 1 })
			}

			const { data } = await API.getCustomers(
				filters || getters.getFormattedFiltersWithoutVoids
			)

			if (isMergeArray) {
				commit(mutationTypes.SET_MERGE_CUSTOMERS, data)
			} else {
				commit(mutationTypes.SET_CUSTOMERS, data)
			}

			commit(mutationTypes.SET_CUSTOMER_LOADING, false)

			return data
		} catch (error: any) {
			commit(mutationTypes.SET_CUSTOMER_LOADING, false)
			throw error
		}
	},

	async [actionTypes.GET_CUSTOMERS_BELONGS_TO](
		{ commit },
		{
			filters,
			isSetActorsSelected = true,
		}: { filters: ICustomerFiltersBelongsTo; isSetActorsSelected?: boolean }
	): Promise<ITypeOrmPaginationTemplate<ICustomer>> {
		try {
			const { data: typeormPagination } = await API.getCustomers(filters)

			if (isSetActorsSelected) {
				commit(mutationTypes.SET_ACTORS_SELECTED, typeormPagination.data)
			}

			return typeormPagination
		} catch (error: any) {
			console.error(error)
			throw error
		}
	},

	async [actionTypes.GET_CUSTOMER]({ commit }, uuid: string): Promise<ICustomer> {
		const { data } = await API.getCustomer(uuid)

		commit(mutationTypes.SET_SELECTED_CUSTOMER, data)

		return data
	},

	async [actionTypes.CREATE_CUSTOMER]({ commit }, payload: any): Promise<ICustomer> {
		const { data } = await API.createCustomer(payload)

		commit(mutationTypes.SET_SELECTED_CUSTOMER, data)
		commit(mutationTypes.ADD_CUSTOMER, data)

		commit(
			`Successes/${successTypes.ADD_SUCCESS}`,
			{ title: i18n.t('modules.customers.feedback.success.create.client') },
			{ root: true }
		)

		return data
	},

	async [actionTypes.UPDATE_CUSTOMER](
		{ commit, getters },
		{ payload, uuid }: any
	): Promise<ICustomer> {
		const { data } = await API.updateCustomer(payload, uuid)

		const index = getters.getCustomerIndexByUuid(uuid)

		commit(mutationTypes.UPDATE_CUSTOMER, { index, payload: data })

		commit(
			`Successes/${successTypes.ADD_SUCCESS}`,
			{ title: i18n.t('modules.customers.feedback.success.update.client') },
			{ root: true }
		)

		return data
	},

	async [actionTypes.UPDATE_CUSTOMER_TYPE](
		{ commit, getters },
		customer: ICustomer
	): Promise<void> {
		const indexCustomer = getters.getCustomerIndexByUuid(customer.uuid)

		if (indexCustomer > -1) {
			commit(mutationTypes.UPDATE_CUSTOMER, { index: indexCustomer, payload: customer })
		}
	},

	async [actionTypes.DELETE_CUSTOMER]({ commit, getters }, uuid: string): Promise<void> {
		await API.deleteCustomer(uuid)

		const index = getters.getCustomerIndexByUuid(uuid)

		commit(mutationTypes.DELETE_CUSTOMER, index)

		commit(
			`Successes/${successTypes.ADD_SUCCESS}`,
			{ title: i18n.t('modules.customers.feedback.success.remove.client') },
			{ root: true }
		)
	},

	async [actionTypes.RESTORE_CUSTOMER]({ commit, getters }, uuid: string): Promise<void> {
		const { status } = await API.restoreCustomer(uuid)

		if (status === STATUS_SUCCESS) {
			const indexCustomer = getters.getCustomerIndexByUuid(uuid)

			if (indexCustomer > -1) {
				commit(mutationTypes.DELETE_CUSTOMER, indexCustomer)
			}

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.customers.feedback.success.remove.restore') },
				{ root: true }
			)
		}
	},

	[actionTypes.SET_RELATIONSHIPS_CUSTOMER](
		{ getters, commit },
		{
			customerActorUuid,
			customerCreated,
		}: { customerActorUuid: string; customerCreated: ICustomer }
	): void {
		const indexCustomer = getters.getCustomerIndexByUuid(customerActorUuid)

		if (indexCustomer > -1) {
			const customer = getters.getCustomerByUuid(customerActorUuid)

			let relationship = []

			if (customer?.relationship) {
				relationship = [...customer.relationship, { ...customerCreated }]
			} else {
				relationship = [{ ...customerCreated }]
			}

			commit(mutationTypes.SET_RELATIONSHIPS_CUSTOMER, { indexCustomer, relationship })
		}
	},

	[actionTypes.SET_ANIMALS_CUSTOMER](
		{ getters, commit },
		{
			customerActorUuid,
			animalCreated,
		}: { customerActorUuid: string; animalCreated: IAnimalResponse }
	): void {
		const indexCustomer = getters.getCustomerIndexByUuid(customerActorUuid)

		if (indexCustomer > -1) {
			const customer = getters.getCustomerByUuid(customerActorUuid)

			let animals = []

			if (customer?.animals) {
				animals = [...customer.animals, { ...animalCreated }]
			} else {
				animals = [{ ...animalCreated }]
			}

			commit(mutationTypes.SET_ANIMALS_CUSTOMER, { indexCustomer, animals })
		}
	},

	async [actionTypes.GET_RELATIONSHIPS_CUSTOMER](
		{ dispatch },
		{ customer, filters }
	): Promise<void> {
		const responseCustomersBelongsTo = await dispatch(actionTypes.GET_CUSTOMERS_BELONGS_TO, {
			filters,
		})

		if (responseCustomersBelongsTo) {
			await dispatch(actionTypes.SET_RELATIONSHIPS_CUSTOMER, {
				customerActorUuid: customer.uuid,
				customerCreated: responseCustomersBelongsTo.data,
			})
		}

		const responseAnimalsByPetOwner = await dispatch(
			`${ANIMAL_NAMESPACE}/${actionTypesOfAnimals.GET_ANIMALS_BY_PET_OWNER}`,
			{
				page: 1,
				limit: 20,
				customerId: customer.id,
			},
			{ root: true }
		)

		if (responseAnimalsByPetOwner && typeof responseAnimalsByPetOwner !== 'boolean') {
			await dispatch(actionTypes.SET_ANIMALS_CUSTOMER, {
				customerActorUuid: customer.uuid,
				animalCreated: responseAnimalsByPetOwner.data,
			})
		}
	},

	[actionTypes.SET_RELATIONSHIPS_MODULES_GLOBAL](
		{ dispatch },
		{
			customerActorUuid,
			customerCreated,
		}: { customerActorUuid: string; customerCreated: ICustomer }
	): void {
		dispatch(actionTypes.SET_RELATIONSHIPS_CUSTOMER, { customerActorUuid, customerCreated })

		dispatch(
			`${ORDER_NAMESPACE}/${actionTypesOrdersOfCustomers.SET_RELATIONSHIPS_CUSTOMER}`,
			{ customerActorUuid, customerCreated },
			{ root: true }
		)

		dispatch(
			`${RECEIVING_NAMESPACE}/${actionTypesReceivingOfCustomers.SET_RELATIONSHIPS_CUSTOMER}`,
			{ customerActorUuid, customerCreated },
			{ root: true }
		)
	},

	[actionTypes.SET_ANIMALS_CUSTOMER_MODULES_GLOBAL](
		{ dispatch },
		{
			customerActorUuid,
			animalCreated,
		}: { customerActorUuid: string; animalCreated: IAnimalResponse }
	): void {
		dispatch(actionTypes.SET_ANIMALS_CUSTOMER, { customerActorUuid, animalCreated })

		dispatch(
			`${ORDER_NAMESPACE}/${actionTypesOrdersOfCustomers.SET_ANIMALS_CUSTOMER}`,
			{ customerActorUuid, animalCreated },
			{ root: true }
		)

		dispatch(
			`${RECEIVING_NAMESPACE}/${actionTypesReceivingOfCustomers.SET_ANIMALS_CUSTOMER}`,
			{ customerActorUuid, animalCreated },
			{ root: true }
		)
	},

	async [actionTypes.GET_RELATIONSHIPS_CUSTOMER_MODULES_GLOBAL](
		{ dispatch },
		{
			stakeholderModule = 'Customers',
			customer,
			filters,
		}: {
			stakeholderModule?: 'Customers' | 'Orders' | 'Request' | 'Exam'
			customer: ICustomer
			filters: ICustomerFiltersBelongsTo
		}
	): Promise<void | boolean> {
		switch (stakeholderModule) {
			case 'Customers':
				await dispatch(actionTypes.GET_RELATIONSHIPS_CUSTOMER, { customer, filters })
				break
			case 'Orders':
				await dispatch(
					`${ORDER_NAMESPACE}/${actionTypesOrdersOfCustomers.GET_RELATIONSHIPS_CUSTOMER}`,
					{ customer, filters },
					{ root: true }
				)
				break
			case 'Request':
				await dispatch(
					`${RECEIVING_NAMESPACE}/${actionTypesReceivingOfCustomers.GET_RELATIONSHIPS_CUSTOMER}`,
					{ customer, filters },
					{ root: true }
				)
				break
			case 'Exam':
				break
		}
	},

	async [actionTypes.UPDATE_CUSTOMER_TYPE_MODULES_GLOBAL](
		{ dispatch, commit },
		{ uuid, typeId }: { uuid: string; typeId: number }
	): Promise<ICustomer | boolean> {
		const { status, data: customer } = await API.updateCustomerType({ uuid, typeId })

		if (status === STATUS_SUCCESS) {
			dispatch(actionTypes.UPDATE_CUSTOMER_TYPE, customer)

			dispatch(
				`${ORDER_NAMESPACE}/${actionTypesOrdersOfCustomers.UPDATE_CUSTOMER_TYPE}`,
				customer,
				{ root: true }
			)

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.customers.feedback.success.update.client') },
				{ root: true }
			)
		}

		return status === STATUS_SUCCESS ? customer : false
	},
}

export default actions
