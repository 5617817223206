




























import {
	Component,
	InjectReactive,
	Mixins,
	Prop,
	ProvideReactive,
	Watch,
} from 'vue-property-decorator'
import ExamAttributeChildrenGroup from './ExamAttributeChildrenGroup.vue'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import { IAttribute, IAttributeGroup } from '@/modules/exams/store/attributes/types'
import CreateAttributeAndAttributeGroup from '@/modules/exams/mixins/CreateAttributeAndAttributeGroupMixin'
import { AttributeType } from '@/utils/constants/examConstants'

@Component({
	name: 'ExamAttributeThree',
	components: {
		ExamAttributeChildrenGroup,
		ButtonIcon,
	},
	mixins: [CreateAttributeAndAttributeGroup],
})
export default class ExamAttributeThree extends Mixins(CreateAttributeAndAttributeGroup) {
	@Prop({ type: Object, default: null }) attribute?: IAttribute<unknown>
	@Prop({ type: Object, required: true }) attributeGroup!: IAttributeGroup
	@Prop({ type: Boolean, default: false }) firstIndex?: boolean
	@Prop({ type: Boolean, default: false }) isAction?: boolean
	@Prop({ type: Boolean, default: false }) detailsAttributeMode!: boolean

	@InjectReactive('simpleAttributeMode') simpleAttributeMode!: boolean

	@ProvideReactive('attribute') attributeProvider?: IAttribute<unknown> | null = null
	@ProvideReactive('attributeGroup') attributeGroupProvider?: IAttributeGroup | null = null

	get defaultAttributeType(): string {
		return AttributeType.LONG_TEXT_TYPE
	}

	@Watch('attribute', { immediate: true, deep: true }) onAttributeChanged(): void {
		this.attributeProvider = this.attribute
	}

	@Watch('attributeGroup', { immediate: true, deep: true }) onAttributeGroupChanged(): void {
		this.attributeGroupProvider = this.attributeGroup
	}
}
