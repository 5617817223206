import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ExamsGroupState, IExamRelationShipExamsGroup, IExamsGroupResponse } from '../types'
import { IResponseExam } from '@/modules/exams/services/types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_MODAL_CREATE_EXAMS_GROUP = 'SET_MODAL_CREATE_EXAMS_GROUP',
	SET_MODAL_UPDATE_EXAMS_GROUP = 'SET_MODAL_UPDATE_EXAMS_GROUP',

	ADD_META_PAGE_EXAMS_GROUPS = 'ADD_META_PAGE_EXAMS_GROUPS',
	SET_META_PAGE_EXAMS_GROUPS = 'SET_META_PAGE_EXAMS_GROUPS',
	SET_LOADING_EXAMS_GROUPS = 'SET_LOADING_EXAMS_GROUPS',
	SET_SEARCH_EXAMS_GROUPS = 'SET_SEARCH_EXAMS_GROUPS',

	SET_EXAMS = 'SET_EXAMS',
	SET_EXAMS_LOADED = 'SET_EXAMS_LOADED',

	RESET_EXAMS = 'RESET_EXAMS',
	UPDATE_LOADING_EXAMS = 'UPDATE_LOADING_EXAMS',

	RESET_EXAMS_GROUPS = 'RESET_EXAMS_GROUPS',

	UPDATE_PAGE_EXAMS_GROUPS = 'UPDATE_PAGE_EXAMS_GROUPS',

	SET_EXAMS_GROUP_SELECTED = 'SET_EXAMS_GROUP_SELECTED',

	ADD_EXAMS_GROUP = 'ADD_EXAMS_GROUP',
	UPDATE_EXAMS_GROUP = 'UPDATE_EXAMS_GROUP',
	REMOVE_EXAMS_GROUP = 'REMOVE_EXAMS_GROUP',
}

export type Mutations<S = ExamsGroupState> = {
	[mutationTypes.SET_LOADING_EXAMS_GROUPS](state: S, loadingState: boolean): void

	[mutationTypes.ADD_META_PAGE_EXAMS_GROUPS](
		state: S,
		response: ITypeOrmPaginationTemplate<IExamsGroupResponse>
	): void

	[mutationTypes.SET_META_PAGE_EXAMS_GROUPS](
		state: S,
		response: ITypeOrmPaginationTemplate<IExamsGroupResponse>
	): void

	[mutationTypes.UPDATE_PAGE_EXAMS_GROUPS](state: S, page: number): void

	[mutationTypes.SET_MODAL_CREATE_EXAMS_GROUP](state: S, modalState: boolean): void

	[mutationTypes.SET_MODAL_UPDATE_EXAMS_GROUP](state: S, modalState: boolean): void

	[mutationTypes.ADD_EXAMS_GROUP](
		state: S,
		{
			indexCurrentPage,
			examsGroup,
		}: { indexCurrentPage: number; examsGroup: IExamsGroupResponse }
	): void

	[mutationTypes.UPDATE_EXAMS_GROUP](
		state: S,
		{
			indexCurrentPage,
			indexExamsGroup,
			examsGroup,
		}: { indexCurrentPage: number; indexExamsGroup: number; examsGroup: IExamsGroupResponse }
	): void

	[mutationTypes.REMOVE_EXAMS_GROUP](
		state: S,
		{ indexCurrentPage, indexExamsGroup }: { indexCurrentPage: number; indexExamsGroup: number }
	): void

	[mutationTypes.SET_EXAMS](state: S, exams: ITypeOrmPaginationTemplate<IResponseExam>): void

	[mutationTypes.RESET_EXAMS](state: S): void

	[mutationTypes.UPDATE_LOADING_EXAMS](state: S, loading: boolean): void

	[mutationTypes.SET_EXAMS_GROUP_SELECTED](state: S, examsGroup: IExamsGroupResponse): void

	[mutationTypes.SET_EXAMS_LOADED](state: S, exams: IExamRelationShipExamsGroup[]): void

	[mutationTypes.SET_SEARCH_EXAMS_GROUPS](
		state: S,
		{ search, page }: { search?: string; page?: number }
	): void

	[mutationTypes.RESET_EXAMS_GROUPS](state: S): void
}
