import API from '@/axios'
import ErrorService from '@/error'
const Endpoint = '/animals/v1/'
const AnimalsEndpoint = 'animals/'
const EndpointRotulo = '/rotulos/'
const History = '/history/'

export default {
	async getAnimais() {
		return await API.get(Endpoint + AnimalsEndpoint).then((response) => response.data)
	},

	async visualizarAnimal(id) {
		return await API.get(Endpoint + AnimalsEndpoint + id).then((response) => response.data)
	},

	async visualizeHistory(id) {
		return await API.get(Endpoint + AnimalsEndpoint + id + History).then(
			(response) => response.data
		)
	},

	async criarAnimal(payload) {
		try {
			return await API.post(Endpoint + AnimalsEndpoint, payload, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async editarAnimal(id, payload) {
		try {
			return await API.put(Endpoint + AnimalsEndpoint + id, payload, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async excluirAnimal(id) {
		return await API.delete(Endpoint + AnimalsEndpoint + id).then((response) => response.data)
	},

	async gerarAUID() {
		return await API.get(Endpoint + AnimalsEndpoint + '/gerar-auid').then(
			(response) => response
		)
	},

	async selectTag(id, idAnimal) {
		return await API.post(EndpointRotulo + id + '/animais', { id: idAnimal }).then(
			(response) => response.data
		)
	},

	async deselectTag(id, idAnimal) {
		return await API.delete(EndpointRotulo + id + '/animais', { data: { id: idAnimal } }).then(
			(response) => response.data
		)
	},
}
