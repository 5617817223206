import API from '@/axios'
const EndpointExam = '/models'
const EndpointMethod = '/methods/'

export default {
	async getMethods() {
		return await API.get(EndpointExam + EndpointMethod).then((response) => response)
	},

	async createMethod(payload) {
		return await API.post(EndpointExam + EndpointMethod, payload).then((response) => response)
	},

	async updateMethod(id, payload) {
		return await API.put(EndpointExam + EndpointMethod + id, payload).then(
			(response) => response
		)
	},

	async deleteMethod(id) {
		return await API.delete(EndpointExam + EndpointMethod + id).then((response) => response)
	},
}
