import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { RootState } from '@/store/types'
import { ActionTree } from 'vuex'
import API from '../../service'
import { getterTypes } from '../getters/getter-types'
import { mutationTypes } from '../mutations/mutation-types'
import { Actions, actionTypes as types } from './action-types'
import { ReceivingState } from '../types'
import { EXAM_CANCELED_STATUS_ID, EXAM_SENT_STATUS_ID } from '@/utils/constants/requestConstants'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'

export const actions: ActionTree<ReceivingState, RootState> & Actions = {
	// Requests
	async [types.CREATE_REQUEST]({ commit }, payload) {
		try {
			const response = await API.createRequest(payload)

			if (response) {
				commit(mutationTypes.RESET_SELECTED)
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.requestCreated') },
					{ root: true }
				)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_REQUEST](
		{ commit, getters, rootGetters },
		{ payload, setSelectedRequest = true }
	) {
		try {
			const id = payload.id

			delete payload[id]
			const response = await API.updateRequest(payload.id, payload)

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.receiving.alerts.success.requestUpdated') },
				{ root: true }
			)

			const receivingRequestIndex = getters[getterTypes.GET_INDEX_REQUEST](id)
			const requestIndex = rootGetters['Request/getIndex'](id)

			commit(mutationTypes.UPDATE_REQUESTS, {
				index: receivingRequestIndex,
				request: response,
			})
			commit(
				'Request/updateRequest',
				{ index: requestIndex, payload: response },
				{ root: true }
			)

			if (response && setSelectedRequest) {
				commit(mutationTypes.SET_SELECTED_REQUEST, response)
			}

			return response ? response : false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_REQUEST_STATUS]({ commit }, payload) {
		try {
			const id = payload.id

			delete payload[id]
			const response = await API.updateRequestStatus(payload.id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.requestUpdated') },
					{ root: true }
				)

				commit(mutationTypes.SET_SELECTED_REQUEST, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.SET_SELECTED_REQUEST]({ commit }, payload) {
		const request = Object.assign({}, payload)

		commit(mutationTypes.SET_SELECTED_REQUEST, request)
	},

	async [types.VISUALIZE_REQUEST]({ commit }, payload) {
		try {
			commit(mutationTypes.RESET_SELECTED)
			commit(mutationTypes.SET_LOADING_RECEIVING_SUBHEADER, ENABLE_LOADING)
			const request_id = payload.id
			const response = await API.getRequest(request_id)

			if (response) {
				commit(mutationTypes.SET_SELECTED_REQUEST, response)
				commit(mutationTypes.SET_SELECTED_PATIENT, response.pacient)
				commit(mutationTypes.SET_SELECTED_DOCTOR, response.veterinarian)
				commit(mutationTypes.SET_LOADING_RECEIVING_SUBHEADER, DISABLE_LOADING)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_REQUESTS]({ commit }, page) {
		try {
			const response = await API.getRequests(page)

			if (response) {
				commit(mutationTypes.SET_REQUESTS, response)
			}
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.SEARCH_REQUESTS]({ commit, getters }, { filters, reset, store = true }) {
		try {
			const response = await API.searchRequests({
				...getters[getterTypes.GET_REQUEST_FILTERS_WITHOUT_NULL],
				...filters,
			})

			if (response) {
				if (reset) {
					commit(mutationTypes.RESET_REQUESTS)
				}

				if (store) {
					commit(mutationTypes.SET_REQUESTS, response)
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_REQUEST_STATUS_LIST]({ state, commit }) {
		try {
			const response = await API.getRequestStatusList()

			if (response) {
				commit(mutationTypes.SET_REQUEST_STATUS, response)
			}

			return state.requestStatus
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_OBSERVATIONS]({ state, commit }) {
		try {
			const request_id = state.selectedRequest.id
			const response = await API.getObservations(request_id)

			if (response) {
				commit(mutationTypes.SET_OBSERVATIONS, response)
			}

			return state.observations
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	// Actors
	async [types.SET_SELECTED_CLINIC]({ commit }, payload) {
		const clinic = Object.assign({}, payload)

		commit(mutationTypes.SET_SELECTED_CLINIC, clinic)
	},

	async [types.SET_SELECTED_DOCTOR]({ commit }, payload) {
		const doctor = Object.assign({}, payload)

		commit(mutationTypes.SET_SELECTED_DOCTOR, doctor)
	},

	async [types.SET_SELECTED_PATIENT]({ commit }, payload) {
		const patient = Object.assign({}, payload)

		commit(mutationTypes.SET_SELECTED_PATIENT, patient)
	},

	async [types.CREATE_OBSERVATION]({ state, commit }, { text }) {
		try {
			const request_id = state.selectedRequest.id
			const observation = await API.createObservation(request_id, text)

			if (observation) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.observationCreated') },
					{ root: true }
				)
				commit(mutationTypes.ADD_OBSERVATION, observation)
			}

			return observation
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_OBSERVATION]({ commit, getters }, payload) {
		try {
			const id = payload.id
			const text = payload.text
			const observation = await API.updateObservation(text, id)

			if (observation) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.observationUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_OBSERVATION](id)

				commit(mutationTypes.UPDATE_OBSERVATION, { index, observation })
			}

			return observation
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.DELETE_OBSERVATION]({ commit, getters }, id) {
		try {
			const observation = await API.deleteObservation(id)

			if (observation) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.observationDeleted') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_OBSERVATION](id)

				commit(mutationTypes.DELETE_OBSERVATION, index)
			}

			return observation
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_REQUESTED_EXAMS]({ state, commit, getters }, id) {
		try {
			const request_id = id ? id : state.selectedRequest.id

			const page = state.requestedExams.currentPage
			const perPage = state.requestedExams.perPage

			const response = await API.getRequestedExams(request_id, page, perPage)

			if (response) {
				commit(mutationTypes.SET_REQUESTED_EXAMS, response)

				const requestIndex = getters[getterTypes.GET_INDEX_REQUEST](request_id)

				if (requestIndex !== -1) {
					const request = getters[getterTypes.GET_REQUEST_BY_ID](request_id)

					request.requested_exams = response.data
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_REQUESTED_EXAMS](
		{ state, commit, getters },
		{ id = false, store = true, filters }
	) {
		try {
			const request_id = id || state.selectedRequest.id

			const page = filters?.page || state.requestedExams.currentPage
			const perPage = filters?.perPage || state.requestedExams.perPage

			const response = await API.getRequestedExams(request_id, page, perPage)

			if (response && store) {
				commit(mutationTypes.SET_REQUESTED_EXAMS, response)

				const request = getters[getterTypes.GET_REQUEST_BY_ID](request_id)

				if (request) {
					request.requested_exams = response.data
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CREATE_REQUESTED_EXAMS]({ state, dispatch, commit }, { payload }) {
		try {
			const request_id = state.selectedRequest.id ? state.selectedRequest.id : 1
			const response = await API.createRequestedExams(request_id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examAdded') },
					{ root: true }
				)
				dispatch(types.GET_ALL_EXAM_GROUPS, state.selectedRequest.id)
				dispatch(types.GET_ALL_REQUESTED_EXAMS, state.selectedRequest.id)
			}

			return response ? response : false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [types.CREATE_REQUESTED_EXAMS_AGREEMENT]({ dispatch, commit }, { request_id, payload }) {
		try {
			const { status, data: responseExam } = await API.createRequestedExamsAgreement(
				request_id,
				payload
			)

			if (status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examAdded') },
					{ root: true }
				)

				dispatch(types.GET_ALL_REQUESTED_EXAMS, request_id)
			}

			return status === STATUS_SUCCESS ? responseExam : false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [types.UPDATE_REQUESTED_EXAMS]({ commit, getters }, payload) {
		try {
			const id = payload.id

			const exam = await API.updateRequestedExams(id, payload)

			if (exam) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_EXAM](id)

				commit(mutationTypes.UPDATE_REQUESTED_EXAM, { index, exam: exam })
			}

			return exam
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_STATUS_REQUESTED_EXAMS]({ state, commit, getters }, { id, status_id }) {
		try {
			const { status, data: exam } = await API.updateStatusRequestedExams(id, status_id)

			if (status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_EXAM](id)
				const examIndex = getters[getterTypes.GET_INDEX_EXAMS](id)

				if (index !== -1) {
					commit(mutationTypes.UPDATE_REQUESTED_EXAM, { index, exam: exam })
				}

				if (examIndex !== -1) {
					commit(mutationTypes.UPDATE_EXAMS, { index: examIndex, exam: exam })
				}

				if (state.selectedRequest) {
					const request = getters[getterTypes.GET_REQUEST_BY_ID](state.selectedRequest.id)

					if (request?.requested_exams) {
						const requestExamIndex = request.requested_exams.findIndex(
							(examReq: any) => examReq.id === exam.id
						)

						request.requested_exams.splice(requestExamIndex, 1, exam)
						const requestIndex = getters[getterTypes.GET_INDEX_REQUEST](request.id)

						commit(mutationTypes.UPDATE_REQUESTS, { index: requestIndex, request })
					}
				}
			}

			return status === STATUS_SUCCESS ? exam : false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [types.UPDATE_PRIORITY_REQUESTED_EXAMS](
		{ state, rootState, rootGetters, commit, getters },
		{ id, priority }
	) {
		try {
			const exam = await API.updateRequestedExams(id, priority)

			if (exam) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_EXAM](id)
				const examIndex = getters[getterTypes.GET_INDEX_EXAMS](id)

				if (index !== -1) commit(mutationTypes.UPDATE_REQUESTED_EXAM, { index, exam: exam })
				if (examIndex !== -1)
					commit(mutationTypes.UPDATE_EXAMS, { index: examIndex, exam: exam })

				if (rootState.InsertResult.selectedExam.id === exam.id)
					commit('InsertResult/setSelectedExam', exam, { root: true })

				if (state.selectedRequest.id) {
					const request = getters[getterTypes.GET_REQUEST_BY_ID](state.selectedRequest.id)

					const requestExamIndex = request.requested_exams.findIndex(
						(examReq: any) => examReq.id === exam.id
					)

					request.requested_exams.splice(requestExamIndex, 1, exam)
					const requestIndex = getters[getterTypes.GET_INDEX_REQUEST](request.id)

					commit(mutationTypes.UPDATE_REQUESTS, { index: requestIndex, request })
				}

				if (rootState.Request.requests.data?.length) {
					const requestIndex = rootGetters['Request/getIndex'](exam.exam_request.id)

					const request = rootState.Request.requests.data[requestIndex]

					const examIndex = request.requested_exams.findIndex(
						(reqExam: any) => reqExam.id === exam.id
					)

					request.requested_exams.splice(examIndex, 1, exam)

					commit(mutationTypes.UPDATE_REQUESTS, { index: requestIndex, request })
				}
			}

			return exam ? exam : false
		} catch (error: any) {
			console.log(error)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [types.DELETE_REQUESTED_EXAMS]({ state, commit }, { requested_exams_ids, request_id }) {
		try {
			const requestId = request_id ? request_id : state.selectedRequest.id

			const { status, data: exams } = await API.deleteRequestedExams(
				requestId,
				requested_exams_ids
			)

			if (status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examDeleted') },
					{ root: true }
				)
			}

			return status === STATUS_SUCCESS ? exams : false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_AVAILABLE_EXAMS](
		{ state, commit },
		{
			page = 1,
			search = undefined,
			showRepeated = undefined,
			hasAgreement = undefined,
			customerId = undefined,
		}
	) {
		try {
			commit(mutationTypes.UPDATE_LOADING_AVAILABLE_EXAMS, ENABLE_LOADING)

			const request_id = state.selectedRequest.id ? state.selectedRequest.id : 1
			const response = await API.getAvailableExams(request_id, {
				page: page,
				search: search,
				showRepeated: showRepeated,
				hasAgreement: hasAgreement,
				customerId: customerId,
			})

			if (response) {
				commit(mutationTypes.SET_AVAILABLE_EXAMS, response)
			}

			commit(mutationTypes.UPDATE_LOADING_AVAILABLE_EXAMS, DISABLE_LOADING)

			return response
		} catch (error: any) {
			commit(mutationTypes.UPDATE_LOADING_AVAILABLE_EXAMS, DISABLE_LOADING)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_EXAM_GROUPS]({ state, commit }, id) {
		try {
			const request_id = state.selectedRequest.id ? state.selectedRequest.id : id
			const response = await API.getRequestedExamGroups(request_id)

			if (response) {
				commit(mutationTypes.SET_REQUESTED_EXAM_GROUPS, response)
			}

			return state.requestedExams
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_AVAILABLE_EXAM_GROUPS]({ state, commit }, { page, search, showRepeated }) {
		try {
			const request_id = state.selectedRequest.id ? state.selectedRequest.id : 1
			const response = await API.getAvailableExamsGroups(request_id, {
				page,
				search,
				showRepeated,
			})

			if (response) {
				commit(mutationTypes.SET_AVAILABLE_EXAM_GROUPS, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_EXAM_STATUS_LIST]({ state, commit }) {
		if (state.statusExams.length) return

		try {
			const statusList = await API.getExamStatusList()

			if (statusList) {
				commit(mutationTypes.SET_EXAM_STATUS, statusList)
			}

			return state.statusExams
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.DELETE_REQUESTED_EXAM_GROUPS]({ state, commit }, { requested_exams_group_ids }) {
		try {
			const request_id = state.selectedRequest.id

			const { status } = await API.deleteRequestedExamGroups(
				request_id,
				requested_exams_group_ids
			)

			if (status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.examDeleted') },
					{ root: true }
				)
			}

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_EXAMS](
		{ commit },
		{
			page = 1,
			perPage = 25,
			search,
			requestedExamStatus,
			requestedExamPriority,
			orderDirection,
			examRequestStatus,
			examRequestPriority,
			orderBy,
			createdAfter,
			createdBefore,
			orderId,
			reset,
			groupBy = null,
			customerId,
			billed = undefined,
			paid = undefined,
		}
	) {
		try {
			const response = await API.getAllExams(
				page,
				perPage,
				search,
				requestedExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
				groupBy,
				customerId,
				billed,
				paid
			)

			if (response) {
				if (reset) {
					commit(mutationTypes.RESET_EXAMS)
				}

				commit(mutationTypes.SET_EXAMS, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_PAYERS]({ commit }, id) {
		try {
			const response = await API.getPayers(id)

			if (response) {
				commit(mutationTypes.SET_PAYERS, response)
			}
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CREATE_PAYER]({ commit }, payload) {
		try {
			const id = payload.id

			delete payload[id]
			const response = await API.addPayer(id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.payerAdded') },
					{ root: true }
				)
				commit(mutationTypes.SET_PAYERS, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_PAYER]({ commit }, payload) {
		try {
			const id = payload.id

			delete payload.id
			const response = await API.removePayer(id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.payerDeleted') },
					{ root: true }
				)
				commit(mutationTypes.SET_PAYERS, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_ATTACHMENTS_REQUEST](
		{ state, commit },
		{ id, withRequestedExamForms = false, withSamples = false }
	) {
		try {
			const attachments = await API.getAttachmentsRequest({
				id,
				withRequestedExamForms,
				withSamples,
			})

			if (attachments) {
				commit(mutationTypes.SET_ATTACHMENTS, attachments)
			}

			return state.attachments
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CREATE_ATTACHMENT]({ commit }, payload) {
		try {
			const attachment = await API.createAttachment(payload)

			if (attachment) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.create.attachment') },
					{ root: true }
				)
				commit(mutationTypes.ADD_ATTACHMENT, attachment)
			}

			return attachment
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_ATTACHMENT_REQUEST]({ getters, commit }, uuid) {
		try {
			const response = await API.removeAttachment(uuid)

			if (response.status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.remove.attachment') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_ATTACHMENT](uuid)

				if (index > -1) {
					commit(mutationTypes.REMOVE_ATTACHMENT, index)
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_ATTACHMENT]({ getters, commit }, { uuid, resource }) {
		try {
			const response = await API.removeAttachment(uuid)

			if (response.status === STATUS_SUCCESS) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.remove.attachment') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_ATTACHMENT](uuid)

				if (index > -1) {
					commit(mutationTypes.REMOVE_ATTACHMENT, index)
				}

				if (resource.type === 'sample') {
					const sample = getters[getterTypes.GET_SAMPLE_BY_ID](resource.id)

					if (sample) {
						const sampleIndex = getters[getterTypes.GET_INDEX_SAMPLE](resource.id)

						const attachmentIndex = sample.attachments.findIndex(
							(attachment: any) => attachment.uuid === uuid
						)

						if (attachmentIndex > -1) sample.attachments.splice(attachmentIndex, 1)

						commit(mutationTypes.UPDATE_SAMPLE, { index: sampleIndex, sample })
					}
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ATTACHMENT_DOWNLOAD]({ commit }, uuid) {
		try {
			const attachment = await API.getAttachmentDownload(uuid)

			if (attachment) {
				return attachment
			}

			return false
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CANCEL_RECEIVING]({ getters, commit }, { id, payload }) {
		try {
			const response = await API.cancelReceiving(id, payload)

			if (response) {
				const index = getters[getterTypes.GET_INDEX_REQUEST](id)

				if (index > -1) {
					commit(mutationTypes.SET_REQUEST_CANCELED, index)
				}

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.requestCanceled') },
					{ root: true }
				)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CANCEL_SAMPLE]({ getters, commit }, { id, payload }) {
		try {
			const { status, data: sample } = await API.cancelSample(id, payload)

			if (status === STATUS_SUCCESS) {
				const index = getters[getterTypes.GET_INDEX_SAMPLE](id)

				if (index > -1) {
					commit(mutationTypes.UPDATE_SAMPLE, { index: index, sample: sample })

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.receiving.alerts.success.sampleCanceled') },
						{ root: true }
					)
				}
			}

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_FORMS]({ commit }, id) {
		try {
			const response = await API.getForms(id)

			if (response.status === STATUS_SUCCESS || response.status !== 400) {
				commit(mutationTypes.SET_FORMS, response.data)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ALL_SAMPLES]({ commit }, { id, page, perPage }) {
		try {
			const response = await API.getSamples(id, page, perPage)

			if (response.status === STATUS_SUCCESS || response.status !== 400) {
				commit(mutationTypes.SET_SAMPLES, response.data)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CREATE_SAMPLE]({ commit }, { id, payload }) {
		try {
			const sampleArray = await API.createSample(id, payload)

			if (sampleArray) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.tc(
							'modules.receiving.alerts.success.sampleCreated',
							sampleArray.length
						),
					},
					{ root: true }
				)
				sampleArray.forEach((sample: any) => commit(mutationTypes.ADD_SAMPLE, sample))
			}

			return sampleArray
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_SAMPLE]({ commit, getters }, { id, payload }) {
		try {
			const sample = await API.editSample(id, payload)

			if (sample) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.sampleUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_SAMPLE](id)

				if (index !== -1) {
					commit(mutationTypes.UPDATE_SAMPLE, { index, sample })
				}
			}

			return sample
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_SAMPLE]({ getters, commit }, { payload }) {
		try {
			const response = await API.removeSample(payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.sampleDeleted') },
					{ root: true }
				)

				if (Array.isArray(payload.samples_ids)) {
					payload.samples_ids.forEach((id: number) => {
						const index = getters[getterTypes.GET_INDEX_SAMPLE](id)

						commit(mutationTypes.REMOVE_SAMPLE, index)
					})
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_SAMPLE_STATUS_LIST]({ state, commit }) {
		try {
			if (!state.sampleStatus.length) {
				const statusList = await API.getSampleStatusList()

				if (statusList) {
					commit(mutationTypes.SET_SAMPLE_STATUS_LIST, statusList)
				}
			}
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CHANGE_SAMPLE_STATUS]({ commit, getters }, { id, payload }) {
		try {
			const sample = await API.changeSampleStatus(id, payload)

			if (sample) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.receiving.alerts.success.sampleUpdated') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_SAMPLE](id)

				if (index !== -1) {
					commit(mutationTypes.UPDATE_SAMPLE, { index, sample })
				}
			}

			return sample
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_SAMPLES_TO_RELATE]({ commit }, { id, page = 1, parentless }) {
		try {
			return await API.getSamplesToRelate(id, page, parentless)
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_SAMPLES_EXAMS_TO_RELATE]({ commit }, { id, page = 1 }) {
		try {
			return await API.getSamplesExamsToRelate(id, page)
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_SAMPLE_RELATIONSHIPS](
		{ commit },
		{ protocol_number, barcode_protocol_number }
	) {
		try {
			return await API.getSampleRelationShips({ protocol_number, barcode_protocol_number })
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	[types.UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM](
		{ getters, commit },
		{ idExamGroup, idExam, status }
	) {
		const indexExamGroup = getters[getterTypes.GET_INDEX_EXAM_GROUP](idExamGroup)

		if (indexExamGroup > -1) {
			const indexExam = getters[getterTypes.GET_INDEX_EXAM_GROUP_REQUESTED_EXAM]({
				indexExamGroup: indexExamGroup,
				idExam: idExam,
			})

			if (indexExam > -1) {
				commit(mutationTypes.UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM, {
					indexExamGroup: indexExamGroup,
					indexExam: indexExam,
					status: status,
				})
			}
		}
	},

	async [types.GET_ALL_REQUESTEDS_EXAMS](
		{ commit },
		{ page, limit, customer_id, requestedExamStatus }
	) {
		try {
			const { status, data: allRequestedsExams } = await API.getAllRequestedsExams({
				page,
				limit,
				customer_id,
				requestedExamStatus,
			})

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_ALL_REQUESTEDS_EXAMS, allRequestedsExams)
			}

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.DELETE_MULTIPLE_REQUESTED_EXAMS]({ commit }, { selected, ids }) {
		try {
			let validRequest = true

			selected.forEach((e: any) => {
				if (e.status.id === EXAM_SENT_STATUS_ID || e.status.id === EXAM_CANCELED_STATUS_ID)
					validRequest = false
			})

			if (!validRequest) {
				commit(
					`Errors/${errorTypes.ADD_ERROR}`,
					{
						title: 'Exame',
						description: i18n.tc('modules.receiving.alerts.error.cantDeleteSentExams'),
					},
					{ root: true }
				)
				return false
			}

			await API.deleteMultipleRequestedExams(ids)

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{
					title: i18n.tc('modules.receiving.alerts.success.examsDeleted'),
				},
				{ root: true }
			)
			return true
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},
}

export default { actions }
