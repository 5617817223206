


































































































import ButtonDefault from '../Buttons/ButtonDefault.vue'
import { Component, Prop, PropSync, Ref, VModel, Vue } from 'vue-property-decorator'

@Component({
	name: 'InputAutoComplete',
	components: {
		ButtonDefault,
	},
})
export default class InputAutoComplete extends Vue {
	@VModel({ type: [String, Number, Array, Object, Boolean], default: null }) valueTextField!:
		| null
		| string
		| number
		| unknown[]
		| boolean

	@Prop({ type: Number, default: undefined }) readonly width?: number
	@Prop({ type: Number, default: undefined }) readonly height?: number
	@Prop({ type: String, default: undefined }) readonly error?: string
	@Prop({ type: Boolean, default: false }) readonly outlined!: boolean
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean
	@Prop({ type: String, default: undefined }) readonly successMessage?: string
	@Prop({ type: String, default: undefined }) readonly suffix?: string
	@Prop({ type: String, default: undefined }) readonly prefix?: string
	@Prop({ type: Number, default: undefined }) readonly minHeight!: number
	@Prop({ type: String, default: '' }) readonly rules!: string
	@Prop({ type: String, default: undefined }) readonly label?: string
	@Prop({ type: Array, default: () => [] }) items!: unknown[]
	@Prop({ type: String, default: 'id' }) readonly itemValue?: string
	@Prop({ type: String }) readonly itemText?: string
	@Prop({ type: Boolean, default: false }) readonly filled?: boolean
	@Prop({ type: Boolean, default: false }) readonly hideDetails?: boolean
	@Prop({ type: String, default: undefined }) readonly translateModule?: string
	@Prop({ type: Boolean, default: undefined }) readonly multiple?: boolean
	@Prop({ type: String, default: undefined }) placeholder?: string
	@Prop({ type: String, default: undefined }) readonly prependInnerIcon!: string
	@Prop({ type: Boolean, default: false }) readonly showAppendButton!: boolean
	@Prop({ type: Boolean, default: undefined }) readonly returnObject!: boolean
	@Prop({ type: Boolean, default: undefined }) loading!: boolean
	@Prop({ type: String, default: undefined }) clearIcon!: string
	@Prop({ type: String, default: null }) readonly textTooltipAppend!: string
	@Prop({ type: String, default: null }) readonly textButtonAppend!: string
	@Prop({ type: Boolean, default: true }) readonly closeOnContentClick!: boolean
	@Prop({ type: Boolean, default: undefined }) readonly readonly?: boolean
	@Prop({ type: Boolean, default: undefined }) readonly disabled?: boolean
	@Prop({ type: String, default: undefined }) readonly itemDisabled?: string
	@Prop({ type: String, default: undefined }) readonly messages?: string

	@PropSync('filter', { type: Function, default: undefined }) filterSync?: void
	@PropSync('searchInput', { type: String, default: null }) searchInputSync?: null | string

	@Ref('autoComplete') readonly autoComplete!: any
	@Ref('provider') readonly provider!: any

	get getHideDetails(): (errorProvider?: string[]) => boolean {
		return (errorProvider?: string[]) => {
			const noErrorsDetected = !(this.error || (errorProvider && !!errorProvider.length))

			return noErrorsDetected && !!this.hideDetails
		}
	}

	get dynamicMinHeight(): string {
		if (this.height && !isNaN(this.height)) {
			return `${this.height}px`
		}

		return `${this.minHeight}px`
	}

	get dynamicMaxWidth(): string {
		if (this.width && !isNaN(this.width)) {
			return `${this.width}px`
		}

		return '100%'
	}

	getText(item: any) {
		if (this.translateModule && item.slug) {
			return this.$t(`${this.translateModule}.${item.slug}`)
		}

		return typeof item === 'object' && this.itemText ? item[this.itemText] : item
	}

	isSlots(slotName: string) {
		return this.$scopedSlots[slotName]
	}

	closeMenu() {
		const autoCompleteRef = this.autoComplete
		const menuRef = autoCompleteRef.$refs.menu

		menuRef.isActive = false
	}
}
