var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":_vm.topTooltip,"bottom":_vm.bottomTooltip,"disabled":!_vm.tooltipMessage,"open-delay":_vm.tooltipOpenDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-menu',{staticClass:"elevation-1",attrs:{"offset-y":"","close-on-content-click":false,"disabled":_vm.menuDisabled,"content-class":"white","nudge-left":_vm.menuLeft,"nudge-bottom":_vm.menuBottom},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var menu = ref.on;
var attrs = ref.attrs;return [_c('v-btn',_vm._g(_vm._b({ref:"button",class:[
						'container-icon-button',
						( _obj = {}, _obj[_vm.contentClass] = true, _obj.readonly = _vm.readonly, _obj.hiddenButtonBorder = _vm.hiddenButtonBorder, _obj ) ],attrs:{"color":_vm.color,"disabled":_vm.disabled,"icon":"","small":_vm.small,"loading":_vm.loading},on:{"click":_vm.click}},'v-btn',attrs,false),Object.assign({}, menu, tooltip)),[_c('badge',{attrs:{"value":_vm.badge,"icon":_vm.icon,"content":_vm.contentBadge,"offset-x":_vm.offsetX,"offset-y":_vm.offsetY}},[_c('v-icon',{attrs:{"small":_vm.small,"size":_vm.sizeIcon}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)],1)]}}],null,true)},[_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipMessage))])])}
var staticRenderFns = []

export { render, staticRenderFns }