export default {
	modules: {
		requisicoes: 'Peticiones',
		clientes: 'Clientes',
		recipientes: 'Destinatarios',
		exames: 'Registro de examen',
		animais: 'Animales',
		demands: 'Demandas',

		auth: {},

		generalize: {
			list: 'Lista',
			painting: 'Cuadro',
			timeline: 'Linea del tiempo',
			groupByStatus: 'Agrupar por estado',
			notStarted: 'No empezado',
			started: 'Empezado',
			concluded: 'Concluido',
		},

		coats: {
			name: 'Abrigos',
			placeholderSearch: 'Buscar abrigos',

			table: {
				coat: 'Abrigo',
				notFound: 'No se encontraron registros de abrigos.',
			},

			form: {
				addCoat: 'Añadir Abrigo',
				editCoat: 'Editar Abrigo',
				title: 'Añadir Abrigo',
				coatName: 'Nombre del Abrigo',
			},

			tooltip: {
				editCoat: 'Editar Abrigo',
				removeCoat: 'Eliminar abrigo',
			},

			alert: {
				title: 'Eliminar Abrigo',
				subtitle: '¿Estás seguro de que quieres quitar este abrigo?',
			},

			feedback: {
				success: {
					create: {
						coat: 'El abrigo se creó con éxito.',
					},

					update: {
						coat: 'El escudo se ha actualizado con éxito.',
					},

					remove: {
						coat: 'El abrigo se quitó con éxito.',
					},
				},
			},
		},

		menu: {
			tooltip: {
				closeMenu: 'Cerrar Menú',
				openMenu: 'Menú abierto',
			},
		},

		shortcuts: {
			tooltip: {
				newAnimalTutor: 'Nuevo Paciente/Tutor',
				newClient: 'Nuevo Cliente',
				newExam: 'Nuevo Examen',
				examRequest: 'Solicitud de examen',
				newExamRequest: 'Nueva Solicitud de Examen',
				newOrder: 'Nuevo Pedido',
				insertResult: 'Inserción de Resultados',
			},
		},

		agreements: {
			name: 'Acuerdos',
			placeholderSearch: 'Buscar acuerdos',

			table: {
				headers: {
					creator: 'Creador',
					createdIn: 'Creado en',
					examName: 'Nombre del examen',
					examPrice: 'Precio del examen',
					examPriceDiscount: 'Precio de descuento',
				},

				notFound: 'No se encontró registro de acuerdos.',
				notFoundSelect: 'No se seleccionó ningún examen',
				notFoundExam: 'No se encontró ningún examen',
				notFoundSearch: 'Sin registro',
			},

			model: {
				name: 'Acuerdo',
				newAgreement: 'Nuevo Acuerdo',
				title: 'Agregar acuerdo',
				titleEditAgreement: 'Editar Acuerdo',
				descriptionAgreement: 'Descripción del Acuerdo',
				applyDiscount: 'Aplicar descuento',
				showPriceDiscount: '¿Mostrar el precio como un descuento?',
				defaultAgreement: 'Acuerdo estándar?',
				textDefaultAgreement:
					'Si selecciona este acuerdo como predeterminado, el\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\tlos precios seguirán este acuerdo como\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\treferencia!',
				selectAllExams: '¿Seleccionar todos los exámenes?',
				valueTables: 'Tabla de valores',
				addAgreement: 'Agregar Acuerdo',
				biggerDiscountText:
					'El Acuerdo se aplicó solo a los exámenes que superaban el descuento',
				percentageBiggerDiscountText:
					'No se ha aplicado el porcentaje porque es superior al precio del examen',
				deleted: 'Este acuerdo ha sido eliminado.',
			},

			tooltip: {
				editAgreement: 'Editar Acuerdo',
				removeAgreement: 'Quitar Acuerdo',
				downloadAgreement: 'Descargar acuerdo',
				unlinkExam: 'Desvincular examen',
			},

			status: {
				default: 'Acuerdo estándar',
				agreementByCustomers: 'Acuerdo por Cliente(s)',
			},

			feedback: {
				success: {
					create: {
						agreement: 'El acuerdo se creó con éxito.',
					},

					update: {
						agreement: 'El Acuerdo ha sido actualizado con éxito.',
					},

					remove: {
						agreement: 'El acuerdo se eliminó con éxito.',
					},
				},
			},
		},

		exams: {
			name: 'Exámenes',
			notFound: 'No se encontraron registros de examen.',
			buttonNew: 'Crear Examen',
			placeholder: 'Buscar exámenes...',
			examDefaultName: 'Examen sin nombre',
			attributeGroupDefaultName: 'Nuevo grupo de atributos',
			attributeDefaultName: 'Nuevo Atributo',

			attributeTypes: {
				title: 'Escribe',
				simpleText: 'Texto sencillo',
				textarea: 'Texto largo',
				text: 'Texto',
				integer: 'Entero',
				decimal: 'Decimal',
				formula: 'Fórmula',
				date: 'Fecha',
				positiveNegative: 'Positivo / negativo',
				image: 'Imagen',
				select: 'Opción multiple',
				percentage: 'Porcentaje',
			},

			bottomSheet: {
				examModels: 'Plantillas de Examen',
				selectTemplateToNext: 'Seleccione un modelo para continuar',
				clusterVetExamTemplates: 'Plantillas de Examen de ClusterVet',
				myExamsTemplates: 'Mis plantillas de examen',
				textReadyModel: 'Plantillas listas para usar. Edita solo el contenido',
				inBlank: 'En blanco',
				select: 'Seleccione',
				save: 'Ahorrar',
				saveAndNew: 'Guardar e insertar nuevo',
				examRegistration: 'Registro de examen',
				examEdition: 'Edición de Examen',

				tab: {
					exam: 'Examen',
					referenceTables: 'Tablas de Referencia',
					relatedExams: 'Exámenes Relacionados',
				},

				related: {
					notFound: 'Aún no hay relaciones =(',
					textRelated:
						'Para vincular un examen a otro, haga clic en el botón de abajo y vincule un atributo a otro examen.',
					linkNewExam: 'Vincular nuevo Examen',
					addAnotherRelationship: 'Agregar otra relación',
					warning: '¡Advertencia!',
					textAlert: 'Solo se puede relacionar este examen desde la inscripción.',
					subTextAlert: '¿Quiere guardar este examen ahora?',
					notSave: 'No',
					saveExam: 'Guardar examen',
					disableRelated: 'Desactivar relación',
					editRelated: 'Editar Relación',
					unlinkRelated: 'Desvincular Relación',
					examOf: 'Examen de',
					attribute: 'Atributo',
				},

				reference: {
					warning: '¡Advertencia!',
					textAlert: 'Solo puede hacer referencia a este examen desde el registro.',
					subTextAlert: '¿Quiere guardar este examen ahora?',
					notSave: 'No',
					saveExam: 'Guardar examen',
				},

				attribute: {
					name: 'Atributo',
					addOuther: 'Agrega otro',
					group: 'Grupo',
					or: 'o',
				},
			},

			form: {
				isTemplate: 'Es plantilla',
				disableStatus: 'Desactivar el examen',
				activeStatus: 'Activar el examen',
				editExam: 'Editar Examen',
				unlinkExam: 'Desvincular Examen',
				examName: 'Nombre del Examen',
				period: 'Período',
				workingDays: 'Días laborables',
				hours: 'Horas',
				price: 'Precio',
				reference: 'Referencia',
				referenceTooltip: 'Utilizado para identificación externa (Opcional)',
				category: 'Categoría',
				addCategory: 'Añadir categoría',
				notFoundRecipient: 'Sin registro',
				notFoundCategory: 'Sin registro',
				notFoundForm: 'Sin registro',
				notFoundEquipment: 'Sin registro',
				notFoundMethod: 'Sin registro',
				notFoundMaterial: 'Sin registro',
				notResultForm: 'Sin registro',
				notResultEquipment: 'Sin registro',
				notResultMethod: 'Sin registro',
				notResultMaterial: 'Sin registro',
				recipients: 'Destinatarios',
				amountSample: 'Cantidad Muestras',
				descriptionSample: 'Descripción de la muestra',
				sampleCollectionInstructions: 'Instrucciones de recogida de muestras',
				technicalManager: 'Responsable técnico',
				tm: 'Patólogos',
				notFoundTM: 'Aún no tienes un Responsable Técnico',
				form: 'Forma',
				select: 'Seleccione',
				equipment: 'Equipo',
				useds: 'usado(s)',
				method: 'Método',
				methodUsed: 'Usado (Técnica)',
				methodDescription: 'Método utilizado [Técnica] (Opcional)',
				materialUsed: 'Material utilizado (Opcional)',
				materialUsedOptional: 'usado (Opcional)',
				samples: 'Muestras',
				automatic: 'Automático',
				addRecipient: 'Agregar destinatario',
				addMaterial: 'Agregar material',
				addMethod: 'Agregar método',
				removeExam: 'Eliminar Examen',
				selectUnit: 'Seleccione una unidad',
			},

			modal: {
				addExamsProfiles: 'Agregar exámenes y perfiles',
			},

			table: {
				header: {
					sigla: 'Iniciales',
					examName: 'Nombre del Examen',
					price: 'Precio',
					period: 'Período',
					method: 'Método',
					status: 'Estado',
				},
			},

			referenceTable: {
				warning: 'Advertencia',
				doNotHaveReferences: 'Este Examen aún no tiene tablas de referencia',
				canSaveLikeThat:
					'Puede guardar de esta manera, pero el examen se guardará con estado inactivo.',

				buttons: {
					yes: 'Sí, guardar de todos modos',
					no: 'No, volver a editar',
				},

				referenceNote:
					'Aquí puede establecer valores de referencia para comparar en los resultados de su examen.',
				createNewTable: 'Crear nueva tabla',
				searchRefTable: 'Buscar tabla de referencia',
				learnMore: 'Learn more',

				table: {
					exam: 'Examen',
					specie: 'Especies',
					refTable: 'Tabla de referencia',
					ageGroup: 'Grupo de edad',
					noRegisterFound: 'No se encontraron registros de referencia.',
					from: 'De',
					to: 'A',
					years: 'años',
					months: 'meses',
				},

				form: {
					addReferences: 'Añadir Referencia',
					editReferences: 'Editar Referencia',
					selectExamNote:
						'Seleccione el examen que desea crear en la tabla de referencia, luego agregue los valores de atributo correspondientes a la especie.',
					messageObservation:
						'Nota: Este examen solo será referenciado para el paciente con esa edad.',
					name: 'Nombre *',
					specie: 'Especies *',
					initialAge: 'Edad inicial *',
					finalAge: 'Edad final *',
					periodIn: 'Período en',
					notFoundSpecie: 'No se encontraron especies',
					noRecord: 'Sin registros',
					years: 'Años',
					months: 'Meses',
					insertConditional: 'Insertar condicionales',

					header: {
						attribute: 'Atributo',
						complement: 'Complementar',
						minRef: 'Ref. Mínima',
						maxRef: 'Ref. Máxima',
						unit: 'Unidad',
					},

					label: {
						nameTable: 'Nombre para la identificación de la tabla',
						selectSpecie: 'Seleccionar especie',
					},
				},
			},

			alert: {
				title: 'Eliminar Examen',
				subtitle: '¿Estás seguro de que quieres eliminar este examen?',

				discardTitle: 'Descartar los Cambios',
				discardSubtitle: '¿Está seguro de que desea descartar estos cambios?',
			},

			feedback: {
				success: {
					create: {
						exam: 'El examen fue creado con éxito.',
					},

					update: {
						exam: 'El examen ha sido actualizado con éxito.',
						examStatus: 'El estado del examen ha sido actualizado.',
					},

					remove: {
						exam: 'El examen se eliminó con éxito.',
					},
				},

				errors: {
					EXAM_HAS_REQUESTED_EXAM_RELATIONSHIPS: {
						title: 'El examen ya se está utilizando.',
						message: 'El examen no se puede eliminar porque ya se está utilizando.',
					},
				},
			},
		},

		examsGroup: {
			name: 'Perfil',

			feedback: {
				success: {
					create: {
						examsGroup: 'El perfil fue creado exitosamente.',
					},

					update: {
						examsGroup: 'El perfil se ha actualizado correctamente.',
						examsGroupStatus: 'El estado del perfil ha sido actualizado.',
					},

					remove: {
						examsGroup: 'El perfil se ha eliminado correctamente.',
						unlinkExam: 'El examen se desvinculó exitosamente.',
					},
				},
			},
		},

		category: {
			name: 'Categorías',
			title: '¿Sabías que puedes agrupar tus exámenes por categorías?',
			searchExamsChips: 'Buscar exámenes relacionados',

			addCategory: 'Añadir Categoría',

			table: {
				notFound: 'No se encontraron registros de categoría.',
				category: 'Categoría',
				exams: 'Examenes',
			},

			form: {
				addCategory: 'Añadir Categoría',
				editCategory: 'Editar Categoria',
				categoryName: 'Nombre de la Categoría',
			},

			tooltip: {
				editCategory: 'Editar categoria',
				removeCategory: 'Quitar categoría',
				notFoundExam: 'En el examen',
				examCategory: 'Categoría de Examen',
			},

			alert: {
				title: 'Eliminar Categoría',
				subtitle: '¿Está seguro de que desea eliminar esta categoría?',
			},

			feedback: {
				success: {
					create: {
						category: 'Categoría creada con éxito',
					},

					update: {
						category: 'Categoría actualizada con éxito',
					},

					remove: {
						category: 'Categoría eliminada con éxito',
					},
				},
			},
		},

		species: {
			name: 'Especies',
			noAnimal: 'En el animal',
			addSpecie: 'Añadir especie',

			table: {
				specie: 'Especie',
				notFound: 'No se encontraron registros de especies.',
			},

			form: {
				addSpecie: 'Añadir especie',
				editSpecie: 'Editar especie',
				specieName: 'Nombre de la especie',
			},

			tooltip: {
				editSpecie: 'Editar especies',
				removeSpecie: 'Eliminar especies',
			},

			alert: {
				title: 'Eliminar especie',
				subtitle: '¿Estás seguro de que quieres eliminar esta especie?',
			},

			feedback: {
				success: {
					create: {
						specie: 'La especie fue criada con éxito.',
					},

					update: {
						specie: 'La especie se actualizó con éxito.',
					},

					remove: {
						specie: 'La especie fue eliminada con éxito.',
					},
				},
			},
		},

		materials: {
			name: 'Materiales',
			descriptionPlan:
				'Gestionar los materiales que se utilizarán en la inscripción de los Exámenes.',

			addMaterial: 'Agregar Material',

			form: {
				title: 'Agregar Material',
				titleEdit: 'Editar Material',
				addMaterial: 'Agregar Material',
				materialName: 'Nombre del Material',
			},

			table: {
				notFound: 'No se encontraron registros materiales.',
			},

			tooltip: {
				editMaterial: 'Editar Material',
				removeMaterial: 'Quitar material',
			},

			alert: {
				title: 'Eliminar material',
				subtitle: '¿Está seguro de que desea eliminar este material?',
			},

			feedback: {
				success: {
					create: {
						material: 'Material creado con éxito',
					},

					update: {
						material: 'Material actualizado con éxito',
					},

					remove: {
						material: 'Material eliminado con éxito',
					},
				},
			},
		},

		races: {
			name: 'Razas',

			addRace: 'Agregar carrera',

			form: {
				title: 'Agregar Carrera',
				titleEdit: 'Editar Carrera',
				addRace: 'Agregar Carrera',
				existRace: 'Ya existe una carrera con ese nombre',
				raceName: 'Nombre de la carrera',
				specie: 'Especies',
				specieNotFound: 'No se encontraron especies',
				minimumWeight: 'Peso mínimo (Opcional)',
				minimum: 'mínimo',
				maximumWeight: 'Peso máximo (Opcional)',
				maximum: 'máximo',
			},

			table: {
				specie: 'Especies',
				breed: 'Raça',
				notFound: 'No se encontraron registros de raza.',
			},

			tooltip: {
				editRace: 'Editar carrera',
				removeRace: 'Quitar carrera',
			},

			alert: {
				title: 'Quitar Carrera',
				subtitle: '¿Estás seguro de que quieres eliminar esta carrera?',
			},

			feedback: {
				success: {
					create: {
						breed: 'La raza fue creada con éxito.',
					},

					update: {
						breed: 'La carrera ha sido actualizada con éxito.',
					},

					remove: {
						breed: 'La carrera se eliminó con éxito.',
					},
				},
			},
		},

		address: {
			form: {
				addAddress: 'Añadir Dirección',
				editAddress: 'Editar Dirección',
				identifyAddress: '¿Cómo desea identificar esta dirección?',
				note: 'Nota:',
				facilityText: 'Esto ayudará a facilitar su búsqueda de servicios o promociones.',
				address: 'Dirección',
				state: 'Estado',
				number: 'Número',
				city: 'Ciudad',
				neighborhood: 'Vecindario',
				complement: 'Complementar',
				zipCode: 'Código postal',
			},
		},

		profiles: {
			name: 'Perfiles',
			notFound: 'No se encontraron registros de perfil.',
			buttonNew: 'Crear Perfil',
			placeholder: 'Buscar Perfiles...',

			form: {
				editProfile: 'Editar perfil',
				newProfile: 'Nuevo Perfil',
				examsPartProfile: 'Exámenes que forman parte del perfil',
				discount: 'Descuento',
				totalProfileValue: 'Valor total del perfil',
				addProfile: 'Añadir perfil',
				removeProfile: 'Eliminar perfil',
			},

			table: {
				header: {
					sigla: 'Iniciales',
					profileName: 'Nombre de perfil',
					description: 'Descripción',
					price: 'Precio (R$)',
					status: 'Estado',
				},
			},

			alert: {
				title: '¿Desvincular examen?',
				subtitle: '¿Está seguro de que desea desvincular el examen de este perfil?',
				titleRemoveProfile: 'Eliminar Perfil',
				subtitleRemoveProfile: '¿Estás seguro de que quieres eliminar este perfil?',
			},

			tooltip: {
				activeStatusExamGroup: 'Activar perfil',
				disabledStatusExamGroup: 'Desactivar perfil',
			},
		},

		requests: {
			name: 'Peticiones',
			request: 'Solicitud',

			table: {
				header: {
					numberRequest: 'Nº Solicitud',
					requester: 'Solicitante',
					patient: 'Paciente',
					progress: 'Progreso',
					start: 'D.H. Inicio',
					request: 'D.H. Solicitud',

					exams: {
						sigle: 'Abreviaturas',
						exam: 'Examen',
						andament: 'Comportamiento',
						rt: 'R.T',
						recipients: 'Destinatarios',
						period: 'Período',
					},
				},
				insertResult: 'insertar resultado',
				viewRequest: 'Ver Solicitud',
				searchExamPlaceHolder: 'Buscar Exámenes...',
				noOwnerFound: 'No se encontró propietario.',
				noAnimalFound: 'Ningún animal encontrado.',
				noDoctorFound: 'Ningún doctor encontrado.',
				noTagFound: 'No se encontró ninguna etiqueta.',
				noLaboratoryFound: 'No se encontró laboratorio.',
				notStarted: 'No empezado',
				notFound: 'No se encontraron registros de solicitud.',
				search: 'Solicitudes de Búsqueda',
				selectCriterFilter: 'Seleccione un criterio de filtrado',
				newRequest: 'Nueva Solicitud',
				newOrder: 'Nuevo Pedido',
				filterByDate: 'Filtrar por Fecha',
				andaments: {
					name: 'Progreso',
					draft: 'Reclutar',
					new: 'Nuevo',
					newItem: 'Nuevo',
					waitingForRoute: 'Esperando ruta',
					inTransit: 'En tránsito',
					noncompliance: 'Incumplimiento',
					inProgress: 'En progreso',
					processing: 'Procesando',
					finished: 'Acabado',
					analyse: 'Bajo análisis',
					typed: 'Mecanografiado',
					released: 'Liberado',
					waiting: 'Esperando',
					canceled: 'Cancelado',
				},
				criters: {
					examProgress: 'Progreso del examen',
					RequestProgress: 'Solicitar progreso',
					examLabels: 'Etiquetas de examen',
					urgentRequests: 'Solicitudes urgentes',
				},
				progress: {
					canceled: 'Cancelado',
					waitingForSample: 'Esperando muestra',
					prevented: 'Prevenido',
					processing: 'Procesando',
					concluded: 'Concluido',
				},
				notHaveProfilesExams: 'Este laboratorio no tiene perfiles ni exámenes',
				noResultsFilter: 'No hay resultados para este filtro',
			},

			checkout: {
				order: 'Ordenar',
				infoPatient: 'Información del paciente',
				patient: 'Paciente',
				retractCart: 'Retraer carro',
				expandCart: 'Ampliar carro',
				allPrice: 'Valor total',
				allPriceDiscount: 'Valor total',
				discount: 'Desct',
				valueAll: 'Monto',
				discountValue: 'Valor de descuento',
				totalDiscount: 'Total con descuento',

				tooltip: {
					descriptionBelongsExam: 'Este examen pertenece al perfil',
				},

				examDescription: 'Descripción del examen',

				alert: {
					descriptionErrorDoctor:
						'Este laboratorio no cuenta con clientes de tipo médico para realizar la solicitud.',
					descriptionErrorCustomers:
						'No tiene clientes, por favor agregue clientes de tipo médico para poder realizar la solicitud.',
				},
			},
		},

		receiving: {
			name: 'Recibo',
			viewRequest: 'Ver solicitud',
			editRequest: 'Editar solicitud',
			removeRequest: 'Eliminar solicitud',
			cancelRequest: 'Cancelar solicitud',
			cancelSample: 'Cancelar muestra',
			rReport: 'Informe',
			notStarted: 'No empezado',
			inProgress: 'En progreso',
			finished: 'Acabado',
			notFound: 'No se encontraron registros de factura',

			header: {
				draftMode: 'Modo borrador',
				saving: 'Ahorro...',
				done: 'Hecho',
				newRequest: 'Nueva solicitud',
			},

			tabs: {
				notes: 'Notas',
				exams: 'Exámenes',
				requests: 'Peticiones',
				logistic: 'Logístico',
				payments: 'Pagos',
				billings: 'facturación',
			},

			tabContent: {
				notes: {},
				exams: {
					addExamOrProfile: 'Agregar Exámenes/Perfiles',
				},
				logistic: {
					addShipping: 'Agregar Envío',
				},
				payments: {
					addPayment: 'Agregar Pago',
				},
				billings: {
					addBilling: 'Agregar Facturación',
				},
			},

			sidebar: {
				samples: 'Muestras de pacientes',
				addSample: 'Añadir muestra',
				forms: 'formularios',
				lastRequests: 'Últimas solicitudes:',
				answerForm: 'Responder',
			},

			form: {
				title: 'Nueva solicitud',
				subtitle: 'Seleccione los campos que desea copiar a la nueva solicitud',
				order: 'Ordenar',
				veterinarian: 'Doctor veterinario',
				patient: 'Paciente',
				samples: 'Muestras',
				exams: 'Exámenes',
				logistics: 'Logística',
				attributes: 'Atributos',
			},

			attachmentForm: {
				title: 'Añadir un adjunto',
				titleForm: 'Título',
				description: 'Descripción',
				attach: 'Adjuntar',
				relate: 'Relatar',
			},

			sampleForm: {
				title: 'Agregar muestra',
				titleEdit: 'Editar muestra',
				details: 'Detalles',
				material: 'Material',
				recipient: 'Envase',
				volume: 'Volumen',
				unitOfMeasurement: 'Unidad de medida',
				deadline: 'Plazo',
				deadlineType: 'Tipo de término',
				sampleOrigin: 'Origen de la muestra',
				exams: 'Examenes | {count} Examen | {count} Examenes',
				samples: 'Submuestras | {count} Submuestra | {count} Submuestras',
				attachments: 'Adjuntos | {count} Adjunto | {count} Adjuntos',
				relateExam: 'Relacionar Exámenes',
				relateSample: 'Relacionar Muestras',
				reportSample:
					'El informe se agregará a todos los exámenes vinculados a la muestra.',
				alert: {
					title: 'Eliminar relación de muestra',
					subtitle:
						'Para crear {count} muestras, se eliminarán las relaciones de muestra, ¿quieres continuar?',
				},
				tooltip: {
					amountToCreate1: 'Número de muestras a crear. Si el valor es mayor que 1,',
					amountToCreate2: 'no será posible relacionar muestras secundarias',
				},
			},

			logisticForm: {
				title: 'Registro de reunión',
				samples: 'Muestras',
				shipping: 'Transporte',
				provider: 'Proveedor',
				status: 'Estado',
				cost: 'Costo',
			},

			paymentForm: {
				title: 'Agregar pago',
				editPayment: 'Editar pago',
				payer: 'Pago',
				value: 'Valor',
				attachment: 'Archivo adjunto',
				relate: 'Relatar',
				transaction: 'Transacción',
				titleAttachment: 'Describa el bono',
				descriptionAttachment: 'Comentarios',
				receipt: 'Comprovante',
			},

			billingForm: {
				title: 'Nueva Factura',
			},

			selectOrderForm: {
				title: 'Seleccione el orden',
				order: 'Ordenar',
				observation: 'Observación',
				clinic: 'Clínica',
				selectClinic: 'Seleccione la clínica',
				selectClinic2: 'Selecciona la clínica responsable del pedido',
			},

			requestStatus: {
				draft: 'Reclutar',
				draftPrefix: 'Reclutar',
				new: 'Nuevo',
				newPrefix: 'Nuevo',
				waitingForRoute: 'Esperando ruta',
				waitingForRoutePrefix: 'Ruta de espera',
				inTransit: 'En tránsito',
				inTransitPrefix: 'En tránsito',
				nonCompliance: 'Incumplimiento',
				nonCompliancePrefix: 'Incumplimiento',
				inProgress: 'En progreso',
				inProgressPrefix: 'En progreso',
				finished: 'Acabado',
				finishedPrefix: 'Acabado',
				canceled: 'Cancelado',
				canceledPrefix: 'Cancelado',
				awaitingSample: 'Muestra en espera',
				awaitingSamplePrefix: 'Muestra en espera',
				received: 'Recibió',
				receivedPrefix: 'Recibió',
				processing: 'Procesando',
				processingPrefix: 'Procesando',
				waitingForAnalysis: 'Aguardando análise',
				waitingForAnalysisPrefix: 'Aguardando análise',
				awaitingAuthorization: 'En espera de autorización',
				awaitingAuthorizationPrefix: 'En espera de autorización',
				inAnalysis: 'En análisis',
				inAnalysisPrefix: 'En análisis',
				typing: 'En Mecanografía',
				typingPrefix: 'En Mecanografía',
				inValidation: 'En Validación',
				inValidationPrefix: 'En Validación',
				awaitingRelease: 'En espera de publicación',
				awaitingReleasePrefix: 'En espera de publicación',
				sent: 'Enviado',
				sentPrefix: 'Enviado',
			},

			logisticStatus: {
				notAssigned: 'No asignado',
				assigned: 'Asignado',
				accepted: 'Acepto',
				onRoute: 'En Movimiento',
				finished: 'Acabado',
				canceled: 'Cancelado',
			},

			sampleStatus: {
				onSchedule: 'En la Fecha Prevista',
				scheduledCollection: 'Recogida Programada',
				inTransit: 'En tránsitoo',
				nonCompliance: 'Incumplimiento',
				collected: 'Recogido',
				received: 'Recibió',
				discarted: 'Descartado',
				awaitingSample: 'Muestra en espera',
				inAnalysis: 'En análisis',
				canceled: 'Cancelado',
				expired: 'Atrasado',
				archived: 'Archivado',
			},

			alerts: {
				success: {
					requestCreated: 'Solicitud creada con éxito',
					requestUpdated: 'Solicitud actualizada con éxito',
					requestCanceled: 'Solicitud cancelada con éxito',
					sampleCanceled: 'Muestra cancelada con éxito',
					observationCreated: 'Nota creada con éxito',
					observationUpdated: 'Nota actualizada correctamente',
					observationDeleted: 'Nota eliminada con éxito',
					examAdded: 'Examen añadido con éxito',
					examUpdated: 'Exame atualizado com sucesso',
					examDeleted: 'Examen actualizado con éxito',
					payerAdded: 'Pagador añadido con éxito',
					payerDeleted: 'Pagador eliminado con éxito',
					sampleCreated: 'Muestra creada con éxito | {count} muestras creadas con éxito',
					sampleUpdated: 'Muestra actualizada con éxito',
					sampleDeleted: 'Muestra eliminada con éxito',
					examsDeleted: 'Exámenes eliminados con éxito',
				},

				removeExam: {
					title: 'Eliminar examen',
					subtitle: '¿Está seguro de que desea eliminar este examen?',
					titleMultipleExams: 'Eliminar exámenes',
					subtitleMultipleExams:
						'¿Está seguro de que desea eliminar todos los exámenes seleccionados?',
				},

				removeExamGroup: {
					title: 'Eliminar Perfil',
					subtitle: '¿Está seguro de que desea eliminar este perfil?',
					titleMultipleExamGroups: 'Eliminar Perfiles',
					subtitleMultipleExamGroups:
						'¿Está seguro de que desea eliminar todos los perfiles seleccionados?',
				},

				error: {
					cantDeleteSentExams: 'No puede eliminar los exámenes completados',
				},
			},

			tooltip: {
				copyRequest: 'Solicitud de copia',
				nextStatus: 'Estado siguiente',
			},
		},

		attachments: {
			name: 'Archivos adjuntos',

			alert: {
				title: 'Eliminar archivo adjunto',
				subtitle: '¿Estás seguro de que quieres eliminar este archivo adjunto?',
			},

			feedback: {
				success: {
					create: {
						attachment: 'Adjunto agregado con éxito',
					},

					remove: {
						attachment: 'Adjunto eliminado con éxito',
					},
				},
			},

			tooltip: {
				removeAttachment: 'Eliminar archivo adjunto',
			},
		},

		insertResult: {
			name: 'Inserción de resultados',

			signature: 'Firma',
			sendReport: 'Enviar Reporte',
			completeReport: 'Informe Completo',
			resendReport: 'Reenviar informe',
			reportSent: 'Reporte enviado',
			tutor: 'Tutor',
			veterinarian: 'Veterinario',
			patient: 'Paciente',
			clinic: 'Clínica',
			details: 'Detalles',
			history: 'Historia',
			patientSamples: 'Muestras de pacientes',
			viewExam: 'Ver examen',
			editExam: 'Editar examen',
			removeExam: 'Quitar examen',
			cancelExam: 'Cancelar examen',
			sampleReports: 'Observación de muestras',

			signatureForm: {
				title: 'Agregar firma',
				pathologist: 'Patólogo',
				signature: 'Firma',
				saveSignature: 'Guardar firma',
				sign: 'Señal',
			},

			sendReportForm: {
				title: 'Enviar Reporte',
				sendImmediately: 'Enviar Inmediatamente',
				sendWithTimer: 'Enviar con Temporizador',
				sendUsingV1: 'Enviar con V1',
				sendUsingV2: 'Enviar con V2',
			},

			tooltip: {
				timer: 'Tiempo para enviar el informe',
				pathologistSignature: 'Firma del Patólogo',
				showVersionamentHistory: 'Historial de versiones',
				useVersionEditable: 'Usar esta versión del Examen',
				updateExamLayout: 'Restablecer el diseño',
				clearFields: 'Borrar campos',
			},

			alert: {
				titleSign: 'Eliminar Signo',
				subtitleSign: '¿Estás seguro de que quieres eliminar el cartel?',
				titleOldVersion: 'Versión antigua',
				subtitleOldVersion: 'Está ingresando a una versión antigua, ¿quiere continuar?',
				titleCancelExam: 'Cancelar examen',
				subtitleCancelExam:
					'¿Está seguro de que desea cancelar este examen? Después de eso, será redirigido a la pantalla de inicio.',
			},

			examResultStatus: {
				new: 'Nuevo',
				newPrefix: 'Nuevo',
				underAnalysis: 'Bajo análisis',
				underAnalysisprefix: 'Bajo análisis',
				typing: 'En Mecanografía',
				typingPrefix: 'Mecanografía',
				toSign: 'Para firmar',
				toSignPrefix: 'Para firmar',
				completed: 'Terminado',
				completedPrefix: 'Terminado',
				canceled: 'Cancelado',
				canceledPrefix: 'Cancelado',
				waitingForAnalysis: 'Aguardando análise',
				waitingForAnalysisPrefix: 'Aguardando análise',
				awaitingAuthorization: 'En espera de autorización',
				awaitingAuthorizationPrefix: 'En espera de autorización',
				inAnalysis: 'En análisis',
				inAnalysisPrefix: 'En análisis',
				inValidation: 'En Validación',
				inValidationPrefix: 'En Validación',
				awaitingRelease: 'En espera de publicación',
				awaitingReleasePrefix: 'En espera de publicación',
				sent: 'Enviado',
				sentPrefix: 'Enviado',
				nonCompliance: 'Incumplimiento',
				nonCompliancePrefix: 'Incumplimiento',
				examType: 'Tipo de Examen',
				interfaced: 'Interfaz',
			},

			errorsForm: {
				requestedExamNotFound: 'No se encontró el examen solicitado.',
				passwordIncorrect: 'La contraseña es incorrecta.',
				signedAlready: 'Este resultado ya está firmado..',
				unknownError: 'Un error desconocido a ocurrido.',
			},

			addon: {
				name: 'Adicional',
				addons: 'Adicional',
				titleModal: 'Seleccionar Complemento',
				titleModalAddAddon: 'Agregar Complemento',
				addManually: 'Registro Adicional',
				editAddon: 'Editar',
				removeAddon: 'Eliminar',
				newAddon: 'Nuevo Adicional',
				notFound: 'No se encontró ningún adicional.',
				messageRemoveAddon:
					'Después de la acción de guardar, este complemento se eliminará.',
				totalAddons: 'Total',

				alert: {
					title: 'Eliminar Complemento',
					subtitle: '¿Seguro que quieres eliminar este complemento?',
				},

				tooltip: {
					addAddon: 'Añadir adicional',
					messageOtherPageSelected:
						'¡Cuidadoso! Tiene complemento(s) agregado(s), que están en otra(s) página(s).',
				},
			},

			feedback: {
				success: {
					create: {
						version: 'Se ha creado una nueva versión con éxito',
						reportSent: 'Informe enviado con éxito',
						signature: 'Firma añadida con éxito',
					},

					update: {
						version: 'Informe actualizado correctamente',
					},

					remove: {
						signature: 'Firma eliminada con éxito',
					},
				},
			},
		},

		financial: {
			alert: {
				title: 'Eliminar Pago',
				subtitle: '¿Está seguro de que desea eliminar el pago?',
			},

			tooltip: {
				editPayment: 'Editar pago',
				removePayment: 'Eliminar pago',
			},

			table: {
				paying: 'Pago',
				receipt: 'Recibo',
				registeredBy: 'Registrado por',
				transaction: 'Transacción',
				value: 'Valor',
			},

			typePaymentMethods: {
				other: 'Otros',
				bankingBillet: 'Comprobante Bancario',
				paymentLink: 'Enlace de Pago',
				instantPayment: 'PIX - Pago instantáneo',
				pixCharge: 'Facturación por PIX',
				fidelityProgram: 'Programa de Fidelidad',
				creditCard: 'Tarjeta de Crédito',
				debitCard: 'Tarjeta de Débito',
				withoutPayment: 'Sin Pagar',
				bankingTransfer: 'Transferencia Bancaria',
				foodVoucher: 'Cupones de Alimentos',
				fuelVoucher: 'Vale Combustible',
				giftVoucher: 'Vale Regalo',
				mealVoucher: 'Boleto de la Comida',
				digitalWallet: 'Monedero Digital',
				cashback: 'Devolución de Dinero',
				check: 'Controlar',
				virtualCredit: 'Crédito Virtual',
				storeCredit: 'Crédito de la Tienda',
				cash: 'Dinero',
				bankingDeposit: 'Depósito Bancario',
			},

			feedback: {
				success: {
					create: {
						payment: 'Pago creado con éxito',
					},

					update: {
						payment: 'Pago actualizado con éxito',
					},

					remove: {
						payment: 'Pago eliminado con éxito',
					},
				},
			},
		},

		invoices: {
			status: {
				pending: 'Pendiente',
				paid: 'Paga',
				typing: 'Al escribir',
				awaitingPayment: 'En espera de pago',
				canceled: 'Cancelado',
			},
		},

		previousResults: {
			table: {
				notFound: 'No se encontraron resultados anteriores.',
			},
		},

		orders: {
			name: 'Pedidos',
			order: 'Solicitud',
			createdBy: 'Creado por',
			copyOrderLink: 'Copiar enlace de pedido',
			viewOrder: 'Ver pedido',
			editOrder: 'Editar pedido',
			removeOrder: 'Borrar pedido',
			cancelOrder: 'Cancelar pedido',
			newOrder: 'Nuevo Pedido',
			notFound: 'No se encontró ningún registro de pedido',

			newOrderForm: {
				title: 'Nuevo orden',
			},

			form: {
				cancelOrder: 'Cancelar orden',
			},

			table: {
				search: 'Buscar ordenes',
			},

			status: {
				result: 'Resultado',
				draft: 'Reclutar',
				draftPrefix: 'Reclutar',
				new: 'Nuevo',
				newPrefix: 'Nuevo',
				inLogistics: 'En Logística',
				inLogisticsPrefix: 'En LGSTS.',
				processing: 'Procesando',
				processingPrefix: 'Procesando',
				delivered: 'Entregado',
				deliveredPrefix: 'Entregado',
				billed: 'Facturado',
				billedPrefix: 'Facturado',
				finished: 'Acabado',
				finishedPrefix: 'Acabado',
				canceled: 'Cancelado',
				canceledPrefix: 'Cancelado',
				budget: 'Presupuesto',
				awaitingSample: 'Muestra en espera',
				awaitingPayment: 'En espera de pago',
				received: 'Recibió',
				awaitingRelease: 'En espera de liberación',
			},

			feedback: {
				success: {
					create: {
						order: 'Pedido creado con éxito',
					},

					update: {
						order: 'Pedido actualizado con éxito',
					},

					remove: {
						order: 'Pedido eliminado con éxito',
					},

					cancel: {
						order: 'Pedido cancelado con éxito',
					},
				},
			},
		},

		navigationDrawer: {
			search: 'Búsqueda',
			orderBy: 'Ordenar por',
			sortOrder: 'Orden de clasificación',
			filterByDate: 'Filtrar por fecha',
			examPriority: 'Prioridad del examen',
			examCategory: 'Categoría de Examen',
			examStatus: 'Estado del examen',
			filterByBilling: 'Filtrar por facturación',

			sortBy: {
				status: 'Estado',
				priority: 'Prioridad',
				price: 'Precio',
				discount: 'Descuento',
				dueDate: 'Plazo',
				createdDate: 'Fecha de creación',
				entranceDate: 'Dia de entrada',
				lastUpdate: 'Última actualización',
				ascending: 'Ascendente',
				descending: 'Descendiendo',
				yes: 'Sí',
				no: 'No',
				protocolNumber: 'Número de protocolo',
				issuedAt: 'Emitido en',
			},

			request: {
				filterRequests: 'Filtrar solicitudes',
				canSearchBy1: 'Puede buscar por: número de protocolo corto,',
				canSearchBy2: 'número de protocolo, nombre del cliente, veterinario o clínica.',
				requestPriority: 'Solicitar prioridad',
				requestStatus: 'Estado de la solicitud',
			},

			exam: {
				filterExams: 'Filtrar exámenes',
			},

			bill: {
				filterBills: 'filtrar facturación',
			},

			order: {
				filterOrder: 'Filtrar pedidos',
				orderStatus: 'Estado del pedido',
				orderPriority: 'Prioridad del pedido',
			},
		},

		animals: {
			name: 'Pacientes',

			searchPlaceholder: 'Buscar pacientes',
			alive: 'Vivo',
			dead: 'Muerto',
			castrated: 'Castrado',
			doctor: 'Médico',
			responsableExist: 'Ellos existen',
			descriptionDoctorResponsable: 'médicos responsables del paciente',
			descriptionTutorResponsable: 'Guardianes responsables del paciente',
			notFoundTutors: 'No tiene tutor',
			notFoundDoctors: 'No tiene doctor',

			table: {
				headers: {
					animalTutor: 'Paciente / Tutor',
					animal: 'Paciente',
					raceSpecie: 'Raza / Especie',
					sex: 'Sexo',
					age: 'Años',
				},

				notFound: 'No se encontraron registros de pacientes.',
			},

			header: {
				backToList: 'Volver a la lista de paciente',
				tutor: 'Tutor',
				editAnimal: 'Editar paciente',
			},

			form: {
				title: 'Nuevo Pacientes',
				editAnimal: 'Editar Pacientes',
				generateNewAUID: 'Generar un nuevo AUID',
				uniqueIdentification: 'Identificación Única del Paciente',
				animalName: 'Nombre de paciente',
				birthDate: 'Fecha de nacimiento',
				year: 'Años (Calcular Fecha)',
				days: 'Días (Calcular fecha)',
				yearNotFound: 'No se encontró ningún año.',
				month: 'Meses (Calcular Fecha)',
				monthNotFound: 'No se encontró ningún mes.',
				specie: 'Especies',
				raceNotFound: 'No se encontraron carreras.',
				sex: 'Sexo',
				coat: 'Abrigo',
				fertility: 'Fertilidad',
				weight: 'Peso',
				microchip: 'Pastilla',
				animalRegistration: 'Registro de Paciente',
				observations: 'Comentarios',
				tutorOrDoctor: 'Tutor o doctor veterinario',
				selectRegisterAnimal: 'Seleccionar o registrar a los responsables del paciente',
				searchTutorExisting: 'Busca uno',
				searchTutorExisting2: 'existente o registrar uno nuevo.',
				selectTutorClient: 'Seleccione un tutor / cliente',
				doctor: 'veterinario',
				selectDoctorClient: 'Selecciona un Veterinario',
				addAnimal: 'Agregar Paciente',
				addPatient: 'Incluir Paciente',
				note1: '¡Intenta registrar la mayor cantidad de información posible sobre el paciente!',
				note2:
					'Cuanto más precisa sea la información ingresada, más\n' +
					'\t\t\t\t\t\t\t\t\tinteligente la plataforma se volverá.',
				import: 'Importar',
			},

			bottomSheet: {
				allCustomerFound: 'Total de clientes encontrados',
				allCustomer: 'Clientes totales',
				searchPlaceholder: 'Buscar por Nombre, Cpf, Teléfono, Email...',
				doctor: 'Veterinario',
				clinic: 'Clínica',
				doctorSimple: 'médico',
				tutor: 'Tutor',

				alert: {
					existCustomerType: 'Hay Clientes del tipo',
					to: ', por',
					viewCustomer: 'ver el cliente',
					hereYouShould: 'aquí deberías',
					updateRegister: 'Actualizar Registro',
				},

				selectClientType: 'Seleccionar tipo de cliente',
				titleNotFound: 'No encontramos ningún resultado para su búsqueda..',
				descriptionNotFound:
					'Intente rehacer su búsqueda usando más caracteres o cree un nuevo registro usando el\n' +
					'\t\t\tboton de abajo.',
				selectTutorDoctor: 'Seleccionar tutor / médico',
				chooseAnimal: 'Elige un paciente',
				selectAnimal: 'Seleccionar paciente',
				hideInfoAnimal: 'Ocultar paciente de',
				viewInfoAnimal: 'Ver paciente de',
				hideInfoContact: 'Ocultar información de contacto',
				viewInfoContact: 'Ver información de contacto',
				telephone: 'Teléfono',
				textRegister1: 'Te puedes registrar',
				textRegister2: 'uno',
				textRegister3: 'Nuevo',
				register: 'Registro',
				alreadyRegisterAnimal:
					'Ya estás registrando un paciente, por favor selecciona el dueño o médico',
				clientType: 'Tipo de cliente',
				client: 'Cliente',
				animal: 'Paciente',
				simpleAnimal: 'paciente',
			},

			tooltip: {
				animalDetails: 'Detalles de paciente',
				removeAnimal: 'Quitar Paciente',
			},

			details: {
				breed: 'Raza',
				specie: 'Especies',
				size: 'Tamaño',
				tutor: 'Tutor',
				coat: 'Abrigo',
				noCoat: 'Sin abrigo',
				sex: 'Sexo',
				age: 'Años',
				death: {
					name: 'Muerto',
					yes: 'Sí',
					no: 'En el',
					uninformed: 'No informado',
				},
				male: 'Masculino',
				female: 'Femenino',
				noSex: 'Indefinido',
				day: 'día',
				days: 'días',
				month: 'mes',
				months: 'meses',
				year: 'año',
				years: 'años',
				categories: {
					history: 'Historia',
					weights: 'Pesos',
					appointments: 'Equipo',
					vaccines: 'Vacunas',
					prescriptions: 'Recetas',
					exams: 'Exámenes',
					medicines: 'Medicamentos',
					documents: 'Documentos',
					observation: 'Observación',
				},
			},

			customer: {
				doesntHave: '¡Este cliente aún no tiene una paciente! =D',
				note: 'Para registrar un paciente, simplemente agregue un paciente con ',
				note2: 'el nombre de este cliente que el paciente será computado.',
				startRegister: 'Iniciar registro de paciente',
			},

			feedback: {
				success: {
					create: {
						animal: 'Paciente creado con éxito',
					},

					update: {
						animal: 'Paciente actualizado con éxito',
					},

					remove: {
						animal: 'Paciente eliminado con éxito',
					},
				},
			},
		},

		customers: {
			name: 'Clientes',

			addCustomer: 'Agregar Cliente',
			bin: 'Papelera',
			costumerList: 'Lista de clientes',
			import: 'Importar',

			table: {
				headers: {
					nameSocialReason: 'Nombre / Razón Social',
					phone: 'Teléfono',
				},
				notFound: 'No se encontraron registros de clientes.',
				noPhone: 'Sin teléfono',
				verified: 'Verificado',
				pending: 'Pendiente',
			},

			header: {
				averageTicket: 'Boleto Promedio',
				accomplishedServices: 'Servicios realizados',
				schedules: 'Horarios',
			},

			placeholders: {
				selectCustomerType: 'Seleccione el tipo de cliente',
			},

			customerDetails: {
				customerData: 'Datos de registro',
				phones: 'Los teléfono(s)',
				profile: 'Perfil',
				crmv: 'CRMV',
				address: 'Dirección',
				noPhone: 'No tengo telefono',
				noAddress: 'Sin dirección',
				noNeighborhood: 'Sin barrio',
				noComplement: 'No complementar',
				noCPFCNPJ: 'Sin CPF/CNPJ',
				noCity: 'En la ciudad',
				noZipCode: 'Sin código postal',
			},

			history: {
				doesntHave: 'Este cliente aún no tiene historia! =D',
				note: 'Para que se registre un historial basta con tramitar algún servicio o producto en nombre',
				note2: 'de este cliente y se calculará el historial.',
				startRequest: 'Iniciar una solicitud',
				createSchedule: 'Crear un horario',
				importHistory: 'Importar historial de otro sistema',
			},

			exams: {
				doesntHave: 'Este cliente aún no tiene examen! =D',
				note: 'Para registrar un examen, simplemente registre un examen',
				note2: 'con este cliente que será computado.',
				registerExam: 'Inicie el registro del examen',
				importHistory: 'Importar exámenes de otro sistema',
			},

			agreements: {
				doesntHave: 'Este cliente aún no tiene un acuerdo! =D',
				note: 'Para registrar al cliente en un acuerdo, simplemente cree un nuevo acuerdo',
				note2: 'o agregarlo a uno existente.',
				registerAgreement: 'Crear un nuevo acuerdo',
			},

			searchCustomers: 'Agregue filtros presionando "ENTER"',

			form: {
				addCustomer: 'Agregar Cliente',
				editCustomer: 'Editar Cliente',
				customerProfile: 'Perfil de Cliente',
				registrationType: 'Tipo de registro',
				physicalPerson: 'Persona física',
				legalPerson: 'Persona legal',
				maskActive: 'Máscara activada',
				maskDesactive: 'Desenmascarar',
				maskActiveText:
					'Deshabilitar máscara automática (si la máscara no tiene el formato de su ID)',
				maskDesactiveText: 'Habilitar máscara automática (hay dos tipos de formato)',
				issuingAgency: 'Agencia Emisora',
				fullName: 'Nombre completo',
				state: 'Estado',
				mainEmail: 'Correo electrónico principal',
				emailCollections: 'Correo electrónico para colecciones',
				businessPhone: 'Teléfono de Negocios',
				zipCodeNotFound: '¡No pudimos encontrar su dirección por código postal!',
				messageFirstCpf: 'Por favor ingrese CPF primero.',
				requesterCRMV: 'CRMV del solicitante',
				municipalRegistration: 'Registro Municipal',
				fantasyName: 'Nombre de Fantasía',
				socialReason: 'Nombre de Empresa',
				requester: 'Solicitante',
				emailRequester: 'Correo electrónico del solicitante',
			},

			filters: {
				tutor: 'Tutor',
				doctor: 'Médico',
				clinic: 'Clínica',
				laboratory: 'Laboratorio',
				uninformed: 'No informado',
				placeholder: 'Tipo de registro',
			},

			issuingNotFound: 'Órgano no encontrado',
			stateNotFound: 'Estado no encontrado.',
			placeholderIssuing: 'Seleccione la Agencia Emisora',

			startCustomers: {
				title: '¡Upppss! Todavía no tienen registros de clientes =D',
				subtitle: 'Para que una solicitud sea procesada, debe haber una',
				subtitle2: 'registro de cliente\n' + '\t\t\t\tpara vincular a la solicitud.',
				registerNewCustomers: 'Registrar nuevo cliente',
				importCustomers: 'Importar clientes de otro sistema',
			},

			customerView: {
				backToList: 'Volver a la lista de clientes',
				categories: {
					historic: 'Historia',
					bonds: 'Vínculos',
					animals: 'Animales',
					schedules: 'Horarios',
					requestedExams: 'Exámenes solicitados',
					purchases: 'Compras',
					documents: 'Documentos',
					examReport: 'Informe',
					agreement: 'Acuerdo',
				},
			},

			tooltip: {
				viewProfile: 'Ver perfil',
				removeCustomer: 'Eliminar cliente',
				restoreCustomer: 'Restaurar cliente',
				profile: 'Perfil',
			},

			alert: {
				title: 'Eliminar cliente',
				subtitle: '¿Estás seguro de que quieres eliminar a este cliente?',
			},

			feedback: {
				success: {
					create: {
						client: 'Cliente creado con éxito',
						importClient: 'Clientes importados con éxito',
					},

					update: {
						client: 'Cliente actualizado con éxito',
					},

					remove: {
						client: 'Cliente eliminado con éxito',
						importHistory: 'Historial de importación eliminado con éxito',
						restore: 'Cliente restaurado exitosamente',
					},
				},
			},
		},

		apiKey: {
			name: 'Administrar claves API',
			apiKeyAbbreviation: 'API',

			addKey: 'Agregar clave de API',

			table: {
				keyName: 'Claves API',
				tokenPrefix: 'Prefijo token',
				createdBy: 'Creado por',
				updatedOn: 'Actualizado en',
				addedIn: 'Añadido en',
				status: 'Estado',
			},

			form: {
				addKey: 'Agregar clave de api',
				title: 'Clave API creada',
				subtitle: `Copie la clave y utilícela con el parámetro “api_key” en el encabezado de sus solicitudes`,
				accessKey: 'Llave de acceso',
			},

			alert: {
				title: 'Eliminar clave API',
				subtitle: '¿Está seguro de que desea eliminar la clave API?',
			},

			tooltip: {
				editApikey: 'Editar clave API',
				removeApikey: 'Eliminar clave API',
			},

			feedback: {
				successes: {
					create: {
						apikey: 'Clave de API creada con éxito',
					},

					update: {
						apikey: 'Clave de API actualizada con éxito',
						status: 'Estado actualizado con éxito',
					},

					remove: {
						apikey: 'Clave de API eliminada con éxito',
					},
				},
			},
		},

		recipients: {
			name: 'Destinatarios',
			addRecipient: 'Agregar Destinatario',

			descriptionPlan:
				'Administrar los destinatarios que se utilizarán en el registro del examen.',
			knowMore: 'Saber más',
			searchPlaceholder: 'Buscar destinatarios',

			table: {
				notFound: 'No se encontraron registros de destinatarios.',
				recipient: 'Destinatario',
				subtitle: 'Subtitular',
			},

			form: {
				addRecipient: 'Agregar Destinatario',
				editRecipient: 'Editar Destinatario',
				descriptionSample: 'Descripción de la muestra',
				subtitle: 'Subtitular',
				icon: 'Icono',
				color: 'Color',
			},

			icons: {
				pipe: 'Tubo',
				blade: 'Espada',
				bowl: 'Recipiente',
				swab: 'Torunda',
				drop: 'Soltar',
				soda: 'Soda',
				dropper: 'Cuentagotas',
				bullet: 'Bala',
				flask: 'Matraz',
				flaskRound: 'Matraz Redondo',
				cylinder: 'Cilindro',
				nail: 'Uña',
				oar: 'Remo',
				rivet: 'Remache',
				syringe: 'Jeringa',
			},

			alert: {
				title: 'Eliminar contenedor',
				subtitle: '¿Está seguro de que desea eliminar este contenedor?',
				want: 'Desear',
				deleteRecipient: '¿Eliminar este destinatario?',
				ifDeleteRecipient: 'Si excluye a este destinatario',
				requestExam: 'solicitud de examen',
				warningDescription:
					'todos los exámenes que contengan este contenedor vinculado se perderán puede causar\n' +
					'\t\t\t\tproblemas en',
				recomendation:
					'Le recomendamos que cree un nuevo contenedor y realice cambios en los exámenes.\n' +
					'\t\t\t\t\tque usan este contenedor, para que no tengas ningún problema.',
			},

			tooltip: {
				editRecipient: 'Editar destinatario',
				removeRecipient: 'Eliminar destinatario',
			},

			feedback: {
				successes: {
					create: {
						recipient: 'Contenedor creado con éxito',
					},

					update: {
						recipient: 'Contenedor actualizado correctamente',
					},

					remove: {
						recipient: 'Contenedor eliminado con éxito',
					},
				},
			},
		},

		tags: {
			name: 'Tags',
			descriptionPlan:
				'Administrar las etiquetas que se utilizarán en el registro del examen.',
			searchPlaceholder: 'Buscar etiquetas',
			addTag: 'Añadir etiqueta',

			table: {
				tag: 'Etiqueta',
				notFound: 'Sin registro de etiquetas encontradas.',
			},

			form: {
				addTag: 'Añadir Etiqueta',
				editTag: 'Editar Etiqueta',
				tagName: 'Nombre de Etiqueta',
				preview: 'Avance',
				description: 'Descripción (opcional)',
			},

			tooltip: {
				editTag: 'Editar Etiquetas',
				removeTag: 'Remover etiqueta',
			},

			alert: {
				title: 'Remover Etiqueta',
				subtitle: '¿Estás seguro de que quieres eliminar esta etiqueta?',
			},

			feedback: {
				success: {
					create: {
						tag: 'Etiqueta creada con éxito',
					},

					update: {
						tag: 'Etiqueta actualizada con éxito',
					},

					remove: {
						tag: 'Etiqueta eliminada con éxito',
					},
				},
			},
		},

		methods: {
			name: 'Métodos',

			addMethod: 'Agregar Método',

			tooltip: {
				editMethod: 'Método de edición',
				removeMethod: 'Método de eliminación',
			},

			alert: {
				title: 'Quitar método',
				subtitle: '¿Está seguro de que desea eliminar este método?',
			},

			feedback: {
				success: {
					create: {
						method: 'Método creado con éxito',
					},

					update: {
						method: 'Método actualizado con éxito',
					},

					remove: {
						method: 'Método eliminado con éxito',
					},
				},
			},
		},

		equipments: {
			name: 'Equipo',
			equipmentsAbbreviation: 'Equipo',

			equipment: 'equipo',

			form: {
				addEquipment: 'Agregar equipo',
				editEquipment: 'Edit Equipment',
				equipmentName: 'Nombre del equipo',
			},

			table: {
				equipment: 'Equipo',
			},

			alert: {
				title: 'Quitar equipo',
				subtitle: '¿Está seguro de que desea eliminar este equipo?',
			},

			tooltip: {
				editEquipment: 'Editar equipo',
				removeEquipment: 'Quitar equipo',
			},

			status: {
				newInterfacing: 'Nueva interfaz',
				inHomologation: 'En homologación',
				homologated: 'Homologado',
				waitingForApproval: 'Esperando aprobación',
				interfaced: 'Interconectada',
			},

			feedback: {
				success: {
					create: {
						equipment: 'Equipo creado con éxito',
					},

					update: {
						equipment: 'Equipo actualizado con éxito',
						bound: 'Equipo vinculado con éxito',
					},

					remove: {
						equipment: 'Equipo eliminado con éxito',
					},
				},
			},
		},

		attributes: {
			name: 'Permisos',

			table: {
				searchLabel: 'Buscar por permisos',
				createNewAttribute: 'Crear nuevo permiso',
				noDataFound: 'No se encontraron registros de atributos.',
				noMembersFound: 'No se encontraron registros de miembros.',
			},
			addMembers: 'Agregar miembros de atributo',
			addResources: 'Agregar recursos',
			editPermissions: 'Editar permisos',
			resourcesAndPermissions: 'Recursos y permisos',
			newAttribute: 'Nuevo permiso',
			addAllResources: 'Añadir todo',
			updateAttribute: 'Actualizar atributo',
			notFound: 'No se encontró ningún atributo',
			sector: 'Sector o Departamento',
			functionSector: 'Función o Ocupación',
			alert: {
				titleSelectGroupAttribute: 'Agregar grupo/atributo',
				subtitleSelectGroupAttribute: 'Seleccionar grupo o atributo',
				options: {
					group: 'Grupo',
					attribute: 'Atributo',
				},
				titleAddGroup: 'Añadir Grupo',
				titleEditGroup: 'Editar Grupo',
				subtitleAddGroup: 'Nombre del Grupo',
				titleAddAttribute: 'Agregar atributo',
				subtitleAddAttribute: 'Nombre del Atributo',
				titleRemoveAttribute: 'Eliminar Atributo',
				titleEditAttribute: 'Editar atributo',
				subtitleRemoveAttribute: '¿Está seguro de que desea eliminar este atributo?',
				subtitleRemoveAttributeFormula:
					'Si elimina este atributo, todas las fórmulas vinculadas a él también se eliminarán.',
				attributeTypes: {
					title: 'Escribe',
					simpleText: 'Texto sencillo',
					textarea: 'Texto largo',
					integer: 'Entero',
					decimal: 'Decimal',
					formula: 'Fórmula',
					date: 'Fecha',
					positiveNegative: 'Positivo / negativo',
					image: 'Imagen',
					select: 'Opción multiple',
					percentage: 'Porcentaje',
				},
				unit: 'Unidad',
				selectOption: 'Opciones',
				percentage: {
					line1: 'El atributo de tipo porcentaje solo debe usarse si se basa en otro atributo de tipo entero o decimal,',
					line2: 'la suma de todos los atributos de tipo porcentual vinculados a un mismo atributo debe dar un total de 100%.',
				},
				percentageWarning:
					'The percentage attribute is unique to the CBC exam. To use common percentage values, change to integer or decimal type.',
			},
			formula: {
				title: 'Información de atributos',
				attributeName: 'Nombre del atributo',
				type: 'Escribe',
				unit: 'Unidad',
				specie: 'Especie (Opcional)',
				specieNotFound: 'Especie no encontrada',
				attributeFormula: 'Fórmula de atributo',
				createFormulaText: 'Crea tu fórmula seleccionando atributos y operaciones',
				note: 'Después de seleccionar la fórmula deseada, debe vincular los atributos del examen a la fórmula para obtener el resultado deseado.',
				learnMore: 'Aprende más',
				addOtherFormula: 'Añadir otra fórmula',
				save: 'Ahorrar',
				formula: 'Fórmula',
				specieSelected: 'Especies',
				youAreEditing: 'Estás editando esta fórmula.',
				formulaName: 'Nombre de la fórmula',
				selectAttribute: 'Seleccionar atributo',
			},
			dontShowTitle: 'No mostrar título',
			tooltip: {
				removeGroup: 'Quitar grupo',
				editGroup: 'Editar grupo',
				disableGroup: 'Deshabilitar grupo',
				removeAttribute: 'Eliminar atributo',
				editAttribute: 'Editar atributo',
				addAttribute: 'Agregar atributo',
			},

			feedback: {
				success: {
					create: {
						attribute: 'Atributo creado con éxito',
						memberAttribute: 'Atributo agregado al miembro con éxito',
					},

					updated: {
						attribute: 'Atributo actualizado con éxito',
					},

					remove: {
						attribute: 'Atributo eliminado con éxito | Atributos eliminados con éxito',
						memberAttribute: 'Atributo eliminado del miembro con éxito',
					},
				},
			},
		},

		users: {
			table: {
				searchLabel: 'Buscar por miembros',
				noDataFound: 'No se encontraron registros de formulario.',
				filterAttribute: 'Filtrar por atributos',
				header: {
					attributes: 'Atributos',
					policies: 'Políticas',
					invitedBy: 'Invitado por',
					guestIn: 'Invitado',
					lastActivity: 'Última actividad',
				},
				buttonInviteUser: 'Invitar usuario',
				notFoundInvite: 'No se encontraron registros de invitación.',
				notFoundMember: 'No se encontraron registros de miembros.',
			},

			alert: {
				kickMember: 'Expulsar miembro',
				textKickMember:
					'¿Estás seguro de que quieres expulsar a este miembro de la organización?',
			},

			tooltip: {
				disableStatus: 'Deshabilitar estado',
				activeStatus: 'Activar estado',
				activePathologist: 'Activar Patólogo',
				disablePathologist: 'Deshabilitar Patólogo',
				pathologistNotCrmv:
					'El usuario no tiene información de registro de la junta médica para calificar como patólogo',
				invalidEmail: '¡Email inválido!',
				removeInvite: 'Eliminar invitación',
				removeInvites: 'Eliminar invitación(es)',
				copyLinkInvite: 'Copiar enlace de invitación',
				kickUser: 'Expulsar miembro',
				kickUsers: 'Expulsar miembro(s)',
			},

			form: {
				emailAndUser: 'Ingrese el correo electrónico o nombre de usuario del usuario',
				inviteLink: 'Enlace de invitación',
				attributes: 'Atributos',
				policies: 'Políticas',
				notifyPerson: 'Notificar a los invitados',
				notifyDescription:
					'Enviar una notificación a los correos electrónicos seleccionados.',
				personalizedMessage: 'Mensaje personalizado',
				membersAlreadyInvitations:
					'Los usuarios ya invitados no aparecerán en la selección.',
			},
		},
		forms: {
			table: {
				searchLabel: 'Buscar por formularios...',
				noDataFound: 'No se encontraron registros de formulario.',
			},
			templates: {
				startANewForm: 'Iniciar un nuevo formulario',
				modelGallery: 'Galería de modelos',
				inBlank: 'En blanco',
				defaultTemplate: 'Plantilla predeterminada {n}',
			},
			questionTypes: {
				shortAnswer: 'Respuesta corta',
				paragraph: 'Párrafo',
				multipleChoice: 'Opción multiple',
				draw: 'Dibujar',
				checkBoxes: 'Casillas de verificación',
				fileUpload: 'Subir archivo',
			},
			textPlaceholders: {
				paragraph: 'Texto de respuesta larga',
				text: 'Texto de respuesta corta',
				date: 'Mes, día, año',
				time: 'Tiempo',
			},

			tooltip: {
				editForm: 'Editar formulario',
				removeForm: 'Eliminar formulario',
			},

			alert: {
				title: 'Eliminar formulario',
				subtitle: '¿Está seguro de que desea eliminar este formulario?',
			},

			addOption: 'Añadir opción',
			addOther: 'Añadir "otro"',
			addQuestion: 'Agregar pregunta',
			addSection: 'Agregar sección',
			addTitleAndDescription: 'Agregar título y descripción',
			or: 'o',
			untitled: 'Intitulado',
			untitledForm: 'Forma sin titulo',
			untitledSection: 'Sección sin título',
			withoutDescription: 'Sin descripción',
			formWithoutDescription: 'Form without description',
			sectionWithoutDescription: 'Sección sin descripción',
			preview: 'Avance',
			description: 'Descripción (opcional)',
			questions: 'Preguntas',
			question: 'Pregunta',
			answers: 'respuestas',
			section: 'Sección {current} de {total}',
			option: 'Opción {n}',
		},

		account: {
			profile: 'Perfil',
			nickname: 'Apodo',
			cpf: 'CPF',
			gender: 'Género',
			language: 'Idioma',
			personalData: 'Información Personal',
			personalAboutYou: 'Por favor, proporcione información básica sobre usted',
			additionalData: 'Datos adicionales',
			aboutYou: 'Queremos saber un poco más de ti y de tu día a día.',
			defaultOrganization: 'Organización Predeterminada',
			defaultOrganizationAbout:
				'La organización que se seleccionará automáticamente al acceder a la plataforma.',
			selectOrganization: 'Seleccionar organización (Optativo)',
			occupation: '¿Cuál es tu ocupación?',
			interestArea: 'Áreas de interés',
			council: 'Consejo',
			register: 'Registro',
			state: 'Estado',
			mapa: 'Registro de MAPA',
			noOccupation: 'No se encontró ocupación',
			photoChanged: '¡Foto cambiada con éxito!',
			noInterest: 'No se encontraron intereses',
			beCalled: '¿Cómo te gustaría que te llamaran?',
			whatEmail: '¿Cuál es su dirección de correo electrónico?',
			genres: {
				male: 'Masculino',
				female: 'Femenino',
				other: 'Prefiero no informar',
			},
			interests: {
				medical: 'Clinica Medica',
				acupuncture: 'Acupuntura',
				anesthesia: 'Anestesia',
				cattleRaising: 'Ganadería',
				cardiology: 'Cardiología',
				surgicalClinic: 'Clínica Quirúrgica',
				behavioral: 'Conductual',
				dermatology: 'Dermatología',
				edocrinology: 'Endocrinología',
				physiotherapy: 'Fisioterapia',
				homeopathy: 'Homeopatía',
				immunology: 'Inmunología',
				nephrology: 'Nefrología',
				neurology: 'Neurología',
				diagnosticMedicine: 'Medicina Diagnóstica',
			},
			occupations: {
				veterinarian: 'Veterinario',
				vetStudent: 'Alumno de medicina veterinaria',
				cattleman: 'Vendedor',
				distributor: 'Comerciante / Distribuidor',
				tutor: 'Tutor',
			},
			phone: {
				title: 'Mis números de teléfono',
				subtitle: 'Ver y administrar todos los teléfonos asociados con su cuenta.',
				addNewPhone: 'Agregar el número de teléfono',
				alert: {
					title: 'Agregar el número de teléfono',
					subtitle: 'Te enviaremos un SMS a tu número de teléfono',
					user: 'Usuario',
					organization: 'Organización',
					phoneOrCell: 'Número de teléfono o celular',
					smsSent: 'SMS enviado a',
					validateSms: 'Validar SMS',
					resendSms: 'Reenviar SMS',
					validate: 'Validar',
					successPhone: '¡El teléfono ha sido registrado con éxito!',
					successCell: '¡El celular fue registrado exitosamente!',
					deleteMessage: {
						want: 'Quieres',
						delete: 'eliminar',
						thisPhone: '¿este teléfono?',
						thisCell: 'este celular?',
						this: 'Esto',
						deletion: 'supresión',
						willBe: 'estarán',
						irreversible: 'irreversible',
						pleaseMakeSure: 'por favor asegúrese de que desea eliminarlo.',
						removedPhone: 'El teléfono se eliminó con éxito.',
						removedCell: 'El celular fue eliminado exitosamente.',
					},
					yesDelete: '¡Sí, eliminar!',
					noDelete: 'no quiero',
				},
				phone: 'Teléfono',
				cell: 'Teléfono móvil',

				alertRemove: {
					title: 'Eliminar teléfono',
					subtitle: '¿Seguro que quieres eliminar este teléfono?',
				},

				feedback: {
					success: {
						create: {
							phone: 'El teléfono se ha agregado con éxito.',
						},

						update: {
							phone: 'El teléfono se ha actualizado con éxito.',
						},

						remove: {
							phone: 'El teléfono ha sido eliminado con éxito.',
						},
					},
				},
			},
			address: {
				myAddresses: 'Mis direcciones',
				subtitleMyAddresses:
					'Ver y administrar todas las direcciones asociadas con su cuenta.',
				addAddress: 'Añadir dirección',
				alert: {
					identify: '¿Cómo desea identificar esta dirección?',
				},
			},

			feedback: {
				success: {
					create: {},

					update: {
						profile: 'El perfil ha sido actualizado con éxito.',
					},

					remove: {},
				},
			},

			main: 'Principal',
			choose: 'Elegir',
		},

		organizations: {
			name: 'Organizaciones',
			description: 'Consulta y gestiona toda la información de tu organización.',

			errorInter: 'ha ocurrido un error interno',
			notFound: 'No perteneces a ninguna organización..',

			addOrganization: 'Agregar organización',
			loading: 'cargando...',

			tooltip: {
				editOrganization: 'Editar Organización',
				removeOrganization: 'Eliminar Organización',
				transferOwnership: 'Transferir Propiedad',
				exitOrganization: 'Abandonar la Organización',
			},

			form: {
				addOrganization: 'Agregar organización',
				membersOrganization: 'Miembros de la Organización',
			},

			alert: {
				title: 'Eliminar organización',
				subtitle: '¿Está seguro de que desea eliminar esta organización?',
			},

			cpfOwner: 'CPF del propietario',
			ownerName: 'Nombre del dueño',
			socialReason: 'Razón Social',
			tradeName: 'Nombre de Fantasía',
			personType: 'Tipo de Persona',
			organizationType: 'Tipo de organización',
			domain: 'Dominio',
			domainInfo:
				'Este dominio es único y se utilizará para que tus clientes accedan a tu Área de Cliente',
			imageSize:
				'La imagen debe contener un {maxSize}. (*.PNG). Evite dejar cualquier espacio en blanco a los lados de la imagen y use el logotipo con un fondo transparente.',
			maxImageSize: 'máximo de 200x70 px',
			nsfeWarning: 'Su "compañía" no podrá emitir NFS-e.',
			onePhysicalOrganization: 'Solo puede ser propietario de 1 organización (individual)',

			feedback: {
				success: {
					create: {
						organization: 'La organización fue creada con éxito.',
					},

					update: {
						organization: 'La organización se actualizó con éxito.',
					},

					patch: {
						organization: 'Has transferido correctamente la organización.',
					},

					remove: {
						organization: 'La organización se eliminó con éxito.',
						getOutOrganization: 'Saliste con éxito de la organización.',
					},
				},
			},
			referrer: 'Referido por (Opcional)',
		},

		importations: {
			name: 'Importación y Exportación',
			title: 'Importe fácilmente datos de otros sistemas a ClusterVet',
			import: 'Importar',
			customers: 'clientes',
			descriptionImport: 'Importa tu lista de empresas/clientes',
			productServices: 'productos y servicios',
			descriptionProductServices: 'Importe sus exámenes y la configuración del diseño',
			historyImportation: 'Importar historial',

			table: {
				headers: {
					file: 'Expediente',
					module: 'Módulo',
					registers: 'Registros',
					imported: 'Importado',
					invalids: 'Inválidos',
				},

				notFound: 'No se encontraron registros de importación.',
			},

			form: {
				title: 'Importar clientes',
				selectFileCsvTsv: 'Seleccione el archivo (CSV o TSV) e impórtelo a ClusterVet.',
				selectFileText: 'Seleccione Archivo',
				hintText: 'Formatos permitidos: CSV o TSV',
				ifYouPrefer: 'Si tu prefieres,',
				downloadText: 'descarga nuestra plantilla de hoja de cálculo',
				compartionText:
					'y compare con el archivo de importación para asegurarse de que su archivo\n' +
					'\t\t\t\t\t\t\tajustarse a las normas.',
				importQuestions: '¿Importar preguntas?',
				mapFields: 'Campos del mapa',
				checkColumnText:
					'Compruebe que las columnas del archivo importado coincidan con las columnas que se van a importar\n' +
					'\t\t\t\t\t\tpara ClusterVet.',
				fieldsClusterVet: 'Campos ClusterVet',
				columnCsv: 'Columnas CSV',
				required: 'requerido',
				selectColumn: 'Seleccionar columna',
				changeFile: 'Cambiar archivo',
				importing: 'Importador',
				import: 'Importar',
				importInProgress: 'Importación en curso',
				importInProgressDescription:
					'Estamos procesando tu importación, en un momento podrás ver los datos\n' +
					'\t\t\t\t\t\timportado a tu lista',
				seeDetails: 'Ver detalles',
				mappedFields: 'Campos asignados',
				totalColumnFile: 'Columnas totales en el archivo',
				columnSelected: 'Columnas seleccionadas',
				registers: 'Registros',
				fileLines: 'Líneas de archivo',
				totalValidImport: 'Total válido (s) para la importación',
				lineErrorImport: 'Líneas con error(es)',
				downloadCsv: 'Descargar CSV actualizado',
				detailsName: 'Importar detalles',
				searchPlaceholderDetails: 'Buscar por nombre, correo electrónico, CNPJ, CPF.',
				filterBySituation: 'Filtrar por situación',
				filterSelecteds: 'Filtro seleccionado',
				filters: {
					nomeInvalid: 'nombre inválido',
					cpfInvalid: 'cpf inválido',
					cnpjInvalid: 'cnpj inválido',
					emailInvalid: 'email inválido',
					success: 'éxito',
				},
				registerImported: 'Registro importado con éxito',
			},

			pastDays: 'día(s) pasado(s)',
			imported: 'Importado',
			noImported: 'No importado',

			tooltip: {
				seeDetails: 'Ver detalles',
				removeImport: 'Eliminar historial de importación',
			},

			alert: {
				title: 'Eliminar historial de importación',
				subtitle: '¿Está seguro de que desea eliminar este historial de importación?',
			},
		},
		preferences: {
			security: {
				title: 'Seguridad',
				subtitle:
					'Cambie la contraseña de su cuenta con frecuencia para evitar el acceso no autorizado a su cuenta.',
				emailAddress: 'Dirección de correo electrónico',
				password: 'Clave',
				redefinePassword: 'Redefinir contraseña',
				newPassword: 'Nueva contraseña',
				confirmPassword: 'Confirmar la nueva contraseña',
				passMin:
					'La contraseña debe tener al menos 8 caracteres y contener números y símbolos',
				updatePassword: 'Actualiza contraseña',
				twoFactor: {
					title: 'Autenticación de dos factores',
					note: 'La autenticación de dos factores agrega una capa adicional de seguridad a su cuenta.',
					note2: 'Para iniciar sesión, también deberá proporcionar un código de 6 dígitos.',
					soon: 'Pronto',
					enable: 'Habilitar',
					authenticatorApps: 'Aplicaciones de autenticación',
					sms: 'SMS',
					chooseMethod: 'Elija un método de autenticación',
					methodNote:
						'Además de su nombre de usuario y contraseña, deberá ingresar un código (enviado a través de la aplicación o SMS) para iniciar sesión en su cuenta.',
					appsOption:
						'Obtenga códigos de una aplicación como Google Authenticator, Microsoft Authenticator, Authy o 1Password',
					smsOption:
						'Le enviaremos un código por SMS si necesita usar su método de inicio de sesión de respaldo.',
				},
			},
			associatedAccounts: {
				title: 'Cuentas Asociadas',
				subtitle:
					'Ver y administrar la lista de cuentas que están asociadas con su cuenta de ClusterVet.',
				connect: 'Conectar',
			},
			notifications: {
				title: 'Notificaciones',
				subtitle:
					'Ver y administrar la lista de cuentas que están asociadas con su cuenta de ClusterVet.',
				activity: {
					title: 'Actividad',
					serviceEmail: {
						title: 'Recibir un correo electrónico cuando alguien solicite un servicio',
						subtitle:
							'Cuando el cliente solicite un servicio, se le notificará a través del correo electrónico registrado.',
					},
				},
				application: {
					title: 'Solicitud',
				},
				contact: {
					title: 'Opciones de contacto',
				},
			},
			deleteAccount: {
				title: 'Borrar cuenta',
				subtitle:
					'Eliminar permanentemente toda la información de la cuenta y los datos del servicio.',
				note: 'Una vez eliminada, esta cuenta ya no estará disponible de forma permanente.',
				note2: 'Por favor, asegúrese de que quiere hacer esto.',
				delete: 'Borrar cuenta',
			},
		},

		webcam: {
			takePhotoWithWebcam: 'Capturar imagen con cámara web',
			videoUnavailable: 'Vídeo no disponible',
			permissionDenied:
				'No se puede acceder a la cámara web, para usar esta función, habilite el acceso a la cámara web a través de su navegador.',
			takePhoto: 'Tomar la foto',
			takeAnotherPhoto: 'Toma otra foto',
		},

		webhooks: {
			integrations: 'Integraciones',
			automateYourWork: '¡Integre con sus herramientas favoritas y automatice su trabajo!',
			labcloud: {
				name: 'Labcloud',
				apiKey: 'Clave API',
				laboratoryId: 'ID del laboratorio',
				description:
					'Conéctese con labcloud y envíe informes de exámenes directamente a sus clientes.',
				configurations: 'Configuración de Labcloud',
				functions: {
					visualizeLabcloudClients: 'Ver clientes de labcloud',
					sendExamReports: 'Enviar informes de examen',
				},
			},
			asaas: {
				name: 'Asaas',
				apiKey: 'Clave API',
				walletId: 'ID de billetera',
				paymentMethod: 'Método de pago por defecto',
				configurations: 'Configuración de Asaas',
				description: 'Conéctese con asaas para enviar cargos y recibir pagos.',
				functions: {
					sendPayments: 'Enviar cargos',
					syncronization: 'Sincronización con facturas de ClusterVet',
				},
			},
		},

		bills: {
			feedback: {
				success: {
					removeBillItem: 'Éxito en la eliminación del elemento',
				},
			},
			status: {
				pending: 'Pendiente',
				paid: 'Pagado',
				canceled: 'Cancelado',
				typing: 'Escribiendo',
				awaitingPayment: 'Esperando El Pago',
			},
		},
	},

	cropper: {
		cropImage: 'Delimitar imagem',
	},

	chips: {
		actions: {
			messageCreateItem: `Presiona "Enter" para crear el`,
			changeColor: 'Cambiar el color',
			rename: 'Rebautizar',
			remove: 'Remover',
		},

		message: 'Buscar o crear nuevos',
		messageNewTag: 'Buscar o crear nuevas etiquetas',
		messageSearch: 'Buscar el',
		messageSearchNotFound: 'Sin registros con este atributo',

		tooltip: {
			preview: 'Ver etiquetas',
		},
	},

	feedback: {
		feedback: 'Reportar Problema',
		sendFeedback: 'Enviar comentarios',
		describeYourProblem: 'Describa su problema o sugerencia',
		howWeCanImprove: 'Cuéntanos cómo podemos mejorar nuestra plataforma',
		sendImages: 'Enviar imágenes',
		newScreenShot: 'Nueva captura de pantalla',
		screenshot: 'Captura de pantalla',
	},

	notifications: {
		notifications: 'Notificaciones',
		unread: 'No leído',
		mentioned: 'yo fui mencionado',
		assigned: 'Asignado a mí',
		nothingNew: 'Nada nuevo por aquí.',
	},

	configuration: {
		menu: {
			users: 'Usuarios',
			profileAcess: 'Perfil de Acceso',
			integrations: 'integraciones',
			importAndExport: 'Importación y Exportación',
			groups: 'Grupos',
			agreements: 'Acuerdos',
			customFields: 'Campos Personalizados',
			attributes: 'Permisos',
		},

		registers: {
			species: 'Especies',
			races: 'Razas',
			coats: 'Abrigos',
			illnesses: 'Enfermedades',
			typeOfService: 'tipos de servicio',
			vaccines: 'Vacunas',
			examsAndProfiles: 'Exámenes / Perfiles',
			category: 'Categorías de examen',
			forms: 'Formularios',
			tags: 'Etiquetas',
			materials: 'Materiales',
			recipients: 'Destinatarios',
			methods: 'Métodos',
			equipments: 'Equipo',
			invoices: 'Facturas',
			bills: 'Facturas',
		},

		menu_user: {
			myProfile: 'Mi perfil',
			preferences: 'Preferencias',
			organizations: 'Organizaciones',
			rewards: 'Recompensas',
		},

		menu_automations: {
			webhooks: 'Webhooks',
			triggersAndEvents: 'Disparadores y eventos',
			notifications: 'Notificaciones',
			apikey: 'Clave API',
			integration: 'Integraciones',
			webhook: 'Webhook',
		},

		custom_menu: {
			customFields: 'Campos Personalizados',
			customerPortal: 'Portal del Cliente',
			preferences: 'Preferencias',
			emailTemplates: 'Plantillas de correo electrónico',
		},

		financial_menu: {
			splits: 'Divisiones',
			paymentGateway: 'Via de pago',
			Preferences: 'Preferencias',
		},

		menu_sessions: {
			activeSessions: 'Sesiones activas',
			activeTokens: 'Fichas activas',
		},
	},

	menu: {
		feedback: 'Retroalimentación',
		referer: 'Referir a ClusterVet',
		help: 'Ayuda y Apoyo',
		invite: 'Invitar a los miembros',
		office: 'Dueño',
		office_personal: 'Cuenta personal',
		officeOwner: 'Dueño',
		officeMember: 'Miembro',
		officeTechnicalManager: 'Gerente tecnico',
		newSection: 'Crear nueva sección',
		editSection: 'Editar sección',
		newMark: 'Nueva etiqueta',
		editMark: 'Editar etiqueta',
		selectIcon: 'Seleccionar icono',
		route: 'Camino/Ruta',
		expandedByDefault: 'Expandido por estándar',
		previewRoute: 'Ver ruta',
		clickInTheIcon: 'Haga clic en el icono para seleccionar',
	},

	menu_dropdown: {
		clinica: 'Clínica veterinaria',
		laboratorio: 'Laboratorio Veterinario',
		petshop: 'La tienda de animales',
		conta: 'Cuenta',
		explorar: 'Para explorar',
		organizacoes: 'Mis organizaciones',
		perfil: 'Mi perfil',
		config: 'Ajustes',
		integracao: 'Integraciones',
		importacao: 'Datos de importacion',
		novo: `Qué hay de nuevo`,
		marketplace: 'Mercado',
		clusterVetlabs: 'ClusterVet.labs',
		invite: 'Invitar a los miembros',
		darkmode: 'Habilitar modo oscuro',
		lightmode: 'Habilitar modo branco',
		sair: 'Salida',
		adicionaOrg: 'Agregar organización',
	},

	resources: {
		attribute: 'Atributo',
		exam: 'Examen',
		animal: 'Animal',
		request: 'Solicitud',
		form: 'Forma',
		agreement: 'Convenio',
		examgroup: 'Grupo de Examen',
		coat: 'Abrigo',
		customer: 'Cliente',
		examcategory: 'Categoría de examen',
		import: 'Importar',
		recipient: 'destinatario',
		breed: 'La raza',
		label: 'Etiqueta',
		material: 'Material',
		users: 'Usuarios',
		species: 'Especies',
		requestedexams: 'Solicitud de Exámenes',
		requestedexam: 'Solicitud de Exámenes',
		method: 'Métodos',
		bill: 'Facturas',
		order: 'Peticiones',
	},

	actions: {
		create: 'Crear',
		read: 'Leer',
		update: 'Actualizar',
		delete: 'Eliminar',
	},

	buttons: {
		save: 'Ahorrar',
		cancel: 'Cancelar',
		send: 'Enviar',
		register: 'Registro',
		saveChanges: 'Guardar cambios',
		add: 'Agregar',
		kick: 'Echar a patadas',
		remove: 'Eliminar',
		discard: 'Descartar los cambios',
		ok: 'Ok',
		unlink: 'Desconectar',
		next: 'Próximo',
		close: 'Cerca',
		transferOwnership: 'Transferir Propiedad',
		select: 'Seleccione',
		confirm: 'Confirmar',
		printOut: 'Imprimir',
		buttonUseVersion: 'Usar versión',
		resetFilters: 'Eliminar filtros',
		finalize: 'Concluir',
		invite: 'Invitar usuario',
	},

	default: {
		selectAll: 'Seleccionar todo',
		removeAll: 'Eliminar todo',
		default: 'defecto',
		attribute: 'Atributo',
		active: 'Activo',
		addedBy: 'Añadido por',
		attributes: 'Atributos',
		users: 'Usuarios',
		people: 'Gente',
		inactive: 'Inactivo',
		name: 'Nombre',
		edit: 'Editar',
		mandatory: 'Obligatorio',
		creator: 'Creador',
		tags: 'Etiquetas',
		remove: 'Remover',
		resources: 'Recursos',
		resource: 'Recurso',
		status: 'Estado',
		send: 'Enviar',
		key: 'Key',
		value: 'Valor',
		description: 'Descripción',
		optional: 'Opcional',
		cancel: 'Cancelar',
		preview: 'Avance',
		change: 'Cambio',
		create: 'Crear',
		createdAt: 'Creado en',
		creationDateAndTime: 'Fecha y hora de creación',
		fieldName: 'Nombre del campo',
		configurations: 'Configuraciones',
		context: 'Contexto',
		date: 'Fecha',
		duplicate: 'Duplicado',
		time: 'Tiempo',
		forms: 'Formularios',
		freeTrial: 'Estás en una prueba gratuita.',
		upgradeAccount: '¿Quieres actualizar tu cuenta?',
		addImage: 'Añadir imagen',
		changeImage: 'Cambiar imagen',
		members: 'Miembros',
		member: 'Miembro',
		invites: 'Invita',
		viewMode: 'Modo de vista',
		all: 'Todos',
		color: 'Color',
		financial: 'Financiero',
		personalization: 'Personalización',
		permissions: 'Permisos',
		automations: 'Automatizaciones',
		registers: 'Registros',
		sessions: 'Sesiones',
		back: 'Atrás',
		welcomeDashboard: 'Mi ClusterVet',
		you: 'Tú',
		organization: 'Organización',
		inviteUser: 'Invitar usuarios',
		unknown: 'Desconocido',
		new: 'Nuevo',
		guest: 'Invitado',
		selectItem: 'Elemento seleccionado',
		selectItems: 'Elementos seleccionados',
		none: 'Ninguno',
		view: 'Vista',
		amount: 'Monto',
		subtitle: 'Subtitular',
		uninformed: 'No informado',
		select: 'Seleccione',
		type: 'Escribe',
		updateColor: 'Actualizar color',
		notFound: 'No hay registro',
		reset: 'Reiniciar',
		laboratory: 'Laboratorio',
		price: 'Precio',
		dontCrmv: 'No tienes un crmv',
		doctorVeternary: 'Veterinario',
		ok: 'Ok',
		warning: '¡Advertencia!',
		searchTable: 'Buscando...',
		knowMore: 'Saber más',
		search: 'Búsqueda',
		required: 'Requerido',
		email: 'Correo electrónico',
		situation: 'Situación',
		transferOwnership: 'Transferir Propiedad',
		actual: 'Actual',
		owner: 'Dueño',
		clinic: 'Clínica',
		zipCode: 'Código Postal',
		verifiedByClusterVet: 'Verificado por ClusterVet',
		fullName: 'Nombre Completo',
		birthDate: 'Fecha de nacimiento',
		company: 'Compañía',
		noPhone: 'Sin teléfono',
		verified: 'Verificado',
		pending: 'Pendiente',
		choose: 'Elegir',
		loading: 'Cargando',
		entranceDate: 'Fecha de Entrada',
		samples: 'Muestras',
		requests: 'Requisiciones',
		exams: 'Examen | Examenes',
		day: 'Día',
		days: 'Días',
		hour: 'Hora',
		hours: 'Horas',
		copy: 'Copiar',
		copied: 'Copiado',
		reason: 'Razón',
		modeFullscreen: 'Abrir modo de pantalla completa',
		exitModeFullScreen: 'Salir del modo de pantalla completa',
		selectFile: 'Seleccione Archivo',
		icon: 'Icono',
		created: 'Creado',
		update: 'Actualizado',
		title: 'Título',
		waiting: 'Espera',
		pixels: 'Píxeles',
		line: 'Líneas',
	},
}
