import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		paymentMethods: {
			loading: false,
			data: [],
		},

		payments: {
			loading: false,
			data: [],
		},

		paymentTerms: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	},

	getters: {
		getIndex: (state) => {
			return ({ key, id }) => {
				return state[key].data.findIndex((item) => item.id === id)
			}
		},

		getID: (state) => {
			return ({ key, id }) => {
				return state[key].data.find((item) => item.id === id)
			}
		},
	},

	mutations: {
		setPayments: (state, payments) => (state.payments.data = payments),

		setPaymentMethods: (state, paymentMethods) => (state.paymentMethods.data = paymentMethods),

		addPayment: (state, payment) => state.payments.data.push(payment),

		updatePayment: (state, { index, payment }) => state.payments.data.splice(index, 1, payment),

		setLoading: (state, { key, boolean }) => (state[key].loading = boolean),

		removePayment: (state, { index }) => {
			state.payments.data.splice(index, 1)
		},
	},

	actions: {
		async getAllPayments({ commit }, { entity_id, entity_type, has_bill, payer_id }) {
			try {
				commit('setLoading', { key: 'payments', boolean: ENABLE_LOADING })

				const { status, data: payments } = await API.getPayments(
					entity_id,
					entity_type,
					has_bill,
					payer_id
				)

				if (status === STATUS_SUCCESS) {
					commit('setPayments', payments)
					commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getManyEntityPayments({ commit }, { entity_ids, entity_type }) {
			try {
				commit('setLoading', { key: 'payments', boolean: ENABLE_LOADING })

				const { status, data: payments } = await API.getManyEntityPayments(
					entity_ids,
					entity_type
				)

				if (status === STATUS_SUCCESS) {
					commit('setPayments', payments)
					commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getPaymentMethods({ state, commit }) {
			try {
				if (!state.paymentMethods.data.length) {
					commit('setLoading', { key: 'paymentMethods', boolean: ENABLE_LOADING })

					const { status, data: paymentMethods } = await API.getPaymentMethods()

					if (status === STATUS_SUCCESS) {
						commit('setPaymentMethods', paymentMethods)
						commit('setLoading', { key: 'paymentMethods', boolean: DISABLE_LOADING })
					}

					return status === STATUS_SUCCESS
				}
			} catch (error) {
				commit('setLoading', { key: 'paymentMethods', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createPayment({ commit }, { payload }) {
			try {
				commit('setLoading', { key: 'payments', boolean: ENABLE_LOADING })

				const { status, data: payment } = await API.createPayment(payload)

				if (status === STATUS_CREATED) {
					commit('addPayment', payment)
					commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.financial.feedback.success.create.payment'),
						},
						{ root: true }
					)
				}

				return status === STATUS_CREATED
			} catch (error) {
				commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updatePayment({ getters, commit }, { paymentId, payload }) {
			try {
				commit('setLoading', { key: 'payments', boolean: ENABLE_LOADING })

				const { status, data: payment } = await API.updatePayment(paymentId, payload)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex({ key: 'payments', id: paymentId })

					if (index > -1) {
						commit('updatePayment', { index, payment: payment })
						commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.financial.feedback.success.update.payment'),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removePayment({ getters, commit }, id) {
			try {
				commit('setLoading', { key: 'payments', boolean: ENABLE_LOADING })

				const { status } = await API.removePayment(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex({ key: 'payments', id: id })

					if (index > -1) {
						commit('removePayment', { index: index })
						commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.financial.feedback.success.remove.payment'),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', { key: 'payments', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
