import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import {
	AuthenticationState,
	mutationTypes,
	actionTypes,
	ILogInResponse,
	IAuthGooglePayload,
	IAuthPayload,
} from './types'
import { STATUS_NOT_FOUND, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import API from '../service'
import { updateAbility } from '@/utils/permissions/updateAbility'
import { permissionsMiddleware } from '@/middleware/permissionMiddleware'
import { Route } from 'vue-router'
import { SIGNATURE_NAMESPACE } from '@/modules/signature/store'
import { mutationTypes as mutationTypesOfSignature } from '@/modules/signature/store/mutations/mutation-types'

export const actions: ActionTree<AuthenticationState, RootState> = {
	async [actionTypes.LOG_IN](
		{ commit },
		payload: IAuthPayload
	): Promise<ILogInResponse | boolean> {
		const { status, data: authResponse } = await API.logIn(payload)

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.SET_UPDATE_LOGGED_STATE, authResponse)
			commit(
				`${SIGNATURE_NAMESPACE}/${mutationTypesOfSignature.UPDATE_SIGNATURE}`,
				authResponse.usuario.assinatura,
				{ root: true }
			)

			commit(mutationTypes.SET_IS_LOGGED_IN, true)
		}

		return status === STATUS_SUCCESS ? authResponse : false
	},

	async [actionTypes.LOG_IN_BY_GOOGLE](
		{ commit },
		payload: IAuthGooglePayload
	): Promise<boolean | number> {
		const { status, data: authResponse } = await API.logInByGoogle(payload)

		if (status === STATUS_SUCCESS) {
			if (authResponse.redirect) {
				return STATUS_NOT_FOUND
			}

			commit(mutationTypes.SET_UPDATE_LOGGED_STATE, authResponse)
			commit(mutationTypes.SET_IS_LOGGED_IN, true)
		}

		return status === STATUS_SUCCESS
	},

	async [actionTypes.GET_PERMISSIONS]({ commit }): Promise<boolean | number> {
		const { status, data: permissions } = await API.getPermissions()

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.SET_PERMISSIONS, permissions)
		}

		return status === STATUS_SUCCESS ? permissions : false
	},

	async [actionTypes.SET_PERMISSIONS_USER](_, route: Route): Promise<boolean | void> {
		await updateAbility()

		return permissionsMiddleware(route)
	},
}

export default { actions }
