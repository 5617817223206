// import API from '../service'

export default {
	namespaced: true,

	state: {
		notifications: [],
		contacts: [],
	},

	getters: {},
	mutations: {
		setNotifications: (state, payload) => (state.notifications = payload),
		setContacts: (state, payload) => (state.contacts = payload),
	},
	actions: {},
}
