import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		carregado: false,
		convenios: [],
		selecionado: {},
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.convenios.findIndex((convenio) => convenio.id === id)
			}
		},

		getSigla: (state) => {
			return (sigla) => {
				return state.convenios.find((convenio) => convenio.sigla === sigla)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.convenios.find((convenio) => convenio.id === id)
			}
		},
	},

	mutations: {
		setConvenios: (state, payload) => {
			state.convenios = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.convenios = []
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),
		setCarregado: (state, bool) => (state.carregado = bool),
		backPage: (state, payload) => (state.pagina_atual = payload),
		addAgreement: (state, payload) => state.convenios.push(payload),
		updateAgreement: (state, { index, agreement }) =>
			state.convenios.splice(index, 1, agreement),
		deleteAgreement: (state, index) => state.convenios.splice(index, 1),
		resetSelecionado: (state) => (state.selecionado = {}),
		clearConvenio: (state) => (state.convenios = []),
	},

	actions: {
		selecionar({ commit }, payload) {
			const convenio = Object.assign({}, payload)

			commit('setSelecionado', convenio)
		},

		async getAll({ state, commit }) {
			try {
				if (!state.carregado) {
					const response = await API.getConvenio()

					commit('setConvenios', response)
				}

				return state.convenios
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeConvenio({ getters, commit }, id) {
			try {
				const item = getters.getID(id)
				const convenio = await API.visualizaConvenio(item.id)

				commit('setSelecionado', convenio)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAgreement({ commit }, payload) {
			try {
				const agreement = await API.createAgreement(payload)

				if (agreement) {
					commit('addAgreement', agreement)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.agreements.feedback.success.create.agreement') },
						{ root: true }
					)
				}

				return agreement
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteAgreement({ commit, getters }, id) {
			try {
				const response = await API.deleteAgreement(id)
				const sucessStatus = 200

				if (response.status === sucessStatus) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteAgreement', index)
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.agreements.feedback.success.remove.agreement') },
						{ root: true }
					)
				}

				return response.status === sucessStatus
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateAgreement({ commit, getters }, payload) {
			try {
				const agreement = await API.updateAgreement(payload, payload.id)

				if (agreement) {
					const index = getters.getIndex(payload.id)

					if (index > -1) {
						commit('updateAgreement', { index, agreement })
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.agreements.feedback.success.update.agreement') },
						{ root: true }
					)
				}

				return agreement
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async validateSigla(_, sigla) {
			try {
				const response = await API.validarSigla(sigla)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},
	},
}
