



































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

export interface RadioSelectGroupOption {
	value: string
	title: string
	description?: string
}

@Component({
	name: 'RadioSelectGroup',
})
export default class RadioSelectGroup extends Vue {
	@VModel({ type: String, required: true }) selectedOption!: string

	@Prop({ type: Array, required: true }) readonly options!: RadioSelectGroupOption[]

	@Prop({ type: String, default: '' }) readonly rules!: string
}
