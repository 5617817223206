import API from '@/axios'
const EndpointFinancial = '/financial'
const EndpointPayment = '/payments'
const EndpointMethods = '/methods'
const EndpointMany = '/many'

export default {
	async getPayments(entity_id, entity_type, has_bill, payer_id) {
		return await API.get(EndpointFinancial + EndpointPayment, {
			params: {
				entity_id,
				entity_type,
				has_bill,
				payer_id,
			},
		}).then((response) => response)
	},

	async getManyEntityPayments(entity_ids, entity_type) {
		return await API.get(EndpointFinancial + EndpointPayment + EndpointMany, {
			params: {
				entity_ids,
				entity_type,
			},
		}).then((response) => response)
	},

	async getPaymentMethods() {
		return await API.get(EndpointFinancial + EndpointPayment + EndpointMethods).then(
			(response) => response
		)
	},

	async createPayment(payload) {
		return await API.post(EndpointFinancial + EndpointPayment, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async updatePayment(paymentId, payload) {
		return await API.put(EndpointFinancial + EndpointPayment + `/${paymentId}`, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async removePayment(id) {
		return await API.delete(EndpointFinancial + EndpointPayment + `/${id}/cancel`).then(
			(response) => response
		)
	},
}
