import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { SpecieState } from '../types'
import { Getters, getterTypes as types } from './getter-types'

export const getters: GetterTree<SpecieState, RootState> & Getters = {
	[types.GET_INDEX_SPECIE]: (state) => (specieId) => {
		return state.species.data.findIndex((specie) => specie.id === specieId)
	},
}

export default getters
