/* eslint-disable no-unused-vars */
import { RootState } from '@/store/types'
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'

import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { Getters } from '../getters/getter-types'
import { OrderState } from '../types'

export enum actionTypes {
	GET_ORDERS = 'getAll',
	SEARCH_ORDER = 'searchOrder',

	GET_ORDER = 'getOrder',

	GET_STATUS_ORDER = 'getStatusList',

	SET_ORDER_SELECTED = 'setSelectedOrder',

	CREATE_ORDER = 'createOrder',
	UPDATE_ORDER = 'updateOrder',
	REMOVE_ORDER = 'removeOrder',
	CANCEL_ORDER = 'cancelOrder',

	UPDATE_STATUS_ORDER = 'updateOrderStatus',

	GET_ATTACHMENTS_ORDER = 'getAllAttachmentsOrder',

	CREATE_ATTACHMENT = 'createAttachment',
	REMOVE_ATTACHMENT = 'removeAttachmentOrder',
	DOWNLOAD_ATTACHMENT = 'downloadFileAttachment',

	CHECK_DRAFT_ORDER_REQUESTS_FOR_PATIENT = 'checkDraftOrderRequestsForPatient',

	CHECK_DRAFT_ORDER_REQUESTS = 'checkDraftOrderRequests',

	GET_ITEMS_BY_SEARCH = 'getItemsBySearch',
}

export interface Actions {
	[actionTypes.GET_ORDERS](
		{ getters, commit }: AugmentedActionContext,
		page: number
	): Promise<any>

	[actionTypes.SEARCH_ORDER](
		{ getters, commit }: AugmentedActionContext,
		data: { filters: any; reset: boolean; store: boolean; storePerStatus: boolean }
	): Promise<any>

	[actionTypes.GET_ORDER]({ getters, commit }: AugmentedActionContext, id: number): Promise<any>

	[actionTypes.SET_ORDER_SELECTED]({ commit }: AugmentedActionContext, payload: any): void

	[actionTypes.CREATE_ORDER]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.UPDATE_ORDER](
		{ commit }: AugmentedActionContext,
		data: { payload: any; orderId: number | string }
	): Promise<any>

	[actionTypes.REMOVE_ORDER](
		{ commit }: AugmentedActionContext,
		data: { orderId: number | string; payload: any; statusId: number }
	): Promise<any>

	[actionTypes.UPDATE_STATUS_ORDER](
		{ commit }: AugmentedActionContext,
		payload: any
	): Promise<any>

	[actionTypes.CANCEL_ORDER](
		{ state, commit, getters }: AugmentedActionContext,
		data: { orderId: number | string; payload: any; statusId: number }
	): Promise<any>

	[actionTypes.GET_ATTACHMENTS_ORDER](
		{ state, commit }: AugmentedActionContext,
		id_order: string | number
	): Promise<any>

	[actionTypes.CREATE_ATTACHMENT](
		{ state, commit }: AugmentedActionContext,
		payload: any
	): Promise<any>

	[actionTypes.REMOVE_ATTACHMENT](
		{ state, commit }: AugmentedActionContext,
		uuid: string
	): Promise<any>

	[actionTypes.DOWNLOAD_ATTACHMENT](
		{ commit }: AugmentedActionContext,
		uuid: string
	): Promise<any>

	[actionTypes.GET_STATUS_ORDER]({ commit }: AugmentedActionContext): Promise<any>

	[actionTypes.CHECK_DRAFT_ORDER_REQUESTS_FOR_PATIENT](
		{ commit }: AugmentedActionContext,
		data: { orderId: number | string; patientId: number }
	): Promise<any>

	[actionTypes.CHECK_DRAFT_ORDER_REQUESTS](
		{ commit }: AugmentedActionContext,
		data: { orderId: number | string }
	): Promise<any>

	[actionTypes.GET_ITEMS_BY_SEARCH](
		{ commit }: AugmentedActionContext,
		data: { orderId: number | string; search: null | string }
	): Promise<any>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<OrderState, RootState>, 'getters' | 'commit' | 'dispatch'>
