import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { AuthenticationState } from './types'
import { getters } from './getters'
import { mutations } from './mutations'
import { mutations as mutationsOfRefreshToken } from './refresh_token/mutations'
import { actions } from './actions'
import { actions as actionsOfRefreshToken } from './refresh_token/actions'

export const state: AuthenticationState = {
	isLoggedIn: false,
	permissions: [],
	token: {
		token_type: 'Bearer',
		access_token: undefined,
		expires_in: undefined,
		refresh_token: undefined,
	},
	loggedUser: undefined,
	tokenUpdateTimerId: undefined,
}

export const auth: Module<AuthenticationState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	mutations: { ...mutations, ...mutationsOfRefreshToken },
	actions: { ...actions, ...actionsOfRefreshToken },
}
