






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import TileButton from '@/components/atomic/atoms/Buttons/TileButton.vue'

@Component({
	name: 'ItemsNotFound',
	components: {
		TileButton,
	},
})
export default class ItemsNotFound extends Vue {
	@Prop({ type: String, default: undefined }) title?: string
	@Prop({ type: String, default: undefined }) subtitle?: string
	@Prop({ type: String, default: undefined }) buttonName?: string
	@Prop({ type: Boolean, default: undefined }) disabled?: boolean
	@Prop({ type: Boolean, default: undefined }) loading?: boolean
	@Prop({ type: String, default: 'inherit' }) minWidthContainerImg!: string
	@Prop({ type: String, default: '400px' }) maxWidthContainerImg!: string
	@Prop({ type: String, default: 'inherit' }) minHeightContainerImg!: string
	@Prop({ type: String, default: '400px' }) maxHeightContainerImg!: string
	@Prop({ type: String, default: '538px' }) maxWidthContainerButton!: string
	@Prop({ type: String, default: '' }) contentClass!: string
	@Prop({ type: String, default: 'my-4' }) contentClassTitle!: string
	@Prop({ type: String, default: 'import-photos/photoImport1.svg' }) img!: string

	click() {
		this.$emit('click')
	}
}
