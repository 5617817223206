import { IPermissions } from '@/modules/authentication/store/types'
import Vue from 'vue'
import { Route } from 'vue-router'

export function permissionsMiddleware(to: Route): boolean {
	const { permissions } = to.meta as { permissions: IPermissions[] }

	if (permissions) {
		const hasPermission = permissions.some((permission) => {
			const { action, resource } = permission

			return Vue.prototype.$ability.can(action, resource)
		})

		return hasPermission
	}

	return true
}

export default { permissionsMiddleware }
