import API from '@/axios'
import { IResponseEquipment } from '@/modules/equipments/store/types'
import { IResponseForm } from '@/modules/forms/store/types'
import { IResponseMaterial } from '@/modules/materiais/service/types'
import { IResponseMethod } from '@/modules/methods/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { AxiosResponse } from 'axios'
import { IExamFilters } from '../store/types'
import {
	IPayloadExam,
	IPayloadUpdateExam,
	IPayloadUpdateExamEquipments,
	IPayloadUpdateExamForms,
	IPayloadUpdateExamMaterials,
	IPayloadUpdateExamMethods,
	IPayloadUpdateExamStatus,
	IPayloadUpdateExamTechnicalManager,
	IResponseExam,
} from './types'

const Endpoint = '/models/exams/'
const EndpointInterfacing = '/interfacing'
const ModelsEndpoint = '/models/'
const RotuloEndpoint = '/rotulos/'

export default {
	async getExam(id: number): Promise<AxiosResponse<IResponseExam>> {
		return await API.get(`${Endpoint}${id}`)
	},

	async getExams(
		filters: IExamFilters
	): Promise<AxiosResponse<ITypeOrmPaginationTemplate<IResponseExam>>> {
		return await API.get(Endpoint, { params: filters })
	},

	async createExam(payload: IPayloadExam): Promise<AxiosResponse<IResponseExam | boolean>> {
		return await API.post(Endpoint, payload)
	},

	async cloneExam(id: string): Promise<AxiosResponse<IResponseExam | boolean>> {
		return await API.post(`${Endpoint}/${id}/actions/clone`)
	},

	async updateExam({
		id,
		payload,
	}: IPayloadUpdateExam): Promise<AxiosResponse<IResponseExam | boolean>> {
		return await API.put(`${Endpoint}${id}`, payload)
	},

	async updateExamStatus({
		id,
		payload,
	}: IPayloadUpdateExamStatus): Promise<AxiosResponse<IResponseExam | boolean>> {
		return await API.patch(`${Endpoint}${id}`, payload)
	},

	async updateExamForms({
		id,
		payload,
	}: IPayloadUpdateExamForms): Promise<AxiosResponse<IResponseForm[]>> {
		return await API.patch(`${Endpoint}${id}/form`, payload)
	},

	async updateExamEquipments({
		id,
		payload,
	}: IPayloadUpdateExamEquipments): Promise<AxiosResponse<IResponseEquipment[]>> {
		return await API.patch(`${Endpoint}${id}/equipment`, payload)
	},

	async updateExamMethods({
		id,
		payload,
	}: IPayloadUpdateExamMethods): Promise<AxiosResponse<IResponseMethod[]>> {
		return await API.patch(`${Endpoint}${id}/method`, payload)
	},

	async updateExamMaterials({
		id,
		payload,
	}: IPayloadUpdateExamMaterials): Promise<AxiosResponse<IResponseMaterial[]>> {
		return await API.patch(`${Endpoint}${id}/material`, payload)
	},

	async updateExamTechnicalManager({
		id,
		payload,
	}: IPayloadUpdateExamTechnicalManager): Promise<AxiosResponse<IResponseMaterial[]>> {
		return await API.patch(`${Endpoint}${id}/responsible-technician`, payload)
	},

	async deleteExam(
		id: number
	): Promise<AxiosResponse<{ message: string; deleted: boolean } | boolean>> {
		return await API.delete(`${Endpoint}${id}`)
	},

	async getExamModels() {
		return await API.get(ModelsEndpoint + 'exam?default=true')
	},

	async useExamModel(id: string) {
		return await API.post(ModelsEndpoint + 'exam/create-from-model/' + id)
	},

	async getMappedExamFields(id: number) {
		return await API.get(EndpointInterfacing + '/exams/' + id + '/mapped-exam-fields/all')
	},

	async selectTag(id: number, idExam: number) {
		return await API.post(RotuloEndpoint + id + '/exames', { id: idExam }).then(
			(response) => response.data
		)
	},

	async deselectTag(id: number, idExam: number) {
		return await API.delete(RotuloEndpoint + id + '/exames', { data: { id: idExam } }).then(
			(response) => response.data
		)
	},
}
