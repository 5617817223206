























































































































import { Component, Vue, Prop, VModel, Mixins } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import {
	actionTypes,
	IReference,
	IReferenceEditMode,
	IReferenceGroup,
} from '@/modules/exams/store/references/types'
import InputAutoComplete from '@/components/atomic/atoms/Inputs/InputAutoComplete.vue'
import InputTextField from '@/components/atomic/atoms/Inputs/InputTextField.vue'
import {
	IDeleteReferenceGroupParams,
	IUpdateReferenceParams,
} from '@/modules/exams/services/references/types'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import Chip from '@/components/atomic/atoms/Chip.vue'
import AlertConfirmation from '@/components/global/AlertConfirmation.vue'
import GetSpeciesToSelectMixin from '@/modules/exams/mixins/GetSpeciesToSelectMixin'

@Component({
	name: 'ReferenceFormHeader',
	components: {
		InputAutoComplete,
		InputTextField,
		ButtonIcon,
		Chip,
		AlertConfirmation,
	},
	mixins: [GetSpeciesToSelectMixin],
})
export default class ReferenceFormHeader extends Mixins(GetSpeciesToSelectMixin) {
	@VModel({ type: Object, required: true })
	form!: any

	@Prop({ type: Boolean, default: true }) showAttributes!: boolean

	@State(({ Exams }) => Exams.selectedReference) reference!: IReference
	@State(({ Exams }) => Exams.referenceGroups) referenceGroups!: IReferenceGroup[]

	openAlertConfirmation = false
	groupToRemove: IReferenceGroup | null = null
	alertConfirmationText = {
		title: 'Remover grupo',
		subtitle:
			'Você tem certeza que deseja remover o grupo selecionado? O grupo será removido de todos os campos que o utilizam.',
	}

	@Action(`Exams/${actionTypes.UPDATE_REFERENCE}`)
	updateReference!: (payload: IUpdateReferenceParams) => Promise<void>

	@Action(`Exams/${actionTypes.DELETE_REFERENCE_GROUP}`)
	deleteReferenceGroup!: (payload: IDeleteReferenceGroupParams) => Promise<void>

	async handleUpdateReference() {
		const { nome, especie_id, idade_inicial, idade_final, tipo_idade } = this.form

		await this.updateReference({
			referenceId: this.reference.id,
			payload: {
				nome,
				especie_id: especie_id === 0 ? null : especie_id,
				idade_inicial: idade_inicial || 0,
				idade_final: idade_final || 0,
				tipo_idade,
			},
		})
	}

	handleDeleteReferenceGroup() {
		if (this.groupToRemove)
			this.deleteReferenceGroup({
				groupId: this.groupToRemove.id,
				payload: {
					editMode: IReferenceEditMode.ALL_GROUPS,
				},
			})
	}

	openAlertRemove(group: IReferenceGroup) {
		this.groupToRemove = group
		this.openAlertConfirmation = true
	}
}
