const routes = [
	{
		name: 'request.index',
		path: '/requests',
		component: () => import(/* webpackChunkName: 'requests' */ './views/index'),
		meta: {
			permissions: [{ action: 'read', resource: 'Request' }],
		},
	},

	{
		name: 'request.checkout',
		path: '/requests/checkout',
		component: import(/* webpackChunkName: 'requestCheckout' */ './views/RequestCheckoutIndex'),
		meta: {
			permissions: [{ action: 'read', resource: 'Request' }],
		},
	},

	{
		name: 'request.checkout.resume',
		path: '/requests/checkout/resume',
		component: import(
			/* webpackChunkName: 'requestCheckoutResume' */
			'./components/RequestCheckout/ResumeRequest/ResumeRequestIndex'
		),
		meta: {
			permissions: [{ action: 'read', resource: 'Request' }],
		},
	},
]

export default routes
