import API from '@/axios'
import axios from 'axios'

const Endpoint = '/clientes/' // aqui é o erro
const EndpointRotulo = '/rotulos/'

const clientAPI = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 30000,
})

const ZipcodeApi = axios.create({
	baseURL: 'https://viacep.com.br/ws/',
	timeout: 30000,
})

export default {
	// TODO: achei o erro do delay, trazendo clientes sem paginação
	async getClientes(deleted) {
		return await API.get(Endpoint, { params: { deleted } }).then((response) => response.data)
	},

	async restoreCustomer(uuid) {
		return await API.patch(`${Endpoint}/${uuid}/restore`).then((response) => response)
	},

	async getClientsLaboratories(token, organizacao) {
		return await clientAPI
			.get(Endpoint, {
				headers: {
					Authorization: 'Bearer ' + token,
					'organizacao-id': organizacao,
				},
			})
			.then((response) => response.data)
	},

	async importarClientes(titulo, importar, erros) {
		return await API.post(Endpoint + 'importar', { titulo, importar, erros }).then(
			(response) => response.data
		)
	},

	async visualizaCliente(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async criarCliente(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async editarCliente(payload, uuid) {
		return await API.put(Endpoint + uuid, payload).then((response) => response.data)
	},

	async updateClientImage(payload, uuid) {
		return await API.post(Endpoint + uuid + '/update-image', payload).then(
			(response) => response.data
		)
	},

	async deleteCustomer(uuid) {
		return await API.delete(Endpoint + uuid).then((response) => response.data)
	},

	async getHistoricoImportacao() {
		return await API.get(Endpoint + 'importar').then((response) => response.data)
	},

	async visualizaHistorico(id) {
		return await API.get(Endpoint + 'importar/' + id).then((response) => response.data)
	},

	async excluirHistoricoImportacao(id) {
		return await API.delete(Endpoint + /importar/ + id).then((response) => response.data)
	},

	async validarCPF(cpf) {
		const novo_cpf = cpf.replace(/[^\d]/g, '')

		return await API.post(Endpoint + 'validate/cpf', { cpf: novo_cpf }).then(
			(response) => response.data
		)
	},

	async validarCNPJ(cnpj) {
		const novo_cnpj = cnpj.replace(/[^\d]/g, '')

		return await API.post(Endpoint + 'validate/cnpj', { cnpj: novo_cnpj }).then(
			(response) => response.data
		)
	},

	async validarEmail(email) {
		return await API.post(Endpoint + 'validate/email', { email: email }).then(
			(response) => response.data
		)
	},

	async selectTag(id, idCustomer) {
		return await API.post(EndpointRotulo + id + '/clientes', { id: idCustomer }).then(
			(response) => response.data
		)
	},

	async deselectTag(id, idCustomer) {
		return await API.delete(EndpointRotulo + id + '/clientes', {
			data: { id: idCustomer },
		}).then((response) => response.data)
	},

	async getAddressZipCode(cep) {
		return await ZipcodeApi.get(cep + '/json/').then((response) => response.data)
	},

	async createLinkedCustomer(payload) {
		return await API.post(Endpoint + 'link-to/', payload).then((response) => response)
	},
}
