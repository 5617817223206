<template>
	<div class="d-content">
		<!-- Group Attribute -->
		<v-skeleton-loader
			width="100%"
			height="100%"
			max-height="48"
			type="heading"
			class="group-attribute ma-0 pa-0"
		/>

		<!-- Table Attributes -->
		<v-skeleton-loader width="100%" height="100%" type="table-tbody@1" class="ma-0 pa-0" />
	</div>
</template>

<script>
export default {
	name: 'InsertionResultLoader',
}
</script>

<style scoped lang="scss">
.d-content {
	display: contents;
}

::v-deep .group-attribute .v-skeleton-loader__heading {
	min-height: 48px;
	border-radius: 4px;
	width: 100%;
	border-left: 6px solid rgba(0, 0, 0, 0.12);
}
</style>
