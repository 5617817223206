import { IGlobalState, IOptionsAlertConfirmation } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	OPEN_MODAL_CONFIRMATION = 'OPEN_MODAL_CONFIRMATION',
	TRIGGER_EVENTS_MODAL_CONFIRMATION = 'TRIGGER_EVENTS_MODAL_CONFIRMATION',

	CLOSE_MODAL_CONFIRMATION = 'SET_MODAL_CONFIRMATION',
}

export type Mutations<S = IGlobalState> = {
	[mutationTypes.OPEN_MODAL_CONFIRMATION](state: S, options: IOptionsAlertConfirmation): void

	[mutationTypes.CLOSE_MODAL_CONFIRMATION](state: S): void

	[mutationTypes.TRIGGER_EVENTS_MODAL_CONFIRMATION](state: S, event: 'click:action'): void
}
