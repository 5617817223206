/* eslint-disable no-case-declarations */
import Vue from 'vue'
import { format, parseISO } from 'date-fns'
import i18n from '../../plugins/vue-i18n'
import { enUS, pt } from 'date-fns/locale'

Vue.filter('localDate', (value) => {
	switch (i18n.locale) {
		case 'pt':
			return format(new Date(value), 'dd/MM/yyyy', { locale: pt })
		case 'en':
			return format(new Date(value), 'dd/MM/yyyy', { locale: pt })
		default:
			return value
	}
})

Vue.filter('localDateTime', (value) => {
	switch (i18n.locale) {
		case 'pt':
			return format(new Date(value), 'dd/MM/yyyy HH:mm (EEEE)', { locale: pt })
		case 'en':
			return format(new Date(value), 'MM/dd/yyyy HH:mm (EEEE)', { locale: enUS })
		default:
			return value
	}
})

Vue.filter('timeAgo', (date) => {
	const formatter = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'always' })
	const timeDivision = [
		{ amount: 60, name: 'seconds' },
		{ amount: 60, name: 'minutes' },
		{ amount: 24, name: 'hours' },
		{ amount: 7, name: 'days' },
		{ amount: 4.34524, name: 'weeks' },
		{ amount: 12, name: 'months' },
		{ amount: Number.POSITIVE_INFINITY, name: 'years' },
	]

	if (date === null) {
		return ''
	} else {
		date = new Date(date)

		let duration = (date - new Date()) / 1000

		for (let i = 0; i <= timeDivision.length; i++) {
			const division = timeDivision[i]

			if (Math.abs(duration) < division.amount) {
				return formatter.format(Math.round(duration), division.name)
			}

			duration /= division.amount
		}
	}
})
