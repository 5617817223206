import API from '@/axios'
import { ICustomer } from '@/modules/clientes/store/types'
import { AxiosResponse } from 'axios'
import {
	IAgreementResponse,
	ICreateAgreementPayload,
	IExamAgreementResponse,
} from '../../store/types'
import { IGetAgreementsQuery, IUpdateAgreementParams } from './types'

const Endpoint = '/models/agreements'
const EndpointCustomers = '/clientes'
const EndpointExams = '/models/exam-requests'

export default {
	async getAgreements(query: IGetAgreementsQuery): Promise<AxiosResponse<IAgreementResponse[]>> {
		return await API.get(Endpoint, { params: query })
	},

	async getCustomerAgreements(id: number): Promise<AxiosResponse<IAgreementResponse[]>> {
		return await API.get(Endpoint + `/customer/` + id)
	},

	async getCustomersAgreements(hasAgreement = false): Promise<AxiosResponse<ICustomer[]>> {
		return await API.get(EndpointCustomers, { params: { hasAgreement: hasAgreement } }).then(
			(response) => response
		)
	},

	async getExamsAgreements({
		hasAgreement,
		page,
		limit,
		search,
	}: {
		hasAgreement?: boolean
		page: number
		limit: number
		search?: string
	}): Promise<AxiosResponse<IExamAgreementResponse[]>> {
		return await API.get(EndpointExams + `/available-exams`, {
			params: { hasAgreement: hasAgreement, page: page, limit: limit, search: search },
		}).then((response) => response)
	},

	async createAgreement(
		payload: ICreateAgreementPayload
	): Promise<AxiosResponse<IAgreementResponse>> {
		return await API.post(Endpoint, payload)
	},

	async updateAgreement({
		payload,
		agreementId,
	}: IUpdateAgreementParams): Promise<AxiosResponse<IAgreementResponse>> {
		return await API.put(Endpoint + `/${agreementId}`, payload)
	},

	async visualizeAgreement(agreementId: number): Promise<AxiosResponse<IAgreementResponse>> {
		return await API.get(Endpoint + `/${agreementId}`)
	},

	async updateStatusAgreement({
		agreementId,
		payload,
	}: {
		agreementId: number
		payload: { status: boolean }
	}): Promise<AxiosResponse<IAgreementResponse>> {
		return await API.patch(Endpoint + `/${agreementId}`, payload)
	},

	async removeAgreement(
		agreementId: number
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.delete(Endpoint + `/${agreementId}`)
	},

	async removeAgreements(agreementsToRemove: {
		ids: number[]
	}): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.delete(Endpoint, { data: agreementsToRemove })
	},

	async validateAbbreviation(
		abbreviation: string
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.post(Endpoint + `/validate/abbreviation`, { abbreviation: abbreviation })
	},

	async validateAbbreviationByAgreementId(
		agreementId: number,
		abbreviation: string
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.post(Endpoint + `/${agreementId}/validate/abbreviation`, {
			abbreviation: abbreviation,
		})
	},
}
