import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions/actions'
import { getters } from './getters/getters'
import { mutations } from './mutations/mutations'
import { actions as actionsCustomers } from './actions/customers/actions'
import { actions as actionsAnimals } from './actions/animals/actions'
import { mutations as mutationsCustomers } from './mutations/customers/mutations'
import { mutations as mutationsAnimals } from './mutations/animals/mutations'
import { getters as gettersCustomers } from './getters/customers/getters'
import { ReceivingState } from './types'

export const state: ReceivingState = {
	load: false,
	selectedRequest: {},
	payers: [],
	requests: {
		data: [],
		links: {},
		meta: {},
	},
	filter: {
		requests: {
			search: null,
			examRequestStatus: [2, 3, 4, 5, 6],
			orderBy: 'entrance_date',
			orderDirection: 'desc',
		},
		exams: {
			search: null,
			orderBy: 'updated_at',
			orderDirection: 'desc',
		},
	},
	requestStatus: [],
	selectedClinic: {},
	selectedDoctor: {},
	selectedPetOwner: {},
	selectedPatient: {},
	observations: {
		data: [],
		links: {},
		meta: {},
	},
	requestedExams: {
		data: [],
		links: {},
		meta: {},
		currentPage: 1,
		perPage: 20,
	},

	requestedExamGroups: {
		data: [],
		links: {},
		meta: {},
	},
	availableExams: {
		data: [],
		links: {},
		meta: {},
	},
	availableExamGroups: {
		data: [],
		links: {},
		meta: {},
	},
	exams: {
		data: [],
		links: {},
		meta: {},
	},
	statusRequest: [
		{ id: 1, name: 'Em rascunho', preffix: 'Rascunho' },
		{ id: 2, name: 'Novo', preffix: 'Novo' },
		{ id: 3, name: 'Aguardando rota', preffix: 'Aguard. Rota' },
		{ id: 4, name: 'Em trânsito', preffix: 'Em trânsito' },
		{ id: 5, name: 'Não conformidade', preffix: 'NC' },
		{ id: 6, name: 'Em progresso', preffix: 'Em progresso' },
		{ id: 7, name: 'Finalizado', preffix: 'Finalizado' },
		{ id: 8, name: 'Cancelado', preffix: 'Cancelado' },
	],
	statusExams: [],
	attachments: [],
	forms: [],
	sampleStatus: [],
	samples: {
		data: [],
		links: {},
		meta: {},
	},
	priorities: [
		{
			id: 4,
			label: 'Urgente',
			translate: 'urgent',
			color: 'error',
			icon: 'mdi-flag',
		},
		{
			id: 3,
			label: 'Alta',
			translate: 'hight',
			color: 'warning',
			icon: 'mdi-flag',
		},
		{
			id: 2,
			label: 'Normal',
			translate: 'normal',
			color: 'info',
			icon: 'mdi-flag',
		},
		{
			id: 1,
			label: 'Baixa',
			translate: 'low',
			color: 'backgroundapp darken-2',
			icon: 'mdi-flag',
		},
	],
	allRequestedsExams: {
		data: [],
		links: {},
		meta: {},
	},
	loadingReceivingSubHeader: false,
	cartSelectItems: [],
	loadingAvailableExams: false,

	openStakeholderSelection: false,
	selectedActors: {
		customer: undefined,
		veterinarian: undefined,
		patient: undefined,
		petOwner: undefined,
	},
	customers: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},
	customerFilters: {
		limit: 10,
		page: 1,
		search: '',
		deleted: false,
		customerType: undefined,
		belongsTo: undefined,
		parentsOf: undefined,
	},
	animals: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
		loading: false,
	},
	animalFilters: {
		page: 1,
		limit: 10,
		breedId: undefined,
		customerId: undefined,
		hasRelationships: true,
		hasRequests: undefined,
		orderBy: undefined,
		orderDirection: undefined,
		search: undefined,
		sex: undefined,
		speciesId: undefined,
	},
	customerIsLoading: false,
	animalIsLoading: false,
	isModeCreateReceiving: false,
	selectionMode: 'Customer',
	samplePrintConfig: {
		typeBarcode: 'ITF14',
		valueBarcode: undefined,
		height: 10,
		margin: 0,
		scale: 1,
		textMargin: 0,
		fontSize: 13,
		includetext: true,
		paddingwidth: 0,
		showborder: false,
		paddingLeftBarcode: 0,
		paddingLeft: 0,
		paddingTop: 0,
		onlyBarcode: false,
		showRecipient: true,
		showMaterial: true,
		showSpecie: true,
	},
	isErrorGenerateBarcode: false,
	loadingRequestedExams: false,
	loadingStakeholderSelection: false,
}

export const RECEIVING_NAMESPACE = 'Receiving'

export const receiving: Module<ReceivingState, RootState> = {
	namespaced: true,
	state: state,
	getters: { ...getters, ...gettersCustomers },
	mutations: { ...mutations, ...mutationsCustomers, ...mutationsAnimals },
	actions: { ...actions, ...actionsCustomers, ...actionsAnimals },
}
