import { types as errorTypes } from '@/modules/errors/store/types'
import store from '@/store/store'
import { STATUS_NOT_FOUND, STATUS_UNAUTHORIZED } from '@/utils/constants/statusConstants'
import { AxiosInstance } from 'axios'
import refreshToken from './axios_referesh_token'

const responseInterceptorResponse = (Http: AxiosInstance) => {
	return Http.interceptors.response.use(
		(config) => {
			return config
		},
		async (error) => {
			const prevRequest = error.config

			const {
				token: { refresh_token },
			} = store.state.Auth

			if (
				error?.response?.status === STATUS_UNAUTHORIZED &&
				!prevRequest.sent &&
				refresh_token
			) {
				prevRequest.sent = true

				await refreshToken()

				return error
			} else if (
				error?.response?.status !== STATUS_UNAUTHORIZED &&
				error?.response?.status !== STATUS_NOT_FOUND
			) {
				store.commit(`Errors/${errorTypes.ADD_ERROR}`, error.response)
			}

			throw error
		}
	)
}

export default responseInterceptorResponse
