/* eslint-disable no-unused-vars */
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'
import { SpecieState, ISpecieResponse, ISpeciePayload } from '../types'
import { RootState } from '@/store/types'

import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
import { Getters } from '../getters/getter-types'

export enum actionTypes {
	GET_SPECIES = 'GET_SPECIES',

	CREATE_SPECIE = 'CREATE_SPECIE',
	UPDATE_SPECIE = 'UPDATE_SPECIE',
	REMOVE_SPECIE = 'REMOVE_SPECIE',
}

export interface Actions {
	[actionTypes.GET_SPECIES](
		{ commit }: AugmentedActionContext,
		filters: IFiltersTemplate
	): Promise<ITypeOrmPaginationTemplate<ISpecieResponse> | boolean>

	[actionTypes.CREATE_SPECIE](
		{ commit }: AugmentedActionContext,
		payload: ISpeciePayload
	): Promise<ISpecieResponse | boolean>

	[actionTypes.UPDATE_SPECIE](
		{ getters, commit }: AugmentedActionContext,
		{ specieId, payload }: { specieId: number; payload: ISpeciePayload }
	): Promise<ISpecieResponse | boolean>

	[actionTypes.REMOVE_SPECIE](
		{ getters, commit }: AugmentedActionContext,
		specieId: number
	): Promise<boolean>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<SpecieState, RootState>, 'getters' | 'commit' | 'dispatch'>
