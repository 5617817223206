const InsertResultIndex = () =>
	import(/* webpackChunkName: 'insertResult' */ './views/InsertResultIndex')

const InsertResultExamGroupIndex = () =>
	import(
		/* webpackChunkName: 'insertResultExamGroupIndex' */
		'@/modules/insertionResultExamGroup/views/InsertResultExamGroupIndex'
	)

const ResultViewIndex = () =>
	import(/* webpackChunkName: 'resultViewIndex' */ './views/ResultViewIndex')

const ResultViewIndexV2 = () =>
	import(/* webpackChunkName: 'resultViewIndexV2' */ './views/ResultViewIndexV2')

export const insertResultRoutes = [
	{
		name: 'insertResult.index',
		path: '/insert-result/:id',
		component: InsertResultIndex,
	},
]

export const resultRoutes = [
	{
		name: 'resultView.index',
		path: '/results',
		query: 'exam',
		meta: { auth: false },
		component: ResultViewIndex,
	},
]

export const previewResultRoutes = [
	{
		name: 'previewResult.index',
		path: '/insert-result/:id/preview',
		component: ResultViewIndex,
	},
	{
		name: 'previewResultv2.index',
		path: '/insert-result/:id/v2/preview',
		component: ResultViewIndexV2,
	},
]

export const insertResultExamGroupRoutes = [
	{
		name: 'insertResultExamGroup.index',
		path: '/insert-result/examGroup/:requestId/:examRequestedId',
		component: InsertResultExamGroupIndex,
	},
]
