import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { AnimalState } from '../types'
import { Getters, getterTypes as types } from './getter-types'

export const getters: GetterTree<AnimalState, RootState> & Getters = {
	[types.GET_INDEX_ANIMAL_PAGINATION]: (state) => (currentPage) => {
		return state.animals.data.findIndex(
			(pagination) => pagination.meta.current_page === currentPage
		)
	},

	[types.GET_ANIMAL_PAGINATION]: (state) => (currentPage) => {
		return state.animals.data.find((pagination) => pagination.meta.current_page === currentPage)
	},

	[types.GET_INDEX_ANIMAL]:
		(state) =>
		({ indexAnimalPagination, animalId }) => {
			return state.animals.data[indexAnimalPagination].data.findIndex(
				(animal) => animal.id === animalId
			)
		},

	[types.GET_INDEX_REACTIVITY_ANIMAL]:
		(state) =>
		({ currentPage, animalId }) => {
			const indexAnimalPagination = state.animals.data.findIndex(
				(pagination) => pagination.meta.current_page === currentPage
			)

			if (indexAnimalPagination === -1) return -1

			const indexAnimal = state.animals.data[indexAnimalPagination].data.findIndex(
				(animal) => animal.id === animalId
			)

			if (indexAnimal === -1) return -1

			return { indexAnimalPagination: indexAnimalPagination, indexAnimal: indexAnimal }
		},
}

export default getters
