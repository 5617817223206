function mergeObjectsWithoutDuplicates({
	arrayLoaded,
	newArray,
	keyToCompare,
	keyDateLatest = 'updated_at',
}) {
	const concatItems = [...arrayLoaded, ...newArray]

	const mergedArray = concatItems.reduce((acc, currentItem) => {
		const existingItemIndex = acc.findIndex(
			(item) => item[keyToCompare] === currentItem[keyToCompare]
		)

		if (existingItemIndex === -1) {
			acc.push(currentItem)
		} else {
			const existingItem = acc[existingItemIndex]

			if (!currentItem[keyDateLatest]) {
				acc[existingItemIndex] = currentItem
			} else if (currentItem[keyDateLatest] > existingItem[keyDateLatest]) {
				acc[existingItemIndex] = currentItem
			}
		}

		return acc
	}, [])

	return mergedArray
}

export { mergeObjectsWithoutDuplicates }
