/* eslint-disable no-unused-vars */
import { RootState } from '@/store/types'
import { ActionContext } from 'vuex'
import { Mutations } from '../../mutations/animals/mutation-types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ReceivingState } from '../../types'
import { IAnimalResponse } from '@/modules/animals/store/types'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'

export enum actionTypes {
	GET_ANIMALS = 'GET_ANIMALS',

	GET_PATIENTS_PET_OWNER_CHILDREN = 'GET_PATIENTS_PET_OWNER_CHILDREN',
}

export interface Actions {
	[actionTypes.GET_ANIMALS](
		{ state }: AugmentedActionContext,
		filters: IFiltersTemplate
	): Promise<ITypeOrmPaginationTemplate<IAnimalResponse> | boolean>

	[actionTypes.GET_PATIENTS_PET_OWNER_CHILDREN](
		{ state, getters, commit }: AugmentedActionContext,
		data: { petOwnerId: string | number; ownerActorId: string | number }
	): void
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: any

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	// dispatch<K extends keyof any>(
	// 	key: K,
	// 	payload: Parameters<Actions[K]>[1],
	// 	root?: { root: boolean }
	// ): ReturnType<Actions[K]>
} & Omit<ActionContext<ReceivingState, RootState>, 'getters' | 'commit' /* | 'dispatch' */>
