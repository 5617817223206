import { ReceivingState } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_INDEX_REQUEST = 'getIndexRequest',
	GET_REQUEST_BY_ID = 'getRequestById',

	GET_REQUEST_INDEX_BY_REQUESTED_EXAM_ID = 'getRequestIndexByRequestedExamId',
	GET_PAYER_INDEX = 'getPayerIndex',
	GET_INDEX_OBSERVATION = 'getIndexObservation',
	GET_ID_OBSERVATION = 'getIDObservation',

	GET_INDEX_EXAM = 'getIndexExam',
	GET_ID_EXAM = 'getIDExam',

	GET_INDEX_EXAM_GROUP = 'getIndexExamGroup',
	GET_INDEX_EXAM_GROUP_REQUESTED_EXAM = 'getIndexExamGroupRequestedExam',
	GET_ID_EXAM_GROUP = 'getIdExamGroup',

	GET_INDEX_AVAILABLE_EXAM = 'getIndexAvailableExam',
	GET_ID_AVAILABLE_EXAM = 'getIdAvailableExam',
	GET_INDEX_AVAILABLE_EXAM_GROUPS = 'getIndexAvailableExamGroups',
	GET_ID_AVAILABLE_EXAM_GROUPS = 'getIdAvailableExamGroups',

	GET_INDEX_EXAMS = 'getIndexExams',
	GET_INDEX_REQUESTED_EXAMS = 'getIndexRequestedExams',
	GET_INDEX_ATTACHMENT = 'getIndexAttachment',

	GET_ID_ATTACHMENT = 'getIdAttachment',

	GET_INDEX_SAMPLE = 'getIndexSample',
	GET_SAMPLE_BY_ID = 'getSampleById',

	GET_REQUEST_FILTERS_WITHOUT_NULL = 'getRequestFiltersWithoutNull',
	GET_INDEX_ITEM_CARD = 'getIndexItemCard',
}

export type Getters<S = ReceivingState> = {
	[getterTypes.GET_INDEX_REQUEST]: (state: S) => (id: string | number) => number

	[getterTypes.GET_REQUEST_BY_ID]: (state: S) => (id: string | number) => any

	[getterTypes.GET_REQUEST_INDEX_BY_REQUESTED_EXAM_ID]: (
		state: S
	) => (id: string | number) => number

	[getterTypes.GET_PAYER_INDEX]: (state: S) => (id: string | number) => number

	[getterTypes.GET_INDEX_OBSERVATION]: (state: S) => (id: string | number) => number

	[getterTypes.GET_ID_OBSERVATION]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_EXAM]: (state: S) => (id: string | number) => number

	[getterTypes.GET_ID_EXAM]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_EXAM_GROUP]: (state: S) => (id: string | number) => number

	[getterTypes.GET_INDEX_EXAM_GROUP_REQUESTED_EXAM]: (
		state: S
	) => (data: { indexExamGroup: number; idExam: number | string }) => number

	[getterTypes.GET_ID_EXAM_GROUP]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_AVAILABLE_EXAM]: (state: S) => (id: string | number) => number

	[getterTypes.GET_ID_AVAILABLE_EXAM]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_AVAILABLE_EXAM_GROUPS]: (state: S) => (id: string | number) => number

	[getterTypes.GET_ID_AVAILABLE_EXAM_GROUPS]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_EXAMS]: (state: S) => (id: string | number) => number

	[getterTypes.GET_INDEX_REQUESTED_EXAMS]: (state: S) => (id: string | number) => number

	[getterTypes.GET_INDEX_ATTACHMENT]: (state: S) => (id: string | number) => number

	[getterTypes.GET_ID_ATTACHMENT]: (state: S) => (id: string | number) => any

	[getterTypes.GET_INDEX_SAMPLE]: (state: S) => (id: string | number) => number

	[getterTypes.GET_SAMPLE_BY_ID]: (state: S) => (id: string | number) => any

	[getterTypes.GET_REQUEST_FILTERS_WITHOUT_NULL]: (state: S) => any

	[getterTypes.GET_INDEX_ITEM_CARD]: (state: S) => (id: string | number) => number
}
