import { AuthenticationState } from '@/modules/authentication/store/types'
import router from '@/router'
import store from '@/store/store'
import isTrialExpired from '@/utils/functions/isTrialExpired'
import { AxiosInstance } from 'axios'
const EndpointAuthLogin = '/auth/login'
const EndpointAuthLoginByGoogle = '/auth/login/google'
const EndpointOrganization = '/organizacoes'

const responseInterceptorRequest = (Http: AxiosInstance) => {
	return Http.interceptors.request.use(
		(config) => {
			const {
				isLoggedIn,
				token: { access_token: token },
			}: AuthenticationState = store.state.Auth

			if (isLoggedIn && token) {
				config.headers.Authorization = 'Bearer ' + token
			}

			if (store.state.Organizacao.organizacao) {
				const trialDateExpires = store.state.Organizacao.organizacao.trial_date_expires

				const keysHeaders = ['organizacao-id', 'organization_id', 'organization-id']

				Object.values(keysHeaders).forEach((value) => {
					config.headers[value] = store.state.Organizacao.organizacao.id
				})

				if (
					trialDateExpires &&
					isTrialExpired(trialDateExpires) &&
					router.currentRoute.name !== 'plans.active'
				) {
					router.push({ name: 'plans.active' })
				}
			} else {
				if (store.state.Organizacao.organizacoes.length) {
					const organizacao =
						store.state.Organizacao.organizacoes.find(
							(organizacao) =>
								organizacao.id ===
								store.state.Auth.loggedUser?.default_organization_id
						) || store.state.Organizacao.organizacoes[0]

					store.commit(`Organizacao/setOrganizacao`, organizacao)

					const keysHeaders = ['organizacao-id', 'organization_id', 'organization-id']

					Object.values(keysHeaders).forEach((value) => {
						config.headers[value] = store.state.Organizacao.organizacao.id
					})
				} else if (
					config.url !== EndpointAuthLogin &&
					config.url !== EndpointAuthLoginByGoogle &&
					!config.url?.includes(EndpointOrganization)
				) {
					router.push({ name: 'welcomeSystem.index' })
				}
			}

			return config
		},
		(error) => Promise.reject(error)
	)
}

export default responseInterceptorRequest
