/* eslint-disable no-unused-vars */

import { CancelTokenSource } from 'axios'

// eslint-disable-next-line no-shadow
export enum types {
	ADD_FILES_TO_UPLOAD = 'ADD_FILES_TO_UPLOAD',
	REMOVE_FILE_TO_UPLOAD = 'REMOVE_FILE_TO_UPLOAD',
	REMOVE_FILES_TO_UPLOAD = 'REMOVE_FILES_TO_UPLOAD',
	UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS',
	FILE_UPLOADED = 'FILE_UPLOADED',

	SET_UPLOAD_FILE_REQUEST = 'SET_UPLOAD_FILE_REQUEST',
	SET_UPLOAD_FILE_INDEX = 'SET_UPLOAD_FILE_INDEX',
}

export interface FileToUpload {
	file: File
	handler: (
		cancelToken: CancelTokenSource,
		onUploadProgress: (progressEvent: ProgressEvent) => void
	) => Promise<void>
	progress?: number
	done?: boolean
	canceled?: boolean
}

export interface UploadsState {
	filesToUpload: FileToUpload[]
	uploadFileRequest: CancelTokenSource | null
	uploadFileIndex: number | null
}
