
















































import { Component, Prop, PropSync, VModel, Vue } from 'vue-property-decorator'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import ExamAttributeGroupDetailsAction from './Fields/ExamAttributeGroupDetailsAction.vue'
import InputTextField from '@/components/atomic/atoms/Inputs/InputTextField.vue'
import {
	IResponseAttributes,
	IUpdateAttributeGroupParams,
} from '@/modules/exams/services/attributes/types'
import { Action } from 'vuex-class'
import { actionTypes } from '@/modules/exams/store/attributes/types'

@Component({
	name: 'ExamAttributeContentGroup',
	components: {
		ButtonIcon,
		ExamAttributeGroupDetailsAction,
		InputTextField,
	},
})
export default class ExamAttributeContentGroup extends Vue {
	@VModel({ type: Boolean, default: false }) showAttributeChildren!: boolean

	@Prop({ type: Object, required: true }) group!: IResponseAttributes
	@Prop({ type: Boolean, default: false }) simpleGroupAttributeMode!: boolean
	@PropSync('simpleAttributeMode', { type: Boolean, required: true })
	isSimpleAttributeMode!: boolean

	activeAttribute = true
	groupName = ''
	hideTitle = false

	@Action(`Exams/${actionTypes.UPDATE_ATTRIBUTE_GROUP}`)
	updateAttributeGroup!: (payload: IUpdateAttributeGroupParams) => Promise<void>

	created(): void {
		this.groupName = this.group.name
		this.hideTitle = !this.group.show_title
	}

	handleUpdateAttributeGroup() {
		this.updateAttributeGroup({
			attributeGroupId: this.group.id,
			payload: {
				name: this.groupName,
				show_title: !this.hideTitle,
			},
		})
	}

	updateShowAttributeChildren() {
		this.showAttributeChildren = !this.showAttributeChildren
	}
}
