import API from '@/axios'
import axios from 'axios'

const EndpointWebhooks = '/webhooks'
const EndpointApplications = '/applications'
const EndpointNatives = '/natives'
const EndpointLabcloud = '/labcloud'
const EndpointAsaas = '/asaas'
const EndpointCustomers = '/customers'
const EndpointClients = '/clients'
const EndpointSynchronizations = '/syncronizations'
const EndpointSync = '/sync'

const LabcloudResultados = '/resultados'

const LabcloudAPI = axios.create({
	baseURL: 'https://plataforma.labcloud2.com.br/ws',
})

export default {
	async registerLabcloud({ payload }) {
		return await API.post(
			EndpointWebhooks + EndpointApplications + EndpointNatives + EndpointLabcloud,
			payload
		).then((response) => response.data)
	},

	async registerAsaas({ payload }) {
		return await API.post(
			EndpointWebhooks + EndpointApplications + EndpointNatives + EndpointAsaas,
			payload
		).then((response) => response.data)
	},

	async registerLabcloudClient({ payload }) {
		return await API.post(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointLabcloud +
				EndpointCustomers,
			payload
		).then((response) => response.data)
	},

	async viewLabcloudApplication() {
		return await API.get(
			EndpointWebhooks + EndpointApplications + EndpointNatives + EndpointLabcloud
		).then((response) => response.data)
	},

	async viewAsaasApplication() {
		return await API.get(
			EndpointWebhooks + EndpointApplications + EndpointNatives + EndpointAsaas
		).then((response) => response.data)
	},

	async viewApplicationCustomers({ id }) {
		return await API.get(
			EndpointWebhooks + EndpointApplications + '/' + id + EndpointCustomers
		).then((response) => response.data)
	},

	async editLabcloudApplication({ payload }) {
		return await API.put(
			EndpointWebhooks + EndpointApplications + EndpointNatives + EndpointLabcloud,
			payload
		).then((response) => response.data)
	},

	async viewLabcloudClients() {
		return await API.get(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointLabcloud +
				EndpointClients
		).then((response) => response.data)
	},

	async getLabcloudClient({ uuid }) {
		return await API.get(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointLabcloud +
				EndpointCustomers +
				'/' +
				uuid
		).then((response) => response.data)
	},

	async sendReportToLabcloudApp({ payload, headers }) {
		return await LabcloudAPI.post(LabcloudResultados, payload, {
			headers: headers,
		})
	},

	async createLabcloudCustomer({ payload }) {
		return await API.post(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointLabcloud +
				EndpointCustomers,
			payload
		).then((response) => response.data)
	},

	async editApplicationCustomer({ customerId, payload }) {
		return await API.put(EndpointWebhooks + EndpointCustomers + '/' + customerId, payload).then(
			(response) => response.data
		)
	},

	async removeApplicationCustomer({ customerId }) {
		return await API.delete(EndpointWebhooks + EndpointCustomers + '/' + customerId).then(
			(response) => response.data
		)
	},

	async getLabcloudFailedSyncronizations() {
		return await API.get(
			EndpointWebhooks + EndpointApplications + EndpointSynchronizations
		).then((response) => response.data)
	},

	async synchronizeLabcloudCustomers() {
		return await API.post(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointLabcloud +
				EndpointSync
		).then((response) => response.data)
	},

	async getLabcloudSynchronization(id) {
		return await API.get(
			EndpointWebhooks + EndpointApplications + EndpointSynchronizations + `/${id}`
		).then((response) => response.data)
	},
}
