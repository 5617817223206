import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		loading: false,
		carregado: false,
		especies: [],
		selecionado: {},
		openModalCreateSpecie: false,
		openModalUpdateSpecie: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.especies.findIndex((especie) => especie.id === id)
			}
		},

		getId: (state) => {
			return (id) => {
				return state.especies.find((especie) => especie.id === id)
			}
		},
	},

	mutations: {
		setEspecies: (state, payload) => {
			state.especies = payload
			state.carregado = true
		},

		backPage: (state, payload) => (state.pagina_atual = payload),

		setPage: (state, payload) => {
			const current_page = payload.current_page

			state.paginas_lidas[current_page] = payload
			state.pagina_atual = current_page
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),
		resetSpecie: (state) => (state.selecionado = {}),
		addSpecie: (state, payload) => state.especies.push(payload),
		updateSpecie: (state, { index, specie }) => state.especies.splice(index, 1, specie),
		deleteSpecie: (state, index) => state.especies.splice(index, 1),
		resetSelecionado: (state) => (state.selecionado = {}),

		setOpenModalCreateSpecie: (state, boolean) => (state.openModalCreateSpecie = boolean),
		setOpenModalUpdateSpecie: (state, boolean) => (state.openModalUpdateSpecie = boolean),

		setLoading: (state, stateModal) => (state.loading = stateModal),
	},
	actions: {
		selecionar({ commit }, payload) {
			const especie = Object.assign({}, payload)

			commit('setSelecionado', especie)
		},

		async getAll({ state, commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const response = await API.getEspecies()

				if (response) {
					commit('setEspecies', response)
				}

				commit('setLoading', DISABLE_LOADING)

				return state.especies
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createSpecie({ commit }, payload) {
			try {
				const specie = await API.createSpecie(payload)

				if (specie) {
					commit('addSpecie', specie)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.species.feedback.success.create.specie'),
						},
						{ root: true }
					)
				}

				return specie
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateSpecie({ commit, getters }, { payload, id }) {
			try {
				const specie = await API.updateSpecie(payload, id)

				if (specie) {
					const index = getters.getIndex(id)

					commit('updateSpecie', { index, specie })

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.species.feedback.success.update.specie'),
						},
						{ root: true }
					)
				}

				return specie
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteSpecie({ commit, getters }, id) {
			try {
				const { status } = await API.deleteSpecie(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteSpecie', index)

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.species.feedback.success.remove.specie'),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
