import API from '@/axios'
import ErrorService from '@/error'
import axios from 'axios'

const Endpoint = process.env.VUE_APP_API + '/organizacoes/'
const EndpointValidateDomain = 'validate/dominio'

export default {
	async validateDomain(payload) {
		return await axios
			.post(Endpoint + EndpointValidateDomain, payload)
			.then((response) => response.data)
	},

	async validarDominio(dominio, novo = false) {
		try {
			if (novo) {
				return await axios
					.post(Endpoint + '/validate/dominio', dominio)
					.then((response) => response)
					.catch((error) => error.response)
			} else {
				return await API.post(Endpoint + '/validate/dominio', dominio)
					.then((response) => response)
					.catch((error) => error.response)
			}
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async getOrganizacoes() {
		try {
			return await API.get(Endpoint).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async getReferrersOrganization() {
		try {
			return await API.get(Endpoint + 'referrers')
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async getOrganizacoesLaboratories() {
		try {
			return await API.get(Endpoint + 'marketplace?tipo=2').then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async visualizarOrganizacao(uuid) {
		try {
			return await API.get(Endpoint + uuid).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async criarOrganizacao(payload) {
		try {
			return await API.post(Endpoint, payload, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async editarOrganizacao(payload, uuid) {
		try {
			return await API.post(Endpoint + uuid + '/editar', payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then((response) => response.data)
		} catch (e) {
			ErrorService.onAPI(e)
		}
	},

	async removeOrganization(uuid) {
		return await API.delete(Endpoint + uuid).then((response) => response)
	},

	async getOutOrganizacao(uuid) {
		return await API.delete(Endpoint + uuid + '/sair').then((response) => response)
	},

	async updateOpeningHoursOrganization({ uuid, payload }) {
		return await API.patch(Endpoint + uuid + '/editar/opening-hours', payload).then(
			(response) => response
		)
	},

	async updateHeaderLaud({ uuid, payload }) {
		return await API.post(Endpoint + uuid + '/editar/result-header', payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async updateFooterLaud({ uuid, payload }) {
		return await API.post(Endpoint + uuid + '/editar/result-footer', payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async removeHeaderLaud(uuid) {
		return await API.delete(Endpoint + uuid + '/editar/result-header').then(
			(response) => response
		)
	},

	async removeFooterLaud(uuid) {
		return await API.delete(Endpoint + uuid + '/editar/result-footer').then(
			(response) => response
		)
	},

	async ownershipTransferOrganization(payload, uuid) {
		return await API.patch(Endpoint + uuid + '/transferir', { email: payload }).then(
			(response) => response
		)
	},
}
