import { ICustomerFilters } from '@/modules/customers/store/types'
import { ReceivingState } from '../../types'
import { ICustomer } from '@/modules/clientes/store/types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_FORMATTED_FILTERS_WITHOUT_VOIDS = 'GET_FORMATTED_FILTERS_WITHOUT_VOIDS',

	GET_CUSTOMER_INDEX_BY_UUID = 'GET_CUSTOMER_INDEX_BY_UUID',
	GET_CUSTOMER_INDEX_BY_UUID_RELATIONSHIP = 'GET_CUSTOMER_INDEX_BY_UUID_RELATIONSHIP',
	GET_CUSTOMER_BY_UUID = 'GET_CUSTOMER_BY_UUID',
}

export type Getters<S = ReceivingState> = {
	[getterTypes.GET_FORMATTED_FILTERS_WITHOUT_VOIDS]: (state: S) => Partial<ICustomerFilters>
	[getterTypes.GET_CUSTOMER_INDEX_BY_UUID]: (state: S) => (uuid: string) => number
	[getterTypes.GET_CUSTOMER_BY_UUID]: (state: S) => (uuid: string) => ICustomer | undefined
	[getterTypes.GET_CUSTOMER_INDEX_BY_UUID_RELATIONSHIP]: (
		state: S
	) => (data: { indexOwner: number; petOwnerId: number | string }) => number
}
