<template>
	<ul v-if="pager.pages && pager.pages.length" class="pagination" :style="ulStyles">
		<li
			class="page-item first"
			:class="{ disabled: pager.currentPage === 1 }"
			:style="liStyles"
		>
			<a class="page-link" :style="aStyles" @click="setPage(1)">{{ labels.first }}</a>
		</li>
		<li
			class="page-item previous"
			:class="{ disabled: pager.currentPage === 1 }"
			:style="liStyles"
		>
			<a class="page-link" :style="aStyles" @click="setPage(pager.currentPage - 1)">{{
				labels.previous
			}}</a>
		</li>
		<li
			v-for="page in pager.pages"
			:key="page"
			class="page-item page-number"
			:class="{ active: pager.currentPage === page }"
			:style="liStyles"
		>
			<a class="page-link" :style="aStyles" @click="setPage(page)">{{ page }}</a>
		</li>
		<li
			class="page-item next"
			:class="{ disabled: pager.currentPage === pager.totalPages }"
			:style="liStyles"
		>
			<a class="page-link" :style="aStyles" @click="setPage(pager.currentPage + 1)">{{
				labels.next
			}}</a>
		</li>
		<li
			class="page-item last"
			:class="{ disabled: pager.currentPage === pager.totalPages }"
			:style="liStyles"
		>
			<a class="page-link" :style="aStyles" @click="setPage(pager.totalPages)">{{
				labels.last
			}}</a>
		</li>
	</ul>
</template>

<script>
import paginate from 'jw-paginate'
const defaultLabels = {
	first: '«',
	last: '»',
	previous: '‹',
	next: '›',
}
const defaultStyles = {
	ul: {
		margin: 0,
		padding: 0,
		display: 'inline-block',
	},
	li: {
		listStyle: 'none',
		display: 'inline',
		textAlign: 'center',
	},
	a: {
		cursor: 'pointer',
		padding: '6px 12px',
		display: 'block',
		float: 'left',
	},
}

export default {
	name: 'PaginacaoLista',
	props: {
		items: {
			type: Array,
			required: true,
		},
		initialPage: {
			type: Number,
			default: 1,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		maxPages: {
			type: Number,
			default: 10,
		},
		labels: {
			type: Object,
			default: () => defaultLabels,
		},
		styles: {
			type: Object,
		},
		disableDefaultStyles: {
			type: Boolean,
			default: false,
		},

		totalItems: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			pager: {},
			ulStyles: {},
			liStyles: {},
			aStyles: {},
		}
	},
	watch: {
		items(newValue) {
			if (newValue[0] === null) return

			this.setPage(this.initialPage)
		},
	},

	created() {
		// set default styles unless disabled
		if (!this.disableDefaultStyles) {
			this.ulStyles = defaultStyles.ul
			this.liStyles = defaultStyles.li
			this.aStyles = defaultStyles.a
		}

		// merge custom styles with default styles
		if (this.styles) {
			this.ulStyles = { ...this.ulStyles, ...this.styles.ul }
			this.liStyles = { ...this.liStyles, ...this.styles.li }
			this.aStyles = { ...this.aStyles, ...this.styles.a }
		}

		// set to initial page
		this.setPage(this.initialPage)
	},
	methods: {
		setPage(page) {
			if (page === 0) {
				return
			}

			const { items, pageSize, maxPages } = this

			let pager

			// get new pager object for specified page
			if (this.totalItems > 0) {
				pager = paginate(this.totalItems, page, pageSize, maxPages)
			} else {
				pager = paginate(items.length, page, pageSize, maxPages)
			}

			if (page > pager.totalPages) {
				return
			}

			// get new page of items from items array
			const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)

			// update pager
			this.pager = pager
			this.$emit('currentPage', page)
			// emit change page event to parent component
			this.$emit('changePage', pageOfItems)
		},
	},
}
</script>

<style scoped></style>
