/* [[ ESLINT BUG ENUM NOT USED ]] */
/* eslint-disable no-unused-vars */
import { ICustomer } from '@/modules/clientes/store/types'
import { ICustomerRelationship } from '@/modules/customers/store/types'

export enum mutationTypes {
	SET_MODAL_UPDATE_AGREEMENT = 'SET_MODAL_UPDATE_AGREEMENT',
	SET_READONLY_MODAL_UPDATE_AGREEMENT = 'SET_READONLY_MODAL_UPDATE_AGREEMENT',

	SET_AGREEMENTS = 'SET_AGREEMENTS',
	SET_AGREEMENT_LOADING = 'SET_AGREEMENT_LOADING',

	SET_AGREEMENT_SELECTED = 'SET_AGREEMENT_SELECTED',

	SET_CUSTOMERS = 'SET_CUSTOMERS',
	RESET_CUSTOMERS = 'RESET_CUSTOMERS',

	SET_EXAMS = 'SET_EXAMS',
	SET_AGREEMENT_EXAMS_FILTERS = 'SET_AGREEMENT_EXAMS_FILTERS',
	SET_RESET_EXAMS = 'SET_RESET_EXAMS',

	SET_EXAMS_SELECTED = 'SET_EXAMS_SELECTED',
	SET_RESET_EXAMS_SELECTED = 'SET_RESET_EXAMS_SELECTED',
	ADD_EXAM_SELECTED = 'ADD_EXAM_SELECTED',
	UPDATE_EXAM_SELECTED = 'UPDATE_EXAM_SELECTED',
	REMOVE_EXAM_SELECTED = 'REMOVE_EXAM_SELECTED',
	SET_APPLY_AGREEMENT_VALUE = 'SET_APPLY_AGREEMENT_VALUE',

	SET_LOADING_CUSTOMERS = 'SET_LOADING_CUSTOMERS',

	SET_LOADING_EXAMS = 'SET_LOADING_EXAMS',

	SET_SAVING_AGREEMENT = 'SET_SAVING_AGREEMENT',
	SET_SAVING_AGREEMENT_EXAMS = 'SET_SAVING_AGREEMENT_EXAMS',
	SET_SAVING_AGREEMENT_CUSTOMERS = 'SET_SAVING_AGREEMENT_CUSTOMERS',

	ADD_AGREEMENT = 'ADD_AGREEMENT',
	UPDATE_AGREEMENT = 'UPDATE_AGREEMENT',
	REMOVE_AGREEMENT = 'REMOVE_AGREEMENT',

	ADD_AGREEMENT_EXAM = 'ADD_AGREEMENT_EXAM',
	UPDATE_AGREEMENT_EXAM = 'UPDATE_AGREEMENT_EXAM',
	REMOVE_AGREEMENT_EXAM = 'REMOVE_AGREEMENT_EXAM',

	ADD_AGREEMENT_CUSTOMER = 'ADD_AGREEMENT_CUSTOMER',
	REMOVE_AGREEMENT_CUSTOMER = 'REMOVE_AGREEMENT_CUSTOMER',
}

export enum actionTypes {
	GET_AGREEMENTS = 'GET_AGREEMENTS',

	GET_CUSTOMERS = 'GET_CUSTOMERS',

	GET_EXAMS = 'GET_EXAMS',

	CREATE_AGREEMENT = 'CREATE_AGREEMENT',
	UPDATE_AGREEMENT = 'UPDATE_AGREEMENT',
	UPDATE_STATUS_AGREEMENT = 'UPDATE_STATUS_AGREEMENT',
	VISUALIZE_AGREEMENT = 'VISUALIZE_AGREEMENT',
	REMOVE_AGREEMENT = 'REMOVE_AGREEMENT',
	REMOVE_AGREEMENTS = 'REMOVE_AGREEMENTS',

	CREATE_AGREEMENT_EXAM = 'CREATE_AGREEMENT_EXAM',
	UPDATE_AGREEMENT_EXAM = 'UPDATE_AGREEMENT_EXAM',
	REMOVE_AGREEMENT_EXAM = 'REMOVE_AGREEMENT_EXAM',

	CREATE_AGREEMENT_CUSTOMER = 'CREATE_AGREEMENT_CUSTOMER',
	REMOVE_AGREEMENT_CUSTOMER = 'REMOVE_AGREEMENT_CUSTOMER',
}

export interface IDiscountType {
	value: 'value' | 'percentage'
	text: string
}

export const IDiscountTypesByAgreement: IDiscountType[] = [
	{ value: 'value', text: '$' },
	{ value: 'percentage', text: '%' },
]

export interface IExamAgreementResponse {
	id: number
	agreement_exam_id: number | null
	name: string
	abbreviation: string
	due_date: number
	due_date_type: 'Hours' | 'Days'
	price: number
	status: boolean
	organization_id: number
	category_id: number
	created_at: Date | number
	updated_at: Date | number
	agreement_value: number | null
}

export interface ruleAgreement {
	id: number
	rule: string
	agreement_id: number
	created_at: Date | string
	updated_at: Date | string
}

export interface IItemsLinkedByAgreement {
	id: number
	agreement_id: number
	exam_id: number
	agreement_value: number
	created_at: Date | string
	updated_at: Date | string
	exam: {
		id: number
		name: string
		abbreviation: string
		due_date: number
		due_date_type: 'Hours' | 'days'
		price: number
		status: boolean
		organization_id: number
		category_id: number
		created_at: Date | string
		updated_at: Date | string
	}
	exam_liquid_value: number
}

export interface IAgreementResponse {
	id: number
	name: string
	description: string
	abbreviation: string
	discount_type: 'value' | 'percentage'
	discount_value: number
	status: boolean
	show_at_value: boolean
	default_agreement: boolean
	organization_id: number
	created_by_user_id: number
	created_at: Date | string
	updated_at: Date | string
	deleted_at: null
	exams: IItemsLinkedByAgreement[]
	customers: ICustomerRelationship[]
	rules: ruleAgreement[]
}

export interface IPayloadExamAgreement {
	id: number
	agreement_value: number
}

export interface ICreateAgreementPayload {
	name: string
	abbreviation: string
}

export interface IAgreementPayload {
	name: string
	description?: string | null
	abbreviation: string
	discount_type: 'value' | 'percentage'
	discount_value: number
	status?: boolean
	show_at_value: boolean
	default_agreement: boolean
	exams: IPayloadExamAgreement[]
	customers_ids?: number[]
	rules?: ruleAgreement[]
}

export interface IAgreementFilters {
	search?: string
	default_agreement?: boolean
}

export interface IAgreementExamsFilters {
	page: number
	limit: number
	search?: string
	onlyAgreementExams?: boolean
}

export interface AgreementState<T> {
	agreements: {
		data: IAgreementResponse[]
		meta: T
	}
	agreementSelected: null | IAgreementResponse
	loading: boolean
	saving: boolean
	customers: {
		data: ICustomer[]
		loading: boolean
		saving: boolean
	}
	exams: {
		data: IExamAgreementResponse[]
		meta: {
			current_page: number
			from: number
			last_page: number
			per_page: number
			to: number
			total: number
		}
		loading: boolean
		saving: boolean
	}
	filters: IAgreementFilters
	agreementExamsFilters: IAgreementExamsFilters
	examsSelected: IExamAgreementResponse[]
	openModalUpdateAgreement: boolean
	isModalReadonly: boolean
}
