export default {
	namespaced: true,

	state: {
		measurementUnits: [
			'mm3',
			'/mm3',
			'milhões/mm3',
			'fL',
			'pg',
			'g/dL',
			'U/L',
			'mg/dL',
			'mL',
			'ng/mL',
			'ng/dL',
			'mL/min',
			'mEq/L',
			'ug/dL',
			'uU/mL',
			'mOsm/kg',
		],
	},

	getters: {
		getUnitIndex: (state) => {
			return (name) => {
				return state.measurementUnits.findIndex((unit) => unit === name)
			}
		},
	},

	mutations: {
		addUnit: (state, unit) => state.measurementUnits.push(unit),

		removeUnit: (state, index) => state.measurementUnits.splice(index, 1),
	},
}
