import { render, staticRenderFns } from "./ExamAttributeTypeMultipleChoice.vue?vue&type=template&id=222972d4&scoped=true&"
import script from "./ExamAttributeTypeMultipleChoice.vue?vue&type=script&lang=ts&"
export * from "./ExamAttributeTypeMultipleChoice.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222972d4",
  null
  
)

export default component.exports