import { ITypeOrmPaginationTemplate } from './../../../utils/interfaces/typeormPagination'
import { IAnimalFiltersCustomerId } from './../../animals/store/types'
import { MutationTree } from 'vuex'
import { state as stateOfCustomer } from './index'
import { CustomersState, ICustomerFilters, mutationTypes } from './types'
import { ICustomer } from '@/modules/clientes/store/types'
import { IAnimalResponse } from '@/modules/animals/store/types'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'

export const mutations: MutationTree<CustomersState> = {
	[mutationTypes.ADD_CUSTOMER](state, payload) {
		state.customers.data.push(payload)
		state.customers.meta.total += 1
	},
	[mutationTypes.UPDATE_CUSTOMER](state, { index, payload }) {
		state.customers.data.splice(index, 1, payload)
		state.selectedCustomer = payload
	},
	[mutationTypes.DELETE_CUSTOMER](state, index) {
		state.customers.data.splice(index, 1)
	},
	[mutationTypes.UPDATE_CUSTOMER_AGREEMENT](state, { index, payload }) {
		const selectedCustomer = state.customers.data[index]

		state.customers.data.splice(index, 1, { ...selectedCustomer, agreement: payload })
	},
	[mutationTypes.DELETE_CUSTOMER_AGREEMENT](state, index) {
		const selectedCustomer = state.customers.data[index]

		state.customers.data.splice(index, 1, { ...selectedCustomer, agreement: undefined })
	},
	[mutationTypes.SET_CUSTOMER_LOADING](state, payload) {
		state.customerIsLoading = payload
	},
	[mutationTypes.SET_MODAL_CREATE_CUSTOMER](state, modalState: boolean) {
		state.openModalCreateCustomer = modalState
	},

	[mutationTypes.SET_MODAL_UPDATE_CUSTOMER](state, modalState: boolean) {
		state.openModalUpdateCustomer = modalState
	},

	[mutationTypes.SET_MODAL_STAKEHOLDER_SELECTION](state, modalState: boolean) {
		state.openModalStakeholderSelection = modalState
	},

	[mutationTypes.SET_ACTORS_SELECTED](state, customers: ICustomer[]) {
		state.actorsSelected = customers
	},

	[mutationTypes.ADD_ACTOR_SELECTED](state, customer: ICustomer) {
		state.actorsSelected.push(customer)
	},

	[mutationTypes.UPDATE_ACTOR_SELECTED](
		state,
		{ indexActor, customer }: { indexActor: number; customer: ICustomer }
	) {
		state.actorsSelected.splice(indexActor, 1, customer)
	},

	[mutationTypes.REMOVE_ACTOR_SELECTED](state, { indexActor }: { indexActor: number }) {
		state.actorsSelected.splice(indexActor, 1)
	},

	[mutationTypes.RESET_ACTORS_SELECTED](state) {
		state.actorsSelected = []
	},

	[mutationTypes.SET_CUSTOMER_SAVING](state, payload) {
		state.customerIsSaving = payload
	},
	[mutationTypes.SET_CUSTOMERS](state, payload) {
		state.customers = payload
	},
	[mutationTypes.SET_MERGE_CUSTOMERS](state, payload: ITypeOrmPaginationTemplate<ICustomer>) {
		state.customers.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.customers.data,
			newArray: payload.data,
			keyToCompare: 'id',
		})

		state.customers.meta = payload.meta
	},
	[mutationTypes.SET_SELECTED_CUSTOMER](state, payload) {
		state.selectedCustomer = payload
	},
	[mutationTypes.SET_FILTERS](state, filters: ICustomerFilters) {
		state.filters = filters
	},

	[mutationTypes.RESET_FILTERS](state) {
		state.filters = stateOfCustomer.filters
	},

	[mutationTypes.SET_ANIMALS_BY_CUSTOMER](
		state,
		typeormPaginationAnimal: ITypeOrmPaginationTemplate<IAnimalResponse>
	) {
		state.animalsByCustomer = typeormPaginationAnimal
	},

	[mutationTypes.SET_ANIMALS_FILTERS_BY_CUSTOMER](state, filters: IAnimalFiltersCustomerId) {
		state.filtersAnimalByCustomer = filters
	},

	[mutationTypes.SET_RELATIONSHIPS_CUSTOMER](
		state,
		{ indexCustomer, relationship }: { indexCustomer: number; relationship: ICustomer[] }
	) {
		const customer = { ...state.customers.data[indexCustomer], relationship: relationship }

		state.customers.data.splice(indexCustomer, 1, customer)
	},

	[mutationTypes.SET_ANIMALS_CUSTOMER](
		state,
		{
			key = 'customers',
			indexCustomer,
			animals,
		}: {
			key: 'customers' | 'selectedCustomer'
			indexCustomer?: number
			animals: IAnimalResponse[]
		}
	) {
		if (key === 'customers') {
			const customer = { ...state.customers.data[indexCustomer!], animals: animals }

			state.customers.data.splice(indexCustomer!, 1, customer)
		} else {
			if (state.selectedCustomer) {
				const customer = { ...state.selectedCustomer, animals: animals }

				state.selectedCustomer = Object.assign({}, customer)
			}
		}
	},

	[mutationTypes.ADD_ANIMAL_BY_CUSTOMER](state, animal: IAnimalResponse) {
		state.animalsByCustomer.data.push(animal)
	},

	[mutationTypes.UPDATE_ANIMAL_BY_CUSTOMER](
		state,
		{ indexAnimal, animal }: { indexAnimal: number; animal: IAnimalResponse }
	) {
		state.animalsByCustomer.data.splice(indexAnimal, 1, animal)
	},

	[mutationTypes.REMOVE_ANIMAL_BY_CUSTOMER](state, indexAnimal: number) {
		state.animalsByCustomer.data.splice(indexAnimal, 1)
	},

	[mutationTypes.SET_CUSTOMER_TYPE](state, customerType: 'PetOwner' | 'Veterinarian' | 'Clinic') {
		state.customerType = customerType
	},

	[mutationTypes.RESET_CUSTOMER_TYPE](state) {
		state.customerType = undefined
	},
}

export default mutations
