import API from '@/axios'
import { EndpointAnimalV1 } from '@/modules/animals/service'

const PermissionsEndpoint = '/permissions/'
const Endpoint = '/permissions/attributes/'
const ResourceEndpoints = {
	Exam: '/exames/',
	Animal: EndpointAnimalV1 + '/',
	Customer: '/clientes/',
	Request: '/requisicoes/',
	Attribute: '/permissions/attributes/',
	Form: '/exames/formularios/',
	Agreement: '/models/agreements/',
	ExamGroup: '/models/profiles/',
}

export default {
	async getAttributes() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async getAttribute(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async removeAttribute(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},

	async removeAttributes(ids) {
		return await API.delete(Endpoint, { data: { ids } }).then((response) => response.data)
	},

	async createAttribute({ key, value, description, color }) {
		return await API.post(Endpoint, { key, value, description, color }).then(
			(response) => response.data
		)
	},

	async updateAttribute({ id, key, value, description, color }) {
		return await API.put(Endpoint + id, { key, value, description, color }).then(
			(response) => response.data
		)
	},

	async updateResourcePermissions({ id, payload }) {
		return await API.put(Endpoint + id + '/permissions', { permissions: payload }).then(
			(response) => response
		)
	},

	async addMember({ id, user_id }) {
		return await API.post(Endpoint + id + '/users', { user_id }).then(
			(response) => response.data
		)
	},

	async removeResource({ id, resource_id }) {
		return await API.delete(Endpoint + id + '/resources/' + resource_id).then(
			(response) => response.data
		)
	},

	async removeMember({ id, user_id }) {
		return await API.delete(Endpoint + id + '/users/' + user_id).then(
			(response) => response.data
		)
	},

	async getPermissions() {
		return await API.get(PermissionsEndpoint).then((response) => response.data)
	},

	async getResourceIds(resource) {
		if (Object.keys(ResourceEndpoints).includes(resource)) {
			return await API.get(ResourceEndpoints[resource] + 'ids').then(
				(response) => response.data
			)
		}
	},
}
