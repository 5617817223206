import { ICustomer } from '@/modules/clientes/store/types'
import { ICustomerRelationship } from '@/modules/customers/store/types'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'
import { ITypeOrmPaginationMeta } from '@/utils/interfaces/typeormPagination'
import { MutationTree } from 'vuex'
import {
	AgreementState,
	IAgreementExamsFilters,
	IAgreementResponse,
	IExamAgreementResponse,
	mutationTypes as types,
} from './types'

export const mutations: MutationTree<AgreementState<unknown>> = {
	[types.SET_SAVING_AGREEMENT](state, stateSaving: boolean) {
		state.saving = stateSaving
	},

	[types.SET_SAVING_AGREEMENT_EXAMS](state, stateSaving: boolean) {
		state.saving = stateSaving
		state.exams.saving = stateSaving
	},

	[types.SET_SAVING_AGREEMENT_CUSTOMERS](state, stateSaving: boolean) {
		state.saving = stateSaving
		state.customers.saving = stateSaving
	},

	[types.SET_MODAL_UPDATE_AGREEMENT](state, modalState: boolean) {
		state.openModalUpdateAgreement = modalState
	},

	[types.SET_READONLY_MODAL_UPDATE_AGREEMENT](state, readonlyState: boolean) {
		state.isModalReadonly = readonlyState
	},

	[types.SET_AGREEMENTS](state, agreements: IAgreementResponse[]) {
		state.agreements.data = agreements
	},

	[types.SET_AGREEMENT_LOADING](state, stateLoading: boolean) {
		state.loading = stateLoading
	},

	[types.SET_AGREEMENT_SELECTED](state, agreement: IAgreementResponse) {
		state.agreementSelected = agreement
	},

	[types.ADD_AGREEMENT](state, agreement: IAgreementResponse) {
		state.agreements.data.push(agreement)
	},

	[types.UPDATE_AGREEMENT](
		state,
		{ indexAgreement, agreement }: { indexAgreement: number; agreement: IAgreementResponse }
	) {
		state.agreements.data.splice(indexAgreement, 1, agreement)
		state.agreementSelected = agreement
	},

	[types.REMOVE_AGREEMENT](state, indexAgreement: number) {
		state.agreements.data.splice(indexAgreement, 1)
		state.agreementSelected = null
	},

	[types.SET_CUSTOMERS](state, customers: ICustomer[]) {
		state.customers.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.customers.data,
			newArray: customers,
			keyToCompare: 'id',
			keyDateLatest: 'updated_at',
		})
	},

	[types.RESET_CUSTOMERS](state) {
		state.customers.data = []
	},

	[types.SET_LOADING_CUSTOMERS](state, stateLoading: boolean) {
		state.customers.loading = stateLoading
	},

	[types.SET_EXAMS](
		state,
		exams: {
			data: IExamAgreementResponse[]
			meta: ITypeOrmPaginationMeta
		}
	) {
		state.exams = {
			data: exams.data,
			meta: exams.meta,
			loading: false,
			saving: false,
		}
	},

	[types.SET_AGREEMENT_EXAMS_FILTERS](state, filters: IAgreementExamsFilters) {
		state.agreementExamsFilters = filters
	},

	[types.SET_RESET_EXAMS](state) {
		state.exams.data = []
	},

	[types.SET_LOADING_EXAMS](state, stateLoading: boolean) {
		state.exams.loading = stateLoading
	},

	[types.SET_EXAMS_SELECTED](state, exams: IExamAgreementResponse[]) {
		state.examsSelected = exams
	},

	[types.SET_RESET_EXAMS_SELECTED](state) {
		state.examsSelected = []
	},

	[types.UPDATE_EXAM_SELECTED](
		state,
		{ exam, indexExamSelected }: { exam: IExamAgreementResponse; indexExamSelected: number }
	) {
		state.examsSelected.splice(indexExamSelected, 1, exam)
	},

	[types.SET_APPLY_AGREEMENT_VALUE](
		state,
		{
			agreement_type,
			agreement_value,
		}: { agreement_type: 'value' | 'percentage'; agreement_value: number }
	) {
		// const exams: IExamAgreementResponse[] = Array.from(state.examsSelected)
		// const getAgreementValue = (exam_value: number, agreement_value: number) => {
		// 	if (exam_value >= agreement_value) {
		// 		return agreement_value
		// 	} else {
		// 		return exam_value
		// 	}
		// }
		// const getAgreementPercentageValue = (exam_value: number, agreement_value: number) => {
		// 	if (agreement_value <= 100) {
		// 		return (exam_value * agreement_value) / 100
		// 	} else {
		// 		return exam_value
		// 	}
		// }
		// exams.forEach((exam) => {
		// 	if (agreement_type === 'value') {
		// 		exam.agreement_value = getAgreementValue(exam.exam_value, agreement_value)
		// 	} else if (agreement_type === 'percentage') {
		// 		exam.agreement_value = getAgreementPercentageValue(exam.exam_value, agreement_value)
		// 	}
		// })
		// state.examsSelected = Array.from(exams)
	},

	[types.ADD_EXAM_SELECTED](state, exam: IExamAgreementResponse) {
		state.examsSelected.push(exam)
	},

	[types.REMOVE_EXAM_SELECTED](state, indexExam: number) {
		state.examsSelected.splice(indexExam, 1)
	},

	[types.ADD_AGREEMENT_EXAM](
		state,
		{
			agreementExamIndex,
			agreementExam,
		}: { agreementExamIndex: number; agreementExam: IExamAgreementResponse }
	) {
		state.exams.data.splice(agreementExamIndex, 1, agreementExam)
	},

	[types.UPDATE_AGREEMENT_EXAM](
		state,
		{
			agreementExamIndex,
			agreementExam,
		}: { agreementExamIndex: number; agreementExam: IExamAgreementResponse }
	) {
		state.exams.data.splice(agreementExamIndex, 1, agreementExam)
	},

	[types.REMOVE_AGREEMENT_EXAM](state, agreementExamIndex: number) {
		const exam = state.exams.data[agreementExamIndex]

		const examWithoutAgreement = {
			...exam,
			agreement_exam_id: null,
			agreement_value: null,
		}

		state.exams.data.splice(agreementExamIndex, 1, examWithoutAgreement)
	},

	[types.ADD_AGREEMENT_CUSTOMER](state, customer: ICustomerRelationship) {
		state.agreementSelected?.customers.push(customer)
	},
}

export default { mutations }
