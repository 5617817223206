import { RouteConfig } from 'vue-router'

const metaConfig = { config: true, auth: true }

const routes = [
	{
		path: '/settings/exam-groups',
		name: 'config.examGroups',
		component: () =>
			import(
				/* webpackChunkName: 'exams' */ '@/modules/examGroups/views/ExamGroupsIndex.vue'
			),
		meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Exam' }] },
	},
] as RouteConfig[]

export default routes
