import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ExamState, IExamFilters } from './types'

export const getters: GetterTree<ExamState, RootState> = {
	getExamIndex:
		(state) =>
		(id: number): number => {
			return state.exams.data.findIndex((exam) => exam.id === id)
		},

	getLabelIndex:
		(state) =>
		({ index, idTag }: { index: number; idTag: number }) => {
			return state.exams.data[index].labels.findIndex((label) => label.id === idTag)
		},

	getIndexUnitMeasurement: (state) => (unitMeasurement: string) => {
		return state.unitsMeasurement.findIndex((unitOld) => unitOld === unitMeasurement)
	},
	getFormattedFiltersWithoutVoids(state) {
		const filteredData: Partial<IExamFilters> = Object.entries(state.filters).reduce(
			(acc, [key, value]) => {
				if (value !== undefined && value !== null && value !== '') {
					// eslint-disable-next-line no-param-reassign
					acc = { ...acc, [key]: value }
				}

				return acc
			},
			{}
		)

		return filteredData
	},

	getAllLoadings(state: any) {
		return state.examIsLoading || state.referenceIsLoading || state.attributeIsLoading
	},
	getAllSavings(state: any) {
		return state.examIsSaving || state.referenceIsSaving || state.attributeIsSaving
	},
}

export default getters
