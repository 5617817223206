import { eventBus } from '@/main'
import {
	actionTypes as actionTypesOfAuth,
	mutationTypes as mutationTypesOfAuth,
} from '@/modules/authentication/store/types'
import router from '@/router'
import store from '@/store/store'

let isRefreshing = false

const refreshToken = async () => {
	if (isRefreshing) {
		return false
	}

	isRefreshing = true

	try {
		const {
			token: { refresh_token },
		} = store.state.Auth

		const responseSuccess = await store.dispatch(
			`Auth/${actionTypesOfAuth.LOG_IN_BY_REFRESH_TOKEN}`,
			refresh_token
		)

		if (responseSuccess) {
			const organizations = await store.dispatch('Organizacao/getAll')

			if (
				typeof responseSuccess !== 'boolean' &&
				responseSuccess.usuario?.default_organization_id !== null
			) {
				const organization = organizations.find(
					(organization: any) =>
						organization.id === responseSuccess.usuario.default_organization_id
				)

				if (organization) {
					store.commit(`Organizacao/setOrganizacao`, organization)
					router.push({ name: 'painel' })
				} else {
					store.commit(`Organizacao/setOrganizacao`, organizations[0])
					router.push({ name: 'painel' })
				}
			} else {
				store.commit(`Organizacao/setOrganizacao`, organizations[0])
				router.push({ name: 'painel' })
			}

			return true
		} else {
			eventBus.$emit('resetaTudo')
			store.commit(`Auth/${mutationTypesOfAuth.SET_RESET_LOGOUT}`)
			router.push({ name: 'auth' })
			return false
		}
	} catch (error) {
		eventBus.$emit('resetaTudo')
		store.commit(`Auth/${mutationTypesOfAuth.SET_RESET_LOGOUT}`)
		router.push({ name: 'auth' })
		return false
	} finally {
		isRefreshing = false
	}
}

export default refreshToken
