import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=4688e9a9&scoped=true&"
import script from "./Chip.vue?vue&type=script&lang=ts&"
export * from "./Chip.vue?vue&type=script&lang=ts&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=4688e9a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4688e9a9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})
