import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { STATUS_SUCCESS, STATUS_CREATED } from '@/utils/constants/statusConstants'

let getMaterialsDebounce = null

export default {
	namespaced: true,
	state: {
		carregado: false,
		materiais: [],
		selecionado: {},
		paginas: 0,
		paginas_lidas: [],
		pagina_atual: 1,
		loading: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.materiais.findIndex((material) => material.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.materiais.find((material) => material.id === id)
			}
		},
	},

	mutations: {
		setMaterials: (state, payload) => (state.materiais = payload),
		backPage: (state, payload) => (state.pagina_atual = payload),
		addMaterial: (state, payload) => state.materiais.push(payload),
		updateMaterial: (state, { index, material }) => state.materiais.splice(index, 1, material),
		deleteMaterial: (state, index) => state.materiais.splice(index, 1),
		setLoading: (state, boolean) => (state.loading = boolean),

		resetAll: (state) => {
			state.carregado = false
			state.materiais = []
			state.selecionado = {}
			state.paginas = 0
			state.paginas_lidas = []
			state.pagina_atual = 1
			state.loading = false
		},
	},

	actions: {
		async getAll({ commit }) {
			try {
				if (getMaterialsDebounce) return
				else {
					commit('setLoading', ENABLE_LOADING)
					const { status, data: materials } = await API.getMaterials()

					if (status === STATUS_SUCCESS) {
						commit('setMaterials', materials)
						commit('setLoading', DISABLE_LOADING)
					}

					getMaterialsDebounce = setTimeout(() => {
						getMaterialsDebounce = null
					}, 60000)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createMaterial({ commit }, payload) {
			try {
				const { status, data: material } = await API.createMaterial(payload)

				if (status === STATUS_CREATED) {
					commit('addMaterial', material)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.materials.feedback.success.create.material') },
						{ root: true }
					)
				}

				return status === STATUS_CREATED
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateMaterial({ commit, getters }, payload) {
			try {
				const { status, data: material } = await API.updateMaterial(payload, payload.id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(payload.id)

					if (index > -1) {
						commit('updateMaterial', { index, material })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.materials.feedback.success.update.material') },
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteMaterial({ commit, getters }, id) {
			try {
				const { status } = await API.deleteMaterial(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteMaterial', index)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.materials.feedback.success.remove.material') },
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
