















import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import ClassicInputEditor from './ClassicInputEditor.vue'

@Component({
	name: 'ClassicExamTextEditor',
	components: {
		ClassicInputEditor,
	},
})
export default class ClassicExamTextEditor extends Vue {
	@VModel({ required: true }) data!: string
	@Prop({ default: '' }) placeholder!: string
	@Prop({ default: false }) disabled!: boolean
	@Prop({ default: false }) readonly!: boolean
	@Prop({ default: false }) shouldNotGroupWhenFull!: boolean
	@Prop({ required: true }) id!: string

	toolbarOptions = ['searchPreviousResults', 'searchAnnotations']

	onReady(editor: ClassicEditor): void {
		this.$emit('ready', editor)
	}

	onFocus(): void {
		this.$emit('focus')
	}
}
