/* eslint-disable no-unused-vars */
import { RootState } from '@/store/types'
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { Getters } from '../getters/getter-types'
import { ReceivingState } from '../types'

export enum actionTypes {
	GET_ALL_REQUESTS = 'getAllRequests',

	GET_ALL_AVAILABLE_EXAMS = 'getAllAvailableExams',
	GET_ALL_AVAILABLE_EXAM_GROUPS = 'getAllAvailableExamGroups',

	GET_EXAM_STATUS_LIST = 'getExamStatusList',

	GET_ALL_EXAMS = 'getAllExams',
	GET_ALL_EXAM_GROUPS = 'getAllExamGroups',

	GET_ALL_ATTACHMENTS_REQUEST = 'getAllAttachmentsRequest',

	GET_ALL_FORMS = 'getAllForms',
	GET_ALL_SAMPLES = 'getAllSamples',

	GET_ALL_REQUESTEDS_EXAMS = 'getAllRequestedsExams',

	GET_SAMPLE_STATUS_LIST = 'getSampleStatusList',

	SEARCH_REQUESTS = 'searchRequests',

	CREATE_REQUEST = 'createRequest',

	UPDATE_REQUEST = 'updateRequest',

	UPDATE_REQUEST_STATUS = 'updateRequestStatus',

	SET_SELECTED_REQUEST = 'setSelectedRequest',

	VISUALIZE_REQUEST = 'visualizeRequest',

	GET_REQUEST_STATUS_LIST = 'getRequestStatusList',

	GET_OBSERVATIONS = 'getAllObservations',
	CREATE_OBSERVATION = 'createObservation',
	UPDATE_OBSERVATION = 'updateObservation',
	DELETE_OBSERVATION = 'deleteObservation',

	SET_SELECTED_CLINIC = 'setSelectedClinic',
	SET_SELECTED_DOCTOR = 'setSelectedDoctor',
	SET_SELECTED_PATIENT = 'setSelectedPatient',

	GET_ALL_REQUESTED_EXAMS = 'getAllRequestedExams',
	GET_REQUESTED_EXAMS = 'getRequestedExams',
	CREATE_REQUESTED_EXAMS = 'createRequestedExams',
	CREATE_REQUESTED_EXAMS_AGREEMENT = 'createRequestedExamsAgreement',
	UPDATE_REQUESTED_EXAMS = 'updateRequestedExams',
	UPDATE_STATUS_REQUESTED_EXAMS = 'updateStatusRequestedExams',
	UPDATE_PRIORITY_REQUESTED_EXAMS = 'updatePriorityRequestedExams',
	DELETE_REQUESTED_EXAMS = 'deleteRequestedExams',

	DELETE_REQUESTED_EXAM_GROUPS = 'deleteRequestedExamGroups',

	GET_PAYERS = 'getPayers',
	CREATE_PAYER = 'addPayer',
	REMOVE_PAYER = 'removePayer',

	GET_ATTACHMENT_DOWNLOAD = 'getAttachmentDownload',
	CREATE_ATTACHMENT = 'createAttachment',
	REMOVE_ATTACHMENT = 'removeAttachment',
	REMOVE_ATTACHMENT_REQUEST = 'removeAttachmentRequest',

	CANCEL_RECEIVING = 'cancelReceiving',
	CANCEL_SAMPLE = 'cancelSample',

	CREATE_SAMPLE = 'createSample',
	UPDATE_SAMPLE = 'editSample',
	REMOVE_SAMPLE = 'removeSample',
	CHANGE_SAMPLE_STATUS = 'changeSampleStatus',
	GET_SAMPLES_TO_RELATE = 'getSamplesToRelate',
	GET_SAMPLES_EXAMS_TO_RELATE = 'getSamplesExamsToRelate',
	GET_SAMPLE_RELATIONSHIPS = 'getSampleRelationShips',
	UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM = 'updateStatusRequestedExamGroupsExam',

	DELETE_MULTIPLE_REQUESTED_EXAMS = 'deleteMultipleRequestedExams',
}

export interface Actions {
	[actionTypes.CREATE_REQUEST]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.UPDATE_REQUEST](
		{ commit, getters, rootGetters }: AugmentedActionContext,
		data: { payload: any; setSelectedRequest: boolean }
	): Promise<any>

	[actionTypes.UPDATE_REQUEST_STATUS](
		{ commit }: AugmentedActionContext,
		payload: any
	): Promise<any>

	[actionTypes.SET_SELECTED_REQUEST]({ commit }: AugmentedActionContext, payload: any): void

	[actionTypes.VISUALIZE_REQUEST]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.GET_ALL_REQUESTS]({ commit }: AugmentedActionContext, page: number): Promise<any>

	[actionTypes.SEARCH_REQUESTS](
		{ commit, getters }: AugmentedActionContext,
		data: { filters: any; reset: any; store: boolean }
	): Promise<any>

	[actionTypes.GET_REQUEST_STATUS_LIST]({ state, commit }: AugmentedActionContext): Promise<any>

	[actionTypes.GET_OBSERVATIONS]({ state, commit }: AugmentedActionContext): Promise<any>

	[actionTypes.SET_SELECTED_CLINIC]({ commit }: AugmentedActionContext, payload: any): void

	[actionTypes.SET_SELECTED_DOCTOR]({ commit }: AugmentedActionContext, payload: any): void

	[actionTypes.SET_SELECTED_PATIENT]({ commit }: AugmentedActionContext, payload: any): void

	[actionTypes.CREATE_OBSERVATION](
		{ state, commit }: AugmentedActionContext,
		data: { text: any }
	): Promise<any>

	[actionTypes.UPDATE_OBSERVATION](
		{ state, commit }: AugmentedActionContext,
		payload: any
	): Promise<any>

	[actionTypes.DELETE_OBSERVATION](
		{ state, commit }: AugmentedActionContext,
		id: string | number
	): Promise<any>

	[actionTypes.GET_ALL_REQUESTED_EXAMS](
		{ state, commit, getters }: AugmentedActionContext,
		id: string | number
	): Promise<any>

	[actionTypes.GET_REQUESTED_EXAMS](
		{ state, commit, getters }: AugmentedActionContext,
		data: { id: any; store: boolean; filters: any }
	): Promise<any>

	[actionTypes.CREATE_REQUESTED_EXAMS](
		{ state, commit }: AugmentedActionContext,
		data: { payload: any }
	): Promise<any>

	[actionTypes.CREATE_REQUESTED_EXAMS_AGREEMENT](
		{ dispatch, commit }: AugmentedActionContext,
		data: { request_id: number | string; payload: any }
	): Promise<any>

	[actionTypes.UPDATE_REQUESTED_EXAMS](
		{ commit, getters }: AugmentedActionContext,
		payload: any
	): Promise<any>

	[actionTypes.UPDATE_STATUS_REQUESTED_EXAMS](
		{ state, commit, getters }: AugmentedActionContext,
		data: { id: number | string; status_id: number | string }
	): Promise<any>

	[actionTypes.UPDATE_PRIORITY_REQUESTED_EXAMS](
		{ state, rootState, rootGetters, commit, getters }: AugmentedActionContext,
		data: { id: number | string; priority: any }
	): Promise<any>

	[actionTypes.DELETE_REQUESTED_EXAMS](
		{ state, commit }: AugmentedActionContext,
		data: { requested_exams_ids: number[] | string[]; request_id: number | string }
	): Promise<any>

	[actionTypes.GET_ALL_AVAILABLE_EXAMS](
		{ state, commit }: AugmentedActionContext,
		data: {
			page: number
			search?: null | string
			showRepeated?: boolean
			hasAgreement?: boolean
			customerId?: string | number
		}
	): Promise<any>

	[actionTypes.GET_ALL_EXAM_GROUPS](
		{ state, commit }: AugmentedActionContext,
		id: number | string
	): Promise<any>

	[actionTypes.GET_ALL_AVAILABLE_EXAM_GROUPS](
		{ state, commit }: AugmentedActionContext,
		data: { page: number; search: null | string; showRepeated: boolean }
	): Promise<any>

	[actionTypes.GET_EXAM_STATUS_LIST]({ state, commit }: AugmentedActionContext): Promise<any>

	[actionTypes.DELETE_REQUESTED_EXAM_GROUPS](
		{ state, commit }: AugmentedActionContext,
		data: { requested_exams_group_ids: string[] | number[] }
	): Promise<any>

	[actionTypes.GET_ALL_EXAMS](
		{ commit }: AugmentedActionContext,
		data: {
			page: number
			perPage: number
			search?: string | null
			requestedExamStatus?: any
			requestedExamPriority?: any
			orderDirection?: any
			examRequestStatus?: any
			examRequestPriority?: any
			orderBy?: string
			createdAfter?: string
			createdBefore?: string
			orderId?: number | string
			reset?: boolean
			groupBy?: null | string
			customerId?: string | number
			billed?: boolean
			paid?: boolean
		}
	): Promise<any>

	[actionTypes.GET_PAYERS](
		{ state, commit }: AugmentedActionContext,
		id: number | string
	): Promise<any>

	[actionTypes.CREATE_PAYER]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.REMOVE_PAYER]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.GET_ALL_ATTACHMENTS_REQUEST](
		{ state, commit }: AugmentedActionContext,
		data: { id: number | string; withRequestedExamForms: any; withSamples: any }
	): Promise<any>

	[actionTypes.CREATE_ATTACHMENT]({ commit }: AugmentedActionContext, payload: any): Promise<any>

	[actionTypes.REMOVE_ATTACHMENT_REQUEST](
		{ getters, commit }: AugmentedActionContext,
		uuid: number | string
	): Promise<any>

	[actionTypes.REMOVE_ATTACHMENT](
		{ getters, commit }: AugmentedActionContext,
		data: { uuid: number | string; resource: any }
	): Promise<any>

	[actionTypes.GET_ATTACHMENT_DOWNLOAD](
		{ commit }: AugmentedActionContext,
		uuid: string | number
	): Promise<any>

	[actionTypes.CANCEL_RECEIVING](
		{ getters, commit }: AugmentedActionContext,
		data: { id: number | string; payload: any }
	): Promise<any>

	[actionTypes.CANCEL_SAMPLE](
		{ getters, commit }: AugmentedActionContext,
		data: { id: number | string; payload: any }
	): Promise<any>

	[actionTypes.GET_ALL_FORMS](
		{ getters, commit }: AugmentedActionContext,
		id: number | string
	): Promise<any>

	[actionTypes.GET_ALL_SAMPLES](
		{ commit }: AugmentedActionContext,
		data: { id: string | number; page: number; perPage: number }
	): Promise<any>

	[actionTypes.CREATE_SAMPLE](
		{ commit }: AugmentedActionContext,
		data: { id: string | number; payload: any }
	): Promise<any>

	[actionTypes.UPDATE_SAMPLE](
		{ commit, getters }: AugmentedActionContext,
		data: { id: string | number; payload: any }
	): Promise<any>

	[actionTypes.REMOVE_SAMPLE](
		{ getters, commit }: AugmentedActionContext,
		data: { payload: any }
	): Promise<any>

	[actionTypes.GET_SAMPLE_STATUS_LIST]({ state, commit }: AugmentedActionContext): Promise<any>

	[actionTypes.CHANGE_SAMPLE_STATUS](
		{ commit, getters }: AugmentedActionContext,
		data: { id: number | string; payload: any }
	): Promise<any>

	[actionTypes.GET_SAMPLES_TO_RELATE](
		{ commit }: AugmentedActionContext,
		data: { id: number | string; page: number; parentless: any }
	): Promise<any>

	[actionTypes.GET_SAMPLES_EXAMS_TO_RELATE](
		{ commit }: AugmentedActionContext,
		data: { id: number | string; page: number }
	): Promise<any>

	[actionTypes.GET_SAMPLE_RELATIONSHIPS](
		{ commit }: AugmentedActionContext,
		data: {
			protocol_number?: number | string | null
			barcode_protocol_number?: number | string | null
		}
	): Promise<any>

	[actionTypes.UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM](
		{ getters, commit }: AugmentedActionContext,
		data: { idExamGroup: number | string; idExam: number | string; status: any }
	): void

	[actionTypes.GET_ALL_REQUESTEDS_EXAMS](
		{ commit }: AugmentedActionContext,
		data: {
			page: number
			limit: number
			customer_id?: number | string
			requestedExamStatus: any
		}
	): Promise<any>

	[actionTypes.DELETE_MULTIPLE_REQUESTED_EXAMS](
		{ commit }: AugmentedActionContext,
		data: { selected: any; ids: any }
	): Promise<any>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	rootGetters: any
	rootState: any

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit(key: any, payload?: any, root?: { root: true }): ReturnType<any>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<ReceivingState, RootState>, 'getters' | 'commit' | 'dispatch'>
