import API from '@/axios'
import { AxiosResponse } from 'axios'
import { ICreateAgreementCustomerParams, ICreateAgreementCustomerResponse } from './types'

const AgreementEndpoint = '/models/agreements'
const AgreementCustomersEndpoint = '/models/agreement-customers'

export default {
	async createAgreementCustomer(
		params: ICreateAgreementCustomerParams
	): Promise<AxiosResponse<ICreateAgreementCustomerResponse>> {
		return await API.post(
			`${AgreementEndpoint}/${params.agreementId}/customers`,
			params.payload
		)
	},

	async removeAgreementCustomer(
		agreementCustomerId: number
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.delete(`${AgreementCustomersEndpoint}/${agreementCustomerId}`)
	},
}
