import { RootState } from '@/store/types'
import { Actions, actionTypes as types } from './action-types'
import { mutationTypes } from '../../mutations/animals/mutation-types'
import { ReceivingState } from '../../types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ActionTree } from 'vuex'
import AnimalAPI from '@/modules/animals/service'
import { IAnimalResponse } from '@/modules/animals/store/types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ANIMAL_NAMESPACE } from '@/modules/animals/store'
import { actionTypes as actionTypesOfAnimals } from '@/modules/animals/store/actions/action-types'
import { AxiosResponse } from 'axios'
import { getterTypes } from '../../getters/customers/getter-types'

export const actions: ActionTree<ReceivingState, RootState> & Actions = {
	async [types.GET_ANIMALS](
		{ commit },
		filters
	): Promise<ITypeOrmPaginationTemplate<IAnimalResponse> | boolean> {
		try {
			commit(mutationTypes.SET_ANIMAL_LOADING, ENABLE_LOADING)

			const { status, data: animals } = await AnimalAPI.getAnimals(filters)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_ANIMALS, animals)
			}

			commit(mutationTypes.SET_ANIMAL_LOADING, DISABLE_LOADING)

			return status === STATUS_SUCCESS ? animals : false
		} catch (error: any) {
			commit(mutationTypes.SET_ANIMAL_LOADING, DISABLE_LOADING)
			return false
		}
	},

	async [types.GET_PATIENTS_PET_OWNER_CHILDREN](
		{ dispatch, commit, getters },
		{ petOwnerId, ownerActorId }: { petOwnerId: string | number; ownerActorId: string }
	): Promise<IAnimalResponse[] | boolean> {
		try {
			const responseAnimals: AxiosResponse<IAnimalResponse[]> = await dispatch(
				`${ANIMAL_NAMESPACE}/${actionTypesOfAnimals.GET_ANIMALS_BY_PET_OWNER}`,
				{
					page: 1,
					limit: 20,
					customerId: petOwnerId,
				},
				{ root: true }
			)

			const isSuccess = !!responseAnimals?.data

			if (isSuccess) {
				const indexOwner = getters[getterTypes.GET_CUSTOMER_INDEX_BY_UUID](ownerActorId)

				if (indexOwner > -1) {
					const indexPetOwner = getters[
						getterTypes.GET_CUSTOMER_INDEX_BY_UUID_RELATIONSHIP
					]({
						indexOwner,
						petOwnerId: petOwnerId,
					})

					if (indexPetOwner > -1) {
						commit(mutationTypes.SET_ANIMALS_CHILDREN_PET_OWNER, {
							indexOwner,
							indexPetOwnerRelationship: indexPetOwner,
							animals: responseAnimals.data,
						})
					}
				}
			}

			return isSuccess ? responseAnimals.data : false
		} catch (error: any) {
			return false
		}
	},
}

export default { actions }
