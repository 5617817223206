import { ICustomerFilters } from '@/modules/customers/store/types'
import { IKeyOfSelectedActor, ISelectionMode, ReceivingState } from '../../types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ICustomer } from '@/modules/clientes/store/types'
import { IAnimalResponse } from '@/modules/animals/store/types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_CUSTOMERS = 'SET_CUSTOMERS',
	RESET_CUSTOMERS = 'RESET_CUSTOMERS',

	SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS',
	RESET_CUSTOMER_FILTERS = 'RESET_CUSTOMER_FILTERS',

	SET_RELATIONSHIPS_CUSTOMER = 'SET_RELATIONSHIPS_CUSTOMER',
	SET_ANIMALS_CUSTOMER = 'SET_ANIMALS_CUSTOMER',

	ADD_CUSTOMER = 'ADD_CUSTOMER',
	SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING',

	SET_SELECTED_ACTOR_CUSTOMER = 'SET_SELECTED_ACTOR_CUSTOMER',
	SET_SELECTED_ACTOR_VETERINARIAN = 'SET_SELECTED_ACTOR_VETERINARIAN',
	SET_SELECTED_ACTOR_PET_OWNER = 'SET_SELECTED_ACTOR_PET_OWNER',
	SET_SELECTED_ACTOR_PATIENT = 'SET_SELECTED_ACTOR_PATIENT',

	RESET_ACTORS_SELECTED = 'RESET_ACTORS_SELECTED',

	REMOVE_ACTOR_SELECTED = 'REMOVE_ACTOR_SELECTED',

	UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',

	SET_MODAL_STAKEHOLDER_SELECTION = 'SET_MODAL_STAKEHOLDER_SELECTION',
	SET_IS_MODE_CREATE_RECEIVING = 'SET_IS_MODE_CREATE_RECEIVING',
	RESET_MODE_CREATE_RECEIVING = 'RESET_MODE_CREATE_RECEIVING',

	SET_SELECTION_MODE = 'SET_SELECTION_MODE',
	RESET_SELECTION_MODE = 'RESET_SELECTION_MODE',
}

export type Mutations<S = ReceivingState> = {
	[mutationTypes.SET_CUSTOMERS](
		state: S,
		typeormPagination: ITypeOrmPaginationTemplate<ICustomer>
	): void

	[mutationTypes.SET_CUSTOMER_FILTERS](state: S, filters: ICustomerFilters): void

	[mutationTypes.RESET_CUSTOMERS](state: S): void

	[mutationTypes.RESET_CUSTOMER_FILTERS](state: S): void

	[mutationTypes.SET_CUSTOMER_LOADING](state: S, loading: boolean): void

	[mutationTypes.SET_RELATIONSHIPS_CUSTOMER](
		state: S,
		data: { indexCustomer: number; relationship: ICustomer[] }
	): void

	[mutationTypes.SET_ANIMALS_CUSTOMER](
		state: S,
		data: { indexCustomer: number; animals: IAnimalResponse[] }
	): void

	[mutationTypes.SET_SELECTED_ACTOR_CUSTOMER](state: S, actor: ICustomer): void
	[mutationTypes.SET_SELECTED_ACTOR_VETERINARIAN](state: S, actor: ICustomer): void
	[mutationTypes.SET_SELECTED_ACTOR_PET_OWNER](state: S, actor: ICustomer): void
	[mutationTypes.SET_SELECTED_ACTOR_PATIENT](state: S, animal: IAnimalResponse): void

	[mutationTypes.REMOVE_ACTOR_SELECTED](state: S, key: IKeyOfSelectedActor): void

	[mutationTypes.RESET_ACTORS_SELECTED](state: S): void

	[mutationTypes.UPDATE_CUSTOMER](state: S, data: { index: number; payload: ICustomer }): void

	[mutationTypes.SET_MODAL_STAKEHOLDER_SELECTION](state: S, value: boolean): void

	[mutationTypes.SET_IS_MODE_CREATE_RECEIVING](state: S, value: boolean): void

	[mutationTypes.RESET_MODE_CREATE_RECEIVING](state: S): void

	[mutationTypes.SET_SELECTION_MODE](state: S, selectionMode: ISelectionMode): void

	[mutationTypes.RESET_SELECTION_MODE](state: S): void

	[mutationTypes.ADD_CUSTOMER](state: S, customer: ICustomer): void
}
