<template>
	<v-snackbar
		v-model="open"
		timeout="-1"
		absolute
		right
		height="190"
		transition="fab-transition"
		bottom
		light
		multi-line
		elevation="10"
		style="z-index: 9999"
	>
		<div class="snackbar-content">
			<div class="d-flex justify-space-between">
				<p class="title underline secondary--text text--darken-1 mb-2">
					Bipagem de etiquetas
				</p>
				<v-btn icon @click="open = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<div style="max-width: 300px" class="mb-4">
				<span class="secondary--text fs-14">
					Escaneie o QRCode ou código da amostra para encontrar seus vínculos.
				</span>
			</div>
			<label>Protocolo</label>
			<v-text-field
				v-model="protocol"
				class="secondary--text"
				:disabled="loading"
				autofocus
				:loading="loading"
				:messages="`Aperte 'Enter' para buscar`"
				@keyup.enter="loadSampleRelationships()"
			/>
		</div>
	</v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'

const PROTOCOL_QRCODE_LENGTH = 10
const PROTOCOL_BARCODE_LENGTH = 14

export default {
	name: 'TagPager',

	data() {
		return {
			open: false,
			mounted: false,
			loading: false,
			protocol: null,
			// debouncedInput: null,
		}
	},

	watch: {
		open(value) {
			if (!value) {
				this.protocol = null
			}
		},
	},

	mounted() {
		if (this.mounted) return
		this.mounted = true
		if (this.open) return

		window.addEventListener('keydown', (keyboardEvent) => {
			if (keyboardEvent.ctrlKey && keyboardEvent.code === 'KeyB') {
				if (this.open) {
					return (this.open = !this.open)
				}

				this.openModal()
			}
		})
	},

	methods: {
		...mapActions('Orders', { getOrder: 'getOrder', getOrderStatusList: 'getStatusList' }),
		...mapActions('Receiving', {
			visualizeRequest: 'visualizeRequest',
			getRequestedExams: 'getRequestedExams',
			getRequestStatusList: 'getRequestStatusList',
			getExamStatusList: 'getExamStatusList',
			getSampleRelationships: 'getSampleRelationShips',
		}),

		openModal() {
			this.open = true
		},

		async loadSampleRelationships() {
			if (this.protocol.length === PROTOCOL_QRCODE_LENGTH) {
				this.handleSearchProtocolNumberByQrcode()
			} else if (this.protocol.length === PROTOCOL_BARCODE_LENGTH) {
				this.handleSearchProtocolNumberByBarcode()
			}
		},

		async handleSearchProtocolNumberByQrcode() {
			this.loading = true

			try {
				const relationships = await this.getSampleRelationships({
					protocol_number: this.protocol,
				})

				try {
					await this.goToRequest(relationships.exam_requests[0].id)
				} catch (error) {
					this.loading = false
				}

				// this.protocol = ''
				this.loading = false
			} finally {
				this.loading = false
			}
		},

		async handleSearchProtocolNumberByBarcode() {
			this.loading = true

			try {
				const regex = /\D/g
				const barcode_protocol_number = this.protocol.replace(regex, '')

				const relationships = await this.getSampleRelationships({
					barcode_protocol_number: barcode_protocol_number,
				})

				try {
					await this.goToRequest(relationships.exam_requests[0].id)
				} catch (error) {
					this.loading = false
				}

				this.loading = false
			} finally {
				this.loading = false
			}
		},

		async goToRequest(id) {
			const request = await this.visualizeRequest({ id })

			this.loadAllStatus()

			if (request) {
				await this.getOrder(request.order.id)
				await this.$router.push({
					name: 'receiving.index',
					params: { id: String(request.id) },
					query: {
						menuRight: true,
					},
				})
				this.closeDialog()
			}
		},

		// handleDebouncedInput() {
		// 	if (this.debouncedInput) {
		// 		clearTimeout(this.debouncedInput)
		// 	}

		// 	this.debouncedInput = setTimeout(() => {
		// 		this.loadSampleRelationships()
		// 	}, 600)
		// },

		loadAllStatus() {
			this.getRequestStatusList()
			this.getExamStatusList()
		},

		closeDialog() {
			this.open = false
		},
	},
}
</script>

<style scoped lang="scss">
.snackbar-content {
	padding: 16px;
	width: 350px;
}

::v-deep .v-snack__wrapper {
	align-items: initial;
	border-radius: 4px;
}

::v-deep .v-snack__content {
	border-top: 4px solid var(--v-accent2-base);
	border-left: 4px solid var(--v-primary-base);
	border-radius: 4px;
}
</style>
