import axios from 'axios'
import { MutationTree } from 'vuex'
import { FileToUpload, UploadsState, types } from './types'

export const mutations: MutationTree<UploadsState> = {
	[types.ADD_FILES_TO_UPLOAD](state, files: FileToUpload[]) {
		state.filesToUpload = state.filesToUpload.concat(files)
	},
	[types.REMOVE_FILE_TO_UPLOAD](state, index: number) {
		if (index === state.uploadFileIndex && state.uploadFileRequest) {
			state.uploadFileRequest.cancel()
		}

		state.filesToUpload.splice(index, 1, { ...state.filesToUpload[index], canceled: true })
	},
	[types.REMOVE_FILES_TO_UPLOAD](state) {
		if (state.uploadFileRequest) state.uploadFileRequest.cancel()
		state.filesToUpload = []
		state.uploadFileIndex = 0
		state.uploadFileRequest = null
	},
	[types.UPDATE_FILE_PROGRESS](state, { index, progress }) {
		state.filesToUpload[index].progress = progress
	},
	[types.FILE_UPLOADED](state, index) {
		state.filesToUpload[index].done = true
	},

	[types.SET_UPLOAD_FILE_REQUEST](state) {
		state.uploadFileRequest = axios.CancelToken.source()
	},
	[types.SET_UPLOAD_FILE_INDEX](state, index) {
		state.uploadFileIndex = index
	},
}

export default mutations
