





















import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeReference from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/References/Cards/AttributeReference/AttributeReference.vue'
import { isAttributeIntegerOrDecimalPerType } from '@/utils/functions/isAttributeIntegerOrDecimal'
import { isAttributeSelectPerType } from '@/utils/functions/isAttributeSelect'
import { isAttributePercentagePerType } from '@/utils/functions/isAttributePercentage'
import { isAttributeFormulaPerType } from '@/utils/functions/isAttributeFormula'
import { IAttributeGroup } from '@/modules/exams/store/attributes/types'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'

@Component({
	name: 'AttributeReferenceGroup',
	components: { AttributeReference, ButtonIcon },
})
export default class AttributeReferenceGroup extends Vue {
	@Prop({ type: Object, required: true }) groupAttribute!: IAttributeGroup

	showAttributes = true
	groupNameDefault = 'Valores de Referência'

	get getAttributes() {
		return Array.from(this.groupAttribute.attributes).filter(
			(attribute) =>
				isAttributeIntegerOrDecimalPerType(attribute.type) ||
				isAttributeSelectPerType(attribute.type) ||
				isAttributePercentagePerType(attribute.type) ||
				isAttributeFormulaPerType(attribute.type)
		)
	}
}
