import axios from 'axios'
import axios_interceptors_request from './interceptors/axios_interceptors_request'
import axios_interceptors_errors from './interceptors/axios_interceptors_errors'

const Http = axios.create({
	baseURL: process.env.VUE_APP_API,
})

axios_interceptors_request(Http)
axios_interceptors_errors(Http)

export default Http
