import API from '../../services/attributes'
import { RootState } from '@/store/types'
import { AttributeState, mutationTypes, actionTypes, IFormula, IMappedExamField } from './types'
import { ActionTree } from 'vuex'
import { types as errorTypes } from '@/modules/errors/store/types'
import {
	IResponseAttributeGroup,
	IResponseAttributes,
	IResponseAttribute,
	IUpdateAttributePayload,
	IUpdateAttributeGroupParams,
	ICreateAttributeGroupParams,
	ICreateAttributeParams,
	ICreateAttributeFormulaPayload,
	IUpdateAttributePositionsParams,
	IUpdateAttributeMappedFieldPayload,
	ICreateAttributeMappedFieldPayload,
} from '../../services/attributes/types'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { AxiosError } from 'axios'

export interface ISetFormulaActionParams {
	attributeId: number
	payload: ICreateAttributeFormulaPayload
	attributeGroupId: number
}

export interface ICreateFormulaActionParams {
	attributeId: number
	payload: ICreateAttributeFormulaPayload
	attributeGroupId: number
	indexFormula: number
}

export interface IUpdateFormulaActionParams {
	formulaId: number
	payload: ICreateAttributeFormulaPayload
	attributeGroupId: number
	attributeId: number
}

export interface IDeleteFormulaActionParams {
	formulaId: number
	attributeGroupId: number
	attributeId: number
}

export interface ISetRemoveFormulaActionParams {
	indexFormula: number
	attributeGroupId: number
	attributeId: number
}

export const actions: ActionTree<AttributeState, RootState> = {
	async [actionTypes.GET_ATTRIBUTES](
		{ commit },
		examId: number
	): Promise<IResponseAttributes[] | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_LOADING, true)

			const { status, data: attributes } = await API.getAttributes(examId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_ATTRIBUTES, attributes)
			}

			commit(mutationTypes.SET_ATTRIBUTE_LOADING, false)
			return status === STATUS_SUCCESS ? attributes : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.CREATE_ATTRIBUTE_GROUP](
		{ commit, getters, state },
		{ payload, examId }: ICreateAttributeGroupParams
	): Promise<IResponseAttributeGroup | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)
			const { status, data: attributeGroup } = await API.createAttributeGroup({
				payload,
				examId,
			})

			if (status === STATUS_CREATED) {
				let attributeGroupIndex

				if (state.selectedAttributeGroup.id) {
					attributeGroupIndex = getters.getIndexAttributeGroup(
						state.selectedAttributeGroup.id
					)
				}

				if (!state.selectedAttributeGroup.id || attributeGroupIndex === -1) {
					attributeGroupIndex = getters.getLastAttributeGroupIndex

					if (attributeGroupIndex === -1) {
						attributeGroupIndex = 0
					}
				}

				if (attributeGroupIndex > -1) {
					commit(mutationTypes.CREATE_ATTRIBUTE_GROUP, {
						attributeGroupIndex,
						attributeGroup,
					})
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			return status === STATUS_CREATED ? attributeGroup : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_ATTRIBUTE_GROUP](
		{ getters, commit },
		{ payload, attributeGroupId }: IUpdateAttributeGroupParams
	): Promise<IResponseAttributeGroup | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: attributeGroup } = await API.updateAttributeGroup({
				payload,
				attributeGroupId,
			})

			if (status === STATUS_SUCCESS) {
				const index = getters.getIndexAttributeGroup(attributeGroupId)

				if (index > -1) {
					commit(mutationTypes.UPDATE_ATTRIBUTE_GROUP, { attributeGroup, index })
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			return status === STATUS_SUCCESS ? attributeGroup : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.DELETE_ATTRIBUTE_GROUP](
		{ getters, commit },
		id: number
	): Promise<boolean | AxiosError<{ id: string; message: string }>> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status } = await API.deleteAttributeGroup(id)

			if (status === STATUS_SUCCESS) {
				const index = getters.getIndexAttributeGroup(id)

				if (index > -1) {
					commit(mutationTypes.DELETE_ATTRIBUTE_GROUP, index)
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.CREATE_ATTRIBUTE](
		{ getters, commit, state },
		{ groupId, payload }: ICreateAttributeParams
	): Promise<IResponseAttribute | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: attribute } = await API.createAttribute({
				groupId,
				payload,
			})

			if (status === STATUS_CREATED) {
				const indexAttributeGroup = getters.getIndexAttributeGroup(groupId)
				let attributeIndex

				if (state.selectedAttribute.id) {
					attributeIndex = getters.getIndexAttribute({
						id: state.selectedAttribute.id,
						indexAttributeGroup,
					})
				}

				if (!state.selectedAttribute.id || attributeIndex === -1) {
					attributeIndex = getters.getLastAttributeIndex(indexAttributeGroup)

					if (attributeIndex === -1) {
						attributeIndex = 0
					}
				}

				if (indexAttributeGroup > -1 && attributeIndex > -1) {
					commit(mutationTypes.CREATE_ATTRIBUTE, {
						indexAttributeGroup,
						attributeIndex,
						attribute,
					})
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_CREATED ? attribute : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_ATTRIBUTE](
		{ getters, commit },
		{
			attributeId,
			attributeGroupId,
			payload,
		}: {
			attributeId: number
			attributeGroupId: number
			payload: IUpdateAttributePayload
		}
	): Promise<IResponseAttribute | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: attribute } = await API.updateAttribute({
				payload,
				attributeId,
			})

			if (status === STATUS_SUCCESS) {
				const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

				if (indexAttributeGroup > -1) {
					const indexAttribute = getters.getIndexAttribute({
						id: attributeId,
						indexAttributeGroup: indexAttributeGroup,
					})

					if (indexAttribute > -1) {
						commit(mutationTypes.UPDATE_ATTRIBUTE, {
							indexAttributeGroup: indexAttributeGroup,
							indexAttribute: indexAttribute,
							attribute: attribute,
						})
					}
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_SUCCESS ? attribute : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},
	async [actionTypes.DELETE_ATTRIBUTE](
		{ commit },
		{ attributeId }: { attributeGroupId: number; attributeId: number }
	): Promise<boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: attributes } = await API.deleteAttribute(attributeId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_ATTRIBUTES, attributes)
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.GET_FORMULAS]({ commit }): Promise<IFormula[] | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_LOADING, true)

			const { status, data: formulas } = await API.getFormulas()

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_FORMULAS, formulas)
			}

			commit(mutationTypes.SET_ATTRIBUTE_LOADING, false)
			return status === STATUS_SUCCESS ? formulas : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.SET_FORMULA](
		{ commit, getters },
		{ payload, attributeGroupId, attributeId }: ICreateFormulaActionParams
	): Promise<void> {
		try {
			const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

			if (indexAttributeGroup > -1) {
				const indexAttribute = getters.getIndexAttribute({
					id: attributeId,
					indexAttributeGroup: indexAttributeGroup,
				})

				if (indexAttribute > -1) {
					commit(mutationTypes.SET_FORMULA, {
						formula: payload,
						indexAttributeGroup,
						indexAttribute,
					})
				}
			}

			return
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.CREATE_FORMULA](
		{ commit, getters },
		{ attributeId, attributeGroupId, payload, indexFormula }: ICreateFormulaActionParams
	): Promise<void> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: formula } = await API.createFormula({ attributeId, payload })

			if (status === STATUS_CREATED) {
				const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

				if (indexAttributeGroup > -1) {
					const indexAttribute = getters.getIndexAttribute({
						id: attributeId,
						indexAttributeGroup: indexAttributeGroup,
					})

					if (indexAttribute > -1) {
						commit(mutationTypes.CREATE_FORMULA, {
							formula,
							indexAttributeGroup,
							indexAttribute,
							indexFormula,
						})
					}
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_FORMULA](
		{ commit, getters },
		{ formulaId, attributeGroupId, attributeId, payload }: IUpdateFormulaActionParams
	): Promise<void> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: formula } = await API.updateFormula({ formulaId, payload })

			if (status === STATUS_SUCCESS) {
				const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

				if (indexAttributeGroup > -1) {
					const indexAttribute = getters.getIndexAttribute({
						id: attributeId,
						indexAttributeGroup: indexAttributeGroup,
					})

					if (indexAttribute > -1) {
						const indexFormula = getters.getIndexFormula({
							id: formulaId,
							indexAttributeGroup: indexAttributeGroup,
							indexAttribute: indexAttribute,
						})

						commit(mutationTypes.UPDATE_FORMULA, {
							formula,
							indexAttributeGroup,
							indexAttribute,
							indexFormula,
						})
					}
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.DELETE_FORMULA](
		{ commit, getters },
		{ formulaId, attributeGroupId, attributeId }: IDeleteFormulaActionParams
	): Promise<void> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status } = await API.deleteFormula(formulaId)

			if (status === STATUS_SUCCESS) {
				const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

				if (indexAttributeGroup > -1) {
					const indexAttribute = getters.getIndexAttribute({
						id: attributeId,
						indexAttributeGroup: indexAttributeGroup,
					})

					if (indexAttribute > -1) {
						const indexFormula = getters.getIndexFormula({
							id: formulaId,
							indexAttributeGroup: indexAttributeGroup,
							indexAttribute: indexAttribute,
						})

						commit(mutationTypes.DELETE_FORMULA, {
							indexAttributeGroup,
							indexAttribute,
							indexFormula,
						})
					}
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.SET_REMOVE_FORMULA](
		{ commit, getters },
		{ indexFormula, attributeGroupId, attributeId }: ISetRemoveFormulaActionParams
	): Promise<void> {
		try {
			const indexAttributeGroup = getters.getIndexAttributeGroup(attributeGroupId)

			if (indexAttributeGroup > -1) {
				const indexAttribute = getters.getIndexAttribute({
					id: attributeId,
					indexAttributeGroup: indexAttributeGroup,
				})

				if (indexAttribute > -1) {
					commit(mutationTypes.DELETE_FORMULA, {
						indexAttributeGroup,
						indexAttribute,
						indexFormula,
					})
				}
			}

			return
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [`${actionTypes.UPDATE_ATTRIBUTE_POSITIONS}`](
		{ commit },
		{ examId, payload }: IUpdateAttributePositionsParams
	): Promise<void> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { data } = await API.updateAttributePositions({ examId, payload })

			if (data) {
				commit(mutationTypes.UPDATE_ATTRIBUTE_POSITIONS, data)
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},
	async [actionTypes.CREATE_ATTRIBUTE_MAPPED_FIELD](
		{ commit },
		{
			examId,
			payload,
		}: {
			examId: number
			payload: ICreateAttributeMappedFieldPayload
		}
	): Promise<IMappedExamField | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: mappedField } = await API.createAttributeMappedField({
				examId,
				payload,
			})

			if (mappedField) {
				commit(mutationTypes.CREATE_MAPPED_EXAM_FIELDS, mappedField)
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_SUCCESS ? mappedField : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},
	async [actionTypes.UPDATE_ATTRIBUTE_MAPPED_FIELD](
		{ commit, getters },
		{
			mappedFieldId,
			payload,
		}: {
			mappedFieldId: string
			payload: IUpdateAttributeMappedFieldPayload
		}
	): Promise<IMappedExamField | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: mappedField } = await API.updateAttributeMappedField({
				mappedFieldId,
				payload,
			})

			if (status === STATUS_SUCCESS) {
				const index = getters.getIndexMappedExamField(mappedFieldId)

				if (index > -1) {
					commit(mutationTypes.UPDATE_MAPPED_EXAM_FIELDS, { mappedField, index })
				}
			}

			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)

			return status === STATUS_SUCCESS ? mappedField : false
		} catch (error) {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},
	async [actionTypes.GET_MAPPED_EXAM_FIELDS](
		{ commit },
		examId: number
	): Promise<IMappedExamField[] | boolean> {
		try {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, true)

			const { status, data: mappedExamFields } = await API.getMappedExamFields(examId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_MAPPED_EXAM_FIELDS, mappedExamFields)
			}

			return status === STATUS_SUCCESS ? mappedExamFields : false
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		} finally {
			commit(mutationTypes.SET_ATTRIBUTE_SAVING, false)
		}
	},
}
