/* eslint-disable no-unused-vars */
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'

export enum mutationTypes {
	SET_REFERENCE_LOADING = 'SET_REFERENCE_LOADING',
	SET_REFERENCE_SAVING = 'SET_REFERENCE_SAVING',

	SET_REFERENCES = 'SET_REFERENCES',
	SET_SELECTED_REFERENCE = 'SET_SELECTED_REFERENCE',
	CREATE_REFERENCE = 'CREATE_REFERENCE',
	UPDATE_REFERENCE = 'UPDATE_REFERENCE',
	DELETE_REFERENCE = 'DELETE_REFERENCE',

	SET_REFERENCE_GROUPS = 'SET_REFERENCE_GROUPS',

	CREATE_REFERENCE_GROUPS = 'CREATE_REFERENCE_GROUPS',

	UPDATE_REFERENCE_GROUP = 'UPDATE_REFERENCE_GROUP',

	DELETE_REFERENCE_GROUP = 'DELETE_REFERENCE_GROUP',

	SET_REFERENCED_FIELDS = 'SET_REFERENCED_FIELDS',
	SET_REFERENCED_FIELDS_VALUES = 'SET_REFERENCED_FIELDS_VALUES',
	CREATE_REFERENCE_FIELDS = 'CREATE_REFERENCE_FIELDS',
	UPDATE_REFERENCE_FIELDS_VALUE = 'UPDATE_REFERENCE_FIELDS_VALUE',

	DELETE_REFERENCE_FIELD = 'DELETE_REFERENCE_FIELD',
	DELETE_REFERENCE_FIELD_VALUE = 'DELETE_REFERENCE_FIELD_VALUE',

	RESET_REFERENCE = 'RESET_REFERENCE',
	RESET_CARREGADO = 'RESET_CARREGADO',

	SET_REFERENCE_EDIT_MODE = 'SET_REFERENCE_EDIT_MODE',
}

export enum actionTypes {
	GET_EXAM_REFERENCES = 'GET_EXAM_REFERENCES',
	GET_EXAM_REFERENCE = 'GET_EXAM_REFERENCE',

	GET_GROUPS_BY_REFERENCE = 'GET_GROUPS_BY_REFERENCE',

	CREATE_REFERENCE = 'CREATE_REFERENCE',
	CREATE_REFERENCE_GROUP = 'CREATE_REFERENCE_GROUP',

	UPDATE_REFERENCE = 'UPDATE_REFERENCE',
	UPDATE_REFERENCE_GROUP = 'UPDATE_REFERENCE_GROUP',
	UPDATE_REFERENCE_VALUE = 'UPDATE_REFERENCE_VALUE',

	DELETE_REFERENCE_GROUP = 'DELETE_REFERENCE_GROUP',
	DELETE_REFERENCE = 'DELETE_REFERENCE',
}

export interface IReference {
	id: number
	nome: string
	idade_inicial: number
	idade_final: number
	tipo_idade: string
	exame_id: number
	especie_id: number
	organizacao_id: string
	created_at: string | Date
	updated_at: string | Date
}

export type IReferenceFilters = IFiltersTemplate

export enum IReferenceEditMode {
	CURRENT_GROUP = 'currentGroup',
	ALL_GROUPS = 'allGroups',
}

export interface IReferenceGroup {
	id: number
	nome: string
}

export interface ReferenceState {
	references: IReference[]
	referenceIsLoading: boolean
	referenceIsSaving: boolean
	selectedReference: IReference | null
	referenceFilters: IReferenceFilters
	referenceGroups: IReferenceGroup[]
	referencedFields: any[]
	referenceEditMode: IReferenceEditMode
}
