



































import { Component, Mixins } from 'vue-property-decorator'
import DragCarousel from '@/components/global/DragCarousel/DragCarousel.vue'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import { IAttributeType } from '@/modules/exams/store/attributes/types'
import { TranslateResult } from 'vue-i18n'
import { attributesType, AttributeType } from '@/utils/constants/examConstants'
import CreateAttributeAndAttributeGroupMixin from '@/modules/exams/mixins/CreateAttributeAndAttributeGroupMixin'

@Component({
	name: 'ExamHeaderAttributes',
	components: {
		DragCarousel,
		ButtonIcon,
	},
	mixins: [CreateAttributeAndAttributeGroupMixin],
})
export default class ExamHeaderAttributes extends Mixins(CreateAttributeAndAttributeGroupMixin) {
	loadingButton: AttributeType | null = null
	createAttributeGroupLoading = false

	getAttributesType(): IAttributeType[] {
		return attributesType
	}

	getTranslateAttributeText(attribute: IAttributeType): TranslateResult {
		return this.$t(`modules.attributes.alert.attributeTypes.${attribute.slug}`)
	}

	async handleClickAttribute(type: AttributeType): Promise<void> {
		this.loadingButton = type
		await this.handleCreateAttribute(type)
		this.loadingButton = null
	}

	async handleClickAttributeGroup(): Promise<void> {
		this.createAttributeGroupLoading = true
		await this.handleCreateAttributeGroup()
		this.createAttributeGroupLoading = false
	}
}
