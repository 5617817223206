import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { updateAbility } from '@/utils/permissions/updateAbility'

export default {
	namespaced: true,

	state: {
		attributes: [],
		permissions: {},
	},

	getters: {
		getResources: (state) => {
			return Object.keys(state.permissions)
		},
		getGroupedAttributesByKey: (state) => (key) => {
			const groupedAttributes = state.attributes.reduce((a, b) => {
				const group = Array.from(a)
				const keyAlreadyAddedIndex = group.findIndex((attribute) => attribute.key === b.key)

				const attribute = {
					id: b.id,
					key: b.key,
					value: b.value,
					description: b.description,
					color: b.color,
					created_by: b.created_by_user_id,
					created_at: b.created_at,
				}

				if (b.users) {
					attribute.users = b.users
				}

				if (b.resources) {
					attribute.resources = b.resources
				}

				if (keyAlreadyAddedIndex !== -1) {
					group[keyAlreadyAddedIndex].values.push(attribute)
				} else {
					group.push({
						key: b.key,
						values: [attribute],
					})
				}

				return group
			}, [])

			const selectedAttributeIndex = groupedAttributes.findIndex(
				(attribute) => attribute.key === key
			)

			if (selectedAttributeIndex !== -1) {
				return groupedAttributes[selectedAttributeIndex].values
			}

			return groupedAttributes
		},
		getAttributeIndexById: (state) => (id) => {
			return state.attributes.findIndex((attribute) => attribute.id === id)
		},
		getMemberIndexById:
			(state) =>
			({ index, id }) => {
				return state.attributes[index].users.findIndex((user) => user.id === id)
			},
	},

	mutations: {
		setAttributes: (state, payload) => {
			state.attributes = payload
		},
		setAttribute: (state, { index, payload }) => {
			const attribute = Object.assign(state.attributes[index], payload)

			state.attributes.splice(index, 1, attribute)
		},
		addAttribute: (state, payload) => {
			state.attributes.unshift(payload)
		},
		removeAttribute: (state, index) => {
			state.attributes.splice(index, 1)
		},
		addAttributeMember: (state, { index, payload }) => {
			state.attributes.splice(index, 1, payload)
		},
		removeAttributeMember: (state, { index, memberIndex }) => {
			state.attributes[index].users.splice(memberIndex, 1)
		},
		setPermissions: (state, payload) => {
			state.permissions = payload
		},

		setReset: (state) => {
			state.attributes = []
			state.permissions = {}
		},
	},

	actions: {
		async createAttribute({ commit }, { key, value, color, description }) {
			try {
				const attribute = await API.createAttribute({ key, value, color, description })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attributes.feedback.success.create.attribute') },
					{ root: true }
				)
				commit('addAttribute', attribute)

				return attribute
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateAttribute({ commit, getters }, { id, key, value, color, description }) {
			try {
				const payload = await API.updateAttribute({ id, key, value, color, description })

				const attributeIndex = getters.getAttributeIndexById(id)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attributes.feedback.success.update.attribute') },
					{ root: true }
				)
				commit('setAttribute', { index: attributeIndex, payload })

				return payload
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAttributes({ commit }) {
			try {
				const attributes = await API.getAttributes()

				commit('setAttributes', attributes)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		async getAttribute({ commit, getters }, id) {
			try {
				const attribute = await API.getAttribute(id)

				const attributeIndex = getters.getAttributeIndexById(id)

				commit('setAttribute', { index: attributeIndex, payload: attribute })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeAttribute({ commit, getters }, id) {
			try {
				const response = await API.removeAttribute(id)

				if (response) {
					const attributeIndex = getters.getAttributeIndexById(id)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.tc('modules.attributes.feedback.success.remove.attribute') },
						{ root: true }
					)
					commit('removeAttribute', attributeIndex)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeAttributes({ commit, getters }, ids) {
			try {
				const response = await API.removeAttributes(ids)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.tc(
							'modules.attributes.feedback.success.remove.attribute',
							ids.length
						),
					},
					{ root: true }
				)
				ids.forEach((id) => {
					const attributeIndex = getters.getAttributeIndexById(id)

					commit('removeAttribute', attributeIndex)
				})

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async addAttributeMember({ commit, getters }, { id, memberId }) {
			try {
				const payload = await API.addMember({ id, user_id: memberId })

				const attributeIndex = getters.getAttributeIndexById(id)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attributes.feedback.success.create.memberAttribute') },
					{ root: true }
				)
				commit('addAttributeMember', { index: attributeIndex, payload })

				return payload
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeAttributeMember({ commit, getters }, { id, memberId }) {
			try {
				const response = await API.removeMember({ id, user_id: memberId })

				const attributeIndex = getters.getAttributeIndexById(id)

				const memberIndex = getters.getMemberIndexById({
					index: attributeIndex,
					id: memberId,
				})

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attributes.feedback.success.remove.memberAttribute') },
					{ root: true }
				)
				commit('removeAttributeMember', { index: attributeIndex, memberIndex })

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getPermissions({ commit }) {
			try {
				const payload = await API.getPermissions()

				commit('setPermissions', payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateResourcePermissions({ commit, getters }, { id, payload }) {
			try {
				const { status, data: attribute } = await API.updateResourcePermissions({
					id,
					payload,
				})

				if (status === STATUS_SUCCESS) {
					const attributeIndex = getters.getAttributeIndexById(id)

					commit('setAttribute', { index: attributeIndex, payload: attribute })

					updateAbility()
				}

				return status === STATUS_SUCCESS ? attribute : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
