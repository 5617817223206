import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'

export default {
	namespaced: true,

	state: {
		labcloud: {
			id: null,
			name: 'Labcloud',
			description: 'Conecte com o labcloud e envie os laudos diretamente para seus clientes.',
			image: '/images/integrations/labcloud.svg',
			is_active: false,
			tags: ['Laudos'],
		},
		asaas: {
			id: null,
			name: 'Asaas',
			description: 'Conecte com o Asaas e envie cobranças para seus clientes.',
			image: '/images/integrations/asaas.svg',
			is_active: false,
			tags: ['Cobranças'],
		},
		asaasPaymentMethods: [
			{
				id: 'BOLETO',
				name: 'Boleto',
			},
			{
				id: 'CREDIT_CARD',
				name: 'Cartão de crédito',
			},
		],
		labcloudClients: [],
		labcloudClient: {
			id: null,
			is_active: false,
		},
		labcloudSynchronization: {},
		labcloudFailedSyncronizations: [],
		customers: [],
	},

	getters: {
		getCustomerIndex: (state) => {
			return (id) => {
				return state.customers.findIndex((customer) => customer.id === id)
			}
		},
	},

	mutations: {
		setLabcloud: (state, payload) => (state.labcloud = payload),
		setAsaas: (state, payload) => (state.asaas = payload),

		setLabcloudSynchronization: (state, payload) => (state.labcloudSynchronization = payload),

		setLabcloudFailedSyncronizations: (state, payload) =>
			(state.labcloudFailedSyncronizations = payload),

		setLabcloudClients: (state, payload) => (state.labcloudClients = payload),

		setLabcloudClient: (state, payload) => (state.labcloudClient = payload),

		addCustomer: (state, payload) => state.customers.push(payload),

		setCustomers: (state, payload) => (state.customers = payload),

		editCustomer: (state, { index, payload }) => state.customers.splice(index, 1, payload),

		removeCustomer: (state, index) => state.customers.splice(index, 1),
	},

	actions: {
		async getLabcloudApplication({ commit }) {
			try {
				const labcloud = await API.viewLabcloudApplication()

				if (labcloud) {
					commit('setLabcloud', labcloud)
				}

				return labcloud
			} catch {
				return null
			}
		},

		async getAsaasApplication({ commit }) {
			try {
				const asaas = await API.viewAsaasApplication()

				if (asaas) {
					commit('setAsaas', asaas)
				}

				return asaas
			} catch {
				return null
			}
		},

		async registerLabcloud({ commit }, { payload }) {
			try {
				const labcloud = await API.registerLabcloud({ payload })

				if (labcloud) {
					commit('setLabcloud', labcloud)
				}

				return labcloud
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async registerAsaas({ commit }, { payload }) {
			try {
				const asaas = await API.registerAsaas({ payload })

				if (asaas) {
					commit('setAsaas', asaas)
				}

				return asaas
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async editLabcloudApplication({ commit }, { payload }) {
			try {
				const labcloud = await API.editLabcloudApplication({ payload })

				if (labcloud) {
					commit('setLabcloud', labcloud)
				}

				return labcloud
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createLabcloudCustomer({ commit }, { payload }) {
			try {
				const labcloudCustomer = await API.createLabcloudCustomer({ payload })

				if (labcloudCustomer.id) {
					commit('addCustomer', labcloudCustomer)
				}

				return labcloudCustomer
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getLabcloudClients({ commit, state }) {
			if (state.labcloudClients.length) return

			try {
				const clients = await API.viewLabcloudClients()

				if (clients) {
					commit('setLabcloudClients', clients)
				}

				return clients
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getLabcloudClient({ commit }, { uuid }) {
			try {
				const client = await API.getLabcloudClient({ uuid })

				if (client.id) {
					commit('setLabcloudClient', client)
				} else {
					commit('setLabcloudClient', {})
				}

				return client
			} catch (error) {
				commit('setLabcloudClient', {})
				return false
			}
		},

		async sendReportToLabcloudApp({ commit }, { payload, headers }) {
			try {
				return await API.sendReportToLabcloudApp({ payload, headers })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getApplicationClients({ commit }, { id }) {
			try {
				const customers = await API.viewApplicationCustomers({ id })

				if (customers) {
					commit('setCustomers', customers)
				}

				return customers
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async editApplicationCustomer({ commit, getters }, { customerId, payload }) {
			try {
				const customer = await API.editApplicationCustomer({
					customerId,
					payload,
				})

				if (customer) {
					const customerIndex = getters.getCustomerIndex(customer.id)

					if (customerIndex > -1) {
						commit('editCustomer', { index: customerIndex, payload: customer })
					}
				}

				return customer
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeApplicationCustomer({ commit, getters }, { customerId }) {
			try {
				await API.removeApplicationCustomer({
					customerId,
				})

				const customerIndex = getters.getCustomerIndex(customerId)

				if (customerIndex > -1) {
					commit('removeCustomer', customerIndex)
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async synchronizeLabcloudCustomers({ commit }) {
			try {
				const sync = await API.synchronizeLabcloudCustomers()

				if (sync) {
					commit('setLabcloudSynchronization', sync)
				}

				return sync
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getLabcloudFailedSyncronizations({ commit }) {
			try {
				const failedSyncronizations = await API.getLabcloudFailedSyncronizations()

				if (failedSyncronizations) {
					commit('setLabcloudFailedSyncronizations', failedSyncronizations)
				}

				return failedSyncronizations
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getLabcloudSynchronization({ commit }, { payload }) {
			try {
				const synchronization = await API.getLabcloudSynchronization(payload.id)

				if (synchronization) {
					commit('setLabcloudSynchronization', synchronization)
				}

				return synchronization
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
