import { MutationTree } from 'vuex'
import { state as animalStateObject } from '../index'
import { AnimalState } from '../types'
import { Mutations, mutationTypes as types } from './mutation-types'
import { state as initialState } from '../'

export const mutations: MutationTree<AnimalState> & Mutations = {
	[types.ADD_ANIMALS_PAGINATION](state, animals) {
		state.animals.data.push(animals)
	},

	[types.UPDATE_ANIMALS_PAGINATION](state, { indexAnimalPagination, animalTypeOrmPagination }) {
		state.animals.data.splice(indexAnimalPagination, 1, animalTypeOrmPagination)
	},

	[types.RESET_ANIMALS_PAGINATION](state) {
		state.animals.data = animalStateObject.animals.data
		state.animals.meta = animalStateObject.animals.meta
	},

	[types.SET_PAGINATION_LOADING](state, loading) {
		state.animals.meta.loading = loading
	},

	[types.SET_MODAL_CREATE_ANIMAL](state, modalState) {
		state.openModalCreateAnimal = modalState
	},

	[types.SET_MODAL_UPDATE_ANIMAL](state, modalState) {
		state.openModalUpdateAnimal = modalState
	},

	[types.SET_MODAL_STAKEHOLDER_SELECTION](state, modalState) {
		state.openStakeholderSelection = modalState
	},

	[types.SET_ACTORS_SELECTED](state, customers) {
		state.actorsSelected = customers
	},

	[types.ADD_ACTOR_SELECTED](state, customer) {
		state.actorsSelected.push(customer)
	},

	[types.UPDATE_ACTOR_SELECTED](state, { indexActor, customer }) {
		state.actorsSelected.splice(indexActor, 1, customer)
	},

	[types.REMOVE_ACTOR_SELECTED](state, { indexActor }) {
		state.actorsSelected.splice(indexActor, 1)
	},

	[types.RESET_ACTORS_SELECTED](state) {
		state.actorsSelected = []
	},

	[types.SET_ANIMAL_SELECTED](state, animal) {
		state.animalSelected = animal
	},

	[types.RESET_ANIMAL_SELECTED](state) {
		state.animalSelected = undefined
	},

	[types.RESET_FILTERS_ANIMAL](state) {
		const stateAnimal = Object.assign({}, initialState.animals)

		state.animals.meta.filters = Object.assign({}, stateAnimal.meta.filters)
	},

	[types.ADD_ANIMAL](state, { indexAnimalPagination, animal }) {
		state.animals.data[indexAnimalPagination].data.push(animal)
	},

	[types.UPDATE_ANIMAL](state, { indexAnimalPagination, indexAnimal, animal }) {
		state.animals.data[indexAnimalPagination].data.splice(indexAnimal, 1, animal)
	},

	[types.REMOVE_ANIMAL](state, { indexAnimalPagination, indexAnimal }) {
		state.animals.data[indexAnimalPagination].data.splice(indexAnimal, 1)
	},

	[types.UPDATE_IS_FILTERS_NOT_SAVED](state, value) {
		state.isFiltersNotSaved = value
	},
}

export default { mutations }
