import { MutationTree } from 'vuex'
import { SignatureState } from '@/modules/signature/store/types'
import {
	Mutations,
	mutationTypes as types,
} from '@/modules/signature/store/mutations/mutation-types'

export const mutations: MutationTree<SignatureState> & Mutations = {
	[types.UPDATE_SIGNATURE](state, signature) {
		state.signature = signature
	},

	[types.REMOVE_SIGNATURE](state) {
		state.signature = null
	},
}

export default { mutations }
