const ForbiddenIndex = () => import('./views/index')
const NotFoundOrganizationIndex = () => import('@/modules/notfound/views/NotFoundOrganization.vue')

const routes = [
	{
		name: 'forbidden.index',
		path: '/forbidden-access',
		component: ForbiddenIndex,
		beforeEnter(to, from, next) {
			if (!to.query.redirect) {
				next({ name: 'painel' })
			}

			next()
		},
	},

	{
		name: 'welcomeSystem.index',
		path: '/welcome',
		component: NotFoundOrganizationIndex,
		meta: {
			auth: true,
		},
	},
]

export default routes
