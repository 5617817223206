

























import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonIcon from './Buttons/ButtonIcon.vue'

@Component({
	name: 'CardInformation',
	components: { ButtonIcon },
})
export default class CardInformation extends Vue {
	@Prop({ type: String, default: '' }) readonly text!: string

	close(): void {
		this.$emit('close')
	}
}
