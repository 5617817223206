






















































import { Component, InjectReactive, Mixins, Prop, Vue } from 'vue-property-decorator'
import ExamAttributeCard from './ExamAttributeCard.vue'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import ExamAttributeDetails from './Fields/ExamAttributeDetails.vue'
import {
	IAttribute,
	IAttributeGroup,
	IAttributeType,
	ISelectedAttribute,
	ISelectedAttributeGroup,
	mutationTypes,
} from '@/modules/exams/store/attributes/types'
import { attributesType } from '@/utils/constants/examConstants'
import { Mutation } from 'vuex-class'
import ValidationAttributeTypeMixin from '@/modules/exams/mixins/ValidationAttributeTypeMixin'

@Component({
	name: 'ExamAttributeChildrenGroup',
	components: {
		ExamAttributeCard,
		ButtonIcon,
		ExamAttributeDetails,
	},
})
export default class ExamAttributeChildrenGroup extends Mixins(ValidationAttributeTypeMixin) {
	@InjectReactive('attribute') attribute!: IAttribute<unknown>
	@InjectReactive('attributeGroup') attributeGroup!: IAttributeGroup
	@InjectReactive('simpleAttributeMode') simpleAttributeMode!: number

	@Prop({ type: Boolean, default: false }) detailsAttributeMode!: boolean

	@Mutation(`Exams/${mutationTypes.UPDATE_SELECTED_ATTRIBUTE}`)
	handleUpdateAttributeSelected!: (payload: {
		selectedAttributeGroup: ISelectedAttributeGroup
		selectedAttribute: ISelectedAttribute
	}) => void

	getIcon(): string | undefined {
		if (this.attribute) {
			const types: IAttributeType[] = attributesType

			return types.find((attributeType) => attributeType.type === this.attribute?.type)?.icon
		}
	}

	selectedAttribute() {
		this.handleUpdateAttributeSelected({
			selectedAttribute: {
				id: this.attribute.id,
				position: this.attribute.position,
				type: 'attribute',
			},
			selectedAttributeGroup: {
				id: this.attributeGroup.id,
				position: this.attributeGroup.position,
			},
		})
	}
}
