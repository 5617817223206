import { MutationTree } from 'vuex'
import { SpecieState } from '../types'
import { mutationTypes as types, Mutations } from './mutation-types'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'

export const mutations: MutationTree<SpecieState> & Mutations = {
	[types.SET_MODAL_CREATE_SPECIE](state, modalState) {
		state.openModalCreateSpecie = modalState
	},

	[types.SET_MODAL_UPDATE_SPECIE](state, modalState) {
		state.openModalUpdateSpecie = modalState
	},

	[types.SET_SPECIES](state, species) {
		state.species.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.species.data,
			newArray: species.data,
			keyToCompare: 'id',
		})
		state.species.meta = species.meta
	},

	[types.SET_LOADING_SPECIES](state, loading) {
		state.species.loading = loading
	},

	[types.CREATE_SPECIE](state, { specie }) {
		state.species.data.push(specie)
	},

	[types.UPDATE_SPECIE](state, { index, specie }) {
		state.species.data.splice(index, 1, specie)
	},

	[types.REMOVE_SPECIE](state, { index }) {
		state.species.data.splice(index, 1)
	},

	[types.SET_SPECIE_SELECTED](state, specie) {
		state.specieSelected = specie
	},

	[types.RESET_SPECIE_SELECTED](state) {
		state.specieSelected = undefined
	},
}

export default { mutations }
