import { SignatureState } from '@/modules/signature/store/types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	UPDATE_SIGNATURE = 'UPDATE_SIGNATURE',
	REMOVE_SIGNATURE = 'REMOVE_SIGNATURE',
}

export type Mutations<S = SignatureState> = {
	[mutationTypes.UPDATE_SIGNATURE](state: S, signature: string): void
	[mutationTypes.REMOVE_SIGNATURE](state: S): void
}
