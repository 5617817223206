import { IResponseEquipment } from '@/modules/equipments/store/types'
import { IResponseForm } from '@/modules/forms/store/types'
import { IResponseMaterial } from '@/modules/materiais/service/types'
import { IResponseMethod } from '@/modules/methods/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { MutationTree } from 'vuex'
import { ILabelResponse, IResponseExam } from '../services/types'
import { state as resetAttributeState } from './attributes'
import { state as stateReset } from './index'
import { AllExamState, IExamFilters, IExamModel, mutationTypes as types } from './types'

export const mutations: MutationTree<AllExamState> = {
	[types.SET_EXAM_LOADING](state, loading: boolean) {
		state.examIsLoading = loading
	},
	[types.SET_EXAM_SAVING](state, loading: boolean) {
		state.examIsSaving = loading
	},

	[types.SET_EXAMS](state, exams: ITypeOrmPaginationTemplate<IResponseExam>) {
		state.exams = exams
	},

	[types.SET_FILTERS](state, filters: IExamFilters) {
		state.filters = filters
	},

	[types.UPDATE_TEMPLATE_MODAL_OPEN](state, valueModal: boolean) {
		state.examTemplateSheetModalOptions.isOpen = valueModal
	},

	[types.RESET_MODAL_EXAM](state) {
		state.examLayoutSheetModalOptions = stateReset.examLayoutSheetModalOptions
		state.selectedExam = stateReset.selectedExam
	},

	[types.UPDATE_ASIDE_CONTRACT](state, contractValue: boolean) {
		state.examLayoutSheetModalOptions.asideContract = contractValue
	},

	[types.UPDATE_RIGHT_SIDE_COLLAPSED](state, value: boolean) {
		state.examLayoutSheetModalOptions.rightSideCollapsed = value
	},

	[types.UPDATE_SELECTED_TAB](state, tabValue: number) {
		state.examLayoutSheetModalOptions.selectedTab = tabValue
	},

	[types.ADD_UNIT_MEASUREMENT](state, unitMeasurement: string) {
		state.unitsMeasurement.push(unitMeasurement)
	},
	[types.UPDATE_UNIT_MEASUREMENT](
		state,
		{ index, unitMeasurement }: { index: number; unitMeasurement: string }
	) {
		state.unitsMeasurement.splice(index, 1, unitMeasurement)
	},
	[types.DELETE_UNIT_MEASUREMENT](state, index: number) {
		state.unitsMeasurement.splice(index, 1)
	},

	[types.UPDATE_SELECTED_EXAM](state, exam: IResponseExam) {
		state.selectedExam = exam
		state.selectedAttribute = resetAttributeState.selectedAttribute
		state.selectedAttributeGroup = resetAttributeState.selectedAttributeGroup
	},

	[types.UPDATE_EXAM_STATUS](state, payload: { status: boolean; index: number }) {
		if (!state.selectedExam) return
		state.selectedExam.status = payload.status

		state.exams.data.splice(payload.index, 1, {
			...state.exams.data[payload.index],
			status: payload.status,
		})
	},

	[types.UPDATE_EXAM_FORMS](state, forms: IResponseForm[]) {
		if (!state.selectedExam) return
		state.selectedExam.forms = forms
	},

	[types.UPDATE_EXAM_EQUIPMENTS](state, equipments: IResponseEquipment[]) {
		if (!state.selectedExam) return
		state.selectedExam.equipments = equipments
	},

	[types.UPDATE_EXAM_METHODS](state, methods: IResponseMethod[]) {
		if (!state.selectedExam) return
		state.selectedExam.methods = methods
	},

	[types.UPDATE_EXAM_MATERIALS](state, materials: IResponseMaterial[]) {
		if (!state.selectedExam) return
		state.selectedExam.materials = materials
	},

	[types.DELETE_EXAM](state, index: number) {
		state.exams.data.splice(index, 1)
	},

	[types.SET_EXAM_MODELS](state, examModels: IExamModel[]) {
		state.examModels = examModels
	},

	[types.ADD_EXAM](state, exam: IResponseExam) {
		state.exams.data.push(exam)
	},

	[types.SELECT_TAG](
		state,
		{
			index,
			label,
		}: {
			index: number
			label: ILabelResponse
		}
	) {
		state.exams.data[index].labels.push(label)
	},

	[types.DESELECT_TAG](
		state,
		{
			index,
			indexLabel,
		}: {
			index: number
			indexLabel: number
		}
	) {
		state.exams.data[index].labels.splice(indexLabel, 1)
	},
}

export default { mutations }
