import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import ExamAttributeFactory from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/Attributes/ExamAttributeFactory.vue'
import ReferenceFactory from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/References/ReferenceFactory.vue'

const metaConfig = { config: true, auth: true }

const routes = [
	{
		path: '/settings/exams',
		name: 'config.exames',
		component: () =>
			import(/* webpackChunkName: 'exams' */ '@/modules/exams/views/ExamsIndex.vue'),
		meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Exam' }] },
	},
	{
		path: '/settings/exams/:id',
		component: () =>
			import(
				/* webpackChunkName: 'exams' */ '@/modules/exams/views/ExamLayoutSheetIndex.vue'
			),
		beforeEnter: (to: Route, _: Route, next: NavigationGuardNext) => {
			if (to.params && to.params.id) {
				next()
			} else {
				next({ name: 'config.exames' })
			}
		},
		meta: {
			...metaConfig,
			permissions: [
				{ action: 'read', resource: 'Exam' },
				{ action: 'update', resource: 'Exam' },
			],
		},
		children: [
			{
				path: '',
				name: 'config.visualizeExam',
				components: {
					main: ExamAttributeFactory,
				},
			},
			{
				path: 'references/:referenceId',
				name: 'config.visualizeExamReference',
				components: {
					main: ReferenceFactory,
				},
			},
		],
	},
] as RouteConfig[]

export default routes
