import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { UploadsState } from './types'
import { mutations } from './mutations'

export const state: UploadsState = {
	filesToUpload: [],
	uploadFileRequest: null,
	uploadFileIndex: null,
}

export default {
	namespaced: true,
	state,
	mutations,
} as Module<UploadsState, RootState>
