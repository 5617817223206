var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"disabled":!_vm.isMenu,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":_vm.tooltipTop,"bottom":_vm.tooltipBottom,"disabled":!_vm.tooltipMessage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('v-btn',_vm._g(_vm._b({class:['fs-14', ( _obj = {}, _obj[_vm.contentClass] = _vm.contentClass, _obj )],attrs:{"color":_vm.color,"outlined":_vm.outlined,"disabled":_vm.disabled,"small":_vm.small,"block":_vm.fillWidth,"loading":_vm.loading,"text":_vm.buttonIsText},on:{"click":_vm.click}},'v-btn',Object.assign({}, attrs, attrsMenu),false),Object.assign({}, on, onMenu)),[(_vm.icon)?_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.sizeIcon}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.tooltipMessage)+" ")])]}}])},[(_vm.isMenu)?_vm._t("menu"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }