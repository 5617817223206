import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

export interface ISpecies {
	id: string | null
	nome: string
}

@Component
export default class GetSpeciesToSelectMixin extends Vue {
	@State(({ Especies }) => Especies.especies) species!: ISpecies[]

	get speciesToSelect(): ISpecies[] {
		const species = Array.from(this.species)

		species.unshift({ id: null, nome: 'Todas as especies' })
		return species
	}
}
