/* eslint-disable no-unused-vars */
import { ICreateAgreementCustomerResponse } from '@/modules/agreements/service/agreementCustomers/types'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ICustomer as ICustomerResponse } from '@/modules/clientes/store/types'
import { IAnimalFiltersCustomerId, IAnimalResponse } from '@/modules/animals/store/types'

export enum mutationTypes {
	SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING',
	SET_CUSTOMER_SAVING = 'SET_CUSTOMER_SAVING',

	SET_MODAL_CREATE_CUSTOMER = 'SET_MODAL_CREATE_CUSTOMER',
	SET_MODAL_UPDATE_CUSTOMER = 'SET_MODAL_UPDATE_CUSTOMER',
	SET_MODAL_STAKEHOLDER_SELECTION = 'SET_MODAL_STAKEHOLDER_SELECTION',

	SET_CUSTOMERS = 'SET_CUSTOMERS',
	SET_MERGE_CUSTOMERS = 'SET_MERGE_CUSTOMERS',
	SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER',
	ADD_CUSTOMER = 'ADD_CUSTOMER',
	UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
	UPDATE_CUSTOMER_AGREEMENT = 'UPDATE_CUSTOMER_AGREEMENT',
	DELETE_CUSTOMER_AGREEMENT = 'DELETE_CUSTOMER_AGREEMENT',
	DELETE_CUSTOMER = 'DELETE_CUSTOMER',

	SET_ANIMALS_BY_CUSTOMER = 'SET_ANIMALS_BY_CUSTOMER',
	ADD_ANIMAL_BY_CUSTOMER = 'ADD_ANIMAL_BY_CUSTOMER',
	UPDATE_ANIMAL_BY_CUSTOMER = 'UPDATE_ANIMAL_BY_CUSTOMER',
	REMOVE_ANIMAL_BY_CUSTOMER = 'REMOVE_ANIMAL_BY_CUSTOMER',

	SET_ANIMALS_FILTERS_BY_CUSTOMER = 'SET_ANIMALS_FILTERS_BY_CUSTOMER',

	SET_FILTERS = 'SET_FILTERS',
	RESET_FILTERS = 'RESET_FILTERS',

	SET_RELATIONSHIPS_CUSTOMER = 'SET_RELATIONSHIPS_CUSTOMER',

	SET_ANIMALS_CUSTOMER = 'SET_ANIMALS_CUSTOMER',

	SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE',
	RESET_CUSTOMER_TYPE = 'RESET_CUSTOMER_TYPE',

	SET_ACTORS_SELECTED = 'SET_ACTORS_SELECTED',
	ADD_ACTOR_SELECTED = 'ADD_ACTOR_SELECTED',
	UPDATE_ACTOR_SELECTED = 'UPDATE_ACTOR_SELECTED',
	REMOVE_ACTOR_SELECTED = 'REMOVE_ACTOR_SELECTED',
	RESET_ACTORS_SELECTED = 'RESET_ACTORS_SELECTED',
}

export enum actionTypes {
	GET_CUSTOMER = 'GET_CUSTOMER',
	GET_CUSTOMERS = 'GET_CUSTOMERS',
	CREATE_CUSTOMER = 'CREATE_CUSTOMER',
	UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',

	UPDATE_CUSTOMER_TYPE = 'UPDATE_CUSTOMER_TYPE',
	UPDATE_CUSTOMER_TYPE_MODULES_GLOBAL = 'UPDATE_CUSTOMER_TYPE_MODULES_GLOBAL',

	DELETE_CUSTOMER = 'DELETE_CUSTOMER',
	LINK_CUSTOMER = 'LINK_CUSTOMER',

	GET_CUSTOMERS_BELONGS_TO = 'GET_CUSTOMERS_BELONGS_TO',

	SET_RELATIONSHIPS_CUSTOMER = 'SET_RELATIONSHIPS_CUSTOMER',
	SET_RELATIONSHIPS_MODULES_GLOBAL = 'SET_RELATIONSHIPS_MODULES_GLOBAL',

	SET_ANIMALS_CUSTOMER = 'SET_ANIMALS_CUSTOMER',
	SET_ANIMALS_CUSTOMER_MODULES_GLOBAL = 'SET_ANIMALS_CUSTOMER_MODULES_GLOBAL',

	GET_RELATIONSHIPS_CUSTOMER = 'GET_RELATIONSHIPS_CUSTOMER',
	GET_RELATIONSHIPS_CUSTOMER_MODULES_GLOBAL = 'GET_RELATIONSHIPS_CUSTOMER_MODULES_GLOBAL',

	RESTORE_CUSTOMER = 'RESTORE_CUSTOMER',
}

export interface ICustomerRelationship {
	id: number
	uuid: string
	corporate_name: string
	fantasy_name: string
	avatar: string
	email: string
	agreement_id?: number | null
}

export interface ICustomer {
	id: number
	uuid: string
	name: string
	agreement?: ICreateAgreementCustomerResponse
	relationship?: ICustomerResponse[]
	animals?: IAnimalResponse[]
}

export enum CustomerType {
	TUTOR = 1,
	VETERINARIAN = 2,
	CLINIC = 3,
	UNINFORMED = 7,
}

export interface ICustomerFilters extends IFiltersTemplate {
	deleted?: boolean
	customerType?: CustomerType
	belongsTo?: number
	parentsOf?: number
	search?: string
}

export interface ICustomerFiltersBelongsTo extends ICustomerFilters {
	belongsTo: number
}

export interface CustomersState {
	customers: ITypeOrmPaginationTemplate<ICustomer>
	animalsByCustomer: ITypeOrmPaginationTemplate<IAnimalResponse>
	filtersAnimalByCustomer: IAnimalFiltersCustomerId
	selectedCustomer?: ICustomerResponse
	customerIsLoading: boolean
	customerIsSaving: boolean
	filters: ICustomerFilters
	openModalCreateCustomer: boolean
	openModalUpdateCustomer: boolean
	openModalStakeholderSelection: boolean
	actorsSelected: ICustomerResponse[]
	customerType?: 'PetOwner' | 'Veterinarian' | 'Clinic'
}
