import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { BreedState, IBreedQueryFilters, IBreedResponse } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_BREEDS = 'SET_BREEDS',
	SET_MERGE_BREEDS = 'SET_MERGE_BREEDS',
	SET_LOADING_BREEDS = 'SET_LOADING_BREEDS',

	SET_MODAL_CREATE_BREED = 'SET_MODAL_CREATE_BREED',
	SET_MODAL_UPDATE_BREED = 'SET_MODAL_UPDATE_BREED',

	SET_SPECIE_SELECTED = 'SET_SPECIE_SELECTED',

	CREATE_BREED = 'CREATE_BREED',
	UPDATE_BREED = 'UPDATE_BREED',
	REMOVE_BREED = 'REMOVE_BREED',

	UPDATE_BREED_FILTERS = 'UPDATE_BREED_FILTERS',
	RESET_BREED_FILTERS = 'RESET_BREED_FILTERS',
	RESET_BREEDS = 'RESET_BREEDS',

	SET_BREED_SELECTED = 'SET_BREED_SELECTED',
	RESET_BREED_SELECTED = 'RESET_BREED_SELECTED',
}

export type Mutations<S = BreedState> = {
	[mutationTypes.SET_MODAL_CREATE_BREED](state: S, modalState: boolean): void

	[mutationTypes.SET_MODAL_UPDATE_BREED](state: S, modalState: boolean): void

	[mutationTypes.SET_BREEDS](state: S, breeds: ITypeOrmPaginationTemplate<IBreedResponse>): void

	[mutationTypes.SET_LOADING_BREEDS](state: S, loading: boolean): void

	[mutationTypes.CREATE_BREED](state: S, { breed }: { breed: IBreedResponse }): void

	[mutationTypes.UPDATE_BREED](
		state: S,
		{ index, breed }: { index: number; breed: IBreedResponse }
	): void

	[mutationTypes.REMOVE_BREED](state: S, { index }: { index: number }): void

	[mutationTypes.SET_BREED_SELECTED](state: S, breed: IBreedResponse): void
	[mutationTypes.RESET_BREED_SELECTED](state: S): void

	[mutationTypes.UPDATE_BREED_FILTERS](state: S, filters: IBreedQueryFilters): void

	[mutationTypes.RESET_BREED_FILTERS](state: S): void

	[mutationTypes.RESET_BREEDS](state: S): void
}
