import { CommitOptions, Module } from 'vuex'
import { RootState } from '@/store/types'
import { IGlobalState } from './types'
import { mutations } from './mutations/mutations'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { Store as VuexStore } from 'vuex'

export const GLOBAL_NAMESPACE = 'Globals'

export const state: IGlobalState = {
	openModalConfirmation: {
		currentModuleName: GLOBAL_NAMESPACE,
		value: false,
		message: {
			title: '',
			subtitle: '',
		},
		props: {
			icon: 'mdi-alert',
			iconColor: 'warning',
			buttonCancel: 'Não',
			buttonAction: 'Remover',
			buttonActionColor: 'error',
			showAction: true,
			autoClose: true,
			loading: false,
			customSlotAction: false,
			disabled: undefined,
			width: '500',
		},
		events: {
			handleClickButtonAction: false,
		},
	},
}

export type Store<S = IGlobalState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
}

export const globals: Module<IGlobalState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	mutations: mutations,
}
