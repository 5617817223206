import API from '@/axios'

const EndpointWebhooks = '/webhooks'
const EndpointApplications = '/applications'
const EndpointNatives = '/natives'
const EndpointClickup = '/clickup'
const EndpointTask = '/task'
const EndpointAttachment = '/attachment'

export default {
	async createClickupTask({ payload }) {
		return await API.post(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointClickup +
				EndpointTask,
			payload
		).then((response) => response.data)
	},

	async createClickupTaskAttachment({ id, payload }) {
		return await API.post(
			EndpointWebhooks +
				EndpointApplications +
				EndpointNatives +
				EndpointClickup +
				EndpointAttachment +
				'/' +
				id,
			payload
		).then((response) => response.data)
	},
}
