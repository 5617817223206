import { ICustomer } from '@/modules/clientes/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { AnimalState, IAnimalResponse } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	ADD_ANIMALS_PAGINATION = 'ADD_ANIMALS_PAGINATION',
	UPDATE_ANIMALS_PAGINATION = 'UPDATE_ANIMALS_PAGINATION',
	RESET_ANIMALS_PAGINATION = 'RESET_ANIMALS_PAGINATION',

	SET_PAGINATION_LOADING = 'SET_PAGINATION_LOADING',

	SET_MODAL_CREATE_ANIMAL = 'SET_MODAL_CREATE_ANIMAL',
	SET_MODAL_UPDATE_ANIMAL = 'SET_MODAL_UPDATE_ANIMAL',

	RESET_FILTERS_ANIMAL = 'RESET_FILTERS_ANIMAL',

	SET_ACTORS_SELECTED = 'SET_ACTORS_SELECTED',
	ADD_ACTOR_SELECTED = 'ADD_ACTOR_SELECTED',
	UPDATE_ACTOR_SELECTED = 'UPDATE_ACTOR_SELECTED',
	REMOVE_ACTOR_SELECTED = 'REMOVE_ACTOR_SELECTED',
	RESET_ACTORS_SELECTED = 'RESET_ACTORS_SELECTED',

	SET_MODAL_STAKEHOLDER_SELECTION = 'SET_MODAL_STAKEHOLDER_SELECTION',

	SET_ANIMAL_SELECTED = 'SET_ANIMAL_SELECTED',
	RESET_ANIMAL_SELECTED = 'RESET_ANIMAL_SELECTED',

	ADD_ANIMAL = 'ADD_ANIMAL',
	UPDATE_ANIMAL = 'UPDATE_ANIMAL',
	REMOVE_ANIMAL = 'REMOVE_ANIMAL',

	UPDATE_IS_FILTERS_NOT_SAVED = 'UPDATE_IS_FILTERS_NOT_SAVED',
}

export type Mutations<S = AnimalState> = {
	[mutationTypes.ADD_ANIMALS_PAGINATION](
		state: S,
		animals: ITypeOrmPaginationTemplate<IAnimalResponse>
	): void

	[mutationTypes.UPDATE_ANIMALS_PAGINATION](
		state: S,
		{
			indexAnimalPagination,
			animalTypeOrmPagination,
		}: {
			indexAnimalPagination: number
			animalTypeOrmPagination: ITypeOrmPaginationTemplate<IAnimalResponse>
		}
	): void

	[mutationTypes.RESET_ANIMALS_PAGINATION](state: S): void

	[mutationTypes.SET_PAGINATION_LOADING](state: S, loading: boolean): void

	[mutationTypes.SET_MODAL_CREATE_ANIMAL](state: S, modalState: boolean): void

	[mutationTypes.SET_MODAL_UPDATE_ANIMAL](state: S, modalState: boolean): void

	[mutationTypes.SET_MODAL_STAKEHOLDER_SELECTION](state: S, modalState: boolean): void

	[mutationTypes.SET_ACTORS_SELECTED](state: S, customers: ICustomer[]): void

	[mutationTypes.ADD_ACTOR_SELECTED](state: S, customer: ICustomer): void

	[mutationTypes.UPDATE_ACTOR_SELECTED](
		state: S,
		{ indexActor, customer }: { indexActor: number; customer: ICustomer }
	): void

	[mutationTypes.REMOVE_ACTOR_SELECTED](state: S, { indexActor }: { indexActor: number }): void

	[mutationTypes.RESET_ACTORS_SELECTED](state: S): void

	[mutationTypes.SET_ANIMAL_SELECTED](state: S, animal: IAnimalResponse): void

	[mutationTypes.RESET_ANIMAL_SELECTED](state: S): void

	[mutationTypes.RESET_FILTERS_ANIMAL](state: S): void

	[mutationTypes.ADD_ANIMAL](
		state: S,
		{
			indexAnimalPagination,
			animal,
		}: { indexAnimalPagination: number; animal: IAnimalResponse }
	): void

	[mutationTypes.UPDATE_ANIMAL](
		state: S,
		{
			indexAnimalPagination,
			indexAnimal,
			animal,
		}: { indexAnimalPagination: number; indexAnimal: number; animal: IAnimalResponse }
	): void

	[mutationTypes.REMOVE_ANIMAL](
		state: S,
		{
			indexAnimalPagination,
			indexAnimal,
		}: { indexAnimalPagination: number; indexAnimal: number }
	): void

	[mutationTypes.UPDATE_IS_FILTERS_NOT_SAVED](state: S, value: boolean): void
}
