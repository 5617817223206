import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { CustomersState } from './types'

export const state: CustomersState = {
	customers: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},
	animalsByCustomer: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},
	filtersAnimalByCustomer: {
		page: 1,
		limit: 6,
		customerId: 0,
	},
	selectedCustomer: undefined,
	filters: {
		limit: 10,
		page: 1,
		search: '',
		deleted: false,
		customerType: undefined,
		belongsTo: undefined,
		parentsOf: undefined,
	},
	customerIsLoading: false,
	customerIsSaving: false,
	openModalCreateCustomer: false,
	openModalUpdateCustomer: false,
	openModalStakeholderSelection: false,
	actorsSelected: [],
	customerType: undefined,
}

export const CUSTOMER_NAMESPACE = 'Customers'

export const customers: Module<CustomersState, RootState> = {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
}
