














import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'

@Component({
	name: 'Badge',
	components: {
		ButtonIcon,
	},
})
export default class Badge extends Vue {
	@Prop({ type: Boolean, default: true }) value!: boolean
	@Prop({ type: String, default: undefined }) iconInContentBadge?: string
	@Prop({ type: [String, Number], default: 0 }) content?: string | number
	@Prop({ type: String, default: 'primary' }) color!: string
	@Prop({ type: Number, default: 10 }) offsetX!: number
	@Prop({ type: Number, default: 10 }) offsetY!: number
	@Prop({ type: Boolean, default: true }) bordered!: boolean
}
