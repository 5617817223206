import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { IconView } from '@ckeditor/ckeditor5-ui'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

class SearchPreviousResults extends Plugin {
	init() {
		const editor = this.editor
		const id = editor.config.get('customEditorPlugin.id')

		// The button must be registered among the UI components of the editor
		// to be displayed in the toolbar.
		editor.ui.componentFactory.add('searchPreviousResults', () => {
			// The button will be an instance of ButtonView.
			const button = new ButtonView()

			const iconView = new IconView()

			iconView.content =
				'<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9 6v2H2V6zm0 5v2H2v-2zm9 5v2H2v-2zm1.31-4.5c.44-.68.69-1.5.69-2.39c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5s2 4.5 4.5 4.5c.87 0 1.69-.25 2.38-.68L21 16l1.39-1.39zm-3.81.11c-1.38 0-2.5-1.11-2.5-2.5s1.12-2.5 2.5-2.5a2.5 2.5 0 0 1 0 5"/></svg>'

			button.children.add(iconView)

			button.set({
				label: 'Resultados Anteriores',
				tooltip: true,
			})

			button.on('execute', () => {
				window.dispatchEvent(new CustomEvent(`click:searchPreviousResults:${id}`))
			})

			return button
		})
	}
}

export default SearchPreviousResults
