






































import { Component, Mixins, VModel } from 'vue-property-decorator'
import AttributeReferenceTabButton from './AttributeReferenceTabButton.vue'
import AttributeReferenceBorderValue from './AttributeReferenceBorderValue.vue'
import { Action } from 'vuex-class'
import { actionTypes, IReferenceGroup } from '@/modules/exams/store/references/types'
import AlertConfirmation from '@/components/global/AlertConfirmation.vue'
import { IDeleteReferenceGroupParams } from '@/modules/exams/services/references/types'
import { CreateReferenceGroupMixin } from '@/modules/exams/mixins/CreateReferenceGroupMixin'

@Component({
	name: 'ReferenceTabs',
	components: {
		AlertConfirmation,
		AttributeReferenceBorderValue,
		AttributeReferenceTabButton,
	},
})
export default class ReferenceTabs extends Mixins(CreateReferenceGroupMixin) {
	@VModel({ type: Number, default: 0 }) tabSelected?: number

	openAlertConfirmation = false
	groupToRemove: IReferenceGroup | null = null
	createReferenceGroupLoading = ''
	alertConfirmationText = {
		title: 'Remover grupo',
		subtitle: 'Você tem certeza que deseja remover o grupo selecionado',
	}

	get showNewGroupTab() {
		return (
			this.referenceValues.length < 3 &&
			this.referenceValues.length + this.availableReferenceGroups.length < 3
		)
	}

	@Action(`Exams/${actionTypes.DELETE_REFERENCE_GROUP}`) deleteReferenceGroup!: (
		payload: IDeleteReferenceGroupParams
	) => Promise<void>

	async handleClickCreateReferenceGroup(name: string) {
		this.createReferenceGroupLoading = name
		this.handleCreateReferenceGroup(this.attribute.id, name)
		this.createReferenceGroupLoading = ''
	}

	handleDeleteReferenceGroup() {
		if (this.groupToRemove)
			this.deleteReferenceGroup({
				groupId: this.groupToRemove.id,
				payload: {
					attributeId: this.attribute.id,
					editMode: this.referenceEditMode,
				},
			})
	}

	openAlertRemove(group: IReferenceGroup) {
		this.groupToRemove = group
		this.openAlertConfirmation = true
	}
}
