import API from '@/axios'

const EndpointRequest = '/requisicoes'
const orders = '/orders/'
const shippings = '/shippings'
const zones = '/zones'
const status = '/status'
const attachments = '/attachments'
const volumes = '/volumes'
const relationships = '/relationships'
const kits = '/kits'
const shippingKits = '/shipping-kits'

export default {
	async getShippings(orderId) {
		return await API.get(EndpointRequest + orders + orderId + shippings).then(
			(response) => response.data
		)
	},

	async createShipping(orderID, payload) {
		return await API.post(EndpointRequest + orders + orderID + shippings, payload).then(
			(response) => response.data
		)
	},

	async updateShipping(shippingId, payload) {
		return await API.patch(EndpointRequest + shippings + '/' + shippingId, payload).then(
			(response) => response.data
		)
	},

	async updateShippingStatus(shippingId, payload) {
		return await API.patch(
			EndpointRequest + shippings + '/' + shippingId + status,
			payload
		).then((response) => response.data)
	},

	async removeShipping(shippindId) {
		return await API.delete(EndpointRequest + shippings + '/' + shippindId).then(
			(response) => response.data
		)
	},

	async getZones() {
		return await API.get(EndpointRequest + zones).then((response) => response.data)
	},

	async createZone() {
		return await API.post(EndpointRequest + zones).then((response) => response.data)
	},

	async updateZone(zoneId) {
		return await API.patch(EndpointRequest + zones + '/' + zoneId).then(
			(response) => response.data
		)
	},

	async removeZone(zoneId) {
		return await API.delete(EndpointRequest + zones + '/' + zoneId).then((response) => response)
	},

	async getAllAttachmentsShipping(shipping_id) {
		return await API.get(EndpointRequest + shippings + '/' + shipping_id + attachments).then(
			(response) => response.data
		)
	},

	async createAttachment(payload) {
		return await API.post(EndpointRequest + attachments, payload).then(
			(response) => response.data
		)
	},

	async removeAttachment(uuid) {
		return await API.delete(EndpointRequest + attachments + `/${uuid}`).then(
			(response) => response
		)
	},

	async getVolumes(shipping_id) {
		return await API.get(EndpointRequest + shippings + '/' + shipping_id + volumes).then(
			(response) => response.data
		)
	},

	async createVolumes(shipping_id, payload) {
		return await API.post(
			EndpointRequest + shippings + '/' + shipping_id + volumes,
			payload
		).then((response) => response.data)
	},

	async updateVolume(volume_id, payload) {
		return await API.patch(EndpointRequest + volumes + '/' + volume_id, payload).then(
			(response) => response.data
		)
	},

	async removeVolume(volume_id) {
		return await API.delete(EndpointRequest + volumes + '/' + volume_id).then(
			(response) => response.data
		)
	},

	async createShippingRelationship(payload) {
		return await API.post(EndpointRequest + relationships, payload).then(
			(response) => response.data
		)
	},

	async deleteShippingRelationship(payload) {
		return await API.delete(EndpointRequest + relationships, {
			data: payload,
		}).then((response) => response.data)
	},

	async getKits(shipping_id) {
		return await API.get(EndpointRequest + shippings + '/' + shipping_id + kits).then(
			(response) => response.data
		)
	},

	async createKit(shipping_id, payload) {
		return await API.post(EndpointRequest + shippings + '/' + shipping_id + kits, payload).then(
			(response) => response.data
		)
	},

	async updateKit(shippingKitId, payload) {
		return await API.post(EndpointRequest + shippingKits + '/' + shippingKitId, payload).then(
			(response) => response.data
		)
	},

	async removeKit(shippingKitId, payload) {
		return await API.delete(EndpointRequest + shippingKits + '/' + shippingKitId, payload).then(
			(response) => response.data
		)
	},

	async visualizeShipping(shipping_id) {
		return await API.get(EndpointRequest + shippings + '/' + shipping_id).then(
			(response) => response.data
		)
	},

	async getShippingRelationships(shipping_id) {
		return await API.get(EndpointRequest + shippings + '/' + shipping_id + relationships).then(
			(response) => response.data
		)
	},

	async getShippingStatus() {
		return await API.get(EndpointRequest + shippings + '/status').then((response) => response)
	},
}
