import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import VuexPersistence from 'vuex-persist'

import ApiKey from '../modules/apikey/store'
import Attributes from '../modules/attributes/store'
import Autenticacao from '../modules/autenticacao/store'
import { auth as Auth } from '../modules/authentication/store'
import Cliente from '../modules/clientes/store'
import Preferencia from '../modules/configuracao/preferencias/store'
import Convenio from '../modules/convenios/store'
import Equipments from '../modules/equipments/store'
import Feedback from '../modules/feedback/store'
import Material from '../modules/materiais/store'
import Methods from '../modules/methods/store'
import Organizacao from '../modules/organizacoes/store'
import Recipiente from '../modules/recipientes/store'
import Rotulo from '../modules/rotulos/store'
import Usuarios from '../modules/usuarios/store'
import Webhooks from '../modules/webhooks/store'

// Component Front End Store
import { globals as Globals } from '../components/atomic/store'

import { agreements as Agreements } from '../modules/agreements/store'
import Atendimento from '../modules/atendimento/store'
import { breed as Breeds } from '../modules/breeds/store'
import { customers as Customers } from '../modules/customers/store'
import { examsGroup as ExamsGroup } from '../modules/examGroups/store'
import Exames from '../modules/exames/store'
import { exams as Exams } from '../modules/exams/store'
import InsertResult from '../modules/insertionResult/store'
import InsertionResultExamGroup from '../modules/insertionResultExamGroup/store'
import { order as Orders } from '../modules/orders/store'
import { receiving as Receiving } from '../modules/receiving/store'
import Request from '../modules/requisicoes/store'
import Shippings from '../modules/shippings/store'

import Animais from '../modules/animais/store'
import { animal as Animals } from '../modules/animals/store'
import Categorias from '../modules/categorias/store'
import Especies from '../modules/especies/store'
import Forms from '../modules/forms/store'
import Pelagens from '../modules/pelagens/store'
import Perfil from '../modules/perfis/store'
import Racas from '../modules/racas/store'
import { specie as Species } from '../modules/species/store'

import Errors from '../modules/errors/store'

import Successes from '../modules/successAlert/store'

import Contatos from '../modules/contatos/store'

import QuillEditor from '../modules/quillEditor/store'

import { signature as Signature } from '../modules/signature/store'

import MeasurementUnits from '@/store/global/measurementUnits'
import UserMenu from '@/store/global/userMenu'

import Bills from '../modules/bills/store'
import Financial from '../modules/financial/store'

import SearchExamResults from '../components/global/SearchExamResults/store'
import Uploads from '../components/global/UploadsManagement/store'
import { RootState } from './types'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	key: 'hubvet',
	storage: window.localStorage,
	reducer: (state: RootState) => ({
		Autenticacao: state.Autenticacao,
		Auth: state.Auth,
		Organizacao: state.Organizacao,
		Signature: state.Signature,
		MeasurementUnits: state.MeasurementUnits,
		UserMenu: state.UserMenu,
	}),
})

const store: StoreOptions<RootState> = {
	modules: {
		Agreements,
		Animais,
		Animals,
		ApiKey,
		Attributes,
		Atendimento,
		Auth,
		Autenticacao,
		Bills,
		Breeds,
		Categorias,
		Cliente,
		Convenio,
		Contatos,
		Customers,
		Equipments,
		Errors,
		Especies,
		Exames,
		Exams,
		ExamsGroup,
		Feedback,
		Financial,
		Forms,
		Globals,
		InsertionResultExamGroup,
		InsertResult,
		Material,
		MeasurementUnits,
		Methods,
		Orders,
		Organizacao,
		Perfil,
		Pelagens,
		Preferencia,
		QuillEditor,
		Racas,
		Recipiente,
		Request,
		Receiving,
		Rotulo,
		SearchExamResults,
		Shippings,
		Signature,
		Species,
		Successes,
		Uploads,
		UserMenu,
		Usuarios,
		Webhooks,
	},
	plugins: [vuexLocal.plugin],
}

export default new Vuex.Store<RootState>(store)
