/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_SEARCH_NOTES_ITERATOR_SETTINGS = 'SET_SEARCH_NOTES_ITERATOR_SETTINGS',
	SET_SEARCH_RESULTS_ITERATOR_SETTINGS = 'SET_SEARCH_RESULTS_ITERATOR_SETTINGS',
	SET_SELETED_SEARCH_RESULT_TYPE = 'SET_SELETED_SEARCH_RESULT_TYPE',
	SET_SELETED_RESULT_TEXT = 'SET_SELETED_RESULT_TEXT',
}
export type SearchNotesIteratorSettings = any
export type SearchResultsIteratorSettings = any

export interface SearchExamResultsState {
	selectedResultText: string | null
	selectedSearchResultType: 'annotations' | 'previousResults'
	searchResultsIteratorSettings: SearchResultsIteratorSettings | null
	searchNotesIteratorSettings: SearchNotesIteratorSettings | null
}
