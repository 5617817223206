import { RootState } from '@/store/types'
import { AuthenticationState, ILogInResponse, actionTypes, mutationTypes } from '../types'
import { ActionTree } from 'vuex'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { types as errorTypes } from '@/modules/errors/store/types'
import API from '@/modules/authentication/service'
import isTokenExpired from '@/utils/functions/isTokenExpired'

const INTERVAL_TIMEOUT_MS = 15 * 60 * 1000 // Update every 15 minutes

export const actions: ActionTree<AuthenticationState, RootState> = {
	async [actionTypes.LOG_IN_BY_REFRESH_TOKEN](
		{ commit, dispatch },
		refresh_token: string
	): Promise<ILogInResponse | boolean> {
		try {
			const { status, data: authResponse } = await API.logInByRefreshToken(refresh_token)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_UPDATE_LOGGED_STATE, authResponse)
				commit(mutationTypes.SET_IS_LOGGED_IN, true)

				dispatch(actionTypes.START_TOKEN_UPDATE)
			}

			return status === STATUS_SUCCESS ? authResponse : false
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	[actionTypes.START_TOKEN_UPDATE]({ commit, dispatch }): void {
		dispatch(actionTypes.STOP_TOKEN_UPDATE)

		const timerId = window.setInterval(
			() => dispatch(actionTypes.LOG_IN_BY_REFRESH_TOKEN),
			INTERVAL_TIMEOUT_MS
		)

		commit(mutationTypes.SET_UPDATE_TOKEN_TIMER_ID, timerId)
	},

	[actionTypes.STOP_TOKEN_UPDATE]({ state, commit }): void {
		if (state.tokenUpdateTimerId) {
			clearInterval(state.tokenUpdateTimerId)

			commit(mutationTypes.SET_UPDATE_TOKEN_TIMER_ID, undefined)
		}
	},
}
