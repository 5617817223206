import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types'
import { STATUS_SUCCESS, STATUS_CREATED } from '@/utils/constants/statusConstants'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,

	state: {
		apiKeys: [],
		loading: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.apiKeys.findIndex((key) => key.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.apiKeys.find((key) => key.id === id)
			}
		},

		getUniqueName: (state) => {
			return (name) => {
				return state.apiKeys.find((key) => key.name.toLowerCase() === name.toLowerCase())
			}
		},
	},

	mutations: {
		setApiKeys: (state, payload) => {
			state.apiKeys = payload
		},

		setLoading: (state, boolean) => (state.loading = boolean),

		addApiKey: (state, payload) => {
			state.apiKeys.push(payload)
		},

		removeKeyApi: (state, index) => {
			state.apiKeys.splice(index, 1)
		},
	},

	actions: {
		async getAll({ commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)
				const { status, data: apiKeys } = await API.getApiKeys()

				if (status === STATUS_SUCCESS) {
					commit('setApiKeys', apiKeys)
				}

				commit('setLoading', DISABLE_LOADING)

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async createApiKey({ commit }, payload) {
			try {
				const { status, data: apiKey } = await API.createApiKey(payload)

				if (status === STATUS_CREATED) {
					commit('addApiKey', apiKey)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.apiKey.feedback.successes.create.apikey') },
						{ root: true }
					)
				}

				return {
					success: status === STATUS_CREATED,
					payload: status === STATUS_CREATED ? apiKey : undefined,
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeApiKey({ getters, commit }, id) {
			try {
				const { status } = await API.removeApiKey(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('removeKeyApi', index)

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.apiKey.feedback.successes.remove.apikey') },
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeApiKeys({ getters, commit }, ids) {
			try {
				const { status } = await API.removeApiKeys(ids)

				if (status === STATUS_SUCCESS) {
					for (const id of ids) {
						const index = getters.getIndex(id)

						if (index > -1) {
							commit('removeKeyApi', index)
						}
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.apiKey.feedback.successes.remove.apikey') },
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
