/* [[ ESLINT BUG ENUM NOT USED ]] */
/* eslint-disable no-unused-vars */

import { IModalOptions } from '@/store/global/interface/ModalOptions'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IResponseExam } from '../services/types'
import { AttributeState } from './attributes/types'
import { ReferenceState } from './references/types'

export enum mutationTypes {
	SET_EXAM_LOADING = 'SET_EXAM_LOADING',
	SET_EXAM_SAVING = 'SET_EXAM_SAVING',

	UPDATE_TEMPLATE_MODAL_OPEN = 'UPDATE_TEMPLATE_MODAL_OPEN',
	UPDATE_ASIDE_CONTRACT = 'UPDATE_ASIDE_CONTRACT',
	UPDATE_RIGHT_SIDE_COLLAPSED = 'UPDATE_RIGHT_SIDE_COLLAPSED',
	UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB',

	ADD_UNIT_MEASUREMENT = 'ADD_UNIT_MEASUREMENT',
	UPDATE_UNIT_MEASUREMENT = 'UPDATE_UNIT_MEASUREMENT',
	DELETE_UNIT_MEASUREMENT = 'DELETE_UNIT_MEASUREMENT',

	RESET_MODAL_EXAM = 'RESET_MODAL_EXAM',

	UPDATE_SELECTED_EXAM = 'UPDATE_SELECTED_EXAM',
	SET_EXAMS = 'SET_EXAMS',
	UPDATE_EXAM_STATUS = 'UPDATE_EXAM_STATUS',
	UPDATE_EXAM_FORMS = 'UPDATE_EXAM_FORMS',
	UPDATE_EXAM_EQUIPMENTS = 'UPDATE_EXAM_EQUIPMENTS',
	UPDATE_EXAM_METHODS = 'UPDATE_EXAM_METHODS',
	UPDATE_EXAM_MATERIALS = 'UPDATE_EXAM_MATERIALS',
	DELETE_EXAM = 'DELETE_EXAM',

	SET_FILTERS = 'SET_FILTERS',

	SET_EXAM_MODELS = 'SET_EXAM_MODELS',
	ADD_EXAM = 'ADD_EXAM',

	SELECT_TAG = 'SELECT_TAG',
	DESELECT_TAG = 'DESELECT_TAG',
}

export enum actionTypes {
	GET_EXAM = 'GET_EXAM',
	GET_EXAMS = 'GET_EXAMS',
	CREATE_EXAM = 'CREATE_EXAM',
	CLONE_EXAM = 'CLONE_EXAM',
	UPDATE_EXAM = 'UPDATE_EXAM',
	UPDATE_EXAM_STATUS = 'UPDATE_EXAM_STATUS',
	UPDATE_EXAM_FORMS = 'UPDATE_EXAM_FORMS',
	UPDATE_EXAM_EQUIPMENTS = 'UPDATE_EXAM_EQUIPMENTS',
	UPDATE_EXAM_METHODS = 'UPDATE_EXAM_METHODS',
	UPDATE_EXAM_MATERIALS = 'UPDATE_EXAM_MATERIALS',
	UPDATE_EXAM_TECHNICAL_MANAGER = 'UPDATE_EXAM_TECHNICAL_MANAGER',
	DELETE_EXAM = 'DELETE_EXAM',

	GET_EXAM_MODELS = 'GET_EXAM_MODELS',
	USE_EXAM_MODEL = 'USE_EXAM_MODEL',

	SELECT_TAG = 'SELECT_TAG',
	DESELECT_TAG = 'DESELECT_TAG',
}

export interface IExamLayoutSheetModalOptions {
	asideContract: boolean
	rightSideCollapsed: boolean
	selectedTab: number
	simpleAttributeMode: boolean
}

export interface IReference {
	id: number
	nome: string
	idade_inicial: number
	idade_final: number
	tipo_idade: string
	exame_id: number
	especie_id: number
	organizacao_id: number
	created_at: string
	updated_at: string
}

export type IExamFilters = IFiltersTemplate

export interface IExamModel {
	id: string
	name: string
}

export interface ExamState {
	exams: ITypeOrmPaginationTemplate<IResponseExam>
	examModels: IExamModel[]
	examIsLoading: boolean
	examIsSaving: boolean
	filters: IExamFilters
	selectedExam: IResponseExam
	examLayoutSheetModalOptions: IExamLayoutSheetModalOptions
	examTemplateSheetModalOptions: IModalOptions<unknown>
	unitsMeasurement: string[]
}

export interface AllExamState extends ExamState, ReferenceState, AttributeState {}
