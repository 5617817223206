import { RootState } from '@/store/types'
import { AttributeType } from '@/utils/constants/examConstants'
import { GetterTree } from 'vuex'
import { AttributeState } from './types'

export const getters: GetterTree<AttributeState, RootState> = {
	getIndexAttributeGroup:
		(state) =>
		(id: number): number => {
			return state.attributes.findIndex((attributeGroup) => attributeGroup.id === id)
		},

	getLastAttributeGroupIndex: (state) => {
		return state.attributes.length - 1
	},

	getIndexAttribute:
		(state) =>
		({ id, indexAttributeGroup }: { id: number; indexAttributeGroup: number }) => {
			return state.attributes[indexAttributeGroup].attributes.findIndex(
				(attribute) => attribute.id === id
			)
		},

	getLastAttributeIndex: (state) => (indexAttributeGroup: number) => {
		return state.attributes[indexAttributeGroup].attributes.length - 1
	},

	getIndexFormula:
		(state) =>
		({
			id,
			indexAttributeGroup,
			indexAttribute,
		}: {
			id: number
			indexAttributeGroup: number
			indexAttribute: number
		}) => {
			return state.attributes[indexAttributeGroup].attributes[
				indexAttribute
			].formulas.findIndex((formula) => formula.id === id)
		},

	getNumberTypeAttributesByGroupId: (state) => (groupId: number, exceptId?: number) => {
		const numberTypeAttributes = [
			AttributeType.DECIMAL_TYPE,
			AttributeType.INTEGER_TYPE,
			AttributeType.FORMULA_TYPE,
		]

		return state.attributes
			.map((attributeGroup) => {
				const attributes = attributeGroup.attributes.filter(
					(attribute) =>
						numberTypeAttributes.includes(attribute.type as AttributeType) &&
						attribute.id !== exceptId
				)

				return {
					...attributeGroup,
					attributes,
				}
			})
			.find((attributeGroup) => attributeGroup.id === groupId)?.attributes
	},

	getIndexMappedExamField:
		(state) =>
		(id: string): number => {
			return state.examMappedFields.findIndex((mappedExamField) => mappedExamField.id === id)
		},
}
