import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'
import { actionTypes as actionTypesOfAuth } from '@/modules/authentication/store/types'
import { Route } from 'vue-router'
import { OrganizationResponse } from '@/modules/organizacoes/store/types'

@Component({
	name: 'hasPermissionsMixin',
})
export default class hasPermissionsMixin extends Vue {
	@State(({ Organizacao }) => Organizacao.organizacoes) organizations!: OrganizationResponse[]
	@State(({ Organizacao }) => Organizacao.organizacao) organization!: OrganizationResponse

	@State(({ Auth }) => Auth.isLoggedIn) isLoggedIn!: boolean

	@Mutation('Organizacao/setOrganizacao') setOrganization!: (organization: any) => Promise<any>

	@Action('Organizacao/getAll') loadOrganizations!: () => Promise<any>

	@Action(`Auth/${actionTypesOfAuth.SET_PERMISSIONS_USER}`)
	setPermissionsToUser!: (route: Route) => Promise<boolean | void>

	@Watch('organization', { deep: true })
	async onChangeOrganization(organization: any): Promise<void> {
		if (organization) {
			await this.setOrganization(organization)

			await this.setPermissionsToUser(this.$route)
		}
	}

	async created() {
		if (this.isLoggedIn) {
			await this.setPermissionsToUser(this.$route)
		}
	}
}
