import { ICustomer } from '@/modules/clientes/store/types'
import { OrderState } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_ORDERS_PER_STATUS = 'setOrdersPerStatus',

	REMOVE_ORDER_PER_STATUS = 'removeOrderPerStatus',

	SET_ORDERS = 'setOrders',

	SET_ORDERS_FILTERED = 'setOrdersFiltered',
	SET_FILTER = 'setFilter',

	ADD_ORDERS = 'addOrders',
	UPDATE_ORDERS = 'updateOrders',

	UPDATE_ORDERS_FILTERED = 'updateOrdersFiltered',

	SET_ORDER_SELECTED = 'setSelectedOrder',
	RESET_ORDER_SELECTED = 'resetSelectedOrder',
	ADD_ORDER = 'addOrder',
	REMOVE_ORDER = 'removeOrder',

	RESET_ALL = 'resetAll',

	RESET_ORDERS_FILTERED = 'resetOrdersFiltered',

	SET_ATTACHMENTS = 'setAttachments',
	ADD_ATTACHMENT = 'addAttachment',
	REMOVE_ATTACHMENT = 'removeAttachment',

	SET_STATUS_ORDER = 'setStatusList',

	SET_LOADING_ORDER_SUB_HEADER = 'setLoadingOrderSubHeader',

	SET_MODAL_STAKEHOLDER_SELECTION = 'SET_MODAL_STAKEHOLDER_SELECTION',

	SET_IS_MODE_CREATE_ORDER = 'SET_IS_MODE_CREATE_ORDER',
	SET_IS_MODE_SELECT_CUSTOMER = 'SET_IS_MODE_SELECT_CUSTOMER',

	SET_CUSTOMER_SELECTED = 'SET_CUSTOMER_SELECTED',

	RESET_MODE_CREATE_ORDER = 'RESET_MODE_CREATE_ORDER',
	RESET_MODE_SELECT_CUSTOMER = 'RESET_MODE_SELECT_CUSTOMER',
}

export type Mutations<S = OrderState> = {
	[mutationTypes.SET_ORDERS_PER_STATUS](state: S, data: { payload: any; id: number }): void

	[mutationTypes.REMOVE_ORDER_PER_STATUS](state: S, data: { index: number; status: any }): void

	[mutationTypes.SET_ORDERS](state: S, payload: any): void

	[mutationTypes.SET_ORDERS_FILTERED](state: S, payload: any): void

	[mutationTypes.SET_FILTER](state: S, payload: any): void

	[mutationTypes.ADD_ORDERS](state: S, payload: any): void

	[mutationTypes.UPDATE_ORDERS](state: S, data: { index: number; order: any }): void

	[mutationTypes.UPDATE_ORDERS_FILTERED](state: S, data: { index: number; order: any }): void

	[mutationTypes.SET_ORDER_SELECTED](state: S, payload: any): void

	[mutationTypes.ADD_ORDER](state: S, payload: any): void

	[mutationTypes.RESET_ORDER_SELECTED](state: S): void

	[mutationTypes.RESET_ALL](state: S): void

	[mutationTypes.RESET_ORDERS_FILTERED](state: S): void

	[mutationTypes.SET_ATTACHMENTS](state: S, payload: any): void

	[mutationTypes.ADD_ATTACHMENT](state: S, payload: any): void

	[mutationTypes.REMOVE_ATTACHMENT](state: S, index: number): void

	[mutationTypes.SET_STATUS_ORDER](state: S, payload: any): void

	[mutationTypes.SET_LOADING_ORDER_SUB_HEADER](state: S, loading: boolean): void

	[mutationTypes.REMOVE_ORDER](state: S, data: { index: number }): void

	[mutationTypes.SET_MODAL_STAKEHOLDER_SELECTION](state: S, value: boolean): void

	[mutationTypes.SET_IS_MODE_CREATE_ORDER](state: S, value: boolean): void
	[mutationTypes.SET_IS_MODE_SELECT_CUSTOMER](state: S, value: boolean): void
	[mutationTypes.SET_CUSTOMER_SELECTED](state: S, value: ICustomer): void

	[mutationTypes.RESET_MODE_CREATE_ORDER](state: S): void
	[mutationTypes.RESET_MODE_SELECT_CUSTOMER](state: S): void
}
