import API from '@/axios'
import { AxiosResponse } from 'axios'
import { IExamAgreementResponse } from '../../store/types'
import {
	IAgreementExamResponse,
	ICreateAgreementExamParams,
	IGetAgreementExamsParams,
	IUpdateAgreementExamParams,
} from './types'

const AgreementEndpoint = '/models/agreements'
const AgreementExamsEndpoint = '/models/agreement-exams'

export default {
	async getAgreementExams({
		agreementId,
		query,
	}: IGetAgreementExamsParams): Promise<AxiosResponse<IExamAgreementResponse[]>> {
		return await API.get(`${AgreementEndpoint}/${agreementId}/exams`, {
			params: query,
		}).then((response) => response)
	},

	async createAgreementExam({
		agreementId,
		payload,
	}: ICreateAgreementExamParams): Promise<AxiosResponse<IAgreementExamResponse>> {
		return await API.post(`${AgreementEndpoint}/${agreementId}/exams`, payload)
	},

	async updateAgreementExam({
		payload,
		agreementExamId,
	}: IUpdateAgreementExamParams): Promise<AxiosResponse<IAgreementExamResponse>> {
		return await API.put(`${AgreementExamsEndpoint}/${agreementExamId}`, payload)
	},

	async removeAgreementExam(
		agreementExamId: number
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.delete(`${AgreementExamsEndpoint}/${agreementExamId}`)
	},

	async removeCustomerAgreement({
		customerId,
		agreementId,
	}: {
		customerId: number
		agreementId: number
	}): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.delete(`${AgreementExamsEndpoint}/${agreementId}/customer/${customerId}`)
	},
}
