<template>
	<v-dialog
		:value="open"
		width="500"
		content-class="alert_confirmation"
		persistent
		@keydown.esc="closeDialog"
	>
		<v-card>
			<v-card-text class="py-2 text-h5 secondary--text text--darken-2 d-flex justify-center">
				<v-icon size="90" :color="iconColor"> {{ icon }} </v-icon>
			</v-card-text>

			<v-card-title
				class="pt-0 mt-0 text-h6 titledialog--text d-flex justify-center font-weight-bold"
				style="opacity: 0.8 !important"
			>
				{{ item.title }}
			</v-card-title>

			<v-card-text>
				<p
					class="text-center titledialog--text"
					style="font-size: 16px; opacity: 0.6 !important"
				>
					{{ item.subtitle }}
				</p>
			</v-card-text>

			<v-card-actions class="mt-2">
				<slot name="checkbox-action"></slot>

				<v-spacer></v-spacer>

				<slot v-if="customSlotAction" name="button-action" />

				<v-btn
					v-if="!customSlotAction"
					color="secondary"
					:class="showAction ? 'cancelAction' : ''"
					text
					@click="closeDialog"
				>
					<span class="font-weight-regular" style="font-size: 14px">{{
						buttonCancel === 'Não' ? $t('buttons.cancel') : buttonCancel
					}}</span>
				</v-btn>

				<v-btn
					v-if="showAction && !customSlotAction"
					:loading="loading"
					:disabled="loading"
					:color="buttonActionColor"
					text
					@click="remove"
				>
					<span style="font-size: 14px">{{ buttonAction }}</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'AlertConfirmation',

	props: {
		open: {
			type: Boolean,
			default: false,
		},

		item: {
			type: Object,
			required: true,
		},

		buttonCancel: {
			type: String,
			default: 'Não',
		},

		buttonAction: {
			type: String,
			default: 'Remover',
		},

		showAction: {
			type: Boolean,
			default: true,
		},

		autoClose: {
			type: Boolean,
			default: true,
		},

		loading: {
			type: Boolean,
			default: false,
		},

		icon: {
			type: String,
			default: 'mdi-alert',
		},

		iconColor: {
			type: String,
			default: 'warning',
		},

		buttonActionColor: {
			type: String,
			default: 'error',
		},

		customSlotAction: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		closeDialog() {
			this.autoClose ? this.$emit('close') : this.$emit('cancel')
		},

		remove() {
			this.$emit('remove', true)

			if (this.autoClose) {
				this.closeDialog()
			}
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep .alert_confirmation {
	padding: 0 !important;
}

.v-btn.cancelAction:hover {
	color: var(--v-error-base) !important;
}
</style>
