import API from '@/axios'

const EndpointRequest = '/requisicoes'
const EndpointResults = '/results'
const EndpointMicroorganisms = '/microorganisms'
const EndpointDrugs = '/drugs'

export default {
	async getMicroorganisms() {
		return await API.get(EndpointRequest + EndpointResults + EndpointMicroorganisms).then(
			(response) => response.data
		)
	},

	async getDrugs() {
		return await API.get(EndpointRequest + EndpointResults + EndpointDrugs).then(
			(response) => response.data
		)
	},
}
