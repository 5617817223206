import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { Getters, getterTypes as types } from './getter-types'
import { ICustomerFilters } from '@/modules/customers/store/types'
import { OrderState } from '../../types'
import { ICustomer } from '@/modules/clientes/store/types'

export const getters: GetterTree<OrderState, RootState> & Getters = {
	[types.GET_FORMATTED_FILTERS_WITHOUT_VOIDS]: (state) => {
		const filteredData: Partial<ICustomerFilters> = Object.entries(
			state.customerFilters
		).reduce((acc, [key, value]) => {
			if (value !== undefined && value !== null && value !== '') {
				// eslint-disable-next-line no-param-reassign
				acc = { ...acc, [key]: value }
			}

			return acc
		}, {})

		return filteredData
	},

	[types.GET_CUSTOMER_INDEX_BY_UUID]:
		(state) =>
		(uuid: string): number => {
			return state.customers.data.findIndex((customer) => customer.uuid === uuid)
		},

	[types.GET_CUSTOMER_BY_UUID]:
		(state) =>
		(uuid: string): ICustomer | undefined => {
			return state.customers.data.find((customer) => customer.uuid === uuid)
		},
}

export default getters
