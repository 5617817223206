import API from '../service'
import i18n from '@/plugins/vue-i18n'
import { types as errorTypes } from '@/modules/errors/store/types.ts'

export default {
	namespaced: true,

	state: {
		forms: {
			isLoading: false,
			data: [],
		},
		formDetail: {
			isLoading: false,
			data: {
				// id: 1,
				// nome: "Histopatológico",
				// descricao: "Formulário para exames Histopatológicos",
				// favorito: true,
				// ativo: false,
				// organizacao_id: 1,
				// criado_por_usuario_id: 1,
				// created_at: "2022-03-15T11:42:57.000000Z",
				// updated_at: "2022-03-15T11:42:57.000000Z",
				// deleted_at: null,
				// secoes: [
				// 	{
				// 		titulo: "Formulário Histopatológico",
				// 		descricao: "Responda todas as perguntas obrigatórias deste formulário.",
				// 		formulario_id: 1,
				// 		updated_at: "2022-03-14T14:02:16.000000Z",
				// 		created_at: "2022-03-14T14:02:16.000000Z",
				// 		id: 1,
				// 		componentes: [
				// 			{
				// 				tipo: "paragrafo",
				// 				propriedades: {
				// 					titulo: "Responda atentamente todas as perguntas desse formulário!",
				// 					descricao: "Lembre-se de que a vida de seu animalzinho depende das respostas fornecidas ao nosso laboratório, uma vez que exames incorretos geram diagnósticos incorretos!"
				// 				},
				// 				secao_formulario_id: 1,
				// 				updated_at: "2022-03-15T14:50:35.000000Z",
				// 				created_at: "2022-03-15T14:50:35.000000Z",
				// 				id: 12
				// 			}
				// 		]
				// 	},
				// 	{
				// 		titulo: "Formulário Histopatológico",
				// 		descricao: "Responda todas as perguntas obrigatórias deste formulário.",
				// 		formulario_id: 1,
				// 		updated_at: "2022-03-14T14:02:16.000000Z",
				// 		created_at: "2022-03-14T14:02:16.000000Z",
				// 		id: 2,
				// 		componentes: [
				// 			{
				// 				tipo: "pergunta",
				// 				posicao: 2,
				// 				propriedades: {
				// 					tipo: "checkbox",
				// 					obrigatorio: false,
				// 					titulo: "Assinale os sintomas abaixo:",
				// 					opcoes: [
				// 						{
				// 							valor: "Febre"
				// 						},
				// 						{
				// 							valor: "Cansaço"
				// 						},
				// 						{
				// 							valor: "Perda de Apetite"
				// 						},
				// 						{
				// 							valor: "Insônia"
				// 						}
				// 					]
				// 				},
				// 				secao_formulario_id: 1,
				// 				updated_at: "2022-03-15T14:28:32.000000Z",
				// 				created_at: "2022-03-15T14:28:32.000000Z",
				// 				id: 11
				// 			}
				// 		]
				// 	}
				// ]
			},
		},
	},

	getters: {
		getFormIndexById: (state) => {
			return (id) => {
				return state.forms.data.findIndex((form) => form.id === id)
			}
		},
		getFormSectionIndexById: (state) => {
			return (id) => {
				return state.formDetail.data.secoes.findIndex((section) => section.id === id)
			}
		},
		getFormComponentIndexById: (state) => {
			return (id, sectionIndex) => {
				return state.formDetail.data.secoes[sectionIndex].componentes.findIndex(
					(component) => component.id === id
				)
			}
		},
		getFormTagIndexById: (state) => {
			return ({ indexForm, idTag }) => {
				return state.forms.data[indexForm].rotulos.findIndex((tag) => tag.id === idTag)
			}
		},
		getParsedForms: (state) => {
			if (state.forms.data.length) {
				const data = []

				state.forms.data.forEach((form) => {
					data.push({
						id: form['id'],
						status: form['ativo'],
						name: form['nome'],
						description: form['descricao'],
						organizationId: form['organizacao_id'],
						rotulos: form['rotulos'],
						isFavorite: form['favorito'],
						createdByUser: {
							id: form.criador?.id || '',
							uuid: form.criador?.uuid || '',
							name: form.criador?.nome || '',
							avatar: form.criador?.avatar || '',
						},
						createdAt: form['created_at'],
						updatedAt: form['updated_at'],
						deletedAt: form['deleted_at'],
					})
				})
				return data
			}

			return []
		},
		getParsedFormDetail: (state) => {
			if (state.formDetail.data) {
				const { secoes: sections } = state.formDetail.data
				const parsedSections = []

				if (sections?.length > 0) {
					sections.forEach((section) => {
						const parsedComponents = []

						if (Reflect.has(section, 'componentes')) {
							const { componentes: components } = section

							components.forEach((component) => {
								const { propriedades: properties } = component
								const { opcoes: options } = properties
								const parsedOptions = []

								if (options?.length > 0) {
									options.forEach((option) => {
										parsedOptions.push({
											value: option['valor'],
										})
									})
								}

								parsedComponents.push({
									type: component['tipo'],
									position: component['posicao'],
									properties: {
										type: properties['tipo'],
										title: properties['titulo'],
										description: properties['descricao'],
										isMandatory: properties['obrigatorio'] || false,
										image: properties['imagem'] || '',
										options: parsedOptions,
									},
									formSectionId: component['secao_formulario_id'],
									updateAt: component['updated_at'],
									createdAt: component['created_at'],
									id: component['id'],
								})
							})
						}

						parsedSections.push({
							title: section['titulo'],
							description: section['descricao'],
							formId: section['formulario_id'],
							updatedAt: section['updated_at'],
							createdAt: section['created_at'],
							id: section['id'],
							components: parsedComponents,
						})
					})
				}

				return {
					id: state.formDetail.data['id'],
					name: state.formDetail.data['nome'],
					description: state.formDetail.data['descricao'],
					isFavorite: state.formDetail.data['favorito'],
					status: state.formDetail.data['ativo'],
					organizationId: state.formDetail.data['organizacao_id'],
					createdByUserId: state.formDetail.data['criado_por_usuario_id'],
					createdAt: state.formDetail.data['created_at'],
					updatedAt: state.formDetail.data['updated_at'],
					deletedAt: state.formDetail.data['deleted_at'],
					sections: parsedSections,
				}
			}

			return {}
		},
	},

	mutations: {
		createFormRequest(state) {
			state.formDetail.isLoading = true
			state.forms.isLoading = true
		},
		createForm: (state, payload) => {
			state.formDetail.isLoading = false
			state.formDetail.data = payload
			state.forms.isLoading = false
			state.forms.data.push(payload)
		},
		createFormError(state) {
			state.formDetail.isLoading = false
			state.forms.isLoading = false
		},
		getFormRequest: (state) => {
			state.formDetail.isLoading = true
		},
		setForm: (state, payload) => {
			state.formDetail.isLoading = false
			state.formDetail.data = payload
		},
		getFormError: (state) => {
			state.formDetail.isLoading = false
		},
		getFormsRequest: (state) => {
			state.forms.isLoading = true
		},
		setForms: (state, payload) => {
			state.forms.isLoading = false
			state.forms.data = payload
		},
		getFormsError: (state) => {
			state.forms.isLoading = false
		},
		removeFormRequest: (state) => {
			state.forms.isLoading = true
			state.formDetail.isLoading = true
		},
		removeForm: (state, formIndex) => {
			state.forms.isLoading = false
			state.formDetail.isLoading = false
			state.forms.data.splice(formIndex, 1)
			state.formDetail.data = {}
		},
		removeFormError: (state) => {
			state.forms.isLoading = false
			state.formDetail.isLoading = false
		},
		updateFormRequest: (state) => {
			state.forms.isLoading = true
		},
		updateForm: (state, { formIndex, payload }) => {
			state.forms.isLoading = false
			state.forms.data.splice(formIndex, 1, payload)

			if (state.formDetail.data.id === payload.id) {
				state.formDetail.data = payload
			}
		},
		updateFormError: (state) => {
			state.forms.isLoading = false
		},
		createFormSectionRequest: (state) => {
			state.formDetail.isLoading = true
		},
		createFormSection: (state, payload) => {
			state.formDetail.isLoading = false
			const componentes = []
			const payloadWithEmptyComponents = { componentes, ...payload }

			state.formDetail.data.secoes.push(payloadWithEmptyComponents)
		},
		createFormSectionError: (state) => {
			state.formDetail.isLoading = false
		},
		removeFormSectionRequest: (state) => {
			state.formDetail.isLoading = true
		},
		removeFormSection: (state, sectionIndex) => {
			state.formDetail.data.secoes.splice(sectionIndex, 1)
			state.formDetail.isLoading = false
		},
		removeFormSectionError: (state) => {
			state.formDetail.isLoading = false
		},
		updateFormSectionRequest: (state) => {
			state.formDetail.isLoading = true
		},
		updateFormSection: (state, { sectionIndex, payload }) => {
			const componentes = state.formDetail.data.secoes[sectionIndex].componentes
			const newPayload = { componentes, ...payload }

			state.formDetail.data.secoes.splice(sectionIndex, 1, newPayload)
			state.formDetail.isLoading = false
		},
		updateFormSectionError: (state) => {
			state.formDetail.isLoading = false
		},
		createFormComponentRequest: (state) => {
			state.formDetail.isLoading = true
		},
		createFormComponent: (state, { sectionIndex, payload }) => {
			state.formDetail.data.secoes[sectionIndex].componentes.push(payload)
			state.formDetail.isLoading = false
		},
		createFormComponentError: (state) => {
			state.formDetail.isLoading = false
		},
		removeFormComponentRequest: (state) => {
			state.formDetail.isLoading = true
		},
		removeFormComponent: (state, { sectionIndex, componentIndex }) => {
			state.formDetail.data.secoes[sectionIndex].componentes.splice(componentIndex, 1)
			state.formDetail.isLoading = false
		},
		removeFormComponentError: (state) => {
			state.formDetail.isLoading = false
		},
		updateFormComponentRequest: (state) => {
			state.formDetail.isLoading = true
		},
		updateFormComponent: (state, { sectionIndex, componentIndex, payload }) => {
			state.formDetail.data.secoes[sectionIndex].componentes.splice(
				componentIndex,
				1,
				payload
			)
			state.formDetail.isLoading = false
		},
		updateFormComponentError: (state) => {
			state.formDetail.isLoading = false
		},
		updateFormTagRequest: (state) => {
			state.forms.isLoading = true
		},
		updateFormTag: (state, { formIndex, payload }) => {
			state.forms.data[formIndex].rotulos = payload
			state.forms.isLoading = false
		},
		updateFormTagError: (state) => {
			state.forms.isLoading = false
		},
		removeFormTagRequest: (state) => {
			state.forms.isLoading = true
		},
		removeFormTag: (state, { formIndex, tagIndex }) => {
			state.forms.data[formIndex].rotulos.splice(tagIndex, 1)
			state.forms.isLoading = false
		},
		removeFormTagError: (state) => {
			state.forms.isLoading = false
		},

		addFormTag: (state, { indexForm, tag }) => {
			state.forms.data[indexForm].rotulos.push(tag)
		},

		deselectFormTag: (state, { indexForm, indexTag }) => {
			state.forms.data[indexForm].rotulos.splice(indexTag, 1)
		},

		deselectAllFormTag: (state, idTag) => {
			state.forms.data.forEach((form) => {
				const index = form.rotulos.findIndex((tag) => tag.id === idTag)

				if (index > -1) {
					form.rotulos.splice(index, 1)
				}
			})
		},

		updateAllFormTag: (state, { tag }) => {
			state.forms.data.forEach((form) => {
				const indexTag = form.rotulos.findIndex((tagOld) => tagOld.id === tag.id)

				if (indexTag > -1) {
					form.rotulos.splice(indexTag, 1, tag)
				}
			})
		},
	},

	actions: {
		async getAllForms({ commit }) {
			try {
				commit('getFormsRequest')
				const forms = await API.getAllForms()

				commit('setForms', forms)
			} catch (error) {
				commit('getFormsError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		async getFormDetails({ commit }, id) {
			try {
				commit('getFormRequest')
				const form = await API.getForm(id)

				commit('setForm', form)
			} catch (error) {
				commit('getFormError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		createForm({ commit }, { name, description }) {
			return new Promise((resolve) => {
				commit('createFormRequest')

				API.createForm({
					nome: name,
					descricao: description,
					favorito: false,
					ativo: true,
				})
					.then(async (data) => {
						const newForm = { ...data }
						const formId = newForm.id

						const newSection = await API.createFormSection({
							formId,
							payload: {
								titulo: name,
								descricao: description,
							},
						})

						const componentData = new FormData()

						componentData.append('tipo', 'pergunta')
						componentData.append('posicao', '1')
						componentData.append('propriedades[tipo]', 'radio')
						componentData.append('propriedades[obrigatorio]', '0')
						componentData.append(
							'propriedades[titulo]',
							i18n.t('modules.forms.question').toString()
						)
						componentData.append(
							'propriedades[opcoes][0][valor]',
							i18n.tc('modules.forms.option', [1]).toString()
						)

						const newComponent = await API.createFormComponent({
							formId,
							sectionId: newSection.id,
							payload: componentData,
						})

						newSection.componentes = [newComponent]
						newForm.secoes = [newSection]

						commit('createForm', newForm)
						resolve(true)
					})
					.catch((error) => {
						commit('createFormError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
						resolve(false)
					})
			})
		},
		async removeForm({ commit, getters }, id) {
			try {
				commit('removeFormRequest')

				await API.removeForm(id)
				const formIndex = getters.getFormIndexById(id)

				commit('removeForm', formIndex)
			} catch (error) {
				commit('removeFormError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		async updateForm({ commit, getters }, { id, payload }) {
			try {
				commit('updateFormRequest')

				const keys = {
					status: 'ativo',
					isFavorite: 'favorito',
					name: 'nome',
					description: 'descricao',
				}

				const newPayload = {}

				Object.keys(payload).forEach((key) => {
					Reflect.set(newPayload, keys[key], payload[key])
				})

				const updatedForm = await API.updateForm({ id, payload: newPayload })
				const formIndex = getters.getFormIndexById(id)

				commit('updateForm', { formIndex, payload: updatedForm })
			} catch (error) {
				commit('updateFormError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		createFormSection({ commit }, formId) {
			return new Promise((resolve) => {
				commit('createFormSectionRequest')

				API.createFormSection({
					formId,
					payload: {
						titulo: i18n.t('modules.forms.untitledSection'),
						descricao: i18n.t('modules.forms.sectionWithoutDescription'),
					},
				})
					.then((newSection) => {
						commit('createFormSection', newSection)

						resolve(`${newSection.id}`)
					})
					.catch((error) => {
						commit('createFormSectionError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
						resolve()
					})
			})
		},
		async updateFormSection({ commit, getters }, { formId, sectionId, payload }) {
			try {
				commit('updateFormSectionRequest')

				const data = {}

				if (payload.title) {
					data.titulo = payload.title
				}

				if (payload.description) {
					data.descricao = payload.description
				}

				const newSection = await API.updateFormSection({
					formId,
					sectionId,
					payload: data,
				})

				const sectionIndex = getters.getFormSectionIndexById(sectionId)

				commit('updateFormSection', { sectionIndex, payload: newSection })
			} catch (error) {
				commit('updateFormSectionError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		async removeFormSection({ commit, getters }, { formId, sectionId }) {
			return new Promise((resolve) => {
				commit('removeFormSectionRequest')

				API.removeFormSection({ formId, sectionId })
					.then(() => {
						const sectionIndex = getters.getFormSectionIndexById(sectionId)

						commit('removeFormSection', sectionIndex)

						const previousSectionId =
							getters.getParsedFormDetail.sections[sectionIndex - 1].id

						resolve(`${previousSectionId}`)
					})
					.catch((error) => {
						commit('removeFormSectionError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
					})
			})
		},
		createFormComponent({ commit, getters }, { formId, sectionId }) {
			return new Promise((resolve) => {
				commit('createFormComponentRequest')

				const componentData = new FormData()

				componentData.append('tipo', 'pergunta')
				componentData.append('posicao', '1')
				componentData.append('propriedades[tipo]', 'radio')
				componentData.append('propriedades[obrigatorio]', '0')
				componentData.append(
					'propriedades[titulo]',
					i18n.t('modules.forms.question').toString()
				)
				componentData.append(
					'propriedades[opcoes][0][valor]',
					i18n.tc('modules.forms.option', [1]).toString()
				)

				API.createFormComponent({
					formId,
					sectionId,
					payload: componentData,
				})
					.then((newComponent) => {
						const sectionIndex = getters.getFormSectionIndexById(sectionId)

						commit('createFormComponent', { sectionIndex, payload: newComponent })
						resolve(`${sectionId}${newComponent.id}`)
					})
					.catch((error) => {
						commit('createFormComponentError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
						resolve()
					})
			})
		},
		createFormInfo({ commit, getters }, { formId, sectionId }) {
			return new Promise((resolve) => {
				commit('createFormComponentRequest')

				const componentData = new FormData()

				componentData.append('tipo', 'paragrafo')
				componentData.append('posicao', '1')
				componentData.append(
					'propriedades[titulo]',
					i18n.t('modules.forms.untitled').toString()
				)
				componentData.append(
					'propriedades[descricao]',
					i18n.t('modules.forms.withoutDescription').toString()
				)

				API.createFormComponent({
					formId,
					sectionId,
					payload: componentData,
				})
					.then((newComponent) => {
						const sectionIndex = getters.getFormSectionIndexById(sectionId)

						commit('createFormComponent', { sectionIndex, payload: newComponent })
						resolve(`${sectionId}${newComponent.id}`)
					})
					.catch((error) => {
						commit('createFormComponentError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
						resolve()
					})
			})
		},
		createFormComponentCopy({ commit, getters }, { formId, sectionId, payload }) {
			return new Promise((resolve) => {
				commit('createFormComponentRequest')

				const componentData = new FormData()

				const componentsTypeWithOptionsField = ['checkbox', 'radio']

				componentData.append(
					'propriedades[obrigatorio]',
					String(Number(payload.isMandatory))
				)
				componentData.append('posicao', payload.position)
				componentData.append('propriedades[titulo]', payload.title)

				if (payload.description) {
					componentData.append('propriedades[descricao]', payload.description)
				}

				if (payload.type === 'paragrafo') {
					componentData.append('tipo', 'paragrafo')
				} else {
					componentData.append('tipo', 'pergunta')
					componentData.append('propriedades[tipo]', payload.type)
				}

				if (componentsTypeWithOptionsField.includes(payload.type)) {
					if (payload.options.length > 0) {
						payload.options.forEach((option, index) => {
							componentData.append(
								`propriedades[opcoes][${index}][valor]`,
								option.value
							)
						})
					} else {
						componentData.append(
							'propriedades[opcoes][0][valor]',
							i18n.tc('modules.forms.option', [1])
						)
					}
				}

				if (payload.image) {
					componentData.append('propriedades[imagem]', payload.image)
				}

				API.createFormComponent({
					formId,
					sectionId,
					payload: componentData,
				})
					.then((newComponent) => {
						const sectionIndex = getters.getFormSectionIndexById(sectionId)

						commit('createFormComponent', { sectionIndex, payload: newComponent })
						resolve(`${sectionId}${newComponent.id}`)
					})
					.catch((error) => {
						commit('createFormComponentError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
						resolve()
					})
			})
		},
		async updateFormComponent(
			{ commit, getters },
			{ formId, sectionId, componentId, payload }
		) {
			try {
				commit('updateFormComponentRequest')

				const componentData = new FormData()

				const componentsTypeWithOptionsField = ['checkbox', 'radio']

				componentData.append(
					'propriedades[obrigatorio]',
					String(Number(payload.isMandatory))
				)
				componentData.append('posicao', payload.position)
				componentData.append('propriedades[titulo]', payload.title)

				if (payload.description) {
					componentData.append('propriedades[descricao]', payload.description)
				}

				if (payload.type === 'paragrafo') {
					componentData.append('tipo', 'paragrafo')
				} else {
					componentData.append('tipo', 'pergunta')
					componentData.append('propriedades[tipo]', payload.type)
				}

				if (componentsTypeWithOptionsField.includes(payload.type)) {
					if (payload.options.length > 0) {
						payload.options.forEach((option, index) => {
							componentData.append(
								`propriedades[opcoes][${index}][valor]`,
								option.value
							)
						})
					} else {
						componentData.append(
							'propriedades[opcoes][0][valor]',
							i18n.tc('modules.forms.option', [1])
						)
					}
				}

				if (payload.image) {
					componentData.append('propriedades[imagem]', payload.image)
				}

				const newComponent = await API.updateFormComponent({
					formId,
					sectionId,
					componentId,
					payload: componentData,
				})

				const sectionIndex = getters.getFormSectionIndexById(sectionId)
				const componentIndex = getters.getFormComponentIndexById(componentId, sectionIndex)

				commit('updateFormComponent', {
					sectionIndex,
					componentIndex,
					payload: newComponent,
				})
			} catch (error) {
				commit('updateFormComponentError')
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
		removeFormComponent({ commit, getters }, { formId, sectionId, componentId }) {
			return new Promise((resolve) => {
				commit('removeFormComponentRequest')

				API.removeFormComponent({ formId, sectionId, componentId })
					.then(() => {
						const sectionIndex = getters.getFormSectionIndexById(sectionId)
						const componentIndex = getters.getFormComponentIndexById(
							componentId,
							sectionIndex
						)

						commit('removeFormComponent', { sectionIndex, componentIndex })

						const previousComponentId =
							getters.getParsedFormDetail.sections[sectionIndex].components[
								componentIndex - 1
							].id

						resolve(`${sectionId}${previousComponentId}`)
					})
					.catch((error) => {
						commit('removeFormComponentError')
						commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
					})
			})
		},

		async selectTag({ getters, commit }, { tag, form }) {
			try {
				const response = await API.selectTag(tag.id, form.id)

				if (response) {
					const indexForm = getters.getFormIndexById(form.id)

					if (indexForm > -1) {
						commit('addFormTag', { indexForm: indexForm, tag })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deselectTag({ getters, commit }, { tag, form }) {
			try {
				const response = await API.deselectTag(tag.id, form.id)

				if (response) {
					const indexForm = getters.getFormIndexById(form.id)

					if (indexForm > -1) {
						const indexTag = getters.getFormTagIndexById({ indexForm, idTag: tag.id })

						if (indexTag > -1) {
							commit('deselectFormTag', { indexForm, indexTag })
						}
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
