import { render, staticRenderFns } from "./ReferenceFactory.vue?vue&type=template&id=0c8bdd19&scoped=true&"
import script from "./ReferenceFactory.vue?vue&type=script&lang=ts&"
export * from "./ReferenceFactory.vue?vue&type=script&lang=ts&"
import style0 from "./ReferenceFactory.vue?vue&type=style&index=0&id=0c8bdd19&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8bdd19",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
