import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		loading: false,
		load: false,
		coats: [],
		selected: {},
		registers: [],
		currentPage: 1,
		openModalCreateCoat: false,
		openModalUpdateCoat: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.coats.findIndex((coat) => coat.id === id)
			}
		},
	},

	mutations: {
		setPelagens: (state, payload) => {
			state.coats = payload
			state.load = true
		},

		setSelectedCoat: (state, coat) => (state.selected = coat),

		resetSelectedCoat: (state) => (state.selected = {}),

		addCoat: (state, payload) => state.coats.push(payload),

		updateCoat: (state, { index, coat }) => state.coats.splice(index, 1, coat),

		deleteCoat: (state, index) => state.coats.splice(index, 1),

		updatePage: (state, page) => (state.currentPage = page),

		updateRegisters: (state, registers) => (state.registers = registers),

		resetPage: (state) => (state.currentPage = 1),

		resetRegisters: (state) => (state.registers = []),

		setLoading: (state, stateModal) => (state.loading = stateModal),

		setOpenModalCreateCoat: (state, boolean) => (state.openModalCreateCoat = boolean),
		setOpenModalUpdateCoat: (state, boolean) => (state.openModalUpdateCoat = boolean),
	},
	actions: {
		async getAll({ commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const response = await API.getPelagem()

				commit('setPelagens', response)

				commit('setLoading', DISABLE_LOADING)

				return response
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createCoat({ commit }, payload) {
			try {
				const coat = await API.createCoat(payload)

				if (coat) {
					commit('addCoat', coat)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.coats.feedback.success.create.coat'),
						},
						{ root: true }
					)
				}

				return coat
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateCoat({ commit, getters }, payload) {
			try {
				const coat = await API.updateCoat(payload.id, payload)

				if (coat) {
					const index = getters.getIndex(payload.id)

					commit('updateCoat', { index, coat })

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.coats.feedback.success.update.coat'),
						},
						{ root: true }
					)
				}

				return coat
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteCoat({ commit, getters }, id) {
			try {
				const response = await API.deleteCoat(id)

				if (response) {
					const index = getters.getIndex(id)

					commit('deleteCoat', index)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.coats.feedback.success.remove.coat'),
						},
						{ root: true }
					)
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
