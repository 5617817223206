import { MutationTree } from 'vuex'
import { Mutations, mutationTypes as types } from './mutation-types'
import { ReceivingState } from '../../types'
import { state as stateOfReceiving } from '../../index'

export const mutations: MutationTree<ReceivingState> & Mutations = {
	[types.SET_ANIMALS]: (state, typeormPagination) => {
		state.animals = typeormPagination
	},

	[types.SET_ANIMAL_LOADING]: (state, loading) => {
		state.animalIsLoading = loading
	},

	[types.SET_ANIMALS_CHILDREN_PET_OWNER](
		state,
		{ indexOwner, indexPetOwnerRelationship, animals }
	) {
		const customer = {
			...state.customers.data[indexOwner].relationship[indexPetOwnerRelationship],
			animals: animals,
		}

		if (!customer) {
			throw new Error('Error update animals in pet owner')
		}

		state.customers.data[indexOwner].relationship.splice(indexPetOwnerRelationship, 1, customer)
	},

	[types.SET_ANIMAL_FILTERS]: (state, filters) => {
		state.animalFilters = filters
	},

	[types.RESET_ANIMALS](state) {
		state.animals.data = stateOfReceiving.animals.data
		state.animals.meta = stateOfReceiving.animals.meta
	},
}

export default { mutations }
