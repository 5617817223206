import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,

	state: {
		loading: false,
		carregado: false,
		categorias: [],
		selecionado: undefined,
		busca: null,
		buscando: [],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.categorias.findIndex((categoria) => categoria.id === id)
			}
		},

		getExameIndex: (state) => {
			return ({ index, id }) => {
				return state.categorias[index].exames.findIndex((exame) => exame.id === id)
			}
		},
	},

	mutations: {
		setCategorias: (state, payload) => {
			state.categorias = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.organizacoes = []
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),

		addCategoria: (state, payload) => state.categorias.push(payload),

		addExameCategoria: (state, { index, payload }) =>
			state.categorias[index].exames.push(payload),

		updateExameCategoria: (state, { index, payload, indexExame }) =>
			state.categorias[index].exames.splice(indexExame, 1, payload),

		deleteExameCategoria: (state, { index, indexExame }) =>
			state.categorias[index].exames.splice(indexExame, 1),

		updateCategoria: (state, { index, payload }) => state.categorias.splice(index, 1, payload),

		deleteCategoria: (state, index) => state.categorias.splice(index, 1),

		setLoading: (state, modalState) => (state.loading = modalState),
	},

	actions: {
		selecionar({ commit }, payload) {
			commit('setSelecionado', Object.assign({}, payload))
		},

		async getAll({ commit, state }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const categorys = await API.getCategorias()

				commit('setCategorias', categorys)

				commit('setLoading', DISABLE_LOADING)

				return state.categorias
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createCategoria({ commit }, payload) {
			try {
				const category = await API.criarCategoria(payload)

				if (category) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.category.feedback.success.create.category') },
						{ root: true }
					)
					commit('addCategoria', category)
				}

				return category
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateCategoria({ commit, getters }, payload) {
			try {
				const id = payload.id
				const category = await API.editarCategoria(payload, id)

				if (category) {
					const index = getters.getIndex(id)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.category.feedback.success.update.category') },
						{ root: true }
					)
					commit('updateCategoria', { index, payload: category })
				}

				return category
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		setExameCategoria({ commit, getters }, payload) {
			const index = getters.getIndex(payload.categoria_id)

			commit('addExameCategoria', { index, payload })
		},

		updateExameCategoria({ commit, getters }, payload) {
			const index = getters.getIndex(payload.categoria_id)
			const indexExame = getters.getExameIndex({ index, id: payload.id })

			commit('updateExameCategoria', { index, indexExame, payload })
		},

		deleteExameCategoria({ commit, getters }, payload) {
			const index = getters.getIndex(payload.categoria_id)
			const indexExame = getters.getExameIndex({ index, id: payload.id })

			commit('deleteExameCategoria', { index, indexExame })
		},

		async deleteCategoria({ commit, getters }, id) {
			try {
				const response = await API.excluirCategoria(id)

				if (response) {
					const index = getters.getIndex(id)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.category.feedback.success.remove.category') },
						{ root: true }
					)
					commit('deleteCategoria', index)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
