/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import '@/axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './styles/main.scss'
import './styles/sistema.scss'

import BackButton from '@/components/global/BackButton'
import PaginacaoLista from '@/components/global/PaginacaoLista'

// Plugins
import dotenv from 'dotenv'
import Multiselect from 'vue-multiselect'
import vuetify from './plugins/vuetify'

import VueQrcode from 'vue-qrcode'
Vue.component('Qrcode', VueQrcode)

import './plugins/vee-validate'
// import './plugins/sentry'

import './plugins/CASL/CASLFactory'
import './plugins/ck-editor'
import './plugins/qrcode-vue'
import './plugins/vue-avatar'
import './plugins/vue-barcode'
import './plugins/vue-debounce'
import './plugins/vue-html-to-paper'
import './plugins/vue-meta'
import './plugins/vue-multipane'
import './plugins/vue-sanitize'
import './utils/filters/dateFilters'
import './utils/filters/dueDateFilter'
import './utils/filters/moneyFilter'

import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.component('VTextField', VTextField)
Vue.use(VCurrencyField, {
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
	valueAsInteger: false,
	allowNegative: false,
})

import money from 'v-money'
Vue.use(money, {
	decimal: ',',
	thousands: '.',
	prefix: 'R$ ',
	suffix: '',
	precision: 2,
	masked: false,
})

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
	symbol: 'R$',
	thousandsSeparator: '.',
	fractionCount: 2,
	fractionSeparator: ',',
	symbolPosition: 'front',
	symbolSpacing: true,
})

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueInputAutowidth from 'vue-input-autowidth'
Vue.use(VueInputAutowidth)

import ErrorService from '@/error'
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

Vue.$cookies.config('7d')

Vue.config.errorHandler = (error, vm, info) => {
	ErrorService.onSyntax(error, vm, info)
	ErrorService.initHandler()
}

// //import VeeValidate from 'vee-validate';
// import {localize, ValidationObserver, ValidationProvider} from "vee-validate";
// import PT_BR from 'vee-validate/dist/locale/pt_BR' // vee-validate/dist/locale/pt_BR.json
// localize('pt_BR', PT_BR)
// //Vue.use(VeeValidate);

Vue.config.productionTip = false
dotenv.config()

// Vue.http.headers.common['Access-Control-Allow-Origin'] = true

Vue.component('VBack', BackButton)
Vue.component('Multiselect', Multiselect)
Vue.component('PaginacaoLista', PaginacaoLista)

Vue.filter('celular', function (numero) {
	return numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
})

/**
 * Formatar CNPJ / CPF
 */
const DocumentPersonBrFilter = {
	install(Vue) {
		Vue.filter('cpf_cnpj', (documentId, documentType) => {
			documentId = typeof documentId != 'string' ? documentId.toString() : documentId

			if (documentType === 'PJ') {
				documentId = documentId.padStart(14, '0')
				documentId = documentId.replace(
					/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
					'$1.$2.$3/$4-$5'
				)
			} else {
				documentId = documentId.padStart(11, '0')
				documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
			}

			return documentId
		})
	},
}

Vue.use(DocumentPersonBrFilter)

export const eventBus = new Vue()

import i18n from './plugins/vue-i18n'

new Vue({
	store,
	router,
	vuetify,
	mixins: [Vue2Filters.mixin],
	render: (h) => h(App),
	i18n,
}).$mount('#app')
