<template>
	<div v-if="isLoggedIn" style="display: contents">
		<feedback-button v-if="!feedback" />
		<v-overlay v-show="!hidden" v-model="feedback" style="z-index: 9999" :dark="false">
			<validation-observer ref="form" v-slot="{ invalid }">
				<v-navigation-drawer
					v-show="!hidden"
					v-model="feedback"
					class="feedback-drawer"
					right
					bottom
					fixed
					width="400"
				>
					<div class="pa-6">
						<div>
							<v-icon @click="closeDrawer">mdi-arrow-left</v-icon>
						</div>
						<div class="mt-2">
							<p class="title">{{ $t('feedback.sendFeedback') }}</p>

							<div class="mt-4">
								<validation-provider rules="required">
									<label class="fs-14">{{
										$t('feedback.describeYourProblem')
									}}</label>
									<v-textarea
										v-model="form.message"
										:placeholder="$t('feedback.howWeCanImprove')"
									/>
								</validation-provider>

								<validation-provider v-slot="{ errors }" vid="images" name="images">
									<label class="fs-14">{{ $t('feedback.sendImages') }}</label>
									<v-file-input
										v-model="form.images"
										:rules="attachmentRules"
										multiple
										show-size
										truncate-length="15"
										:accept="getMimes"
										:error-messages="errors"
									/>
								</validation-provider>
							</div>
						</div>

						<div v-show="form.image" class="my-2" style="width: 100%">
							<canvas id="canvas" style="max-width: 100%" @click="editImage" />
						</div>

						<v-btn color="primary" :loading="loadingImage" @click="takeScreenshot">
							{{ image ? $t('feedback.newScreenshot') : $t('feedback.screenshot') }}
						</v-btn>
					</div>

					<template v-slot:append>
						<div class="pa-6 d-flex justify-end">
							<v-btn class="mr-2" @click="closeDrawer">
								{{ $t('buttons.cancel') }}
							</v-btn>
							<v-btn
								color="primary"
								:disabled="invalid || loading"
								:loading="loading"
								@click="handleSubmit"
							>
								{{ $t('buttons.send') }}
							</v-btn>
						</div>
					</template>
				</v-navigation-drawer>
			</validation-observer>
		</v-overlay>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import format from 'date-fns/format'
import { FeedbackPlus } from '@/utils/functions/feedbackplus'
import { FEEDBACK_LIST_ID } from '@/utils/constants/clickupConstants'
import { eventBus } from '@/main'
import FeedbackButton from '@/components/global/Feedback/FeedbackButton'

export default {
	name: 'FeedbackGlobal',
	components: { FeedbackButton },
	props: {
		isSetup: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			mounted: false,
			form: {
				message: '',
				image: null,
				images: [],
			},
			image: null,
			loading: false,
			loadingImage: false,
			hidden: false,
			bitmap: null,
		}
	},

	computed: {
		...mapState('Auth', ['loggedUser', 'isLoggedIn']),
		...mapState('Organizacao', ['organizacao']),
		...mapState('Feedback', ['showFeedback']),

		feedback: {
			get() {
				return this.showFeedback
			},

			set(value) {
				return this.setShowFeedback(value)
			},
		},

		getMimes() {
			return `image/*`
		},

		exceededAmountOfimages() {
			return this.form.images.length > 2
		},

		attachmentRules: () => {
			return [
				(files) =>
					!files ||
					!files.some((file) => file.size > 10e6) ||
					'Cada imagem pode conter no máximo 10 MB!',
			]
		},
	},

	watch: {
		form: {
			deep: true,

			handler() {
				if (this.exceededAmountOfimages) {
					this.$refs.form.setErrors({ images: ['O limite de imagens é 2'] })
				}
			},
		},
	},

	mounted() {
		if (!this.isSetup || this.mounted || this.showFeedback) return
		this.mounted = true

		window.addEventListener('keydown', (keyboardEvent) => {
			if (keyboardEvent.ctrlKey && keyboardEvent.code === 'KeyM') {
				if (this.isLoggedIn) {
					if (this.open) {
						return (this.open = !this.open)
					}

					this.openModal()
				}
			}
		})
	},

	methods: {
		...mapActions('Feedback', ['createClickupTask', 'createClickupTaskAttachment']),
		...mapMutations('Feedback', ['setShowFeedback']),

		openModal() {
			this.setShowFeedback(!this.showFeedback)
		},

		takeScreenshot() {
			this.hidden = true

			setTimeout(() => {
				const feedbackPlus = new FeedbackPlus()

				const canva = document.getElementById('canvas')

				feedbackPlus.capture().then(({ bitmap, width, height }) => {
					canva.width = width
					canva.height = height
					canva.getContext('2d').drawImage(bitmap, 0, 0)
					this.hidden = false
					this.bitmap = bitmap
					this.editImage()
				})
			}, 40)
		},

		editImage() {
			const feedbackPlus = new FeedbackPlus()

			const canva = document.getElementById('canvas')

			feedbackPlus.showEditDialog(
				this.bitmap,
				(canvas) => {
					FeedbackPlus.canvasToBitmap(canvas).then(({ bitmap }) => {
						canva.getContext('2d').drawImage(bitmap, 0, 0)
						canva.toBlob((blob) => {
							const today = new Date().toLocaleString().replace(/\W+/g, '-')

							const file = new File([blob], `Captura-${today}.png`, {
								type: 'image/png',
							})

							this.setImage(file)
							this.bitmap = bitmap
						})
						feedbackPlus.closeEditDialog()
					})
				},
				() => {
					feedbackPlus.closeEditDialog()
				}
			)
		},

		setImage(image) {
			this.form.image = image
		},

		async handleSubmit() {
			if (this.exceededAmountOfimages) {
				this.$refs.form.setErrors({ images: ['O limite de imagens é 2'] })
				return
			}

			this.loading = true
			let description = '### Mensagem: \n' + this.form.message

			const platformDetails = await navigator.userAgentData.getHighEntropyValues([
				'architecture',
				'platform',
				'platformVersion',
				'bitness',
				'uaFullVersion',
			])

			description += '\n\n\n' + '### Detalhes usuario:'
			description += '\n**ID:** ' + this.loggedUser.id
			description += '\n**Email:** ' + this.loggedUser.email
			description += '\n**Tipo:** ' + this.loggedUser.tipo_de_usuario.nome
			description += '\n**Tipo ID:** ' + this.loggedUser.tipo_de_usuario.id

			description += '\n\n' + '### Detalhes da organização:'
			description += '\n**ID:** ' + this.organizacao.id
			description += '\n**Razão Social:** ' + this.organizacao.razao_social

			description += '\n\n' + '### Detalhes da rota:'
			description += '\n**Rota simples:** ' + this.$route.fullPath
			description += '\n**Rota completa:** ' + window.location.href

			description += '\n\n' + '### Detalhes do sistema operacional:'
			description += '\n**OS:** ' + platformDetails.platform
			description += '\n**OS Version:** ' + platformDetails.platformVersion
			description += '\n**Architecture:** ' + platformDetails.architecture
			description += '\n**Bitness:** ' + platformDetails.bitness

			description += '\n\n' + '### Detalhes do navegador:'

			if (platformDetails.brands.length) {
				description += '\n**Navegador:** ' + platformDetails.brands[0].brand
				description += '\n**Versão navegador:** ' + platformDetails.brands[0].version
				description += '\n**UA Full Version:** ' + platformDetails.uaFullVersion
			}

			description += '\n**Mobile:** ' + navigator?.mobile || 'false'
			description += '\n**Vendor:** ' + navigator.vendor
			description += '\n**VendorSub:** ' + navigator.vendorSub
			description += '\n**Linguagem:** ' + navigator.language
			description += '\n**User Agent:** ' + navigator.userAgent

			let priority = 3
			const tags = []
			const bugTags = ['bug', 'erro', 'falha', 'arrumar', 'errado', 'falhou', 'error']
			const improvementTags = ['melhorar', 'sugestão', 'sugerir', 'sugiro', 'melhoria']

			if (bugTags.some((substring) => this.form.message.includes(substring))) {
				tags.push('Bug')
				priority = 1
			}

			if (improvementTags.some((substring) => this.form.message.includes(substring)))
				tags.push('Improvement')

			try {
				const taskPayload = {
					list_id: FEEDBACK_LIST_ID,
					name: 'Feedback - ' + format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
					markdown_description: description,
					priority,
					tags,
				}

				const taskResponse = await this.createClickupTask({ payload: taskPayload })

				if (this.form.image) {
					const imagePayload = new FormData()

					imagePayload.append('attachment', this.form.image)

					await this.createClickupTaskAttachment({
						id: taskResponse.id,
						payload: imagePayload,
					})
				}

				for (const image of this.form.images) {
					const imagePayload = new FormData()

					imagePayload.append('attachment', image)

					await this.createClickupTaskAttachment({
						id: taskResponse.id,
						payload: imagePayload,
					})
				}
			} catch {
				this.loading = false
			}

			this.loading = false
			this.closeDrawer()
		},

		closeDrawer() {
			this.form = {
				message: '',
				image: '',
				images: [],
			}
			this.loading = false
			this.image = null

			this.setShowFeedback(false)
		},
	},
}
</script>

<style scoped lang="scss">
.feedback-drawer {
	border-left: 1px solid var(--v-backgroundSheet-base);
}

::v-deep textarea {
	border: 1px solid gray;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
	height: 150px;
}
</style>

<style>
.feedbackplus.feedbackplus-modal {
	position: fixed;
	width: 90%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2147483647;
	border-radius: 10px;
	background-color: white;
	box-sizing: border-box;
}

.feedbackplus.feedbackplus-canvas-container {
	overflow: auto;
	width: 100%;
	height: calc(90vh - 102px);
	position: relative;
}

.feedbackplus.feedbackplus-highlight {
	position: absolute;
	border: 3px solid #fcc934;
}

.feedbackplus.feedbackplus-hide {
	position: absolute;
	background-color: black;
}

.feedbackplus.feedbackplus-tool-close {
	display: none;
	margin-right: 10px;
	position: absolute;
	background-color: white;
	border-radius: 50%;
	border: 1px solid black;
	padding: 5px;
	top: -10px;
	right: -20px;
	cursor: pointer;
}

.feedbackplus.feedbackplus-hide:hover .feedbackplus.feedbackplus-tool-close,
.feedbackplus.feedbackplus-highlight:hover .feedbackplus.feedbackplus-tool-close {
	display: block;
}

.feedbackplus.feedbackplus-header,
.feedbackplus.feedbackplus-footer {
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.feedbackplus.feedbackplus-header {
	height: 50px;
	max-height: 50px;
}

.feedbackplus.feedbackplus-footer {
	max-height: 52px;
}

.feedbackplus.feedbackplus-header h2 {
	margin: 5px 20px;
	font-size: 16px;
	font-weight: normal;
}

.feedbackplus.feedbackplus-footer {
	padding: 10px 20px;
	padding-top: 0px;
}

.feedbackplus.feedbackplus-finish-actions,
.feedbackplus.feedbackplus-tools {
	margin-top: 10px;
}

.feedbackplus.feedbackplus-backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2147483646;
	background-color: rgb(0, 0, 0, 0.5);
}

.feedbackplus.feedbackplus-tools {
	display: flex;
}

.feedbackplus.feedbackplus-button {
	padding: 5px 20px;
	border-radius: 4px;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	color: #000000;
	font-size: 14px;
}

.feedbackplus.feedbackplus-button.feedbackplus-complete {
	background-color: #44bba4;
	color: #ffffff;
}

.feedbackplus.feedbackplus-button.feedbackplus-complete:hover {
	background-color: rgba(68, 187, 164, 0.74);
}

.feedbackplus.feedbackplus-button.feedbackplus-cancel {
	background-color: #f5f5f5;
}

.feedbackplus.feedbackplus-button.feedbackplus-cancel:hover {
	background-color: rgba(245, 245, 245, 0.79);
}

.feedbackplus.feedbackplus-button:active {
	background-color: #ededed;
}

.feedbackplus.feedbackplus-button.feedbackplus-active {
	border-color: #0b57d0;
	background-color: #e0e0e0;
}

.feedbackplus.feedbackplus-tool-icon {
	margin-right: 7.6px;
}

.feedbackplus.feedbackplus-finish-actions {
	display: flex;
}

.feedbackplus.feedbackplus-close {
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.feedbackplus.feedbackplus-modal {
		top: 0;
		left: 0;
		transform: none;
		height: 100%;
		width: 100%;
		border-radius: 0;
	}

	.feedbackplus.feedbackplus-canvas-container {
		height: calc(100% - 102px);
	}
}
</style>
