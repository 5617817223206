import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { BreedState } from '../types'
import { Actions, actionTypes as types } from './action-types'
import { mutationTypes } from '../mutations/mutation-types'
import { getterTypes } from '../getters/getter-types'
import API from '../../service'
import { STATUS_CREATED, STATUS_NOT_FOUND, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export const actions: ActionTree<BreedState, RootState> & Actions = {
	async [types.GET_BREEDS]({ commit }, { filters, isInfiniteScroll }) {
		try {
			commit(mutationTypes.SET_LOADING_BREEDS, ENABLE_LOADING)

			const { status, data: breeds } = await API.getBreeds(filters)

			if (status === STATUS_SUCCESS) {
				if (isInfiniteScroll) {
					commit(mutationTypes.SET_MERGE_BREEDS, breeds)
				} else {
					commit(mutationTypes.SET_BREEDS, breeds)
				}
			}

			commit(mutationTypes.SET_LOADING_BREEDS, DISABLE_LOADING)

			return status === STATUS_SUCCESS ? breeds : false
		} catch (error: any) {
			commit(mutationTypes.SET_LOADING_BREEDS, DISABLE_LOADING)

			if (error.response.status === STATUS_NOT_FOUND) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}

			return false
		}
	},

	async [types.CREATE_BREED]({ commit }, payload) {
		const { status, data: breed } = await API.createBreed(payload)

		if (status === STATUS_CREATED) {
			commit(mutationTypes.CREATE_BREED, { breed })

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{
					title: i18n.t('modules.races.feedback.success.create.breed'),
				},
				{ root: true }
			)
		}

		return status === STATUS_CREATED ? breed : false
	},

	async [types.UPDATE_BREED]({ getters, commit }, { breedId, payload }) {
		const { status, data: breed } = await API.updateBreed({
			id: breedId,
			payload: payload,
		})

		if (status === STATUS_SUCCESS) {
			const indexBreed = getters[getterTypes.GET_INDEX_BREED](breedId)

			if (indexBreed > -1) {
				commit(mutationTypes.UPDATE_BREED, { index: indexBreed, breed: breed })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.races.feedback.success.update.breed'),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS ? breed : false
	},

	async [types.REMOVE_BREED]({ getters, commit }, breedId) {
		const { status } = await API.deleteBreed(breedId)

		if (status === STATUS_SUCCESS) {
			const indexBreed = getters[getterTypes.GET_INDEX_BREED](breedId)

			if (indexBreed > -1) {
				commit(mutationTypes.REMOVE_BREED, { index: indexBreed })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.races.feedback.success.remove.breed'),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS
	},
}

export default { actions }
