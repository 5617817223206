import { MutationTree } from 'vuex'
import { IGlobalState } from '../types'
import { GLOBAL_NAMESPACE, state as stateOfGlobals } from '../index'
import { mutationTypes as types, Mutations } from './mutation-types'

export const mutations: MutationTree<IGlobalState> & Mutations = {
	[types.OPEN_MODAL_CONFIRMATION](state, options) {
		state.openModalConfirmation = Object.assign({}, options)
	},

	[types.CLOSE_MODAL_CONFIRMATION](state) {
		state.openModalConfirmation = {
			currentModuleName: GLOBAL_NAMESPACE,
			value: false,
			message: {
				title: '',
				subtitle: '',
			},
			props: {
				icon: 'mdi-alert',
				iconColor: 'warning',
				buttonCancel: 'Não',
				buttonAction: 'Remover',
				buttonActionColor: 'error',
				showAction: true,
				autoClose: true,
				loading: false,
				customSlotAction: false,
				disabled: undefined,
				width: '500',
			},
			events: {
				handleClickButtonAction: false,
			},
		}
	},

	[types.TRIGGER_EVENTS_MODAL_CONFIRMATION](state, event) {
		if (event === 'click:action') {
			state.openModalConfirmation.events.handleClickButtonAction = true
		}
	},
}

export default { mutations }
