import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'

export default {
	namespaced: true,
	state: {
		equipments: [],
		equipmentStatusList: [],
		loading: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.equipments.findIndex((method) => method.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.equipments.find((method) => method.id === id)
			}
		},
	},

	mutations: {
		setEquipments: (state, methods) => (state.equipments = methods),

		addEquipment: (state, method) => state.equipments.push(method),

		updateEquipment: (state, { index, equipment }) => {
			state.equipments.splice(index, 1, equipment)
		},

		deleteEquipment: (state, index) => state.equipments.splice(index, 1),

		setEquipmentsStatusList: (state, status) => (state.equipmentStatusList = status),

		setLoading: (state, boolean) => (state.loading = boolean),
	},

	actions: {
		async getAll({ commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)
				const { status, data: equipments } = await API.getEquipments()

				if (status === STATUS_SUCCESS) {
					commit('setEquipments', equipments)
					commit('setLoading', DISABLE_LOADING)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createEquipment({ commit }, payload) {
			try {
				const { status, data: equipment } = await API.createEquipment(payload)

				if (status === STATUS_CREATED) {
					commit('addEquipment', equipment)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.equipments.feedback.success.create.equipment') },
						{ root: true }
					)
				}

				return status === STATUS_CREATED ? equipment : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateEquipment({ commit, getters }, { id, payload }) {
			try {
				const { status, data: equipment } = await API.updateEquipment(id, payload)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateEquipment', { index, equipment })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									`modules.equipments.feedback.success.update.${
										payload.api_key_id ? 'bound' : 'equipment'
									}`
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteEquipment({ commit, getters }, id) {
			try {
				const { status } = await API.deleteEquipment(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteEquipment', index)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.equipments.feedback.success.remove.equipment'
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateEquipmentStatus({ commit, getters }, { id, payload }) {
			try {
				const { status, data: equipment } = await API.updateEquipmentStatus(id, payload)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateEquipment', { index, equipment })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.equipments.feedback.success.update.equipment'
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getEquipmentStatusList({ commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)
				const { status, data: equipmentsStatusList } = await API.getEquipmentStatusList()

				if (status === STATUS_SUCCESS) {
					commit('setEquipmentsStatusList', equipmentsStatusList)
					commit('setLoading', DISABLE_LOADING)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
