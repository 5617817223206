import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { AgreementState } from './types'

export const getters: GetterTree<AgreementState<unknown>, RootState> = {
	getIndexAgreement: (state) => (agreementId: number) => {
		return state.agreements.data.findIndex((agreement) => agreement.id === agreementId)
	},

	getAgreementExamIndex: (state) => (examId: number) => {
		return state.exams.data.findIndex((exam) => exam.id === examId)
	},

	getIndexExamsSelected: (state) => (examId: number) => {
		return state.examsSelected.findIndex((exam) => exam.id === examId)
	},
}

export default getters
