import { Component, Vue } from 'vue-property-decorator'
import {
	INTEGER_TYPE,
	DECIMAL_TYPE,
	PERCENTAGE_TYPE,
	MULTIPLE_CHOICE_TYPE,
	TEXT_TYPE,
	LONG_TEXT_TYPE,
	FORMULA_TYPE,
	IMAGE_TYPE,
} from '@/utils/constants/examConstants'

@Component
export default class ValidationAttributeTypeMixin extends Vue {
	isAttributeTypeText(type: string) {
		return type === TEXT_TYPE || type === LONG_TEXT_TYPE
	}

	isAttributeTypeNumeric(type: string) {
		return type === INTEGER_TYPE || type === DECIMAL_TYPE
	}

	isAttributeTypeMultipleChoice(type: string) {
		return type === MULTIPLE_CHOICE_TYPE
	}

	isAttributeTypePercentage(type: string) {
		return type === PERCENTAGE_TYPE
	}

	isAttributeTypeImage(type: string) {
		return type === IMAGE_TYPE
	}

	isAttributeTypeFormula(type: string) {
		return type === FORMULA_TYPE
	}
}
