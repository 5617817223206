import { ICustomerFilters } from '@/modules/customers/store/types'
import { OrderState } from '../../types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ICustomer } from '@/modules/clientes/store/types'
import { IAnimalResponse } from '@/modules/animals/store/types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_CUSTOMERS = 'SET_CUSTOMERS',
	RESET_CUSTOMERS = 'RESET_CUSTOMERS',

	SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS',
	RESET_CUSTOMER_FILTERS = 'RESET_CUSTOMER_FILTERS',

	SET_RELATIONSHIPS_CUSTOMER = 'SET_RELATIONSHIPS_CUSTOMER',
	SET_ANIMALS_CUSTOMER = 'SET_ANIMALS_CUSTOMER',

	SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING',

	SET_ACTORS_SELECTED = 'SET_ACTORS_SELECTED',
	RESET_ACTORS_SELECTED = 'RESET_ACTORS_SELECTED',

	REMOVE_ACTOR_SELECTED = 'REMOVE_ACTOR_SELECTED',

	UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
}

export type Mutations<S = OrderState> = {
	[mutationTypes.SET_CUSTOMERS](
		state: S,
		typeormPagination: ITypeOrmPaginationTemplate<ICustomer>
	): void

	[mutationTypes.SET_CUSTOMER_FILTERS](state: S, filters: ICustomerFilters): void

	[mutationTypes.RESET_CUSTOMERS](state: S): void

	[mutationTypes.RESET_CUSTOMER_FILTERS](state: S): void

	[mutationTypes.SET_CUSTOMER_LOADING](state: S, loading: boolean): void

	[mutationTypes.SET_RELATIONSHIPS_CUSTOMER](
		state: S,
		data: { indexCustomer: number; relationship: ICustomer[] }
	): void

	[mutationTypes.SET_ANIMALS_CUSTOMER](
		state: S,
		data: { indexCustomer: number; animals: IAnimalResponse[] }
	): void

	[mutationTypes.SET_ACTORS_SELECTED](state: S, actors: ICustomer[]): void

	[mutationTypes.RESET_ACTORS_SELECTED](state: S): void

	[mutationTypes.REMOVE_ACTOR_SELECTED](state: S, indexActorSelected: number): void

	[mutationTypes.UPDATE_CUSTOMER](state: S, data: { index: number; payload: ICustomer }): void
}
