import API from '../service'
import APIUsuario from '../../usuarios/service'
import AtuacaoJson from '../json/area_atuacao.json'
import ErrorService from '@/error'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		carregado: false,
		organizacao: undefined,
		selecionado: undefined,
		organizacoes: [],
		organizacoesProprietario: [],
		members: [],
		tipos: [
			{ id: 1, nome: 'Clínica Veterinária', slug: 'clinica' },
			{ id: 2, nome: 'Laboratório Veterinário', slug: 'laboratorio' },
			{ id: 3, nome: 'Petshop', slug: 'petshop' },
		],
		areasAtuacao: [
			{ id: 1, nome: 'Clínica médica' },
			{ id: 2, nome: 'Acupuntura' },
			{ id: 3, nome: 'Anestesia' },
			{ id: 4, nome: 'Bovinocultura' },
			{ id: 5, nome: 'Cardiologia' },
			{ id: 6, nome: 'Clínica cirúrgica' },
			{ id: 7, nome: 'Comportamental' },
			{ id: 8, nome: 'Dermatologia' },
			{ id: 9, nome: 'Endocrinologia' },
			{ id: 10, nome: 'Fisioterapia' },
			{ id: 11, nome: 'Homeopatia' },
			{ id: 12, nome: 'Imunologia' },
			{ id: 13, nome: 'Nefrologia' },
			{ id: 14, nome: 'Neurologia' },
			{ id: 15, nome: 'Medicina Diagnóstica' },
		],
		atuacaoSelecionado: [],
		referrersUsers: [],
	},

	getters: {
		// OK
		getLogo: () => {
			return (logo) => {
				if (!logo) {
					return '/images/logo_default.png'
				}

				if (logo !== '' && logo !== null) {
					if (logo === '/images/avatar_juridica.png') {
						return '/images/logo_default.png'
					}

					return logo
				}

				return '/images/logo_default.png'
			}
		},

		// OK
		getTipoOrganizacao: (state) => {
			return (id) => {
				return state.tipos.find((tipo) => tipo.id === id)
			}
		},

		getIndex: (state) => {
			return (uuid) => {
				return state.organizacoes.findIndex((organizacao) => organizacao.uuid === uuid)
			}
		},

		getOrganizacaoID: (state) => {
			return (id) => {
				return state.organizacoes.find((organizacao) => organizacao.id === id)
			}
		},

		getOrganizacao: (state) => {
			return (uuid) => {
				return state.organizacoes.find((organizacao) => organizacao.uuid === uuid)
			}
		},

		hasOrganizacaoPF: (state) => {
			const busca = state.organizacoes.find(
				(organizacao) => organizacao.tipo_cadastro === 'PF'
			)

			if (busca) {
				return true
			}

			return false
		},

		getOrganizacaoProprietario: (state) => {
			return (uuid) => {
				return state.organizacoesProprietario.findIndex(
					(organizacao) => organizacao.uuid === uuid
				)
			}
		},

		formOrganizacao: (state) => {
			return state.formOrganizacao
		},

		getIndexByReferrer: (state) => {
			return (id) => {
				return state.referrersUsers.findIndex((referrer) => referrer.id === id)
			}
		},

		getReferrer: (state) => {
			return (id) => {
				return state.referrersUsers.find((referrer) => referrer.id === id)
			}
		},
	},

	mutations: {
		resetCarregado: (state) => {
			state.carregado = false
			state.organizacoes = []
			state.organizacao = undefined
			state.selecionado = undefined
			state.organizacoesProprietario = []
		},

		setAmbientePessoal: (state) => {
			state.organizacao = undefined
		},

		setOrganizacao: (state, payload) => (state.organizacao = payload),

		setOrganizacoes: (state, payload) => {
			state.organizacoes = payload
			state.carregado = true
		},

		setOrganizacaoProprietario: (state) => {
			state.organizacoesProprietario = []
			state.organizacoes.forEach((organizacao) => {
				if (organizacao.vinculo.proprietario === 1) {
					const index = state.organizacoesProprietario.findIndex(
						(org) => org.uuid === organizacao.uuid
					)

					if (index === -1) {
						state.organizacoesProprietario.push(organizacao)
					}
				}
			})
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),

		addOrganizacao: (state, payload) => {
			state.organizacoes.push(payload)
			state.organizacao = payload
		},

		updateOrganizacao: (state, { index, payload }) => {
			state.organizacoes.splice(index, 1, payload)
		},

		removeOrganization: (state, index) => state.organizacoes.splice(index, 1),

		// Sai da organizacão
		getOutOrganizacao: (state, index) => {
			state.organizacoes.splice(index, 1)

			if (state.organizacoes.length) {
				state.organizacao = state.organizacoes[0]
			} else {
				state.organizacao = undefined
			}
		},

		removerPropriedade: (state, index) => {
			const organizacao = Object.assign({}, state.organizacoes[index])

			organizacao.vinculo.proprietario = false

			state.organizacoes.splice(index, 1, organizacao)
			state.organizacao = organizacao
		},

		setMembersOrganizacao: (state, payload) => (state.members = payload),

		setAreasAtuacao: (state) => {
			state.areasAtuacao = AtuacaoJson
			state.atuacaoSelecionado = AtuacaoJson
		},

		setReferrersUsers: (state, referrers) => {
			state.referrersUsers = referrers
		},
	},

	actions: {
		async visualizeOrganizacao({ commit }, payload) {
			try {
				const response = await API.visualizarOrganizacao(payload.uuid)

				commit('setSelecionado', Object.assign({}, response))
				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async validarDominio(_, { dominio, novo }) {
			try {
				return await API.validarDominio({ dominio }, novo)
			} catch (e) {
				ErrorService.onStore(e, this, 'action/validarDominio')
				return e.response
			}
		},

		async getAll({ commit, state }) {
			try {
				const response = await API.getOrganizacoes()

				commit('setOrganizacoes', response)
				// commit('setOrganizacaoProprietario', response)
				return state.organizacoes
			} catch (error) {
				commit('resetCarregado')
				ErrorService.onStore(error, this, 'action/getAll')
				throw error
			}
		},

		async visualizarOrganizacao({ commit, state }, uuid) {
			try {
				const response = await API.visualizarOrganizacao(uuid)

				if (response) {
					const indexOrganization = state.organizacoes.findIndex(
						(currentOrganization) => currentOrganization.uuid === response.uuid
					)

					if (indexOrganization > -1) {
						commit('updateOrganizacao', { index: indexOrganization, payload: response })
					}
				}

				commit('setSelecionado', response)

				if (!state.carregado) {
					const organizacoes = await API.getOrganizacoes()

					commit('setOrganizacoes', organizacoes)
				}

				return response
			} catch (e) {
				ErrorService.onStore(e, this, 'action/getAll')
				throw e
			}
		},

		async createOrganizacao({ commit }, payload) {
			try {
				const response = await API.criarOrganizacao(payload)

				if (response && response.organizacao) {
					commit('addOrganizacao', response.organizacao)
					commit('setOrganizacao', response.organizacao)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t(
								'modules.organizations.feedback.success.create.organization'
							),
						},
						{ root: true }
					)
				}

				return response
			} catch (error) {
				ErrorService.onStore(error, this, 'action/createOrganizacao')
				throw error
			}
		},

		async updateOrganizacao({ commit, getters }, { payload, uuid }) {
			try {
				const { organizacao } = await API.editarOrganizacao(payload, uuid)
				const index = getters.getIndex(uuid)

				commit('updateOrganizacao', { index, payload: organizacao })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.organizations.feedback.success.update.organization') },
					{ root: true }
				)

				return organizacao
			} catch (error) {
				ErrorService.onStore(error, this, 'action/updateOrganizacao')
				throw error
			}
		},

		async removeOrganization({ commit, getters }, uuid) {
			try {
				const { status } = await API.removeOrganization(uuid)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(uuid)

					if (index > -1) {
						commit('removeOrganization', index)

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.organizations.feedback.success.remove.organization'
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async getOutOrganizacao({ commit, getters }, { uuid }) {
			try {
				const { status } = await API.getOutOrganizacao(uuid)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(uuid)

					if (index > -1) {
						commit('getOutOrganizacao', index)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.organizations.feedback.success.remove.getOutOrganization'
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async ownershipTransferOrganization({ commit, getters }, { email, uuid }) {
			try {
				const { status } = await API.ownershipTransferOrganization(email, uuid)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(uuid)

					if (index > -1) {
						commit('removerPropriedade', index)
						commit('Usuarios/setProprietario', email, { root: true })

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.organizations.feedback.success.patch.organization'
								),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async getMembersOrganization({ commit, rootState }, id) {
			try {
				const token = rootState['Auth'].token.access_token
				const response = await APIUsuario.getMembersOrganization(token, id)

				if (response) {
					commit('setMembersOrganizacao', response)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getReferrersOrganization({ commit }) {
			try {
				const { status, data: referrers } = await API.getReferrersOrganization()

				if (status === STATUS_SUCCESS) {
					commit('setReferrersUsers', referrers)
				}

				return status === STATUS_SUCCESS ? referrers : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateOpeningHoursOrganization({ getters, commit }, { uuid, payload }) {
			try {
				const { status, data: organization } = await API.updateOpeningHoursOrganization({
					uuid,
					payload,
				})

				if (status === STATUS_SUCCESS) {
					const indexOrganization = getters.getIndex(uuid)

					if (indexOrganization > -1) {
						commit('updateOrganizacao', {
							index: indexOrganization,
							payload: organization,
						})
					}
				}

				return status === STATUS_SUCCESS ? organization : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateHeaderLaud({ getters, commit }, { uuid, payload }) {
			try {
				const { status, data: organization } = await API.updateHeaderLaud({
					uuid,
					payload,
				})

				if (status === STATUS_SUCCESS) {
					const indexOrganization = getters.getIndex(uuid)

					if (indexOrganization > -1) {
						commit('updateOrganizacao', {
							index: indexOrganization,
							payload: organization,
						})
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: 'Cabeçalho atualizado com sucesso',
						},
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS ? { preferences: organization.preferences } : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateFooterLaud({ getters, commit }, { uuid, payload }) {
			try {
				const { status, data: organization } = await API.updateFooterLaud({
					uuid,
					payload,
				})

				if (status === STATUS_SUCCESS) {
					const indexOrganization = getters.getIndex(uuid)

					if (indexOrganization > -1) {
						commit('updateOrganizacao', {
							index: indexOrganization,
							payload: organization,
						})
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: 'Rodapé atualizado com sucesso',
						},
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS ? { preferences: organization.preferences } : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async removeHeaderLaud({ commit }, uuid) {
			try {
				const { status } = await API.removeHeaderLaud(uuid)

				if (status === STATUS_SUCCESS) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: 'Cabeçalho removido com sucesso',
						},
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async removeFooterLaud({ commit }, uuid) {
			try {
				const { status } = await API.removeFooterLaud(uuid)

				if (status === STATUS_SUCCESS) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: 'Rodapé removido com sucesso',
						},
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},
	},
}
