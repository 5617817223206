const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: 'teste' */ './views/DashboardIndex.vue'),
		name: 'painel',
		meta: {
			auth: true,
		},
	},
]

export default routes
