import API from '@/axios'

const Endpoint = '/recipientes/'

export default {
	async getRecipients() {
		return await API.get(Endpoint).then((response) => response)
	},

	async createRecipient(payload) {
		return await API.post(Endpoint, payload).then((response) => response)
	},

	async updateRecipient(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response)
	},

	async deleteRecipient(id) {
		return await API.delete(Endpoint + id).then((response) => response)
	},
}
