































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	name: 'ButtonDefault',
})
export default class ButtonDefault extends Vue {
	@Prop({ type: String, default: undefined }) icon?: string
	@Prop({ type: String, default: undefined }) text?: string
	@Prop({ type: Boolean, default: undefined }) disabled!: boolean
	@Prop({ type: Boolean, default: undefined }) outlined!: boolean
	@Prop({ type: Boolean, default: false }) loading!: boolean
	@Prop({ type: String, default: undefined }) tooltipMessage?: string
	@Prop({ type: Boolean, default: true }) tooltipTop?: boolean
	@Prop({ type: Boolean, default: undefined }) tooltipBottom?: boolean
	@Prop({ type: String, default: 'text-capitalize fs-14' }) contentClass!: string
	@Prop({ type: String, default: 'primary' }) color?: string
	@Prop({ type: Boolean, default: undefined }) fillWidth?: boolean
	@Prop({ type: Number, default: 24 }) sizeIcon?: number
	@Prop({ type: Boolean, default: undefined }) small?: boolean
	@Prop({ type: Boolean, default: undefined }) buttonIsText?: boolean
	@Prop({ type: Boolean, default: undefined }) isMenu?: boolean

	click() {
		if (!this.disabled) {
			this.$emit('click')
		}
	}
}
