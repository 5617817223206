const routes = [
	{
		name: 'clientes.index',
		path: '/customers',
		component: () => import(/* webpackChunkName: 'customer' */ './views/Customers.vue'),
		meta: { permissions: [{ action: 'read', resource: 'Customer' }] },
	},

	{
		name: 'clientes.perfil',
		path: '/customers/:uuid',
		component: () =>
			import(
				/* webpackChunkName: 'customer' */ '@/modules/customers/views/CustomerProfile.vue'
			),
		meta: { permissions: [{ action: 'read', resource: 'Customer' }] },
	},

	{
		name: 'importCliente.index',
		path: '/import-clients',
		component: () =>
			import(
				/* webpackChunkName: 'customer' */ '@/modules/clientes/views/importClienteIndex.vue'
			),
		meta: { permissions: [{ action: 'read', resource: 'Customer' }] },
	},
]

export default routes
