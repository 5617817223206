import { RouteConfig } from 'vue-router'

const metaConfig = { auth: true }

const routes = [
	{
		path: '/plans/trial',
		name: 'plans.trial',
		component: () =>
			import(
				/* webpackChunkName: 'plans' */ '@/modules/plans/components/General/Organism/StartTrial.vue'
			),
		meta: { ...metaConfig },
	},

	{
		path: '/plans/active',
		name: 'plans.active',
		component: () =>
			import(
				/* webpackChunkName: 'plans' */ '@/modules/plans/components/General/Organism/ActivatePremiumAccount.vue'
			),
		meta: { ...metaConfig },
	},
] as RouteConfig[]

export default routes
