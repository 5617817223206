import Vue from 'vue'
import Store from '../store/store'
import axios from 'axios'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as Validator from 'vee-validate'
import {
	required,
	max,
	min,
	min_value,
	email,
	alpha,
	alpha_spaces,
	alpha_num,
	numeric,
	mimes,
	regex,
	confirmed,
} from 'vee-validate/dist/rules'

import AgreementAPI from '@/modules/agreements/service/agreements'
import ExamsGroupAPI from '@/modules/examGroups/service'
import { STATUS_CREATED } from '@/utils/constants/statusConstants'

import EN from '@/i18n/vee-validate/en-us.json'
import PT_BR from '@/i18n/vee-validate/pt-br.json'

import moment from 'moment'
import OrganizationService from '@/modules/organizacoes/service/index'
//
extend('required', required)
extend('min', min)
extend('min_value', min_value)
extend('confirmed', confirmed)
extend('max', max)
extend('email', email)
extend('alpha', alpha)
extend('alpha_spaces', alpha_spaces)
extend('alpha_num', alpha_num)
extend('regex', regex)
extend('numeric', numeric)
extend('mimes', mimes)
localize('en', EN)
localize('pt_BR', PT_BR)

Validator.extend('numericoBr', {
	validate: (value) => validarNumericoBr(value),
})

Validator.extend('cpf', {
	validate: (value) => testCPF(value),
})

Validator.extend('cnpj', {
	validate: (value) => validarCNPJ(value),
})

Validator.extend('email_convite', {
	validate: (value) => validarEmailConvite(value),
})

Validator.extend('password_strength', {
	validate: (value) => validarPassword(value),
})

Validator.extend('format_birthdate', {
	validate: (value) => {
		const date = moment(value, 'DD/MM/YYYY', true)

		return date.isValid()
	},
})

Validator.extend('birthdate', {
	validate: (value) => {
		const date = moment(value, 'DD/MM/YYYY', true)
		const now = moment().subtract(14, 'years')
		const year = date.year()
		const old_year = now.year()

		return date.isValid() && year < old_year
	},
})

Validator.extend('birthdate_animal', {
	validate: (value) => {
		const date = moment(value, 'DD/MM/YYYY', true)
		const now = moment()
		const year = date.year()
		const old_year = now.year()
		const minYear = 1973

		return date.isValid() && year <= old_year && year >= minYear
	},
})

Validator.extend('cnpj_unique', {
	getMessage: (field, params, data) => data.message,
	validate: (value) => {
		return axios
			.post(process.env.VUE_APP_API + '/auth/validate/cnpj', {
				cnpj: value.replace(/\D/g, ''),
			})
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('cpf_unique', {
	validate: (value) => {
		return axios
			.post(process.env.VUE_APP_API + '/auth/validate/cpf', { cpf: value.replace(/\D/g, '') })
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('agreement_abbreviation_unique', {
	validate: async (value) => {
		try {
			const { status } = await AgreementAPI.validateAbbreviation(value)

			return status === STATUS_CREATED
		} catch (error) {
			return false
		}
	},
})

Validator.extend('exams_group_abbreviation_unique', {
	validate: async (value) => {
		try {
			const { status } = await ExamsGroupAPI.validateAbbreviation(value)

			return status === STATUS_CREATED
		} catch (error) {
			return false
		}
	},
})

Validator.extend('exams_group_abbreviation_unique_by_exams_group', {
	validate: async (value) => {
		try {
			const examsGroupId = Store.state.ExamsGroup.examsGroupSelected.id

			const { status } = await ExamsGroupAPI.validateAbbreviationByExamsGroupId(
				examsGroupId,
				value
			)

			return status === STATUS_CREATED
		} catch (error) {
			return false
		}
	},
})

Validator.extend('agreement_abbreviation_unique_by_agreement_id', {
	validate: async (value) => {
		try {
			const agreementId = Store.state.Agreements.agreementSelected.id

			const { status } = await AgreementAPI.validateAbbreviationByAgreementId(
				agreementId,
				value
			)

			return status === STATUS_CREATED
		} catch (error) {
			return false
		}
	},
})

Validator.extend('email_unique', {
	validate: (value) => {
		const regex = /\S+@\S+\.\S+/

		if (!regex.test(value)) return
		return axios
			.post(process.env.VUE_APP_API + '/auth/validate/email', { email: value })
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('ref_numerico_inteiro', {
	validate: (value) => {
		return true
	},
})

Validator.extend('ref_numerico_decimal_maximo', {
	validate: (value) => {
		const numero = Number(value)

		if (numero === 0.0) {
			return false
		} else {
			return true
		}
	},
})

Validator.extend('ref_numerico_inteiro_maximo', {
	validate: (value) => {
		const numero = Number(value)

		if (numero === 0) {
			return false
		} else {
			return true
		}
	},
})

Validator.extend('dominio_unique', {
	validate: (value) => {
		return axios
			.post(process.env.VUE_APP_API + '/organizacoes/validate/dominio', { dominio: value })
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('cnpj_unique_org', {
	validate: (value) => {
		return axios
			.post(process.env.VUE_APP_API + '/organizacoes/validate/cnpj', {
				cnpj: value.replace(/\D/g, ''),
			})
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('cpf_unique_org', {
	validate: (value) => {
		return axios
			.post(process.env.VUE_APP_API + '/organizacoes/validate/cpf', {
				cpf: value.replace(/\D/g, ''),
			})
			.then((response) => {
				return response.status === 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('domain_unique_org', {
	validate: (value) => {
		const payload = { dominio: value }

		return OrganizationService.validateDomain(payload)
			.then((response) => {
				return response.status !== 200
			})
			.catch(() => {
				return false
			})
	},
})

Validator.extend('sigla_exame_unica', {
	validate: (value) => {
		const selecionado = Store.state.Exames.selecionado

		if (selecionado && value && selecionado.sigla === value) {
			return true
		}

		if (value) {
			const sigla = value
				.normalize('NFD')
				.replace(/[^0-9a-zA-Z\s]/g, '')
				.toUpperCase()
			const buscaSigla = Store.getters['Exames/getSigla'](sigla)

			return !buscaSigla
		}
	},
})

Validator.extend('sigla_perfil_unica', {
	validate: (value) => {
		const selecionado = Store.state.Perfil.selecionado

		if (selecionado && value && selecionado.sigla === value) {
			return true
		}

		if (value) {
			const sigla = value
				.normalize('NFD')
				.replace(/[^0-9a-zA-Z\s]/g, '')
				.toUpperCase()
			const buscaSigla = Store.getters['Perfil/getSigla'](sigla)

			return !buscaSigla
		}
	},
})

Validator.extend('sigla_convenio_unico', {
	validate: (value) => {
		const selecionado = Store.state.Convenio.selecionado

		if (selecionado && value && selecionado.sigla === value) {
			return true
		}

		if (value) {
			const sigla = value
				.normalize('NFD')
				.replace(/[^0-9a-zA-Z\s]/g, '')
				.toUpperCase()
			const buscaSigla = Store.getters['Convenio/getSigla'](sigla)

			return !buscaSigla
		}
	},
})

Validator.extend('cpf_cliente_unique', {
	validate: (value) => {
		const selecionado = Store.state.Cliente.selecionado
		const cpf_cnpj = value.replace(/[^\d]/g, '')

		if (selecionado && value && selecionado.cpf_cnpj === cpf_cnpj) {
			return true
		}

		if (value) {
			const busca_cpf_cnpj = Store.getters['Cliente/getCpfCnpj'](cpf_cnpj)

			return !busca_cpf_cnpj
		}
	},
})

Validator.extend('cnpj_cliente_unique', {
	validate: (value) => {
		const selecionado = Store.state.Cliente.selecionado
		const cpf_cnpj = value.replace(/[^\d]/g, '')

		if (selecionado && value && selecionado.cpf_cnpj === cpf_cnpj) {
			return true
		}

		if (value) {
			const busca_cpf_cnpj = Store.getters['Cliente/getCpfCnpj'](cpf_cnpj)

			return !busca_cpf_cnpj
		}
	},
})

Validator.extend('email_cliente_unique', {
	validate: (email) => {
		const selecionado = Store.state.Cliente.selecionado

		if (selecionado && email && selecionado.email === email) {
			return true
		}

		if (email) {
			const busca_email = Store.getters['Cliente/getEmail'](email)

			return !busca_email
		}
	},
})

Validator.extend('data_coleta', {
	validate: (value) => {
		const date = moment(value, 'DD/MM/YYYY', true)
		const now = moment()
		const year = date.year()
		const old_year = now.year()

		return date.isValid() && year <= old_year && year >= 2010
	},
})

Validator.extend('hora_coleta', {
	validate: (value) => {
		if (value.length !== 5) return
		const valueSplit = value.split(':')
		const hora = valueSplit[0]
		const min = valueSplit[1]

		return hora <= '23' && min < '60'
	},
})

// VERIFICA CELULAR / TELEFONE VÁLIDO
Validator.extend('telefone_celular', {
	validate: (value) => {
		if (value.length < 14) return
		const regex =
			/^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

		if (regex.test(value)) {
			return true
		} else {
			return false
		}
	},
})

// ==================================================
// REDUPLIQUEI O CÓDIGO APENAS MUDANDO O REGEX
// FIZ ISSO PARA EVITAR ALGUM BUG EM OUTROS LUGARES
// MAS BASICAMENTE ELE SÓ VERIFICA NÚMERO REPETIDO EX: 111111111,2222222,3333333 etc...
// E RETORNA FALSE TRANSFORMANDO CPF REPETIDO EM INVÁLIDO
//

Validator.extend('cpf_validator', {
	validate: (cpf) => {
		let Soma
		let Resto

		Soma = 0

		const payload = cpf.replace(/\D/g, '')
		const regex = /\b(\d+)\1+\b/

		if (regex.test(payload)) return false

		for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(payload.substring(i - 1, i)) * (11 - i)
		Resto = (Soma * 10) % 11

		if (Resto === 10 || Resto === 11) Resto = 0
		if (Resto !== parseInt(payload.substring(9, 10))) return false

		Soma = 0
		for (let e = 1; e <= 10; e++) Soma = Soma + parseInt(payload.substring(e - 1, e)) * (12 - e)
		Resto = (Soma * 10) % 11

		if (Resto === 10 || Resto === 11) Resto = 0
		return Resto === parseInt(payload.substring(10, 11))
	},
})

Validator.extend('rg_format', {
	validate: (rg) => {
		const regex = /[\d,]/
		//	const regex = /^[0-9-]+$/g (Validar só numero e "-" )

		if (regex.test(rg)) {
			return true
		} else {
			return false
		}
	},
})

Validator.extend('cep_validation', {
	validate: (cep) => {
		const regex = /^[0-9]{5}-[0-9]{3}$/

		if (regex.test(cep)) {
			return true
		} else {
			return false
		}
	},
})

Validator.extend('apikey-name-unique', {
	validate: (value) => {
		if (value) {
			const uniqueName = Store.getters['ApiKey/getUniqueName'](value)

			return !uniqueName
		}
	},
})

Validator.extend('validationClosingTime', {
	validate(value, args) {
		const openingTime = args[0]
		const [openingHour, openingMinute] = openingTime.split(':').map(Number)
		const [closingHour, closingMinute] = value.split(':').map(Number)

		// Comparação dos horários
		if (closingHour > openingHour) {
			return true
		} else if (closingHour === openingHour && closingMinute > openingMinute) {
			return true
		} else {
			return false
		}
	},
})

// =============================================================

// Vue.use(VeeValidate, { inject: false })
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// --------------------------------------------------------------------------------------------------------------------//
// //////////////////////////////////////////// NÃO MEXA AQUI ///////////////////////////////////////////////////////////
// --------------------------------------------------------------------------------------------------------------------//
function validarNumericoBr(value) {
	const regex = /^[0-9,.]+$/

	if (regex.test(value)) {
		return true
	} else {
		return false
	}
}

function validarEmailConvite(value) {
	return axios
		.post(process.env.VUE_APP_API + '/auth/validate/email', { email: value, convite: true })
		.then((response) => {
			return response.status === 200
		})
		.catch(() => {
			return false
		})
}

const testCPF = function (cpf) {
	let Soma
	let Resto

	Soma = 0

	const payload = cpf.replace(/\D/g, '')

	if (payload === '00000000000') return false
	for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(payload.substring(i - 1, i)) * (11 - i)
	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) Resto = 0
	if (Resto !== parseInt(payload.substring(9, 10))) return false

	Soma = 0
	for (let e = 1; e <= 10; e++) Soma = Soma + parseInt(payload.substring(e - 1, e)) * (12 - e)
	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) Resto = 0
	return Resto === parseInt(payload.substring(10, 11))
}

function validarCNPJ(value) {
	if (!value) return false

	// Aceita receber o valor como string, número ou array com todos os dígitos
	const isString = typeof value === 'string'
	const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

	// Elimina valor em formato inválido
	if (!validTypes) return false

	// Filtro inicial para entradas do tipo string
	if (isString) {
		// Limita ao máximo de 18 caracteres, para CNPJ formatado
		if (value.length > 18) return false

		// Teste Regex para veificar se é uma string apenas dígitos válida
		const digitsOnly = /^\d{14}$/.test(value)
		// Teste Regex para verificar se é uma string formatada válida
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

		// Se o formato é válido, usa um truque para seguir o fluxo da validação
		if (digitsOnly || validFormat) true
		// Se não, retorna inválido
		else return false
	}

	// Guarda um array com todos os dígitos do valor
	const match = value.toString().match(/\d/g)
	const numbers = Array.isArray(match) ? match.map(Number) : []

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) return false

	// Elimina inválidos com todos os dígitos iguais
	const items = [...new Set(numbers)]

	if (items.length === 1) return false

	// Cálculo validador
	const calc = (x) => {
		const slice = numbers.slice(0, x)
		let factor = x - 7
		let sum = 0

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i]

			sum += n * factor--
			if (factor < 2) factor = 9
		}

		const result = 11 - (sum % 11)

		return result > 9 ? 0 : result
	}

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12)

	// Valida 1o. dígito verificador
	const digit0 = calc(12)

	if (digit0 !== digits[0]) return false

	// Valida 2o. dígito verificador
	const digit1 = calc(13)

	return digit1 === digits[1]
}

function validarPassword(val) {
	// const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,20}$/ /* Valida, números e letras */
	const regexSpecial =
		/^(?=.*[_@!#$%^&,.*()/\\])(?=.*[0-9])(?=.*[a-zA-Z])[_@!#$%^&,.*()/\\a-zA-Z0-9]{8,20}$/ /* Valida, números, letras e caracteres especiais*/
	// const regex3 = /^(?=.*[@!#$%^&,.*()/\\])(?=.*[a-zA-Z])[@!#$%^&,.*()/\\a-zA-Z]{8,20}$/ /* Valida valida letra e caracteres especiais*/

	if (regexSpecial.test(val)) {
		return true
	} else {
		return false
	}
}
