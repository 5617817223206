







































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	name: 'TileButton',
})
export default class TileButton extends Vue {
	@Prop({ type: String, default: '#43B7A0' }) readonly color!: string
	@Prop({ type: String, default: null }) readonly icon!: string
	@Prop({ type: Boolean, default: false }) readonly menuDisabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly small!: boolean
	@Prop({ type: Boolean, default: false }) readonly loading!: boolean
	@Prop({ type: Boolean, default: undefined }) disabled?: boolean
	@Prop({ type: Boolean, default: false }) readonly badge!: boolean
	@Prop({ type: String, default: '' }) readonly tooltipMessage!: string
	@Prop({ type: String, default: undefined }) contentClass?: string
	@Prop({ type: String, default: 'mr-4' }) contentClassIcon!: string
	@Prop({ type: String, default: '' }) readonly menuLeft!: string
	@Prop({ type: String, default: '' }) readonly menuBottom!: string
	@Prop({ type: String, default: '' }) readonly buttonName!: string
	@Prop({ type: Boolean, default: undefined }) readonly block!: boolean
}
