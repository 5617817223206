import API from '@/axios'

const Endpoint = '/perfis/'

export default {
	async getPerfis() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async getPerfil(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async criarPerfil(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async editarPerfil(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async changeStatusExamGroup(id, status) {
		return await API.patch(Endpoint + id + '/status', { status: status }).then((response) => {
			response.data
		})
	},

	async excluirPerfil(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},

	async unlinkExam(id, examId) {
		return await API.delete(Endpoint + id + '/exames/' + examId).then(
			(response) => response.data
		)
	},
}
