import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions/actions'
import { getters } from './getters/getters'
import { mutations } from './mutations/mutations'
import { actions as actionsCustomers } from './actions/customers/actions'
import { mutations as mutationsCustomers } from './mutations/customers/mutations'
import { getters as gettersCustomers } from './getters/customers/getters'
import { OrderState } from './types'

export const state: OrderState = {
	load: false,
	selectedOrder: {},
	orders: {
		data: [],
		links: {},
		meta: {},
	},
	ordersFiltered: {
		data: [],
		links: {},
		meta: {},
	},
	ordersPerStatus: [
		{ name: 'Draft', id: 1, data: [], links: {}, meta: {} },
		{ name: 'New', id: 2, data: [], links: {}, meta: {} },
		{ name: 'In logistics', id: 3, data: [], links: {}, meta: {} },
		{ name: 'Processing', id: 4, data: [], links: {}, meta: {} },
		{ name: 'Delivered', id: 5, data: [], links: {}, meta: {} },
		{ name: 'Billed', id: 6, data: [], links: {}, meta: {} },
		{ name: 'Finished', id: 7, data: [], links: {}, meta: {} },
		{ name: 'Canceled', id: 8, data: [], links: {}, meta: {} },
	],
	filter: {
		search: null,
		orderStatus: [2, 3, 4, 5, 6, 7, 10],
		orderBy: 'created_at',
		orderDirection: 'desc',
	},
	statusOrders: [],
	attachments: [],
	loadingOrderSubHeader: false,
	openStakeholderSelection: false,
	actorsSelected: [],
	customers: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},
	customerFilters: {
		limit: 10,
		page: 1,
		search: '',
		deleted: false,
		customerType: undefined,
		belongsTo: undefined,
		parentsOf: undefined,
	},
	customerIsLoading: false,
	isModeCreateOrder: false,
	isModeSelectCustomer: {
		value: false,
		customer: undefined,
	},
}

export const ORDER_NAMESPACE = 'Orders'

export const order: Module<OrderState, RootState> = {
	namespaced: true,
	state: state,
	getters: { ...getters, ...gettersCustomers },
	mutations: { ...mutations, ...mutationsCustomers },
	actions: { ...actions, ...actionsCustomers },
}
