/* eslint-disable no-unused-vars */
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '@/modules/signature/store/mutations/mutation-types'
import { SignatureState } from '@/modules/signature/store/types'
import { RootState } from '@/store/types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'

export enum actionTypes {
	UPDATE_SIGNATURE = 'UPDATE_SIGNATURE',
	REMOVE_SIGNATURE = 'REMOVE_SIGNATURE',
}

export interface Actions {
	[actionTypes.UPDATE_SIGNATURE](
		{ commit }: AugmentedActionContext,
		payload: FormData
	): Promise<boolean | any>

	[actionTypes.REMOVE_SIGNATURE]({ commit }: AugmentedActionContext): Promise<boolean | any>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<SignatureState, RootState>, 'getters' | 'commit' | 'dispatch'>
