import { ReceivingState } from '../../types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IAnimalFilters, IAnimalResponse } from '@/modules/animals/store/types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_ANIMALS = 'SET_ANIMALS',
	SET_ANIMAL_LOADING = 'SET_ANIMAL_LOADING',

	SET_ANIMAL_FILTERS = 'SET_ANIMAL_FILTERS',

	SET_ANIMALS_CHILDREN_PET_OWNER = 'SET_ANIMALS_CHILDREN_PET_OWNER',

	RESET_ANIMALS = 'RESET_ANIMALS',
}

export type Mutations<S = ReceivingState> = {
	[mutationTypes.SET_ANIMALS](
		state: S,
		typeormPagination: ITypeOrmPaginationTemplate<IAnimalResponse>
	): void

	[mutationTypes.SET_ANIMAL_LOADING](state: S, loading: boolean): void

	[mutationTypes.SET_ANIMAL_FILTERS](state: S, filters: IAnimalFilters): void

	[mutationTypes.RESET_ANIMALS](state: S): void

	[mutationTypes.SET_ANIMALS_CHILDREN_PET_OWNER](
		state: S,
		data: { indexOwner: number; indexPetOwnerRelationship: number; animals: IAnimalResponse[] }
	): void
}
