import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'

export default {
	namespaced: true,
	state: {
		variables: [
			{ id: 1, category: 'Microorganismo', items: [], icon: 'mdi-virus' },
			{ id: 2, category: 'Drogas', items: [], icon: 'mdi-pill-multiple' },
		],
		microorganisms: [],
		drugs: [],
	},

	getters: {
		getIndexVariable(state) {
			return (id) => {
				return state.variables.findIndex((variable) => variable.id === id)
			}
		},
	},

	mutations: {
		setMicroorganisms: (state, payload) => {
			state.microorganisms = payload
		},

		setDrugs: (state, payload) => {
			state.drugs = payload
		},

		setItemsToVariable: (state, { index, items }) => {
			state.variables[index].items = items
		},
	},

	actions: {
		async getAllMicroorganisms({ commit, getters }) {
			try {
				const microorganisms = await API.getMicroorganisms()

				if (microorganisms && microorganisms.length) {
					commit('setMicroorganisms', microorganisms)

					const index = getters.getIndexVariable(1)

					if (index > -1) {
						commit('setItemsToVariable', { index, items: microorganisms })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllDrugs({ commit, getters }) {
			try {
				const drugs = await API.getDrugs()

				if (drugs && drugs.length) {
					commit('setDrugs', drugs)

					const index = getters.getIndexVariable(2)

					if (index > -1) {
						commit('setItemsToVariable', { index, items: drugs })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
