import { BreedState } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_INDEX_BREED = 'GET_INDEX_BREED',
}

export type Getters<S = BreedState> = {
	[getterTypes.GET_INDEX_BREED]: (state: S) => (breedId: number) => number
}
