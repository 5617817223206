








































import { Component, PropSync, ProvideReactive, Vue } from 'vue-property-decorator'
import {
	actionTypes,
	ISelectedAttribute,
	ISelectedAttributeGroup,
	mutationTypes,
} from '@/modules/exams/store/attributes/types'
import { IResponseAttributes } from '@/modules/exams/services/attributes/types'
import ExamAttributeCard from './ExamAttributeCard.vue'
import ExamAttributeContentGroup from './ExamAttributeContentGroup.vue'
import ExamAttributeTree from './ExamAttributeTree.vue'
import Draggable from 'vuedraggable'
import { Action, Mutation, State } from 'vuex-class'

@Component({
	name: 'ExamAttributeGroup',
	components: {
		ExamAttributeCard,
		ExamAttributeContentGroup,
		ExamAttributeTree,
		Draggable,
	},
})
export default class ExamAttributeGroup extends Vue {
	@PropSync('group', { type: Object, required: true }) attributeGroup!: IResponseAttributes

	@State((state) => state.Exams.selectedAttribute) selectedAttribute!: ISelectedAttribute

	@Action(`Exams/${actionTypes.DELETE_ATTRIBUTE_GROUP}`) handleDeleteAttributeGroup!: ({
		examId,
		attributeGroupId,
	}: {
		examId: number
		attributeGroupId: number
	}) => Promise<void>

	@ProvideReactive('simpleAttributeMode') simpleAttributeMode = false

	showAttributesChildren = true

	@Mutation(`Exams/${mutationTypes.UPDATE_SELECTED_ATTRIBUTE}`)
	handleUpdateSelectedAttribute!: (payload: {
		selectedAttributeGroup: ISelectedAttributeGroup
		selectedAttribute: ISelectedAttribute
	}) => void

	get isAttributeGroupSelected(): boolean {
		return (
			this.selectedAttribute.type === 'attribute_group' &&
			this.selectedAttribute.id === this.attributeGroup.id
		)
	}

	get isAttributeSeleted() {
		return (id: number) => {
			return this.selectedAttribute.type === 'attribute' && this.selectedAttribute.id === id
		}
	}

	async handleSelectAttributeGroup() {
		this.handleUpdateSelectedAttribute({
			selectedAttribute: {
				id: this.attributeGroup.id,
				position: this.attributeGroup.position,
				type: 'attribute_group',
			},
			selectedAttributeGroup: {
				id: this.attributeGroup.id,
				position: this.attributeGroup.position,
			},
		})
	}
}
