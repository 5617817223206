import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

const statusSuccess = 200

export default {
	namespaced: true,

	state: {
		loading: false,
		carregado: false,
		recipientes: [],
		selecionado: undefined,

		icones: [
			{ icone: 'mdi-test-tube', nome: 'pipe' },
			{ icone: 'mdi-eraser', nome: 'blade' },
			{ icone: 'mdi-delete-variant', nome: 'bowl' },
			{ icone: 'mdi-glass-stange', nome: 'swab' },
			{ icone: 'mdi-water', nome: 'drop' },
			{ icone: 'mdi-bottle-soda-outline', nome: 'soda' },
			{ icone: 'mdi-eyedropper', nome: 'dropper' },
			{ icone: 'mdi-bullet', nome: 'bullet' },
			{ icone: 'mdi-flask', nome: 'flask' },
			{ icone: 'mdi-flask-round-bottom', nome: 'flaskRound' },
			{ icone: 'mdi-gas-cylinder', nome: 'cylinder' },
			{ icone: 'mdi-nail', nome: 'nail' },
			{ icone: 'mdi-oar', nome: 'oar' },
			{ icone: 'mdi-rivet', nome: 'rivet' },
			{ icone: 'mdi-needle', nome: 'syringe' },
		],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.recipientes.findIndex((recipiente) => recipiente.id === id)
			}
		},

		getId: (state) => {
			return (id) => {
				return state.recipientes.find((recipiente) => recipiente.id === id)
			}
		},

		getIcone: (state) => {
			return (icon) => {
				return state.icones.find((icone) => icone.icone === icon)
			}
		},
	},

	mutations: {
		setRecipients(state, payload) {
			state.recipientes = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.recipientes = []
		},

		setSelecionado: (state, { payload, icone }) =>
			(state.selecionado = Object.assign(payload, { icone: icone })),

		setCarregado: (state, bool) => (state.carregado = bool),

		addRecipient: (state, payload) => state.recipientes.push(payload),

		updateRecipient: (state, { index, payload }) => {
			state.recipientes.splice(index, 1, payload)
		},

		deleteRecipient: (state, index) => state.recipientes.splice(index, 1),

		setLoading: (state, stateModal) => (state.loading = stateModal),
	},

	actions: {
		selecionar({ commit, getters }, payload) {
			const icone = getters.getIcone(payload.icone)
			const recipiente = Object.assign({}, payload)

			commit('setSelecionado', { payload: recipiente, icone })
		},

		async getAll({ commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const { status, data: recipients } = await API.getRecipients()

				if (status === statusSuccess) {
					commit('setRecipients', recipients)
				}

				commit('setLoading', DISABLE_LOADING)

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createRecipient({ commit }, payload) {
			try {
				const {
					status,
					data: { recipiente: recipient },
				} = await API.createRecipient(payload)

				if (status === statusSuccess) {
					commit('addRecipient', recipient)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.recipients.feedback.successes.create.recipient') },
						{ root: true }
					)
				}

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateRecipient({ commit, getters }, { id, payload }) {
			try {
				const {
					status,
					data: { recipiente: recipient },
				} = await API.updateRecipient(payload, id)

				if (status === statusSuccess) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateRecipient', { index, payload: recipient })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.recipients.feedback.successes.update.recipient'
								),
							},
							{ root: true }
						)
					}
				}

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteRecipient({ commit, getters }, id) {
			try {
				const { status } = await API.deleteRecipient(id)

				if (status === statusSuccess) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteRecipient', index)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t(
									'modules.recipients.feedback.successes.remove.recipient'
								),
							},
							{ root: true }
						)
					}
				}

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
