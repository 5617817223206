

















import { Component, Mixins, Prop } from 'vue-property-decorator'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import { IAttributeFormula } from '@/modules/exams/store/attributes/types'
import GetSpeciesToSelectMixin from '@/modules/exams/mixins/GetSpeciesToSelectMixin'
import GetAttributeNumbersToSelectMixin from '@/modules/exams/mixins/GetAttributeNumbersToSelectMixin'

@Component({
	name: 'ExamCardFormula',
	components: {
		ButtonIcon,
	},
})
export default class ExamCardFormula extends Mixins(
	GetSpeciesToSelectMixin,
	GetAttributeNumbersToSelectMixin
) {
	@Prop({ type: Object, required: true }) readonly formula!: IAttributeFormula

	get selectedSpecieName() {
		return this.speciesToSelect.find((specie) => specie.id === this.formula.specie_id)?.nome
	}

	get formattedFormula() {
		const keys = this.formula.formula?.match(/:\w+/gim) || []

		const attributeNames = keys.map((key) => {
			const formattedKey = key.replace(':campo', '')
			const attribute = this.attributesToSelect().find(
				(attribute) => attribute.key === formattedKey
			)

			return attribute?.name || 'key'
		})

		let index = 0

		return this.formula.formula?.replace(/:\w+/gim, () => attributeNames[index++])
	}
}
