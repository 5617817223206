










import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component({
	name: 'InputCheckBox',
})
export default class InputCheckBox extends Vue {
	@VModel({ type: Boolean, default: false }) valueCheckBox!: boolean

	@Prop({ type: String, default: undefined }) label?: string

	isSlotDefault() {
		return !this.$slots.default
	}
}
