import API from '@/axios'
const Endpoint = '/rotulos/'

export default {
	async getRotulos() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async criarRotulo(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async editarRotulo(id, payload) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async changeStatusTag(id, status) {
		return await API.patch(Endpoint + id + '/status', { status: status }).then(
			(response) => response.data
		)
	},

	async excluirRotulo(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},
}
