// import API from '../service'

export default {
	namespaced: true,

	state: {
		cliente: {},
		animal: {},
		animais: [],
		clienteSelecionado: false,
		animalSelecionado: false,
		animaisSelecionados: [],
	},

	getters: {},

	mutations: {
		selecionaCliente: (state, payload) => {
			state.cliente = Object.assign({}, payload)
			state.clienteSelecionado = true
			state.animalSelecionado = false
			state.animaisSelecionados = []
		},
		selecionaAnimal: (state, payload) => {
			state.animal = Object.assign({}, payload)
			state.animalSelecionado = true
		},

		setAnimaisSelecionados: (state, payload) => {
			state.animaisSelecionados.push(payload)
		},

		limparClienteSelecionado: (state) => {
			state.cliente = {}
			state.clienteSelecionado = false
			state.animalSelecionado = false
			state.animaisSelecionados = []
			state.animal = {}
		},
	},

	actions: {
		setAnimaisCliente: ({ state, rootState, commit }) => {
			const animais = rootState['Animais'].animais

			animais.forEach((animal) => {
				if (animal.cliente.uuid === state.cliente.uuid) {
					commit('setAnimaisSelecionados', animal)
					commit('selecionaAnimal', animal)
				}
			})
		},

		selecionaCliente: ({ dispatch, commit }, payload) => {
			commit('selecionaCliente', payload)
			dispatch('setAnimaisCliente')
		},
	},
}
