import { MutationTree } from 'vuex'
import { SuccessState, ITypeSuccessfully as types } from './types'
import { Mutations } from './mutations/mutation-types'

const state: SuccessState = {
	successes: [],
}
const mutations: MutationTree<SuccessState> & Mutations = {
	[types.ADD_SUCCESS](state, success) {
		state.successes.push(success)
	},
	[types.REMOVE_SUCCESS](state, index) {
		state.successes.splice(index, 1)
	},
	[types.REMOVE_ALL_SUCCESSES](state) {
		state.successes = []
	},
}

export default {
	namespaced: true,
	state,
	mutations,
}
