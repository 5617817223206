import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'

export default {
	namespaced: true,

	state: {
		carregado: false,
		perfis: [],
		selecionado: {},
		busca: null,
		buscando: [],
		tipos: ['$', '%'],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.perfis.findIndex((perfil) => perfil.id === id)
			}
		},

		getId: (state) => {
			return (id) => {
				return state.perfis.find((perfil) => perfil.id === id)
			}
		},

		getSigla: (state) => {
			return (sigla) => {
				return state.perfis.find((perfil) => perfil.sigla === sigla)
			}
		},
	},

	mutations: {
		setBusca: (state, payload) => {
			state.buscando = []
			if (!payload.nome || payload.nome === '') return
			state.busca = payload.nome.toLowerCase()
			const filtro = payload.registros.filter((perfil) =>
				perfil.nome.toLowerCase().includes(state.busca)
			)

			state.buscando = filtro
		},

		setPerfis: (state, payload) => {
			state.perfis = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.perfis = []
		},

		selecionar: (state, payload) => (state.selecionado = payload), // index
		deselecionar: (state) => (state.selecionado = {}),

		addPerfil: (state, payload) => {
			state.perfis.push(payload)
		},

		updatePerfil: (state, { index, payload }) => {
			state.perfis.splice(index, 1, payload)
		},

		deletePerfil: (state, { index }) => {
			state.perfis.splice(index, 1)
		},
	},

	actions: {
		async getAll({ state, commit }) {
			try {
				if (!state.carregado) {
					const response = await API.getPerfis()

					commit('setPerfis', response)
				}

				return state.perfis
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getId({ commit }, id) {
			try {
				const perfil = await API.getPerfil(id)

				commit('selecionar', perfil)
				return perfil
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async unlinkExam({ commit, getters, dispatch }, { id, idExam }) {
			try {
				const perfil = await API.unlinkExam(id, idExam)
				const index = getters.getIndex(id)

				if (perfil && index > -1) {
					if (!perfil.exames.length) {
						dispatch('deletePerfil', perfil.id)
					} else {
						commit('updatePerfil', { index, payload: perfil })
					}
				}

				return perfil
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createPerfil({ commit }, payload) {
			try {
				const perfil = await API.criarPerfil(payload)

				commit('addPerfil', perfil)
				return perfil
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updatePerfil({ commit, getters }, { payload, id }) {
			try {
				const perfil = await API.editarPerfil(payload, id)
				const index = getters.getIndex(id)

				commit('updatePerfil', { index, payload: perfil })
				return perfil
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async changeStatusExamGroup({ commit, getters }, { id, status }) {
			try {
				const newStatus = await API.changeStatusExamGroup(id, status)

				if (newStatus) {
					const index = getters.getIndex(id)

					commit('updateExamGroupStatus', { index, status: newStatus })
				}

				return newStatus
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deletePerfil({ commit, getters }, id) {
			try {
				const index = getters.getIndex(id)

				const response = await API.excluirPerfil(id)

				if (response) {
					if (index > -1) {
						commit('deletePerfil', { index })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
