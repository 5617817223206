










import { actionTypes, IReference } from '@/modules/exams/store/references/types'
import { DEBOUNCE_TIMEOUT } from '@/utils/constants/generalizeConstants'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { Action, State } from 'vuex-class'

@Component({
	name: 'AttributeReferenceBorderValue',
})
export default class AttributeReferenceBorderValue extends Vue {
	@Ref('input') readonly input!: {
		focus: () => void
	}

	@Prop({ type: Object, required: true }) readonly referenceValueGroup!: any
	@Prop({ type: Boolean, default: false }) readonly thereGroups!: boolean

	@State(({ Exams }) => Exams.selectedReference) reference!: IReference

	// eslint-disable-next-line no-undef
	timeout: NodeJS.Timeout | null = null

	get getGroup() {
		return this.referenceValueGroup.nome
	}
	set getGroup(value) {
		if (this.timeout) clearTimeout(this.timeout)
		this.timeout = setTimeout(() => {
			this.handleUpdateValueGroup(value)
			this.$emit('input', value)
		}, DEBOUNCE_TIMEOUT)
	}

	@Action(`Exams/${actionTypes.UPDATE_REFERENCE_GROUP}`) updateReferenceGroup!: (payload: {
		groupId: number
		payload: any
	}) => Promise<any>

	async handleUpdateValueGroup(groupName: string) {
		const payload = {
			nome: groupName,
		}

		await this.updateReferenceGroup({
			groupId: this.referenceValueGroup.id,
			payload: payload,
		})
	}

	setFocusInput() {
		this.input.focus()
	}

	removeGroup() {
		this.$emit('removeGroup', this.referenceValueGroup)
	}
}
