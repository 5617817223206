/* eslint-disable no-unused-vars */
import { RootState } from '@/store/types'
import { ActionContext, DispatchOptions } from 'vuex'
import { Getters } from '../../getters/customers/getter-types'
import { Mutations } from '../../mutations/customers/mutation-types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { ICustomerFilters, ICustomerFiltersBelongsTo } from '@/modules/customers/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ICustomer } from '@/modules/clientes/store/types'
import { OrderState } from '../../types'

export enum actionTypes {
	GET_CUSTOMERS = 'GET_CUSTOMERS',
	GET_CUSTOMERS_BELONGS_TO = 'GET_CUSTOMERS_BELONGS_TO',

	SET_RELATIONSHIPS_CUSTOMER = 'SET_RELATIONSHIPS_CUSTOMER',
	SET_ANIMALS_CUSTOMER = 'SET_ANIMALS_CUSTOMER',

	GET_RELATIONSHIPS_CUSTOMER = 'GET_RELATIONSHIPS_CUSTOMER',

	UPDATE_CUSTOMER_TYPE = 'UPDATE_CUSTOMER_TYPE',
}

export interface Actions {
	[actionTypes.GET_CUSTOMERS](
		{ state, getters, commit }: AugmentedActionContext,
		data: { resetPage?: boolean; filters?: ICustomerFilters }
	): Promise<ITypeOrmPaginationTemplate<ICustomer> | boolean>

	[actionTypes.GET_CUSTOMERS_BELONGS_TO](
		{ state, getters, commit }: AugmentedActionContext,
		data: { filters: ICustomerFiltersBelongsTo }
	): Promise<ITypeOrmPaginationTemplate<ICustomer> | boolean>

	[actionTypes.GET_RELATIONSHIPS_CUSTOMER](
		{ state, getters, commit }: AugmentedActionContext,
		data: { customer: ICustomer; filters: ICustomerFiltersBelongsTo }
	): void

	[actionTypes.SET_RELATIONSHIPS_CUSTOMER](
		{ state, getters, commit }: AugmentedActionContext,
		data: { customerActorUuid: string; customerCreated: any }
	): void

	[actionTypes.SET_ANIMALS_CUSTOMER](
		{ state, getters, commit }: AugmentedActionContext,
		data: { customerActorUuid: string; animalCreated: any }
	): void

	[actionTypes.UPDATE_CUSTOMER_TYPE](
		{ state, getters, commit }: AugmentedActionContext,
		customer: ICustomer
	): void
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	// dispatch<K extends keyof any>(
	// 	key: K,
	// 	payload: Parameters<Actions[K]>[1],
	// 	root?: { root: boolean }
	// ): ReturnType<Actions[K]>
} & Omit<ActionContext<OrderState, RootState>, 'getters' | 'commit' /* | 'dispatch' */>
