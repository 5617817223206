import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation, State } from 'vuex-class'
import { mutationTypes as exammutationTypes } from '@/modules/exams/store/types'

@Component
export default class UnitsMeasurementMixin extends Vue {
	@State((state) => state.Exams.unitsMeasurement) unitsMeasurement!: string[]

	@Getter('Exams/getIndexUnitMeasurement') getIndexUnitMeasurement!: (item: string) => number

	@Mutation(`Exams/${exammutationTypes.ADD_UNIT_MEASUREMENT}`)
	addUnitMeasurement!: (unit: string) => void

	@Mutation(`Exams/${exammutationTypes.UPDATE_UNIT_MEASUREMENT}`)
	updateUnitMeasurement!: ({
		index,
		unitMeasurement,
	}: {
		index: number
		unitMeasurement: string
	}) => void

	selected: null | string = null

	addOrUpdateItem(unitMeasurement: string): void {
		const index: number = this.getIndexUnitMeasurement(unitMeasurement)

		if (index === -1) {
			this.addUnitMeasurement(unitMeasurement)
		} else {
			this.updateUnitMeasurement({ index: index, unitMeasurement: unitMeasurement })
		}
	}
}
