














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	name: 'ExamAttributeCard',
})
export default class ExamAttributeCard extends Vue {
	@Prop({ type: String, default: undefined }) styleClass?: string

	getStyleClass(): string | undefined {
		if (!this.styleClass) return

		return this.styleClass
	}
}
