export default {
	namespaced: true,

	state: {
		menu: [
			{
				index: 1,
				name: 'Geral',
				active: true,
				items: [
					{
						index: 1,
						name: 'Demandas',
						icon: 'mdi-text-box-check-outline',
						color: 'gray',
						route: 'request.index',
					},
					{
						index: 2,
						name: 'Clientes',
						icon: 'mdi-account-multiple',
						color: 'gray',
						route: 'clientes.index',
					},
					{
						index: 3,
						name: 'Pacientes',
						icon: 'mdi-paw',
						color: 'gray',
						route: 'animais.index',
					},
				],
			},
		],
	},

	getters: {
		getElementIndex: (state) => {
			return (index) => {
				return state.menu.findIndex((item) => item.index === index)
			}
		},

		getElementByIndex: (state) => {
			return (index) => {
				return state.menu.find((item) => item.index === index)
			}
		},
	},

	mutations: {
		updateElements: (state, payload) => {
			state.menu = payload
		},

		addNewChildElement: (state, { index, payload }) => {
			state.menu[index].items.push(payload)
		},
	},

	actions: {
		updateElements: ({ commit }, payload) => {
			commit('updateElements', payload)
		},

		addNewChildElement: ({ commit, getters }, { index, payload }) => {
			const elementIndex = getters.getElementIndex(index)
			const element = getters.getElementByIndex(index)

			let max = {
				index: element.items.length === 1 ? 1 : 0,
			}

			if (element.items.length >= 2) {
				max = element.items.reduce((prev, current) =>
					prev.index > current.index ? prev : current
				)
			}

			payload.index = max.index + 1

			if (elementIndex > -1) {
				commit('addNewChildElement', { index: elementIndex, payload: payload })
			}
		},
	},
}
