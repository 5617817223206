import API from '@/axios'
const Endpoint = '/animals/v1/coats/'

export default {
	async getPelagem() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async createCoat(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async updateCoat(id, payload) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async deleteCoat(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},
}
