import API from '@/axios'
const Endpoint = '/financial/bills'
const EndpointFinancial = '/financial'
const EndpointItems = '/items'
const EndpointStatus = '/status'
const EndpointExternalItem = '/external-items'
const EndpointMany = '/many'
const EndpointGatewayPayments = '/gateway-payments'

export default {
	async getBills(params) {
		return await API.get(Endpoint, {
			params,
		}).then((response) => response)
	},

	async visualizeCustomerBill({ limit = 15, page = 1, groupBy = 'customer', customerId }) {
		return await API.get(Endpoint, {
			params: { limit: limit, page: page, groupBy: groupBy, customerId: customerId },
		}).then((response) => response)
	},

	async getBillStatus() {
		return await API.get(Endpoint + EndpointStatus).then((response) => response)
	},

	async getExternalItemsBill() {
		return await API.get(Endpoint + EndpointExternalItem + '/find-all').then(
			(response) => response
		)
	},

	async createExternalItemBill(payload) {
		return await API.post(Endpoint + EndpointExternalItem + '/create', payload).then(
			(response) => response
		)
	},

	async updateExternalItemBill({ payload, id }) {
		return await API.patch(Endpoint + EndpointExternalItem + '/update/' + id, payload).then(
			(response) => response
		)
	},

	async createBill(payload) {
		return await API.post(Endpoint, payload).then((response) => response)
	},

	async visualizeBill(id) {
		return await API.get(Endpoint + `/${id}`).then((response) => response)
	},

	async updateBill({ id, payload }) {
		return await API.patch(Endpoint + `/${id}`, payload).then((response) => response)
	},

	async updateBillStatus({ id, payload }) {
		return await API.patch(Endpoint + `/${id}` + EndpointStatus, payload).then(
			(response) => response
		)
	},

	async updateBillItem({ id, payload }) {
		return await API.patch(Endpoint + EndpointItems + `/${id}`, payload).then(
			(response) => response
		)
	},

	async createBillItem({ id, payload }) {
		return await API.post(Endpoint + `/${id}` + EndpointItems, payload).then(
			(response) => response
		)
	},

	async createManyBillItems({ id, payload }) {
		return await API.post(Endpoint + `/${id}` + EndpointItems + EndpointMany, payload).then(
			(response) => response
		)
	},

	async createBillByCustomer({ customerId, payload }) {
		return await API.post(Endpoint + `/customer/${customerId}`, null, { params: payload }).then(
			(response) => response
		)
	},

	async createBillByAllCustomers({ payload }) {
		return await API.post(Endpoint + `/customers`, null, {
			params: payload,
		}).then((response) => response)
	},

	async removeBillItem({ id }) {
		return await API.delete(Endpoint + EndpointItems + `/${id}`).then((response) => response)
	},

	async removeBills({ payload }) {
		return await API.delete(Endpoint + '/many', { data: payload }).then((response) => response)
	},

	async cancellationBill(id, payload) {
		return await API.delete(Endpoint + `/${id}`, { data: payload }).then((response) => response)
	},

	async updateStatusBill({ id, payload }) {
		return await API.patch(Endpoint + `/${id}/status`, payload).then((response) => response)
	},

	async updateAddonsToBill({ idRequestedExam }) {
		return await API.patch(Endpoint + EndpointItems + `/exam/${idRequestedExam}`).then(
			(response) => response
		)
	},

	async createBillPayment(payload) {
		return await API.post(EndpointFinancial + EndpointGatewayPayments, payload).then(
			(response) => response
		)
	},

	async deleteBillPayment(id) {
		return await API.delete(EndpointFinancial + EndpointGatewayPayments + `/${id}`).then(
			(response) => response
		)
	},
}
