// TODO Remover esse ts-ignore quando o typescript estiver configurado nesse arquivo
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import API from '@/axios'
import {
	ICreateReferenceGroupParams,
	ICreateReferenceParams,
	IDeleteReferenceGroupParams,
	IUpdateReferenceProps,
} from './types'
const EndpointModel = '/models'
const EndpointReference = '/references'
const EndpointExams = '/exams'

export default {
	async getReferences(examId) {
		return await API.get(EndpointModel + EndpointExams + `/${examId}` + EndpointReference).then(
			(response) => response
		)
	},

	async visualizeReference({ referenceId }) {
		return await API.get(EndpointModel + EndpointReference + `/${referenceId}`).then(
			(response) => response
		)
	},

	async getGroupsByReference(referenceId) {
		return await API.get(EndpointModel + EndpointReference + `/${referenceId}/groups`).then(
			(response) => response
		)
	},

	async createReference({ examId, payload }: ICreateReferenceParams) {
		return await API.post(
			EndpointModel + EndpointExams + `/${examId}` + EndpointReference,
			payload
		).then((response) => response)
	},

	async createReferenceGroup({ referenceId, payload }: ICreateReferenceGroupParams) {
		return await API.post(
			EndpointModel + EndpointReference + `/${referenceId}/groups`,
			payload
		).then((response) => response)
	},

	async createReferencedAttribute({ referenceId, payload }) {
		return await API.post(
			EndpointModel + EndpointReference + `/${referenceId}/referenced-attribute`,
			payload
		).then((response) => response)
	},

	async updateReference({ referenceId, payload }: IUpdateReferenceProps) {
		return await API.patch(EndpointModel + EndpointReference + `/${referenceId}`, payload).then(
			(response) => response
		)
	},

	async updateReferenceGroup({ groupId, payload }) {
		return await API.patch(
			EndpointModel + EndpointReference + `/groups/${groupId}`,
			payload
		).then((response) => response)
	},

	async updateReferenceValue({ referencedAttributeId, payload }) {
		return await API.patch(
			EndpointModel + EndpointReference + `/attribute/${referencedAttributeId}`,
			payload
		).then((response) => response)
	},

	async deleteReferenceGroup({ groupId, payload }: IDeleteReferenceGroupParams) {
		return await API.delete(EndpointModel + EndpointReference + `/groups/${groupId}`, {
			data: payload,
		}).then((response) => response)
	},

	async deleteGroupToAttribute({ fieldReferencedValueId }) {
		return await API.delete(
			EndpointModel + EndpointReference + `/attribute/${fieldReferencedValueId}`
		).then((response) => response)
	},

	async deleteReference(id) {
		return await API.delete(EndpointModel + EndpointReference + `/${id}`).then(
			(response) => response
		)
	},
}
