<template>
	<v-hover
		v-slot="{ hover }"
		style="
			position: absolute;
			z-index: 9998;
			right: -40px;
			top: 50%;
			-ms-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-webkit-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			display: block;
		"
	>
		<div style="margin-right: -45px" :style="{ 'margin-right': hover ? '-45px' : '-65px' }">
			<v-btn :class="color" width="200" @click="openFeedback">
				<span v-if="hover" class="fs-18">{{ $t('feedback.feedback') }}</span>
			</v-btn>
		</div>
	</v-hover>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
	name: 'FeedbackButton',

	props: {
		color: {
			type: String,
			default: 'primary darken-1',
		},
	},

	computed: {
		...mapState('Feedback', ['showFeedback']),
	},

	methods: {
		...mapMutations('Feedback', ['setShowFeedback']),

		openFeedback() {
			this.setShowFeedback(!this.showFeedback)
		},
	},
}
</script>

<style scoped lang="scss"></style>
