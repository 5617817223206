import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ReceivingState } from '../types'
import { Getters, getterTypes as types } from './getter-types'

export const getters: GetterTree<ReceivingState, RootState> & Getters = {
	[types.GET_INDEX_REQUEST]: (state) => {
		return (id) => {
			return state.requests.data.findIndex((req) => req.id === id)
		}
	},
	[types.GET_REQUEST_BY_ID]: (state) => {
		return (id) => {
			return state.requests.data.find((request) => request.id === id)
		}
	},
	[types.GET_REQUEST_INDEX_BY_REQUESTED_EXAM_ID]: (state) => {
		return (id) => {
			return state.requests.data.findIndex((request) =>
				request.requested_exams.some((exam: any) => exam.id === id)
			)
		}
	},
	[types.GET_PAYER_INDEX]: (state) => {
		return (id) => {
			return state.payers.findIndex((payer) => payer.id === id)
		}
	},
	[types.GET_INDEX_OBSERVATION]: (state) => {
		return (id) => {
			return state.observations.data.findIndex((obs) => obs.id === id)
		}
	},
	[types.GET_ID_OBSERVATION]: (state) => {
		return (id) => {
			return state.observations.data.find((obs) => obs.id === id)
		}
	},
	[types.GET_INDEX_EXAM]: (state) => {
		return (id) => {
			return state.requestedExams.data.findIndex((exam) => exam.id === id)
		}
	},
	[types.GET_ID_EXAM]: (state) => {
		return (id) => {
			return state.requestedExams.data.find((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_EXAM_GROUP]: (state) => {
		return (id) => {
			return state.requestedExamGroups.data.findIndex((examGroup) => examGroup.id === id)
		}
	},
	[types.GET_INDEX_EXAM_GROUP_REQUESTED_EXAM]: (state) => {
		return ({ indexExamGroup, idExam }) => {
			return state.requestedExamGroups.data[indexExamGroup]?.exams?.findIndex(
				(exam: any) => exam.id === idExam
			)
		}
	},
	[types.GET_ID_EXAM_GROUP]: (state) => {
		return (id) => {
			return state.requestedExamGroups.data.find((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_AVAILABLE_EXAM]: (state) => {
		return (id) => {
			return state.availableExams.data.findIndex((exam) => exam.id === id)
		}
	},
	[types.GET_ID_AVAILABLE_EXAM]: (state) => {
		return (id) => {
			return state.availableExams.data.find((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_AVAILABLE_EXAM_GROUPS]: (state) => {
		return (id) => {
			return state.availableExamGroups.data.findIndex((exam) => exam.id === id)
		}
	},
	[types.GET_ID_AVAILABLE_EXAM_GROUPS]: (state) => {
		return (id) => {
			return state.availableExamGroups.data.find((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_EXAMS]: (state) => {
		return (id) => {
			return state.exams.data.findIndex((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_REQUESTED_EXAMS]: (state) => {
		return (id) => {
			return state.requestedExams.data.findIndex((exam) => exam.id === id)
		}
	},
	[types.GET_INDEX_ATTACHMENT]: (state) => {
		return (uuid) => {
			return state.attachments.findIndex((attachment) => attachment.uuid === uuid)
		}
	},
	[types.GET_ID_ATTACHMENT]: (state) => {
		return (uuid) => {
			return state.attachments.find((attachment) => attachment.uuid === uuid)
		}
	},
	[types.GET_INDEX_SAMPLE]: (state) => {
		return (id) => {
			return state.samples.data.findIndex((sample) => sample.id === id)
		}
	},
	[types.GET_SAMPLE_BY_ID]: (state) => {
		return (id) => {
			return state.samples.data.find((sample) => sample.id === id)
		}
	},
	[types.GET_REQUEST_FILTERS_WITHOUT_NULL]: (state) => {
		const filtersWithoutNull = Object.fromEntries(
			Object.entries(state.filter.requests).filter(([key, value]) => {
				// Filtra as entradas que possuem valor null ou array vazio
				return value && !(Array.isArray(value) && value.length === 0)
			})
		)
		const payload: any = {}

		Object.entries(filtersWithoutNull).forEach((item) => {
			const key = item[0]
			const value = item[1]

			if (Array.isArray(value)) {
				if (!value.length) return
				payload[key] = value.join(',')
			} else {
				if (value !== null || value !== '') {
					payload[key] = value
				}
			}
		})
		return payload
	},
	[types.GET_INDEX_ITEM_CARD]: (state) => {
		return (id) => {
			return state.cartSelectItems.findIndex((item) => item.id === id)
		}
	},
}

export default getters
