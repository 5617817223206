import { Component, Vue } from 'vue-property-decorator'
import { IAttributeType } from '../store/attributes/types'
import { AttributeType, attributesType } from '@/utils/constants/examConstants'
import { ICreateAttributePayload } from '../services/attributes/types'

@Component
export default class GetDefaultAttributePayloadByTypeMixin extends Vue {
	get defaultAttributePayloads(): ICreateAttributePayload[] {
		return [
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.LONG_TEXT_TYPE,
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.INTEGER_TYPE,
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.DECIMAL_TYPE,
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.PERCENTAGE_TYPE,
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.MULTIPLE_CHOICE_TYPE,
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.IMAGE_TYPE,
				display_method: false,
				options: {
					width: 230,
					height: 160,
				},
				show_in_report: true,
				is_mandatory: true,
			},
			{
				name: `${this.$t('modules.exams.attributeDefaultName')}`,
				type: AttributeType.FORMULA_TYPE,
				formulas: [],
				display_method: false,
				show_in_report: true,
				is_mandatory: true,
			},
		]
	}

	attributeTypes: IAttributeType[] = attributesType

	getAttributeType({
		keyComparation,
		value,
	}: {
		keyComparation: 'id' | 'type'
		value: number | string
	}): IAttributeType | null {
		const attributeType = this.attributeTypes.find(
			(attributeType) => attributeType[keyComparation] === value
		)

		return attributeType || null
	}

	getDefaultAttributePayload({
		keyComparation,
		value,
	}: {
		keyComparation: 'id' | 'type'
		value: number | string
	}): ICreateAttributePayload | null {
		const attributeType = this.getAttributeType({ keyComparation, value })

		if (attributeType) {
			return (
				this.defaultAttributePayloads.find(
					(defaultAttributePayload) => defaultAttributePayload.type === attributeType.type
				) || null
			)
		}

		return null
	}
}
