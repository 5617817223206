import { IAnimalFilters } from './../store/types'
import { ITypeOrmPaginationTemplate } from './../../../utils/interfaces/typeormPagination'
import API from '@/axios'
export const EndpointAnimalV1 = '/animals/v1/animals'
export const EndpointAnimalV2 = '/animals/v2/animals'

import { AxiosResponse } from 'axios'
import { IAnimalResponse } from '../store/types'

export default {
	async getAnimals(
		filters: IAnimalFilters
	): Promise<AxiosResponse<ITypeOrmPaginationTemplate<IAnimalResponse>>> {
		return await API.get(EndpointAnimalV2, { params: filters })
	},

	async createAnimal(payload: FormData): Promise<AxiosResponse<IAnimalResponse>> {
		return await API.post(EndpointAnimalV1, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
	},

	async updateAnimal({
		animalId,
		payload,
	}: {
		animalId: number
		payload: FormData
	}): Promise<AxiosResponse<IAnimalResponse>> {
		return await API.put(EndpointAnimalV1 + `/${animalId}`, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
	},

	async removeAnimal(animalId: number): Promise<AxiosResponse<{ message: string }>> {
		return await API.delete(EndpointAnimalV1 + `/${animalId}`)
	},

	async unlinkAnimal({
		animalId,
		actorId,
	}: {
		animalId: number
		actorId: number
	}): Promise<AxiosResponse<{ message: string }>> {
		return await API.patch(EndpointAnimalV1 + `/${animalId}/remove-relations/${actorId}`)
	},
}
