import { MutationTree } from 'vuex'
import { ReceivingState } from '../types'
import { Mutations, mutationTypes as types } from './mutation-types'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'
import { REQUEST_CANCELED_STATUS_ID } from '@/utils/constants/requestConstants'
import { removedArrayDuplicate } from '@/utils/functions/removedArrayDuplicate'

export const mutations: MutationTree<ReceivingState> & Mutations = {
	// Requests
	[types.ADD_REQUEST]: (state, payload) => state.requests.data.push(payload),

	[types.SET_REQUESTS]: (state, payload) => {
		const requests = [...state.requests.data, ...payload.data]

		const uniq = [...new Set(requests.map(({ id }) => id))].map((requestId) =>
			requests.find(({ id }) => id == requestId)
		)

		state.requests = Object.assign({}, payload)
		state.requests.data = [...uniq]
	},

	[types.SET_FILTER]: (state, { type, payload }) => {
		payload.search === '' ? (payload.search = null) : null

		switch (type) {
			case 'requests': {
				state.filter.requests = payload
				return state.filter.requests
			}

			case 'exams': {
				state.filter.exams = payload
				return state.filter.exams
			}
		}
	},

	[types.UPDATE_REQUESTS]: (state, { index, request }) => {
		state.requests.data.splice(index, 1, { ...state.requests.data[index], ...request })
	},

	[types.SET_PAYERS]: (state, payload) => {
		state.payers = payload
	},

	[types.SET_SELECTED_REQUEST]: (state, payload) => {
		state.selectedRequest = payload
	},

	[types.SET_REQUEST_STATUS]: (state, payload) => {
		state.statusRequest = payload
	},

	[types.SET_EXAM_STATUS]: (state, payload) => {
		state.statusExams = payload
	},

	// Actors
	[types.SET_SELECTED_CLINIC]: (state, payload) => (state.selectedClinic = payload),

	[types.SET_SELECTED_DOCTOR]: (state, payload) => (state.selectedDoctor = payload),

	[types.SET_SELECTED_PET_OWNER]: (state, payload) => (state.selectedPetOwner = payload),

	[types.SET_SELECTED_PATIENT]: (state, payload) => (state.selectedPatient = payload),

	[types.SET_OBSERVATIONS]: (state, payload) => {
		// const data = [...state.observations.data, ...payload.data]

		state.observations = Object.assign({}, payload)
	},

	[types.ADD_OBSERVATION]: (state, payload) => state.observations.data.push(payload),

	[types.UPDATE_OBSERVATION]: (state, { index, observation }) =>
		state.observations.data.splice(index, 1, observation),

	[types.DELETE_OBSERVATION]: (state, index) => state.observations.data.splice(index, 1),

	[types.SET_AVAILABLE_EXAMS]: (state, payload) => {
		// const data = [...state.availableExams.data, ...payload.data]

		state.availableExams = Object.assign({}, payload)
	},

	[types.UPDATE_LOADING_AVAILABLE_EXAMS]: (state, loadingState) => {
		state.loadingAvailableExams = loadingState
	},

	[types.SET_AVAILABLE_EXAM_GROUPS]: (state, payload) => {
		// const data = [...state.availableExamGroups.data, ...payload.data]

		state.availableExamGroups = Object.assign({}, payload)
	},

	[types.SET_REQUESTED_EXAMS]: (state, payload) => {
		// const data = [...state.requestedExams.data, ...payload.data]

		state.requestedExams.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.requestedExams.data,
			newArray: payload.data,
			keyToCompare: 'id',
			keyDateLatest: 'updated_at',
		})

		state.requestedExams.meta = payload.meta
		state.requestedExams.links = payload.links
	},

	[types.ADD_REQUESTED_EXAMS]: (state, exams) => {
		const data: any[] = []

		exams.data.forEach((exam: any) => {
			if (!exam.exam_group) {
				data.push(Object.assign({}, exam))
			}
		})

		state.requestedExams = Object.assign(exams, { data: data })
	},

	[types.UPDATE_REQUESTED_EXAM]: (state, { index, exam }) =>
		state.requestedExams.data.splice(index, 1, exam),

	[types.DELETE_REQUESTED_EXAM]: (state, index) => state.requestedExams.data.splice(index, 1),

	[types.SET_REQUESTED_EXAM_GROUPS]: (state, payload) => {
		// let data = [...state.requestedExamGroups.data, ...payload.data]

		state.requestedExamGroups = Object.assign({}, payload)
	},

	[types.ADD_REQUESTED_EXAM_GROUPS]: (state, exams) => {
		exams.forEach((exam: any) => {
			if (exam.exam_group) {
				state.requestedExamGroups.data.push(Object.assign({}, exam))
			}
		})

		state.requestedExamGroups = Object.assign(exams, {
			data: state.requestedExamGroups.data,
		})
	},

	[types.DELETE_REQUESTED_EXAM_GROUP]: (state, index) =>
		state.requestedExamGroups.data.splice(index, 1),

	[types.RESET_ALL]: (state) => {
		state.load = false
		state.selectedRequest = {}
		state.payers = []
		state.requests = { data: [], links: {}, meta: {} }
		state.requestStatus = []
		state.selectedClinic = {}
		state.selectedDoctor = {}
		state.selectedPatient = {}
		state.observations = { data: [], links: {}, meta: {} }
		state.requestedExams = { data: [], links: {}, meta: {}, currentPage: 1, perPage: 20 }
		state.requestedExamGroups = { data: [], links: {}, meta: {} }
		state.availableExams = { data: [], links: {}, meta: {} }
		state.availableExamGroups = { data: [], links: {}, meta: {} }
		state.exams = { data: [], links: {}, meta: {} }
		state.attachments = []
		state.samples = { data: [], links: {}, meta: {} }
	},

	[types.RESET_SELECTED]: (state) => {
		state.selectedRequest = {}
		state.payers = []
		state.requestStatus = []
		state.selectedClinic = {}
		state.selectedDoctor = {}
		state.selectedPatient = {}
		state.observations = { data: [], links: {}, meta: {} }
		state.requestedExams = { data: [], links: {}, meta: {}, currentPage: 1, perPage: 20 }
		state.requestedExamGroups = { data: [], links: {}, meta: {} }
		state.availableExams = { data: [], links: {}, meta: {} }
		state.availableExamGroups = { data: [], links: {}, meta: {} }
		state.exams = { data: [], links: {}, meta: {} }
		state.attachments = []
		state.samples = { data: [], links: {}, meta: {} }
	},

	[types.RESET_REQUESTS]: (state) => {
		state.requests = { data: [], links: {}, meta: {} }
	},

	[types.RESET_EXAMS]: (state) => {
		state.exams = { data: [], links: {}, meta: {} }
	},

	[types.SET_EXAMS]: (state, payload) => {
		const exams = [...state.exams.data, ...payload.data]

		const uniq = [...new Set(exams.map(({ id }) => id))].map((examId) =>
			exams.find(({ id }) => id == examId)
		)

		state.exams = Object.assign({}, payload)
		state.exams.data = [...uniq]
	},

	[types.UPDATE_EXAMS]: (state, { index, exam }) => {
		state.exams.data.splice(index, 1, exam)
	},

	[types.SET_ATTACHMENTS]: (state, payload) => (state.attachments = payload),

	[types.ADD_ATTACHMENT]: (state, payload) => state.attachments.push(payload),

	[types.REMOVE_ATTACHMENT]: (state, index) => state.attachments.splice(index, 1),

	[types.SET_FORMS]: (state, forms) => (state.forms = forms),

	[types.SET_SAMPLE_STATUS_LIST]: (state, payload) => (state.sampleStatus = payload),

	[types.SET_SAMPLES]: (state, payload) => (state.samples = payload),

	[types.ADD_SAMPLE]: (state, payload) => state.samples.data.push(payload),

	[types.UPDATE_SAMPLE]: (state, { index, sample }) =>
		state.samples.data.splice(index, 1, sample),

	[types.REMOVE_SAMPLE]: (state, index) => state.samples.data.splice(index, 1),

	[types.SET_EXAM_DELETED_AT]: (state, index) => {
		state.exams.data[index].deleted_at = true
	},

	[types.SET_EXAM_PRIORITY]: (state, { index, priority }) =>
		(state.exams.data[index].priority = priority),

	[types.SET_EXAM_STATUS_CANCELED]: (state, { index, status }) =>
		(state.exams.data[index].status = status),

	[types.UPDATE_REQUESTED_EXAM_STATUS]: (state, { index, status }) =>
		(state.requestedExams.data[index].status = status),

	[types.UPDATE_REQUESTED_EXAM_PRIORITY]: (state, { index, priority }) =>
		(state.requestedExams.data[index].priority = priority),

	[types.UPDATE_REQUESTED_EXAM_DELETED_AT]: (state, { index, deleted_at }) => {
		state.requestedExams.data[index].deleted_at = deleted_at
	},

	[types.SET_LOADING_RECEIVING_SUBHEADER]: (state, boolean) => {
		state.loadingReceivingSubHeader = boolean
	},

	[types.SET_REQUEST_CANCELED]: (state, index) => {
		state.requests.data[index].status = state.statusRequest.find(
			(statusOld) => statusOld.id === REQUEST_CANCELED_STATUS_ID
		)
	},

	[types.RESET_ALL_REQUESTED_EXAMS]: (state) => {
		state.allRequestedsExams.data = []
	},

	[types.UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM]: (
		state,
		{ indexExamGroup, indexExam, status }
	) => {
		state.requestedExamGroups.data[indexExamGroup].exams[indexExam].status = status
	},

	[types.UPDATE_PRIORITY_REQUESTED_EXAM_GROUPS_EXAM]: (
		state,
		{ indexExamGroup, indexExam, priority }
	) => {
		state.requestedExamGroups.data[indexExamGroup].exams[indexExam].priority = priority
	},

	[types.SET_ALL_REQUESTEDS_EXAMS]: (state, requestedExams) => {
		state.allRequestedsExams.meta = requestedExams.meta
		state.allRequestedsExams.links = requestedExams.links

		if (!state.allRequestedsExams.data.length) {
			state.allRequestedsExams.data = requestedExams.data
		} else {
			const newAllRequestedsExams = [...state.allRequestedsExams.data, ...requestedExams.data]

			const allRequestedsExamsRemovedDuplicate = newAllRequestedsExams.reduce(
				(prevAllRequestedsExam, currentAllRequestedsExam) => {
					const foundItemDuplicate = prevAllRequestedsExam.find(
						(item: any) => item.id === currentAllRequestedsExam.id
					)

					if (!foundItemDuplicate) {
						return prevAllRequestedsExam.concat([currentAllRequestedsExam])
					} else {
						return prevAllRequestedsExam
					}
				},
				[]
			)

			state.allRequestedsExams.data = allRequestedsExamsRemovedDuplicate
		}
	},

	[types.UPDATE_PAGE_REQUESTED_EXAMS]: (state, { page, perPage = 70 }) => {
		state.requestedExams.currentPage = page
		state.requestedExams.perPage = perPage
	},

	[types.ADD_CART_SELECT_ITEM]: (state, { item }) => {
		state.cartSelectItems.push(item)
	},

	[types.ADD_CART_ALL_SELECT_ITEMS]: (state, { items }) => {
		state.cartSelectItems = removedArrayDuplicate({
			arrayLoaded: state.cartSelectItems,
			newArray: items,
			keyToCompare: 'uniqueKey',
		})
	},

	[types.REMOVE_CART_ALL_ITEMS]: (state) => {
		state.cartSelectItems = []
	},

	[types.REMOVE_CART_SELECT_ITEM]: (state, { index }) => {
		state.cartSelectItems.splice(index, 1)
	},

	[types.SET_SAMPLE_PRINT_CONFIG]: (state, config) => {
		state.samplePrintConfig = config
	},

	[types.UPDATE_IS_ERROR_GENERATE_BARCODE]: (state, value) => {
		state.isErrorGenerateBarcode = value
	},

	[types.UPDATE_LOADING_REQUESTED_EXAMS]: (state, value) => {
		state.loadingRequestedExams = value
	},

	[types.UPDATE_LOADING_STAKEHOLDER_SELECTION]: (state, value) => {
		state.loadingStakeholderSelection = value
	},
}

export default { mutations }
