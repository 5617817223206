import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { actionTypes, IMappedExamField } from '@/modules/exams/store/attributes/types'
import { IUpdateAttributeMappedFieldParams } from '@/modules/exams/services/attributes/types'
import { AxiosResponse } from 'axios'
import { IResponseExam } from '../services/types'

@Component
export default class UpdateAttributeMappedFieldMixin extends Vue {
	@State((state) => state.Exams.selectedExam) selectedExam!: IResponseExam

	updateAttributeMappedFieldDebouncing: NodeJS.Timeout | null = null

	@Action(`Exams/${actionTypes.UPDATE_ATTRIBUTE_MAPPED_FIELD}`)
	updateAttributeMappedField!: ({
		mappedFieldId,
		payload,
	}: IUpdateAttributeMappedFieldParams) => Promise<AxiosResponse<IMappedExamField | boolean>>

	async handleUpdateAttributeMappedField({
		payload,
		mappedFieldId,
	}: IUpdateAttributeMappedFieldParams) {
		if (!this.selectedExam) throw new Error('Exam not loaded')

		try {
			if (this.updateAttributeMappedFieldDebouncing)
				clearTimeout(this.updateAttributeMappedFieldDebouncing)
			this.updateAttributeMappedFieldDebouncing = setTimeout(() => {
				this.updateAttributeMappedField({ mappedFieldId, payload })
			}, 2000)
		} catch {
			throw new Error('Error on update attribute')
		}
	}
}
