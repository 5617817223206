

















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import ExamHeaderAttributes from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/Attributes/ExamHeaderAttributes.vue'
import ExamAttributeGroup from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/Attributes/ExamAttributeGroup.vue'
import ItemsNotFound from '@/components/atomic/molecules/ItemsNotFound.vue'
import CreateAttributeAndAttributeGroup from '@/modules/exams/mixins/CreateAttributeAndAttributeGroupMixin'
import UpdateAttributePositionsMixin from '@/modules/exams/mixins/UpdateAttributePositionsMixin'
import Draggable from 'vuedraggable'
import InsertionResultLoader from '@/components/global/SkeletonLoader/InsertionResultLoader.vue'

@Component({
	name: 'ExamAttributeFactory',
	components: {
		ExamHeaderAttributes,
		ExamAttributeGroup,
		ItemsNotFound,
		Draggable,
		InsertionResultLoader,
	},
	mixins: [CreateAttributeAndAttributeGroup, UpdateAttributePositionsMixin],
})
export default class ExamAttributeFactory extends Vue {
	@State((state) => state.Exams.attributeIsSaving) attributeIsSaving!: boolean
	@State((state) => state.Exams.attributeIsLoading) attributeIsLoading!: boolean

	isInitialLoading = true

	@Watch('attributeIsLoading') handleAttributeIsLoadingChange() {
		if (!this.attributeIsLoading && this.isInitialLoading) {
			this.isInitialLoading = false
		}
	}
}
