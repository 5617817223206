import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types'

export default {
	namespaced: true,

	state: {
		showFeedback: false,
	},

	mutations: {
		setShowFeedback: (state, boolean) => (state.showFeedback = boolean),
	},

	actions: {
		async createClickupTask({ commit }, { payload }) {
			try {
				return await API.createClickupTask({ payload })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createClickupTaskAttachment({ commit }, { id, payload }) {
			try {
				return await API.createClickupTaskAttachment({ id, payload })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
