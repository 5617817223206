import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		carregado: false,
		addressLoad: false,
		telefones: [],
		addresses: [],
		addressesOrganization: [],
		selectedAddress: [],
		selecionado: [],
		busca: null,
		buscando: [],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.telefones.findIndex((telefone) => telefone.id === id)
			}
		},

		getPrincipal: (state) => {
			return (id) => {
				return state.telefones.find((telefone) => telefone.id !== id && telefone.principal)
			}
		},

		getIndexAddress: (state) => {
			return (keyName, id) => {
				return state[keyName].findIndex((address) => address.id === id)
			}
		},

		getPrincipalAddress: (state) => {
			return (id) => {
				return state.addressesOrganization.find(
					(address) => address.id !== id && address.principal
				)
			}
		},
	},

	mutations: {
		setTelefones: (state, payload) => {
			state.telefones = payload
			state.carregado = true
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),

		addTelefone: (state, payload) => state.telefones.push(payload),

		updateTelefone: (state, { index, payload }) => state.telefones.splice(index, 1, payload),

		deleteTelefone: (state, index) => state.telefones.splice(index, 1),

		setAddresses: (state, { addresses, keyName }) => (state[keyName] = addresses),

		setSelected: (state, payload) => (state.selectedAddress = payload),

		addAddress: (state, { payload, keyName }) => state[keyName].push(payload),

		updateAddress: (state, { index, payload, keyName }) =>
			state[keyName].splice(index, 1, payload),

		deleteAddress: (state, { index, keyName }) => state[keyName].splice(index, 1),

		removeDefaultAddress: (state, { id, keyName }) => {
			state[keyName].forEach((address) => {
				if (address.id !== id) {
					address.principal = false
				}
			})
		},

		setMainAddress: (state, { id, keyName }) => {
			state[keyName].forEach((address) => {
				if (address.id === id) {
					address.principal = true
				} else {
					address.principal = false
				}
			})
		},
	},

	actions: {
		async getAll({ commit, state }, organizacao_id) {
			try {
				const telefones = await API.getTelefones(organizacao_id)

				commit('setTelefones', telefones)
				return state.telefones
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createTelefone({ commit }, { payload, organizacao_id }) {
			try {
				const response = await API.criarTelefone(payload, organizacao_id)

				if (response) {
					commit('addTelefone', response.telefone)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.account.phone.feedback.success.create.phone') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateTelefone({ commit, getters }, { payload, organizacao_id }) {
			try {
				const id = payload.id
				const { telefone } = await API.editarTelefone(payload, id, organizacao_id)

				if (telefone) {
					const index = getters.getIndex(id)

					commit('updateTelefone', { index, payload: telefone })

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.account.phone.feedback.success.update.phone') },
						{ root: true }
					)
				}

				return telefone
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteTelefone({ commit, getters }, { id, organizacao_id }) {
			try {
				const response = await API.excluirTelefone(id, organizacao_id)
				const successStatus = 200

				if (response.status === successStatus) {
					const index = getters.getIndex(id)

					commit('deleteTelefone', index)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.account.phone.feedback.success.remove.phone') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllAddresses({ commit, state }, organizacao_id) {
			try {
				const stateKeyName = organizacao_id ? 'addressesOrganization' : 'addresses'
				const { status, data: addresses } = await API.getAddresses(organizacao_id)

				if (status === STATUS_SUCCESS) {
					commit('setAddresses', { addresses, keyName: stateKeyName })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAddress({ commit }, { payload, organization_id }) {
			try {
				const stateKeyName = organization_id ? 'addressesOrganization' : 'addresses'

				const {
					status,
					data: { endereco: address },
				} = await API.createAddress(payload, organization_id)

				if (status === STATUS_SUCCESS) {
					commit('addAddress', { payload: address, keyName: stateKeyName })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateAddress({ commit, getters }, { payload, organization_id }) {
			try {
				const stateKeyName = organization_id ? 'addressesOrganization' : 'addresses'
				const id = payload.id
				const {
					status,
					data: { endereco: address },
				} = await API.updateAddress(payload, id, organization_id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndexAddress(stateKeyName, id)

					commit('updateAddress', { index, payload: address, keyName: stateKeyName })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateMainAddress({ commit, getters }, { id, organization_id }) {
			try {
				const stateKeyName = organization_id ? 'addressesOrganization' : 'addresses'
				const { status } = await API.updateMainAddress(id, organization_id)

				if (status === STATUS_SUCCESS) {
					commit('setMainAddress', { id, keyName: stateKeyName })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteAddress({ commit, getters }, { id, organization_id }) {
			try {
				const stateKeyName = organization_id ? 'addressesOrganization' : 'addresses'
				const { status } = await API.removeAddress(id, organization_id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndexAddress(stateKeyName, id)

					if (index > -1) {
						commit('deleteAddress', { index, keyName: stateKeyName })
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		setAddressDefault({ dispatch, commit }, { payload, organization_id }) {
			const stateKeyName = organization_id ? 'addressesOrganization' : 'addresses'

			dispatch('updateAddress', { payload, organization_id })
			commit('removeDefaultAddress', { id: payload.id, keyName: stateKeyName })
		},
	},
}
