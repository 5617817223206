import {
	ATTRIBUTE_DECIMAL_ID,
	ATTRIBUTE_INTEGER_ID,
	DECIMAL_TYPE,
	INTEGER_TYPE,
} from '@/utils/constants/examConstants'

function isAttributeIntegerOrDecimalPerId(attributeId) {
	return attributeId === ATTRIBUTE_INTEGER_ID || attributeId === ATTRIBUTE_DECIMAL_ID
}

function isAttributeIntegerOrDecimalPerType(typeAttribute) {
	return typeAttribute === INTEGER_TYPE || typeAttribute === DECIMAL_TYPE
}

export { isAttributeIntegerOrDecimalPerId, isAttributeIntegerOrDecimalPerType }
