export const STATUS_SUCCESS = 200
export const STATUS_CREATED = 201
export const STATUS_BAD_REQUEST = 400
export const STATUS_NOT_FOUND = 404
export const STATUS_UNAUTHORIZED = 401

export const GROUP_DEFAULT_STATUS = [
	{ id: 1, slug: 'notStarted' },
	{ id: 2, slug: 'started' },
	{ id: 3, slug: 'concluded' },
]
