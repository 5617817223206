import API from '@/axios'
export const Endpoint = '/auth/me/assinatura'
import { AxiosResponse } from 'axios'

export default {
	async updateSignature(payload: FormData): Promise<AxiosResponse<any>> {
		return await API.post(Endpoint, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
	},

	async removeSignature(): Promise<AxiosResponse<any>> {
		return await API.delete(Endpoint)
	},
}
