import { MutationTree } from 'vuex'
import { state as stateDefault } from './index'
import {
	AuthenticationState,
	ILogInResponse,
	IPermissions,
	IUser,
	mutationTypes as types,
} from './types'

export const mutations: MutationTree<AuthenticationState> = {
	[types.SET_IS_LOGGED_IN](state, loggedInState: boolean) {
		state.isLoggedIn = loggedInState
	},

	[types.SET_UPDATE_LOGGED_STATE](state, authResponse: ILogInResponse) {
		state.token = authResponse.token
		state.loggedUser = authResponse.usuario
	},

	[types.SET_RESET_LOGOUT](state) {
		state.isLoggedIn = false
		state.loggedUser = undefined
		state.permissions = stateDefault.permissions
		state.token = stateDefault.token
	},

	[types.SET_PERMISSIONS](state, permissions: IPermissions[]) {
		state.permissions = permissions
	},

	[types.SET_LOGGED_USER](state, user: IUser) {
		state.loggedUser = user
	},
}

export default { mutations }
