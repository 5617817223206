

















import { Component, VModel, Mixins, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { IAttribute, ISelectedAttribute } from '@/modules/exams/store/attributes/types'
import HandleWithSearchResultsMixin from '@/modules/exams/mixins/HandleWithSearchResultsMixin'
import ClassicExamTextEditor from '@/components/global/TextEditorV2/ClassicExamTextEditor.vue'
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'

@Component({
	name: 'ExamAttributeTypeText',
	components: {
		ClassicExamTextEditor,
	},
})
export default class ExamAttributeTypeText extends Mixins(HandleWithSearchResultsMixin) {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	editor: ClassicEditor | null = null

	readonly = false

	id: null | number = null

	@State((state) => state.Exams.selectedAttribute) selectedAttribute!: ISelectedAttribute

	@Watch('selectedResultText')
	handleSelectedResultTextChange(text: string) {
		if (this.selectedResultText && this.selectedAttribute?.id === this.attribute.id) {
			const content = `${this.attribute.default_value || ''} ${text || ''}`

			if (this.editor) {
				this.editor.setData(content)
				this.setSeletedResultText('')
			}
		}
	}

	created() {
		this.handleResetForm()

		this.id = Math.floor(Math.random() * (999 - 10)) + 50
	}

	onReady(editor: ClassicEditor): void {
		this.editor = editor
	}

	handleResetForm() {
		this.attribute.default_value = this.attribute.default_value || ''
	}
}
