import { RootState } from '@/store/types'
import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'
import { Actions } from './actions/action-types'
import { actions } from './actions/actions'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { mutations } from './mutations/mutations'
import { SignatureState } from './types'

export const state: SignatureState = {
	signature: null,
}

export type Store<S = SignatureState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}

export const SIGNATURE_NAMESPACE = 'Signature'

export const signature: Module<SignatureState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: actions,
	mutations: mutations,
}
