import { render, staticRenderFns } from "./ExamHeaderAttributes.vue?vue&type=template&id=67e3c381&scoped=true&"
import script from "./ExamHeaderAttributes.vue?vue&type=script&lang=ts&"
export * from "./ExamHeaderAttributes.vue?vue&type=script&lang=ts&"
import style0 from "./ExamHeaderAttributes.vue?vue&type=style&index=0&id=67e3c381&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e3c381",
  null
  
)

export default component.exports