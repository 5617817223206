import examsRouter from '@/modules/exams/router'
import examGroupsRouter from '@/modules/examGroups/router'

const metaConfig = { config: true, auth: true }

const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: 'config' */ './views/RouterIndexConfig'),
		name: 'config.index',
		meta: metaConfig,
		children: [
			{
				path: '/my-clustervet',
				component: () =>
					import(/* webpackChunkName: 'config' */ './views/MyClusterVet.vue'),
				name: 'config.myhubvet',
				meta: metaConfig,
			},
			{
				path: '/settings/user/profile',
				component: () =>
					import(/* webpackChunkName: 'profile' */ './views/user/MeuPerfil.vue'),
				name: 'config.usuario.perfil',
				meta: metaConfig,
			},
			{
				path: '/settings/user/preferences',
				component: () =>
					import(/* webpackChunkName: 'profile' */ './views/user/Preferencias.vue'),
				name: 'config.usuario.preferencias',
				meta: metaConfig,
			},
			{
				path: '/settings/organizations',
				component: () =>
					import(/* webpackChunkName: 'organizations' */ './views/user/Organizacoes.vue'),
				name: 'config.organizacoes',
				meta: metaConfig,
			},
			{
				path: '/settings/organizations/:uuid/edit',
				component: () =>
					import(
						/* webpackChunkName: 'organizations' */
						'../modules/organizacoes/components/EditarOrganizacao.vue'
					),
				name: 'config.organizacoes.editar',
				meta: metaConfig,
			},
			{
				path: '/settings/users',
				component: () => import('../modules/usuarios/views/index.vue'),
				name: 'config.usuarios',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Users' }] },
			},
			{
				path: '/settings/attributes',
				component: () => import('../modules/attributes/views/Attributes.vue'),
				name: 'config.attributes',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Attribute' }] },
			},
			{
				path: '/settings/import',
				component: () => import('./views/Importacao.vue'),
				name: 'config.importar',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Import' }] },
			},

			{
				path: '/settings/agreement',
				component: () =>
					import(
						/* webpackChunkName: 'agreement' */ '@/modules/agreements/views/Agreements.vue'
					),
				name: 'config.convenio',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Agreement' }] },
			},

			{
				path: '/settings/species',
				component: () => import('@/modules/species/views/SpeciesIndex.vue'),
				name: 'config.especies',
				meta: metaConfig,
			},
			{
				path: '/settings/breeds',
				component: () => import('@/modules/breeds/views/BreedsIndex.vue'),
				name: 'config.racas',
				meta: metaConfig,
			},
			{
				path: '/settings/coat',
				component: () => import('./views/Coats.vue'),
				name: 'config.pelagens',
				meta: metaConfig,
			},
			{
				path: '/settings/diseases',
				component: () => import('./views/Patologias.vue'),
				name: 'config.patologias',
				meta: metaConfig,
			},
			{
				path: '/settings/recipient',
				component: () => import('./views/Recipientes.vue'),
				name: 'config.recipientes',
				meta: metaConfig,
			},
			{
				path: '/settings/tags',
				component: () => import('./views/Tags.vue'),
				name: 'config.rotulos',
				meta: metaConfig,
			},
			{
				path: '/settings/methods',
				component: () => import('@/modules/methods/views/MethodIndex'),
				name: 'config.methods',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Method' }] },
			},
			{
				path: '/settings/equipments',
				component: () => import('@/modules/equipments/views/EquipmentIndex'),
				name: 'config.equipments',
				meta: metaConfig,
			},
			{
				path: '/settings/materials',
				component: () => import('../modules/materiais/views/Materiais.vue'),
				name: 'config.materiais',
				meta: metaConfig,
			},
			{
				path: '/settings/categories',
				component: () => import('./views/Categorias.vue'),
				name: 'config.categorias',
				meta: metaConfig,
			},
			// {
			// 	path: '/configuracoes/referencia-exames',
			// 	component: () => import('./views/Referencias'),
			// 	name: 'config.referencia',
			// 	meta: metaConfig,
			// },
			// {
			// 	path: '/configuracoes/referencia-exames/novo',
			// 	component: () => import('@/modules/referencias/components/AdicionarReferencia'),
			// 	name: 'adicionar.referencia',
			// 	meta: metaConfig,
			// },
			// {
			// 	path: '/configuracoes/referencia-exames/editar',
			// 	component: () => import('@/modules/referencias/components/EditarReferencia'),
			// 	name: 'editar.referencia',
			// 	meta: metaConfig,
			// },
			{
				path: '/settings/forms',
				component: () => import('@/modules/forms/views/Forms.vue'),
				name: 'config.forms',
				meta: { ...metaConfig, permissions: [{ action: 'read', resource: 'Form' }] },
			},
			{
				path: '/settings/integrations/apikey',
				component: () => import('@/modules/apikey/views/ApiKeyIndex'),
				name: 'config.apikey',
				meta: metaConfig,
			},
			{
				path: '/settings/integrations/webhooks',
				component: () => import('@/modules/webhooks/views/WebhookIndex'),
				name: 'config.webhooks',
				meta: metaConfig,
			},
			...examsRouter,
			...examGroupsRouter,
		],
	},
]

export default routes
