import API from '@/axios'

const Endpoint = '/requisicoes'
const orders = '/orders'
const EndpointSearch = '/search/'
const status = '/status/'
const EndpointAttachments = '/attachments'

export default {
	async getOrders(page) {
		return await API.get(Endpoint + orders + '/?page=' + page).then((response) => response.data)
	},

	async searchOrders({
		page = 1,
		perPage = 25,
		search,
		orderStatus,
		createdAfter,
		createdBefore,
		examRequestStatus,
		examRequestPriority,
		orderBy,
		orderDirection,
		orderCustomerId,
		customerId,
		orderPriority,
		deleted,
	}) {
		return await API.get(Endpoint + orders + EndpointSearch, {
			params: {
				page,
				perPage,
				search,
				orderStatus,
				createdAfter,
				createdBefore,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				orderDirection,
				orderCustomerId,
				customerId,
				orderPriority,
				deleted,
			},
		}).then((response) => response.data)
	},

	async getOrder(id) {
		return await API.get(Endpoint + orders + '/' + id).then((response) => response.data)
	},

	async createOrder(payload) {
		return await API.post(Endpoint + orders, payload).then((response) => response.data)
	},

	async updateOrder(id, payload) {
		return await API.patch(Endpoint + orders + '/' + id, payload).then(
			(response) => response.data
		)
	},

	async updateOrderStatus(id, payload) {
		return await API.patch(Endpoint + orders + '/' + id + status, payload).then(
			(response) => response.data
		)
	},

	async deleteOrder(id, payload) {
		return await API.delete(Endpoint + orders + '/' + id, { data: payload }).then(
			(response) => response
		)
	},

	async getAttachmentsOrder(id_order) {
		return await API.get(Endpoint + orders + '/' + id_order + EndpointAttachments).then(
			(response) => response.data
		)
	},

	async createAttachment(payload) {
		return await API.post(Endpoint + EndpointAttachments, payload).then(
			(response) => response.data
		)
	},

	async removeAttachment(uuid) {
		return await API.delete(Endpoint + EndpointAttachments + `/${uuid}`).then(
			(response) => response
		)
	},

	async downloadAttachment(uuid) {
		return await API.get(Endpoint + EndpointAttachments + `/${uuid}` + '/download').then(
			(response) => response.data
		)
	},

	async getStatusList() {
		return await API.get(Endpoint + orders + status).then((response) => response.data)
	},

	async checkDraftOrderRequestsForPatient({ orderId, patientId }) {
		return await API.get(Endpoint + orders + `/${orderId}/pacient/${patientId}`).then(
			(response) => response
		)
	},

	async checkDraftOrderRequests({ orderId }) {
		return await API.get(Endpoint + orders + `/${orderId}/draft`).then((response) => response)
	},

	async getItemsBySearch({ orderId, search }) {
		return await API.get(Endpoint + orders + `/${orderId}/data`, {
			params: { search: search ? search : undefined },
		}).then((response) => response)
	},
}
