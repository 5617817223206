import { Component, InjectReactive, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { IAttribute, IAttributeGroup } from '../store/attributes/types'

@Component
export default class GetAttributeNumbersToSelectMixin extends Vue {
	@InjectReactive('attributeGroup') attributeGroup!: IAttributeGroup

	@Getter('Exams/getNumberTypeAttributesByGroupId') getNumberTypeAttributesByGroupId!: (
		groupId: number,
		exceptId?: number
	) => IAttribute[]

	get attributesToSelect(): (id?: number) => IAttribute<any>[] {
		return (id?: number) => this.getNumberTypeAttributesByGroupId(this.attributeGroup.id, id)
	}
}
