import API from '../service'
import APIResultExam from '@/modules/insertionResult/service'
import { types as errorTypes } from '@/modules/errors/store/types'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		versionSelected: null,
		loading: false,
		loadingSave: false,
		hasCreatedVersion: false,
		loadingVersionSelected: false,
		groupReportSelected: null,
	},

	getters: {
		getIndexLatestExamResults: (state) => {
			return (id) => {
				return state.versionSelected.latest_exam_results.findIndex(
					(version) => version.requested_exam.id === id
				)
			}
		},

		getIndexGroupAttribute: (state) => {
			return ({ indexExam, position }) => {
				return state.versionSelected.latest_exam_results[
					indexExam
				].result_data.attribute_groups.findIndex(
					(attribute) => attribute.position === position
				)
			}
		},

		getIndexAttribute: (state) => {
			return ({ indexExam, indexGroupAttribute, uuidAttribute }) => {
				return state.versionSelected.latest_exam_results[
					indexExam
				].result_data.attribute_groups[indexGroupAttribute].attributes.findIndex(
					(attribute) => attribute.uuid === uuidAttribute
				)
			}
		},
	},

	mutations: {
		setVersionSelected: (state, version) => (state.versionSelected = version),

		setVersionExamUpdate: (state, { indexExam, indexGroup, indexAttribute, resultValue }) => {
			const attributeNewInstance = Object.assign(
				{},
				state.versionSelected.latest_exam_results[indexExam].result_data.attribute_groups[
					indexGroup
				].attributes[indexAttribute]
			)

			console.log(attributeNewInstance)
			const attribute = Object.assign(attributeNewInstance, { value: resultValue })

			state.versionSelected.latest_exam_results[indexExam].result_data.attribute_groups[
				indexGroup
			].attributes.splice(indexAttribute, 1, attribute)
		},

		setLoading: (state, boolean) => (state.loading = boolean),

		setLoadingVersionSelected: (state, boolean) => (state.loadingVersionSelected = boolean),

		setGroupReportSelected: (state, payload) => (state.groupReportSelected = payload),

		resetHasCreatedVersion: (state) => (state.hasCreatedVersion = false),

		resetAll: (state) => {
			state.loadingSave = false
			state.loading = false
			state.loadingVersionSelected = false
			state.hasCreatedVersion = false
			state.versionSelected = null
			state.groupReportSelected = null
		},
	},

	actions: {
		async visualizeLatestVersion({ commit }, { requestId, examGroupId }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const { status, data: versionLatestExamGroup } = await API.visualizeExamGroup(
					requestId,
					examGroupId
				)

				if (status === STATUS_SUCCESS) {
					commit('setVersionSelected', versionLatestExamGroup)
					commit('setLoading', DISABLE_LOADING)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				commit('setLoading', DISABLE_LOADING)
			}
		},

		async createNewVersion(
			{ state, commit, getters },
			{ id_exam, payload, positionGroupAttribute, uuidAttribute }
		) {
			try {
				state.loadingSave = true
				const version = await APIResultExam.createNewVersion(id_exam, payload)

				if (version) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.insertResult.feedback.success.create.version') },
						{ root: true }
					)
					state.hasCreatedVersion = true

					const indexExamInExamGroup = getters.getIndexLatestExamResults(id_exam)

					if (indexExamInExamGroup > -1) {
						const indexGroup = getters.getIndexGroupAttribute({
							indexExam: indexExamInExamGroup,
							position: positionGroupAttribute,
						})

						if (indexGroup > -1) {
							const indexAttribute = getters.getIndexAttribute({
								indexExam: indexExamInExamGroup,
								indexGroupAttribute: indexGroup,
								uuidAttribute: uuidAttribute,
							})

							if (indexAttribute > -1) {
								commit('setVersionExamUpdate', {
									indexExam: indexExamInExamGroup,
									indexGroup: indexGroup,
									indexAttribute: indexAttribute,
									resultValue: payload.attributes[0].value,
								})
							}
						}
					}
				}

				commit('setLoadingVersionSelected', false)
				setTimeout(() => {
					state.loadingSave = false
				}, 600)
				return version
			} catch (error) {
				commit('setLoadingVersionSelected', false)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateVersion(
			{ state, getters, commit },
			{ id_exam, positionGroupAttribute, uuidAttribute, id_version, payload }
		) {
			try {
				state.loadingSave = true

				const response = await APIResultExam.updateVersion(id_exam, id_version, payload)

				if (response) {
					const indexExamInExamGroup = getters.getIndexLatestExamResults(id_exam)

					if (indexExamInExamGroup > -1) {
						const indexGroup = getters.getIndexGroupAttribute({
							indexExam: indexExamInExamGroup,
							position: positionGroupAttribute,
						})

						if (indexGroup > -1) {
							const indexAttribute = getters.getIndexAttribute({
								indexExam: indexExamInExamGroup,
								indexGroupAttribute: indexGroup,
								uuidAttribute: uuidAttribute,
							})

							if (indexAttribute > -1) {
								commit('setVersionExamUpdate', {
									indexExam: indexExamInExamGroup,
									indexGroup: indexGroup,
									indexAttribute: indexAttribute,
									resultValue: payload.attributes[0].value,
								})
							}
						}
					}
				}

				setTimeout(() => {
					state.loadingSave = false
				}, 600)
				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async sendExamGroupRelatory({ commit }, { requestId, examGroupId }) {
			try {
				const { status } = await API.sendExamGroupRelatory({ requestId, examGroupId })

				if (status === STATUS_SUCCESS) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t(
								'modules.insertResult.feedback.success.create.reportSent'
							),
						},
						{ root: true }
					)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				return { error: error.response.data.message }
			}
		},

		async visualizeExamGroupLatestReport({ state, commit }, { requestId, examGroupId }) {
			try {
				const response = await API.visualizeExamGroupLatestReport({
					requestId,
					examGroupId,
				})

				if (response) {
					commit('setGroupReportSelected', response)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
