import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { RootState } from '@/store/types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { ORDER_CANCELED_STATUS_ID, ORDER_DRAFT_STATUS_ID } from '@/utils/constants/orderConstants'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ActionTree } from 'vuex'
import API from '../../service'
import { getterTypes } from '../getters/getter-types'
import { mutationTypes } from '../mutations/mutation-types'
import { OrderState } from '../types'
import { Actions, actionTypes as types } from './action-types'

export const actions: ActionTree<OrderState, RootState> & Actions = {
	async [types.GET_ORDERS]({ state, commit }, page) {
		try {
			const response = await API.getOrders(page)

			if (response) {
				commit(mutationTypes.SET_ORDERS, response)
			}

			return state.orders
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.SEARCH_ORDER](
		{ commit, getters },
		{ filters, reset, store = true, storePerStatus = false }
	) {
		try {
			if (filters?.search === '') filters.search = null

			const response = await API.searchOrders({
				...getters[getterTypes.GET_FILTERS_WITHOUT_NULL],
				...filters,
			})

			if (response) {
				if (reset) {
					commit(mutationTypes.RESET_ORDERS_FILTERED)
				}

				if (store) {
					commit(mutationTypes.SET_ORDERS_FILTERED, response)
				}

				if (storePerStatus && filters.orderStatus) {
					commit(mutationTypes.SET_ORDERS_PER_STATUS, {
						payload: response,
						id: filters.orderStatus,
					})
				}

				return response
			}
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ORDER]({ commit }, id) {
		try {
			commit(mutationTypes.SET_LOADING_ORDER_SUB_HEADER, ENABLE_LOADING)
			const response = await API.getOrder(id)

			if (response) {
				commit(mutationTypes.SET_ORDER_SELECTED, response)
				commit(mutationTypes.SET_LOADING_ORDER_SUB_HEADER, DISABLE_LOADING)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.SET_ORDER_SELECTED]({ commit }, payload) {
		const order = Object.assign({}, payload)

		commit(mutationTypes.SET_ORDER_SELECTED, order)
	},

	async [types.CREATE_ORDER]({ commit }, payload) {
		try {
			const order = await API.createOrder(payload)

			if (order) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.orders.feedback.success.create.order') },
					{ root: true }
				)
				commit(mutationTypes.SET_ORDER_SELECTED, order)
			}

			return order
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_ORDER]({ state, commit, getters }, { payload, orderId }) {
		try {
			let id: string | number = 0

			if (orderId) {
				id = orderId
			} else {
				id = state.selectedOrder.id
			}

			const response = await API.updateOrder(id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.orders.feedback.success.update.order') },
					{ root: true }
				)
				let order = getters[getterTypes.GET_ORDER_BY_ID](id)
				const indexOrder = getters[getterTypes.GET_ORDER_INDEX](id)

				let orderFiltered = getters[getterTypes.GET_ORDER_FILTERED_BY_ID](id)
				const indexOrderFiltered = getters[getterTypes.GET_ORDER_INDEX_FILTER](id)

				order = { ...order, ...payload }
				orderFiltered = { ...orderFiltered, ...payload }

				commit(mutationTypes.UPDATE_ORDERS, { index: indexOrder, order: order })

				commit(mutationTypes.UPDATE_ORDERS_FILTERED, {
					index: indexOrderFiltered,
					order: orderFiltered,
				})

				if (!orderId) {
					commit(mutationTypes.SET_ORDER_SELECTED, response)
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_ORDER]({ getters, commit }, { orderId, payload, statusId }) {
		try {
			const { status } = await API.deleteOrder(orderId, payload)

			if (status === STATUS_SUCCESS) {
				const index = getters[getterTypes.GET_ORDER_PER_STATUS_INDEX]({
					id: orderId,
					statusId: statusId,
				})

				if (index > -1) {
					commit(mutationTypes.REMOVE_ORDER_PER_STATUS, {
						index: index,
						status: statusId,
					})

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.orders.feedback.success.remove.order') },
						{ root: true }
					)
				}
			}

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.UPDATE_STATUS_ORDER]({ commit }, payload) {
		try {
			const id = payload.id

			delete payload.id
			const response = await API.updateOrderStatus(id, payload)

			if (response) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.orders.feedback.success.update.order') },
					{ root: true }
				)
				commit(mutationTypes.SET_ORDER_SELECTED, response)
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CANCEL_ORDER]({ state, commit, getters }, { orderId, payload, statusId }) {
		try {
			const response = await API.deleteOrder(orderId, payload)

			if (response.status === STATUS_SUCCESS) {
				if (state.selectedOrder.id) {
					const draft = state.selectedOrder.status.id === ORDER_DRAFT_STATUS_ID

					if (draft) {
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.orders.feedback.success.remove.order') },
							{ root: true }
						)
						// Reset Selected Order [Delete from backend]
						commit(mutationTypes.RESET_ORDER_SELECTED)
					} else {
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.orders.feedback.success.cancel.order') },
							{ root: true }
						)

						// Define Status Cancel
						if (state.statusOrders.length) {
							state.selectedOrder.status = state.statusOrders.find(
								(statusOld) => statusOld.id === ORDER_CANCELED_STATUS_ID
							)
						}
					}
				} else {
					if (statusId !== 0) {
						const orderIndex = getters[getterTypes.GET_ORDER_PER_STATUS_INDEX]({
							id: orderId,
							statusId: statusId,
						})

						commit(mutationTypes.REMOVE_ORDER_PER_STATUS, {
							index: orderIndex,
							status: statusId,
						})
					}
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_ATTACHMENTS_ORDER]({ state, commit }, id_order) {
		try {
			const attachments = await API.getAttachmentsOrder(id_order)

			if (attachments) {
				commit(mutationTypes.SET_ATTACHMENTS, attachments)
			}

			return state.attachments
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CREATE_ATTACHMENT]({ commit }, payload) {
		try {
			const attachment = await API.createAttachment(payload)

			if (attachment) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.create.attachment') },
					{ root: true }
				)
				commit(mutationTypes.ADD_ATTACHMENT, attachment)
			}

			return attachment
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.REMOVE_ATTACHMENT]({ getters, commit }, uuid) {
		try {
			const response = await API.removeAttachment(uuid)

			if (response.status === 200) {
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.attachments.feedback.success.remove.attachment') },
					{ root: true }
				)
				const index = getters[getterTypes.GET_INDEX_ATTACHMENT](uuid)

				if (index > -1) {
					commit(mutationTypes.REMOVE_ATTACHMENT, index)
				}
			}

			return response
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.DOWNLOAD_ATTACHMENT]({ commit }, uuid) {
		try {
			return await API.downloadAttachment(uuid)
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.GET_STATUS_ORDER]({ state, commit }) {
		try {
			if (!state.statusOrders.length) {
				const statusList = await API.getStatusList()

				if (statusList) {
					commit(mutationTypes.SET_STATUS_ORDER, statusList)
				}
			}
		} catch (error: any) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
		}
	},

	async [types.CHECK_DRAFT_ORDER_REQUESTS_FOR_PATIENT](_, { orderId, patientId }) {
		try {
			const { status, data: checkDraftOrderRequestsForPatient } =
				await API.checkDraftOrderRequestsForPatient({
					orderId: orderId,
					patientId: patientId,
				})

			if (status === STATUS_SUCCESS) {
				return checkDraftOrderRequestsForPatient
			} else {
				return false
			}
		} catch (error: any) {
			return false
		}
	},

	async [types.CHECK_DRAFT_ORDER_REQUESTS](_, { orderId }) {
		try {
			const { status, data: checkDraftOrderRequests } = await API.checkDraftOrderRequests({
				orderId: orderId,
			})

			if (status === STATUS_SUCCESS) {
				return checkDraftOrderRequests
			} else {
				return false
			}
		} catch (error: any) {
			return false
		}
	},

	async [types.GET_ITEMS_BY_SEARCH](_, { orderId, search }) {
		try {
			const { status, data: itemsBySearch } = await API.getItemsBySearch({
				orderId: orderId,
				search,
			})

			if (status === STATUS_SUCCESS) {
				return { dataResponse: itemsBySearch }
			}

			return false
		} catch (error: any) {
			return false
		}
	},
}

export default { actions }
