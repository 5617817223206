import { MutationTree } from 'vuex'
import { ExamsGroupState } from '../types'
import { mutationTypes as types, Mutations } from './mutation-types'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'
import { state as stateDefault } from '../'

export const mutations: MutationTree<ExamsGroupState> & Mutations = {
	[types.SET_LOADING_EXAMS_GROUPS](state, loadingState: boolean) {
		state.examsGroups.options.isLoading = loadingState
	},

	[types.ADD_META_PAGE_EXAMS_GROUPS](state, response) {
		state.examsGroups.data.push(Object.assign({ loading: false }, response))
	},

	[types.SET_META_PAGE_EXAMS_GROUPS](state, response) {
		const META_PAGE_ARRAY = []

		META_PAGE_ARRAY.push(Object.assign({ loading: false }, response))

		state.examsGroups.data = META_PAGE_ARRAY
	},

	[types.UPDATE_PAGE_EXAMS_GROUPS](state, page) {
		state.examsGroups.meta.page = page
	},

	[types.SET_MODAL_CREATE_EXAMS_GROUP](state, modalState) {
		state.openModalCreateExamsGroup = modalState
	},

	[types.SET_MODAL_UPDATE_EXAMS_GROUP](state, modalState) {
		state.openModalUpdateExamsGroup = modalState
	},

	[types.ADD_EXAMS_GROUP](state, { indexCurrentPage, examsGroup }) {
		state.examsGroups.data[indexCurrentPage].data.push(examsGroup)
	},

	[types.UPDATE_EXAMS_GROUP](state, { indexCurrentPage, indexExamsGroup, examsGroup }) {
		state.examsGroups.data[indexCurrentPage].data.splice(indexExamsGroup, 1, examsGroup)
	},

	[types.REMOVE_EXAMS_GROUP](state, { indexCurrentPage, indexExamsGroup }) {
		state.examsGroups.data[indexCurrentPage].data.splice(indexExamsGroup, 1)
	},

	[types.SET_EXAMS](state, exams) {
		state.exams.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.exams.data,
			newArray: exams.data,
			keyToCompare: 'id',
			keyDateLatest: 'updated_at',
		})
		state.exams.meta = exams.meta
	},

	[types.RESET_EXAMS](state) {
		state.exams.data = []
		state.exams.meta = {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		}
		state.exams.loading = false
	},

	[types.SET_EXAMS_LOADED](state, exams) {
		state.exams.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.exams.data,
			newArray: exams,
			keyToCompare: 'id',
			keyDateLatest: 'updated_at',
		})
	},

	[types.UPDATE_LOADING_EXAMS](state, loading) {
		state.exams.loading = loading
	},

	[types.SET_EXAMS_GROUP_SELECTED](state, examsGroup) {
		state.examsGroupSelected = examsGroup
	},

	[types.SET_SEARCH_EXAMS_GROUPS](state, { search, page }) {
		state.examsGroups.meta.page = page || 1
		state.examsGroups.meta.search = search
	},

	[types.RESET_EXAMS_GROUPS](state) {
		state.examsGroups = Object.assign({}, stateDefault.examsGroups)
		state.examsGroupSelected = null
	},
}

export default { mutations }
