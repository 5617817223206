























import { Component, Mixins } from 'vue-property-decorator'
import { CreateReferenceGroupMixin } from '@/modules/exams/mixins/CreateReferenceGroupMixin'

@Component({
	name: 'AddAttributeGroupMenu',
})
export default class AddAttributeGroupMenu extends Mixins(CreateReferenceGroupMixin) {}
