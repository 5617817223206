import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { IconView } from '@ckeditor/ckeditor5-ui'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

class SearchAnnotations extends Plugin {
	init() {
		const editor = this.editor
		const id = editor.config.get('customEditorPlugin.id')

		// The button must be registered among the UI components of the editor
		// to be displayed in the toolbar.
		editor.ui.componentFactory.add('searchAnnotations', (locale) => {
			// The button will be an instance of ButtonView.
			const button = new ButtonView(locale)

			// Crie uma nova instância de IconView.
			const iconView = new IconView()

			// Defina o caminho do ícone SVG.
			iconView.content =
				'<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.5 12c2.5 0 4.5 2 4.5 4.5c0 .88-.25 1.71-.69 2.4l3.08 3.1L21 23.39l-3.12-3.07c-.69.43-1.51.68-2.38.68c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5m0 2a2.5 2.5 0 0 0-2.5 2.5a2.5 2.5 0 0 0 2.5 2.5a2.5 2.5 0 0 0 2.5-2.5a2.5 2.5 0 0 0-2.5-2.5M13 4v8l-2.5-2.25L8 12V4H6v16h4c.54.81 1.23 1.5 2.03 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7.81c-.58-.55-1.25-1-2-1.31V4z"/></svg>' // Substitua por seu próprio SVG.

			// Adicione o ícone ao botão.
			button.children.add(iconView)

			button.set({
				label: 'Anotações',
				tooltip: true,
			})

			button.on('execute', () => {
				window.dispatchEvent(new CustomEvent(`click:searchAnnotations:${id}`))
			})

			return button
		})
	}
}

export default SearchAnnotations
