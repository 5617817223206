import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { pt, en, es } from 'vuetify/src/locale'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	lang: {
		locales: { pt, en, es },
		current: 'pt',
	},
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#44BBA4',
				greenHighlight: '#DAF1ED',
				verde1: '#a5e8dc',
				verde2: '#caede7',
				secondary: '#757575',
				accent: '#FFD34D',
				accent2: '#9B51E0',
				info: '#42A5F5',
				error: '#FF595D',
				warning: '#F2994A',
				success: '#4CAF50',
				background: '#FFFFFF',
				background2: '#F5F6F8',
				backgroundMenu: '#FFFFFF',
				backgroundapp: '#E5E5E5',
				backgroundgreen: '#ECF8F6',
				backgroundSheet: '#EDEDED',
				backgroundContentSheet: '#FCFCFC',
				menuBorderColor: '#e0e0e0',
				backgroundGreyLight: '#ebeff2',
				dark1: '#607D8B',
				dark2: '#616161',
				titledialog: '#000000',
				backgroundtable: '#f1f1f1',
				colorlabel: '#818E9B',
				colorDisabled: '#E0E0E0',
				lightColor: '#909CA7',
				toolbarMenu: '#FFFFFF',
				asideMenu: '#FFFFFF',
				backgroundCard: '#FFFFFF',
				borderColor: '#e0e0e0',
				backgroundTabs: '#FFFFFF',
				textColor: '#757575',
				backgroundButton: '#FFFFFF',
				backgroundModal: '#FFFFFF',
				backgroundScrollAvailable: '#f1f1f1',
				backgroundScrollMain: '#757575',
				headerToolbar: '#FFFFFF',
				headerTable: '#ebeff2',
				backgroundTable: '#FFFFFF',
				backgroundButtonDownTable: '#EBEFF2',
				backgroundChipLarge: '#ededed',
			},
			dark: {
				primary: '#44BBA4',
				verde1: '#a5e8dc',
				verde2: '#caede7',
				secondary: '#2D3136',
				accent: '#FFD34D',
				accent2: '#9B51E0',
				info: '#42A5F5',
				error: '#FF595D',
				warning: '#F2994A',
				success: '#4CAF50',
				background: '#000000',
				backgroundSheet: '#EDEDED',
				backgroundContentSheet: '#FCFCFC',
				menuBorderColor: '#1e272e',
				backgroundMenu: '#2b343b',
				background2: '#2D3136',
				backgroundapp: '#E5E5E5',
				backgroundgreen: '#ECF8F6',
				backgroundGreyLight: '#ebeff2',
				dark1: '#607D8B',
				dark2: '#616161',
				titledialog: '#000000',
				backgroundtable: '#f1f1f1',
				colorlabel: '#818E9B',
				colorDisabled: '#E0E0E0',
				lightColor: '#909CA7',
				toolbarMenu: '#2A2E34',
				asideMenu: '#30353C',
				backgroundCard: '#30353C',
				borderColor: '#1e272e',
				backgroundTabs: '#30353C',
				textColor: '#d5d6d7',
				backgroundButton: '#2A2E34',
				backgroundModal: '#30353C',
				backgroundScrollAvailable: '#30353C',
				backgroundScrollMain: '#30353C',
				headerToolbar: '#2A2E34',
				headerTable: '#30353C',
				backgroundTable: '#2A2E34',
				backgroundButtonDownTable: '#2A2E34',
				backgroundChipLarge: '#ededed',
			},
		},
	},

	icons: {
		iconfont: 'fa',
	},
})

export default vuetify
