import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { SpecieState, ISpecieResponse } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	SET_SPECIES = 'SET_SPECIES',
	SET_LOADING_SPECIES = 'SET_LOADING_SPECIES',

	SET_MODAL_CREATE_SPECIE = 'SET_MODAL_CREATE_SPECIE',
	SET_MODAL_UPDATE_SPECIE = 'SET_MODAL_UPDATE_SPECIE',

	CREATE_SPECIE = 'CREATE_SPECIE',
	UPDATE_SPECIE = 'UPDATE_SPECIE',
	REMOVE_SPECIE = 'REMOVE_SPECIE',

	SET_SPECIE_SELECTED = 'SET_SPECIE_SELECTED',
	RESET_SPECIE_SELECTED = 'RESET_SPECIE_SELECTED',
}

export type Mutations<S = SpecieState> = {
	[mutationTypes.SET_MODAL_CREATE_SPECIE](state: S, modalState: boolean): void

	[mutationTypes.SET_MODAL_UPDATE_SPECIE](state: S, modalState: boolean): void

	[mutationTypes.SET_SPECIES](
		state: S,
		species: ITypeOrmPaginationTemplate<ISpecieResponse>
	): void

	[mutationTypes.SET_LOADING_SPECIES](state: S, loading: boolean): void

	[mutationTypes.CREATE_SPECIE](state: S, { specie }: { specie: ISpecieResponse }): void

	[mutationTypes.UPDATE_SPECIE](
		state: S,
		{ index, specie }: { index: number; specie: ISpecieResponse }
	): void

	[mutationTypes.REMOVE_SPECIE](state: S, { index }: { index: number }): void

	[mutationTypes.SET_SPECIE_SELECTED](state: S, specie: ISpecieResponse): void
	[mutationTypes.RESET_SPECIE_SELECTED](state: S): void
}
