import { render, staticRenderFns } from "./ExamAttributeTypeImage.vue?vue&type=template&id=6538a072&scoped=true&"
import script from "./ExamAttributeTypeImage.vue?vue&type=script&lang=ts&"
export * from "./ExamAttributeTypeImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6538a072",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VImg,VProgressCircular})
