






































import { Component, Mixins, Watch } from 'vue-property-decorator'
import ReferenceFormHeader from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/References/ReferenceFormHeader.vue'
import AttributeReferenceGroup from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/References/Cards/AttributeReferenceGroup.vue'
import InsertionResultLoader from '@/components/global/SkeletonLoader/InsertionResultLoader.vue'
import { State } from 'vuex-class'
import { GetReferenceMixin } from '@/modules/exams/mixins/GetReferenceMixin'
import { IResponseExam } from '@/modules/exams/services/types'
import { IAttributeGroup } from '@/modules/exams/store/attributes/types'
import ReferenceToolbar from './ReferenceToolbar.vue'

@Component({
	name: 'ReferenceFactory',
	components: {
		InsertionResultLoader,
		AttributeReferenceGroup,
		ReferenceFormHeader,
		ReferenceToolbar,
	},
})
export default class ReferenceFactory extends Mixins(GetReferenceMixin) {
	form: {
		id?: number
		exame_id?: number
		nome: string
		especie_id: number
		idade_inicial: number
		idade_final: number
		tipo_idade: string
	} = {
		nome: 'Referência Sem Nome',
		especie_id: 1,
		idade_inicial: 0,
		idade_final: 9999,
		tipo_idade: 'anos',
	}
	defaultGroupName = 'Valores de Referência'
	showAttributes = true
	loadingContent = true

	@State((state) => state.Exams.attributes) attributes!: IAttributeGroup[]
	@State((state) => state.Exams.selectedExam) exam!: IResponseExam
	@State((state) => state.Exams.selectedReference) reference!: any
	@State((state) => state.Exams.referenceGroups) referenceGroups!: any[]
	@State((state) => state.Exams.referenceIsSaving) referenceIsSaving!: boolean

	@State((state) => state.Especies.especies) species!: any[]

	@Watch('$route.params.referenceId', { immediate: true, deep: true })
	async onReferenceIdChange() {
		this.form.exame_id = this.exam.id
		await this.handleResetGetReference(this.$route.params.referenceId)
		await this.loadDataForm()

		this.loadingContent = false
	}

	async loadDataForm() {
		this.form = Object.assign({}, this.reference)
		this.form.especie_id = +this.form.especie_id
	}
}
