import { MutationTree } from 'vuex'
import {
	SearchExamResultsState,
	SearchNotesIteratorSettings,
	SearchResultsIteratorSettings,
	mutationTypes,
} from './types'

export const mutations: MutationTree<SearchExamResultsState> = {
	[mutationTypes.SET_SEARCH_NOTES_ITERATOR_SETTINGS](
		state,
		payload: SearchNotesIteratorSettings
	) {
		state.searchNotesIteratorSettings = payload
	},
	[mutationTypes.SET_SEARCH_RESULTS_ITERATOR_SETTINGS](
		state,
		payload: SearchResultsIteratorSettings
	) {
		state.searchResultsIteratorSettings = payload
	},
	[mutationTypes.SET_SELETED_SEARCH_RESULT_TYPE](
		state,
		payload: 'annotations' | 'previousResults'
	) {
		state.selectedSearchResultType = payload
	},
	[mutationTypes.SET_SELETED_RESULT_TEXT](state, payload: string) {
		state.selectedResultText = payload
	},
}

export default mutations
