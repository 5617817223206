import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { SignatureState } from '@/modules/signature/store/types'
import { Actions, actionTypes as types } from '@/modules/signature/store/actions/action-types'
import { mutationTypes } from '@/modules/signature/store/mutations/mutation-types'
import API from '@/modules/signature/service'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export const actions: ActionTree<SignatureState, RootState> & Actions = {
	async [types.UPDATE_SIGNATURE]({ state, commit }, payload) {
		try {
			const signatureText = state.signature ? 'atualizado' : 'adicionado'

			const { status, data: profile } = await API.updateSignature(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_SIGNATURE, profile.assinatura)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: 'Assinatura', description: `Assinatura ${signatureText} com sucesso` },
					{ root: true }
				)
			}

			return status === STATUS_SUCCESS ? profile : false
		} catch (error) {
			console.error(error)
		}
	},

	async [types.REMOVE_SIGNATURE]({ commit }) {
		try {
			const { status } = await API.removeSignature()

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.REMOVE_SIGNATURE)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: 'Assinatura', description: 'Assinatura removida com sucesso' },
					{ root: true }
				)
			}

			return status === STATUS_SUCCESS
		} catch (error) {
			console.error(error)
		}
	},
}

export default { actions }
