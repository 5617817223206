import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		loading: false,
		carregado: false,
		rotulos: [],
		selecionado: {},
		currentPage: 1,
		registers: [],
	},

	getters: {
		getID: (state) => {
			return (id) => {
				return state.rotulos.find((rotulo) => rotulo.id === id)
			}
		},

		getIndex: (state) => {
			return (id) => {
				return state.rotulos.findIndex((rotulo) => rotulo.id === id)
			}
		},
	},

	mutations: {
		resetCarregado: (state) => {
			state.carregado = false
			state.rotulos = []
		},

		setRotulos: (state, payload) => {
			state.carregado = true
			state.rotulos = payload
		},

		setCarregado: (state, bool) => (state.carregado = bool),

		addRotulo: (state, payload) => state.rotulos.push(payload),

		updateRotulo: (state, { index, rotulo }) => state.rotulos.splice(index, 1, rotulo),

		deleteRotulo: (state, index) => state.rotulos.splice(index, 1),

		updatePage: (state, page) => {
			state.currentPage = page
		},

		updateRegisters: (state, registers) => {
			state.registers = registers
		},

		resetPage: (state) => (state.currentPage = 1),

		resetRegisters: (state) => (state.registers = []),

		setLoading: (state, stateModal) => (state.loading = stateModal),
	},
	actions: {
		async getAll({ state, commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				const response = await API.getRotulos()

				if (response) {
					commit('setRotulos', response)
				}

				commit('setLoading', DISABLE_LOADING)

				return state.rotulos
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createRotulo({ commit }, payload) {
			try {
				const rotulo = await API.criarRotulo(payload)

				if (rotulo) {
					commit('addRotulo', rotulo)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.tags.feedback.success.create.tag') },
						{ root: true }
					)
				}

				return rotulo
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateRotulo({ commit, getters }, payload) {
			try {
				const rotulo = await API.editarRotulo(payload.id, payload)

				if (rotulo) {
					const index = getters.getIndex(payload.id)

					commit('updateRotulo', { index, rotulo })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.tags.feedback.success.update.tag') },
						{ root: true }
					)
				}

				return rotulo
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async changeStatusTag({ commit, getters }, { id, status }) {
			try {
				const tag = await API.changeStatusTag(id, status)

				if (tag) {
					const index = getters.getIndex(id)

					commit('updateRotulo', { index, rotulo: tag })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.tags.feedback.success.update.tag') },
						{ root: true }
					)
				}

				return tag
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteRotulo({ commit, getters }, id) {
			try {
				const response = await API.excluirRotulo(id)

				if (response) {
					const index = getters.getIndex(id)

					commit('deleteRotulo', index)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.tags.feedback.success.remove.tag') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
