import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { actionTypes } from '@/modules/exams/store/attributes/types'
import {
	IUpdateAttributePayload,
	IResponseAttribute,
} from '@/modules/exams/services/attributes/types'
import { AxiosResponse } from 'axios'
import { IResponseExam } from '../services/types'

@Component
export default class UpdateAttributeMixin extends Vue {
	@State((state) => state.Exams.selectedExam) selectedExam!: IResponseExam

	updateAttributeDebouncing: NodeJS.Timeout | null = null

	@Action(`Exams/${actionTypes.UPDATE_ATTRIBUTE}`) updateAttribute!: ({
		payload,
		examId,
		attributeGroupId,
		attributeId,
	}: {
		payload: IUpdateAttributePayload
		examId: number
		attributeGroupId: number
		attributeId: number
	}) => Promise<AxiosResponse<IResponseAttribute | boolean>>

	async handleUpdateAttribute({
		payload,
		attributeGroupId,
		attributeId,
	}: {
		payload: IUpdateAttributePayload
		attributeGroupId: number
		attributeId: number
	}) {
		if (!this.selectedExam) throw new Error('Exam not loaded')

		try {
			if (this.updateAttributeDebouncing) clearTimeout(this.updateAttributeDebouncing)
			this.updateAttributeDebouncing = setTimeout(() => {
				this.updateAttribute({
					payload,
					examId: this.selectedExam.id,
					attributeGroupId: attributeGroupId,
					attributeId: attributeId,
				})
			}, 2000)
		} catch {
			throw new Error('Error on update attribute')
		}
	}
}
