import API from '@/axios'

const Endpoint = '/models/forms/'
const TagEndpoint = '/rotulos/'

export default {
	async createForm(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},
	async removeForm(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},
	async getAllForms() {
		return await API.get(Endpoint).then((response) => response.data)
	},
	async getForm(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},
	async updateForm({ id, payload }) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},
	async createFormSection({ formId, payload }) {
		return await API.post(Endpoint + formId + '/sections', payload).then(
			(response) => response.data
		)
	},
	async updateFormSection({ formId, sectionId, payload }) {
		return await API.put(Endpoint + formId + '/sections/' + sectionId, payload).then(
			(response) => response.data
		)
	},
	async removeFormSection({ formId, sectionId }) {
		return await API.delete(Endpoint + formId + '/sections/' + sectionId).then(
			(response) => response.data
		)
	},
	async createFormComponent({ formId, sectionId, payload }) {
		return await API.post(
			Endpoint + formId + '/sections/' + sectionId + '/components',
			payload,
			{
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
		).then((response) => response.data)
	},
	async updateFormComponent({ formId, sectionId, componentId, payload }) {
		const url =
			Endpoint + formId + '/sections/' + sectionId + '/components/' + componentId + '/editar'

		return await API.post(url, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		}).then((response) => response.data)
	},
	async removeFormComponent({ formId, sectionId, componentId }) {
		const url = Endpoint + formId + '/sections/' + sectionId + '/components/' + componentId

		return await API.delete(url).then((response) => response.data)
	},
	async selectTag(id, formId) {
		return await API.post(TagEndpoint + id + '/formularios', { id: formId }).then(
			(response) => response.data
		)
	},

	async deselectTag(id, formId) {
		return await API.delete(TagEndpoint + id + '/formularios', { data: { id: formId } }).then(
			(response) => response.data
		)
	},
}
