



































































import {
	Component,
	InjectReactive,
	Mixins,
	Prop,
	PropSync,
	VModel,
	Watch,
} from 'vue-property-decorator'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import InputSwitch from '@/components/atomic/atoms/Inputs/InputSwitch.vue'
import InputCheckBox from '@/components/atomic/atoms/Inputs/InputCheckBox.vue'
import CreateAttributeAndAttributeGroupMixin from '@/modules/exams/mixins/CreateAttributeAndAttributeGroupMixin'
import AlertConfirmation from '@/components/global/AlertConfirmation.vue'
import { actionTypes, IAttribute, IAttributeGroup } from '@/modules/exams/store/attributes/types'
import { Action } from 'vuex-class'
import ValidationAttributeTypeMixin from '@/modules/exams/mixins/ValidationAttributeTypeMixin'

@Component({
	name: 'ExamAttributeDetailsAction',
	components: {
		AlertConfirmation,
		ButtonIcon,
		InputSwitch,
		InputCheckBox,
	},
})
export default class ExamAttributeDetailsAction extends Mixins(
	CreateAttributeAndAttributeGroupMixin,
	ValidationAttributeTypeMixin
) {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	@InjectReactive('attributeGroup') attributeGroup!: IAttributeGroup

	@Prop({ type: [Object, String], default: null }) contentClass?: string | unknown
	@PropSync('showAnnotation', { type: Boolean, default: false }) showAnnotationState!: boolean

	// eslint-disable-next-line
	@PropSync('showMappedAttributeField', { type: Boolean, default: false }) showMappedAttributeState!: boolean

	openAlertConfirmation = false
	alertConfirmationText = {
		title: 'Remover campo',
		subtitle: 'Você tem certeza que deseja remover o campo selecionado?',
	}

	loadingAttributeRemove = false

	@Action(`Exams/${actionTypes.DELETE_ATTRIBUTE}`) removeAttribute!: ({
		attributeGroupId,
		attributeId,
	}: {
		attributeGroupId: number
		attributeId: number
	}) => Promise<void>

	@Watch('showAnnotationState') onShowAnnotationStateChange(value: boolean) {
		if (value) this.attribute.note = ''
		else this.attribute.note = null
		this.$emit('input', this.attribute)
	}

	// async duplicateAttribute() {
	// 	const payload: ICreateAttributePayload = {}

	// 	const keyForbidden = ['id', 'attribute_id', 'formulas', 'key']

	// 	Object.keys(this.attribute).forEach((key) => {
	// 		if (!keyForbidden.includes(key)) {
	// 			if (key === 'position') {
	// 				const indexAttributeGroup = this.getIndexAttributeGroup(this.attributeGroupId)

	// 				if (indexAttributeGroup > -1) {
	// 					payload[key] = this.attributes[indexAttributeGroup].attributes.length + 1
	// 				}
	// 			} else {
	// 				payload[key] = this.attribute[key as keyof IAttribute<unknown>]
	// 			}
	// 		}
	// 	})

	// 	await this.createAttribute({
	// 		groupId: this.attributeGroup.id,
	// 		payload,
	// 	})
	// }

	async handleRemoveAttribute() {
		if (this.selectedExam?.id) {
			try {
				this.loadingAttributeRemove = true

				await this.removeAttribute({
					attributeGroupId: this.attributeGroup.id,
					attributeId: this.attribute.id,
				})
			} finally {
				this.loadingAttributeRemove = false
			}
		}
	}
}
