import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,
	state: {
		shippings: [],
		zones: [],
		attachments: [],
		logisticStatus: [],
		shippingSelected: null,
		shippingRelationships: null,
		kits: [],
		volumes: [],
	},

	getters: {
		getIndexShipping: (state) => {
			return (id) => {
				return state.shippings.findIndex((shipping) => shipping.id === id)
			}
		},

		getIdShipping: (state) => {
			return (id) => {
				return state.shippings.find((shipping) => shipping.id === id)
			}
		},

		getIndexZone: (state) => {
			return (id) => {
				return state.zones.findIndex((zone) => zone.id === id)
			}
		},

		getIdZone: (state) => {
			return (id) => {
				return state.zones.find((zone) => zone.id === id)
			}
		},

		getIndexAttachment: (state) => {
			return (uuid) => {
				return state.attachments.findIndex((attachment) => attachment.uuid === uuid)
			}
		},

		getUuidAttachment: (state) => {
			return (uuid) => {
				return state.attachments.find((attachment) => attachment.uuid === uuid)
			}
		},
	},

	mutations: {
		setShippings: (state, payload) => (state.shippings = payload),

		addShipping: (state, payload) => state.shippings.push(payload),

		updateShipping: (state, { index, payload }) => state.shippings.splice(index, 1, payload),

		removeShipping: (state, index) => state.shippings.splice(index, 1),

		setZones: (state, payload) => (state.zones = payload),

		addZone: (state, payload) => state.zones.push(payload),

		updateZone: (state, { index, payload }) => state.zones.splice(index, 1, payload),

		removeZone: (state, index) => state.zones.splice(index, 1),

		setAttachments: (state, payload) => (state.attachments = payload),

		addAttachment: (state, payload) => state.attachments.push(payload),

		removeAttachment: (state, index) => state.attachments.splice(index, 1),

		resetAttachment: (state) => (state.attachments = []),

		setShippingSelected: (state, payload) => (state.shippingSelected = payload),

		resetShippingSelected: (state) => {
			state.shippingSelected = null
			state.kits = []
			state.volumes = []
			state.attachments = []
			state.shippingRelationships = null
		},

		resetAll: (state) => {
			state.shippings = []
			state.zones = []
			state.attachments = []
			state.shippingSelected = null
			state.shippingRelationships = null
			state.kits = []
			state.volumes = []
		},

		setVolumes: (state, volumes) => (state.volumes = volumes),

		setKits: (state, kits) => (state.kits = kits),

		setShippingRelationships: (state, relationships) =>
			(state.shippingRelationships = relationships),

		setShippingStatus: (state, status) => (state.logisticStatus = status),
	},

	actions: {
		async getAllShippings({ commit }, id_order) {
			try {
				const shippings = await API.getShippings(id_order)

				if (shippings) {
					commit('setShippings', shippings)
				}

				return shippings
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createShipping({ commit }, { orderId, payload }) {
			try {
				const shipping = await API.createShipping(orderId, payload)

				if (shipping) {
					commit('addShipping', shipping)
				}

				return shipping
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateShipping({ commit, getters }, { id_shipping, payload }) {
			try {
				const shipping = await API.updateShipping(id_shipping, payload)

				if (shipping) {
					const indexShipping = getters.getIndexShipping(id_shipping)

					if (indexShipping > -1) {
						commit('updateShipping', { index: indexShipping, payload: shipping })
					}
				}

				return shipping
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateShippingStatus({ commit, getters }, { id_shipping, payload }) {
			try {
				const shipping = await API.updateShippingStatus(id_shipping, payload)

				if (shipping) {
					const indexShipping = getters.getIndexShipping(id_shipping)

					commit('updateShipping', { index: indexShipping, payload: shipping })
				}

				return shipping
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeShipping({ commit, getters }, id_shipping) {
			try {
				const response = await API.removeShipping(id_shipping)

				if (response) {
					const indexShipping = getters.getIndexShipping(id_shipping)

					commit('removeShipping', indexShipping)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllZones({ commit }) {
			try {
				const zones = await API.getZones()

				if (zones) {
					commit('setZones', zones)
				}

				return zones
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createZone({ commit }) {
			try {
				const zones = await API.createZone()

				if (zones) {
					commit('setZones', zones)
				}

				return zones
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateZone({ commit, getters }, zone_id) {
			try {
				const zone = await API.updateZone(zone_id)

				if (zone) {
					const index = getters.getIndexZone(zone_id)

					if (index > -1) {
						commit('addZone', index)
					}
				}

				return zone
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeZone({ commit, getters }, zone_id) {
			try {
				const response = await API.removeZone(zone_id)

				if (response.status === 200) {
					const index = getters.getIndexZone(zone_id)

					if (index > -1) {
						commit('removeZone', index)
					}
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllAttachmentsLogistic({ commit }, { shippingId }) {
			try {
				const attachments = await API.getAllAttachmentsShipping(shippingId)

				if (attachments) {
					commit('setAttachments', attachments)
				}

				return attachments
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAttachmentLogistic({ commit }, { payload: payload, addStore: addStore }) {
			try {
				const attachment = await API.createAttachment(payload)

				if (attachment && addStore) {
					commit('addAttachment', attachment)
				}

				return attachment
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeAttachmentLogistic({ getters, commit }, uuid) {
			try {
				const response = await API.removeAttachment(uuid)

				if (response.status === 200) {
					const index = getters.getIndexAttachment(uuid)

					if (index > -1) {
						commit('removeAttachment', index)
					}
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async downloadFileAttachment({ commit }, uuid) {
			try {
				return await API.downloadAttachment(uuid)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllVolumes({ commit }, shippingId) {
			try {
				const volumes = await API.getVolumes(shippingId)

				if (volumes) {
					commit('setVolumes', volumes)
				}

				return volumes
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createVolumes({ commit }, { shippingId, payload }) {
			try {
				return await API.createVolumes(shippingId, payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateVolume({ commit }, { volumeId, payload }) {
			try {
				return await API.updateVolume(volumeId, payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeVolume({ commit }, { volumeId }) {
			try {
				return await API.removeVolume(volumeId)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllKits({ commit }, shippingId) {
			try {
				const kits = await API.getKits(shippingId)

				if (kits) {
					commit('setKits', kits)
				}

				return kits
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createKit({ commit }, { shippingId, payload }) {
			try {
				return await API.createKit(shippingId, payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateKit({ commit }, { shippingKitId, payload }) {
			try {
				return await API.updateKit(shippingKitId, payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeKit({ commit }, { shippingKitId }) {
			try {
				return await API.removeKit(shippingKitId)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeShipping({ commit }, shippingId) {
			try {
				const shipping = await API.visualizeShipping(shippingId)

				if (shipping) {
					commit('setShippingSelected', shipping)
				}

				return shipping
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createShippingRelationship({ commit }, payload) {
			try {
				return await API.createShippingRelationship(payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteShippingRelationship({ commit }, payload) {
			try {
				return await API.deleteShippingRelationship(payload)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getShippingRelationships({ commit }, shippingId) {
			try {
				const relationships = await API.getShippingRelationships(shippingId)

				if (relationships) {
					commit('setShippingRelationships', relationships)
				}

				return relationships
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getShippingStatus({ state, commit }) {
			try {
				if (!state.logisticStatus.length) {
					const { status, data } = await API.getShippingStatus()

					if (status === STATUS_SUCCESS) {
						commit('setShippingStatus', data)
					}

					return status === STATUS_SUCCESS
				}

				return false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
