import { ActionTree } from 'vuex'
import { ReferenceState, actionTypes, mutationTypes } from './types'
import { RootState } from '@/store/types'
import API from '../../services/references'
import { eventBus } from '@/main'
import { types as errorTypes } from '@/modules/errors/store/types'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import {
	ICreateReferenceGroupParams,
	ICreateReferenceParams,
	IDeleteReferenceGroupParams,
	IUpdateReferenceParams,
} from '../../services/references/types'

export const actions: ActionTree<ReferenceState, RootState> = {
	async [actionTypes.GET_EXAM_REFERENCES]({ commit }, examId) {
		try {
			commit(mutationTypes.SET_REFERENCE_LOADING, true)

			const { status, data: references } = await API.getReferences(examId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_REFERENCES, references)
			}

			commit(mutationTypes.SET_REFERENCE_LOADING, false)

			return status === STATUS_SUCCESS ? references : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			eventBus.$emit('CloseBottomSheet')
		}
	},

	async [actionTypes.GET_EXAM_REFERENCE]({ commit }, referenceId) {
		try {
			commit(mutationTypes.SET_REFERENCE_LOADING, true)

			const { status, data: reference } = await API.visualizeReference({ referenceId })

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_SELECTED_REFERENCE, reference)
			}

			commit(mutationTypes.SET_REFERENCE_LOADING, false)

			return status === STATUS_SUCCESS ? reference : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			eventBus.$emit('CloseBottomSheet')
			return false
		}
	},

	async [actionTypes.GET_GROUPS_BY_REFERENCE]({ commit }, referenceId) {
		try {
			commit(mutationTypes.SET_REFERENCE_LOADING, true)

			const { status, data: groups } = await API.getGroupsByReference(referenceId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_REFERENCE_GROUPS, groups)
			}

			commit(mutationTypes.SET_REFERENCE_LOADING, false)

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_LOADING, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.CREATE_REFERENCE]({ commit }, payload: ICreateReferenceParams) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: reference } = await API.createReference(payload)

			if (status === STATUS_CREATED) {
				commit(mutationTypes.CREATE_REFERENCE, reference)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: 'A Referência foi criada com sucesso',
					},
					{ root: true }
				)
			}

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_CREATED ? { id: reference.id } : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.CREATE_REFERENCE_GROUP](
		{ commit },
		{ referenceId, payload }: ICreateReferenceGroupParams
	) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: reference } = await API.createReferenceGroup({
				referenceId: referenceId,
				payload: payload,
			})

			if (status === STATUS_CREATED) {
				commit(mutationTypes.CREATE_REFERENCE_GROUPS, reference)
			}

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_CREATED ? reference : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.UPDATE_REFERENCE](
		{ getters, commit },
		{ referenceId, payload }: IUpdateReferenceParams
	) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: reference } = await API.updateReference({
				referenceId: referenceId,
				payload: payload,
			})

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_SELECTED_REFERENCE, reference)

				const indexReference = getters.getIndex(referenceId)

				if (indexReference > -1) {
					commit(mutationTypes.UPDATE_REFERENCE, {
						index: indexReference,
						payload: reference,
					})
				}
			}

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_SUCCESS ? { id: reference.id } : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.UPDATE_REFERENCE_GROUP]({ commit, getters }, { groupId, payload }) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: referenceGroup } = await API.updateReferenceGroup({
				groupId: groupId,
				payload: payload,
			})

			const groupIndex = getters.getIndexGroup(groupId)

			commit(mutationTypes.UPDATE_REFERENCE_GROUP, {
				index: groupIndex,
				name: payload.nome,
			})
			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_CREATED ? referenceGroup : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })

			return false
		}
	},

	async [actionTypes.UPDATE_REFERENCE_VALUE]({ commit }, { referencedAttributeId, payload }) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: referenceValue } = await API.updateReferenceValue({
				referencedAttributeId: referencedAttributeId,
				payload: payload,
			})

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_CREATED ? referenceValue : false
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.DELETE_REFERENCE_GROUP](
		{ commit },
		{ groupId, payload }: IDeleteReferenceGroupParams
	) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status, data: reference } = await API.deleteReferenceGroup({
				groupId,
				payload,
			})

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.DELETE_REFERENCE_GROUP, reference)
			}

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			return false
		}
	},

	async [actionTypes.DELETE_REFERENCE]({ commit, getters }, id) {
		try {
			commit(mutationTypes.SET_REFERENCE_SAVING, true)

			const { status } = await API.deleteReference(id)

			if (status === STATUS_SUCCESS) {
				const index = getters.getIndex(id)

				if (index > -1) {
					commit(mutationTypes.DELETE_REFERENCE, index)
				}
			}

			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			return status === STATUS_SUCCESS
		} catch (error: any) {
			commit(mutationTypes.SET_REFERENCE_SAVING, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			eventBus.$emit('CloseBottomSheet')
		}
	},
}
