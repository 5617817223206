import API from '@/axios'
const Endpoint = '/models/profiles'

import { AxiosResponse } from 'axios'
import { IExamsGroupPayload, IExamsGroupResponse } from '../store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'

export default {
	async getExamsGroups({
		page,
		limit,
		search,
	}: IFiltersTemplate): Promise<AxiosResponse<ITypeOrmPaginationTemplate<IExamsGroupResponse>>> {
		return await API.get(Endpoint, { params: { page, limit, search } })
	},

	async createExamsGroup(
		payload: IExamsGroupPayload
	): Promise<AxiosResponse<IExamsGroupResponse>> {
		return await API.post(Endpoint, payload)
	},

	async updateExamsGroup({
		examsGroupId,
		payload,
	}: {
		examsGroupId: number
		payload: IExamsGroupPayload
	}): Promise<AxiosResponse<IExamsGroupResponse>> {
		return await API.put(Endpoint + `/${examsGroupId}`, payload)
	},

	async removeExamsGroup(examsGroupId: number): Promise<AxiosResponse<{ message: string }>> {
		return await API.delete(Endpoint + `/${examsGroupId}`)
	},

	async removeMultipleGroupExams(
		examsGroupsIds: number[]
	): Promise<AxiosResponse<{ message: string }>> {
		return await API.delete(Endpoint, {
			data: {
				ids: examsGroupsIds,
			},
		})
	},

	async validateAbbreviation(
		abbreviation: string
	): Promise<AxiosResponse<{ id?: string; message: string }>> {
		return await API.post(Endpoint + `/validate/abbreviation`, { abbreviation: abbreviation })
	},

	async validateAbbreviationByExamsGroupId(
		examsGroupId: number,
		abbreviation: string
	): Promise<AxiosResponse<{ message: string | undefined }>> {
		return await API.post(Endpoint + `/${examsGroupId}/validate/abbreviation`, {
			abbreviation: abbreviation,
		})
	},

	async unlinkExam({
		examsGroupId,
		examId,
	}: {
		examsGroupId: number
		examId: number
	}): Promise<AxiosResponse<IExamsGroupResponse>> {
		return await API.delete(Endpoint + `/${examsGroupId}/exams/${examId}`)
	},

	async changeStatusExamsGroup({
		examsGroupId,
		status,
	}: {
		examsGroupId: number
		status: boolean
	}): Promise<AxiosResponse<IExamsGroupResponse>> {
		return await API.patch(Endpoint + `/${examsGroupId}/status`, { status: status })
	},
}
