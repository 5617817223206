import { GetterTree } from 'vuex'
import { ReferenceState } from './types'
import { RootState } from '@/store/types'

export const getters: GetterTree<ReferenceState, RootState> = {
	getIndex: (state) => {
		return (id: number) => {
			return state.references.findIndex((reference) => +reference.id === +id)
		}
	},

	getReferenciaExame: (state) => {
		return (id: number) => {
			return state.references.filter((reference) => +reference.exame_id === +id)
		}
	},

	getIndexReferencedFields: (state) => {
		return (id: number) => {
			return state.referencedFields.findIndex((referenceField) => +referenceField.id === +id)
		}
	},

	getIndexReferencedValuesToReferencedFields: (state) => {
		return ({ indexReferencedField, id }: { indexReferencedField: number; id: number }) => {
			return state.referencedFields[indexReferencedField].reference_values.find(
				(referenceValue: { id: number }) => referenceValue.id === id
			)
		}
	},

	getIndexGroup: (state) => {
		return (id: number) => {
			return state.referenceGroups.findIndex((referenceGroup) => +referenceGroup.id === +id)
		}
	},
}
