












import { Component, Mixins } from 'vue-property-decorator'
import TagPager from '@/components/global/TagPager/TagPager.vue'
import FeedbackGlobal from '@/components/global/Feedback/FeedbackGlobal.vue'
import hasPermissionsMixin from '@/modules/authentication/mixins/hasPermissions'

const ErrorWarnings = () =>
	import(/* webpackChunkName: 'errorWarnings' */ './modules/errors/ErrorWarnings.vue')
const SuccessAlert = () =>
	import(/* webpackChunkName: 'successAlert' */ './modules/successAlert/SuccessAlert.vue')
const ModalLoaders = () =>
	import(/* webpackChunkName: 'modalLoaders' */ './components/atomic/modalLoaders.vue')
const UploadsManagement = () =>
	import(
		/* webpackChunkName: 'uploadsManagement' */
		'./components/global/UploadsManagement/UploadsManagement.vue'
	)

@Component({
	name: 'App',
	components: {
		FeedbackGlobal,
		TagPager,
		ErrorWarnings,
		SuccessAlert,
		ModalLoaders,
		UploadsManagement,
	},
})
export default class App extends Mixins(hasPermissionsMixin) {}
