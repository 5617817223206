import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import {
	ISelectedAttribute,
	ISelectedAttributeGroup,
	actionTypes as typesExamOfActions,
} from '@/modules/exams/store/attributes/types'
import {
	ICreateAttributeGroupPayload,
	IResponseAttribute,
	IResponseAttributes,
	ICreateAttributeParams,
	ICreateAttributeGroupParams,
	ICreateAttributePayload,
} from '@/modules/exams/services/attributes/types'
import { AttributeType } from '@/utils/constants/examConstants'
import { AxiosResponse } from 'axios'
import { IResponseExam } from '../services/types'
import GetDefaultAttributePayloadByTypeMixin from './GetDefaultAttributePayloadByTypeMixin'

@Component
export default class CreateAttributeAndAttributeGroupMixin extends Mixins(
	GetDefaultAttributePayloadByTypeMixin
) {
	@State((state) => state.Exams.selectedExam) selectedExam!: IResponseExam
	@State((state) => state.Exams.attributes) attributes!: IResponseAttributes[]
	@State((state) => state.Exams.selectedAttribute) selectedAttribute!: ISelectedAttribute
	@State((state) => state.Exams.selectedAttributeGroup)
	selectedAttributeGroup!: ISelectedAttributeGroup

	@Getter('Exams/getIndexAttributeGroup') getIndexAttributeGroup!: (id: number) => number

	@Action(`Exams/${typesExamOfActions.CREATE_ATTRIBUTE_GROUP}`) createAttributeGroup!: ({
		examId,
		payload,
	}: ICreateAttributeGroupParams) => Promise<void>

	@Action(`Exams/${typesExamOfActions.CREATE_ATTRIBUTE}`) createAttribute!: ({
		groupId,
		payload,
	}: ICreateAttributeParams) => Promise<AxiosResponse<IResponseAttribute> | boolean>

	loading = false

	loadingCreateAttribute = false

	async handleCreateAttributeGroup() {
		if (this.selectedExam) {
			this.loading = true

			const payload: ICreateAttributeGroupPayload = {
				name: `${this.$t('modules.exams.attributeGroupDefaultName')}`,
				status: true,
				show_title: true,
				...(this.selectedAttributeGroup.id && {
					position: this.selectedAttributeGroup.position + 1,
				}),
			}

			try {
				this.loading = true

				await this.createAttributeGroup({
					payload: payload,
					examId: this.selectedExam.id,
				})
			} finally {
				this.loading = false
			}
		}
	}

	async handleCreateAttribute(
		attributeType: AttributeType,
		attributeGroupId?: number
	): Promise<AxiosResponse<IResponseAttribute> | boolean | undefined> {
		let payload: ICreateAttributePayload | null = this.getDefaultAttributePayload({
			keyComparation: 'type',
			value: attributeType,
		})

		if (payload) {
			if (!attributeGroupId && this.selectedExam?.id && this.selectedAttributeGroup?.id) {
				payload = {
					...payload,
					...(this.selectedAttribute.id &&
						this.selectedAttribute.type === 'attribute' && {
							position: this.selectedAttribute.position + 1,
						}),
				}
			}

			try {
				this.loadingCreateAttribute = true

				return await this.createAttribute({
					groupId: attributeGroupId || this.selectedAttributeGroup.id,
					payload,
				})
			} catch {
				throw new Error('Error on create attribute')
			} finally {
				this.loadingCreateAttribute = false
			}
		}
	}
}
