import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { IReference, actionTypes } from '../store/references/types'
import { IResponseExam } from '../services/types'

@Component({
	name: 'GetReferenceMixin',
})
export class GetReferenceMixin extends Vue {
	@State(({ Exams }) => Exams.selectedReference) selectedReference!: IReference[]
	@Action(`Exams/${actionTypes.GET_EXAM_REFERENCE}`) getReference!: (
		referenceId: string
	) => Promise<IResponseExam[]>

	async handleResetGetReference(referenceId: string): Promise<void> {
		await this.getReference(referenceId)
	}
}
