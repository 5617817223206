import { TranslateResult } from 'vue-i18n'

/* eslint-disable no-unused-vars */
export const types: { [id: string]: string } = {
	ADD_SUCCESS: 'ADD_SUCCESS',
	REMOVE_SUCCESS: 'REMOVE_SUCCESS',
	REMOVE_ALL_SUCCESSES: 'REMOVE_ALL_SUCCESSES',
}

export enum ITypeSuccessfully {
	ADD_SUCCESS = 'ADD_SUCCESS',
	REMOVE_SUCCESS = 'REMOVE_SUCCESS',
	REMOVE_ALL_SUCCESSES = 'REMOVE_ALL_SUCCESSES',
}

export interface Success {
	title: string | TranslateResult
	description?: string | TranslateResult
}

export interface SuccessState {
	successes: Success[]
}
