




















































import { Component, VModel, Vue } from 'vue-property-decorator'
import InputTextField from '@/components/atomic/atoms/Inputs/InputTextField.vue'
import axios from 'axios'
import { randomizeItemFromArray } from '@/utils/functions/randomizeItemFromArray'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@/utils/constants/image'
import { IAttribute } from '@/modules/exams/store/attributes/types'

@Component({
	name: 'ExamAttributeTypeImage',
	components: {
		InputTextField,
	},
})
export default class ExamAttributeTypeImage extends Vue {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	LAZY_IMAGE_API = 'https://picsum.photos/id/450/500/500'
	ENDPOINT_IMAGE_API = 'https://picsum.photos/v2/list'
	ENDPOINT_IMAGE_PAGE = 4
	ENDPOINT_IMAGE_LIMIT = 5
	urlPreview = null

	get getDefaultWidth(): number {
		return DEFAULT_IMAGE_WIDTH
	}

	get getDefaultHeight(): number {
		return DEFAULT_IMAGE_HEIGHT
	}

	async created(): Promise<void> {
		await this.getImagePreview()
	}

	async getImagePreview(): Promise<void> {
		const response = await axios.get(this.ENDPOINT_IMAGE_API, {
			params: {
				page: this.ENDPOINT_IMAGE_PAGE,
				limit: this.ENDPOINT_IMAGE_LIMIT,
			},
		})

		if (response?.status === STATUS_SUCCESS) {
			const image = randomizeItemFromArray(response.data)

			if (image) {
				this.urlPreview = image.download_url
			}
		}
	}
}
