import API from '@/axios'
const Endpoint = '/perfis/convenios/'

export default {
	async getConvenio() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async visualizaConvenio(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async createAgreement(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async updateAgreement(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async deleteAgreement(id) {
		return await API.delete(Endpoint + id).then((response) => response)
	},

	async validarSigla(sigla) {
		return await API.post(Endpoint + 'validate/sigla', sigla).then((response) => response.data)
	},
}
