function removedArrayDuplicate({ arrayLoaded, newArray, keyToCompare }) {
	const concatItems = [...arrayLoaded, ...newArray]

	return concatItems.reduce((prevItem, currentItem) => {
		const searchItemDuplicate = prevItem.find((item) => {
			return item[keyToCompare] === currentItem[keyToCompare]
		})

		if (!searchItemDuplicate) {
			return prevItem.concat([currentItem])
		} else {
			return prevItem
		}
	}, [])
}

export { removedArrayDuplicate }
