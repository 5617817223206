import API from '@/axios'
const Endpoint = '/apikeys/apikeys'

export default {
	async getApiKeys() {
		return await API.get(Endpoint).then((response) => response)
	},

	async createApiKey(payload) {
		return await API.post(Endpoint, payload).then((response) => response)
	},

	async removeApiKey(id) {
		return await API.delete(Endpoint + `/${id}`).then((response) => response)
	},

	async removeApiKeys(ids) {
		return await API.delete(Endpoint + '/multiple', { data: { ids: ids } }).then(
			(response) => response
		)
	},
}
