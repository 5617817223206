/* eslint-disable @typescript-eslint/no-explicit-any */
import API from '@/axios'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import axios, { AxiosResponse } from 'axios'
import { ICustomer, ICustomerFilters } from '../store/types'

const Endpoint = '/apikeys/customers/'
const EndpointRotulo = '/rotulos/'

const clientAPI = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 30000,
})

const ZipcodeApi = axios.create({
	baseURL: 'https://viacep.com.br/ws/',
	timeout: 30000,
})

export default {
	async getCustomers(
		params: ICustomerFilters
	): Promise<AxiosResponse<ITypeOrmPaginationTemplate<ICustomer>>> {
		return await API.get(Endpoint, { params })
	},

	async getCustomer(uuid: string): Promise<AxiosResponse<ICustomer>> {
		return await API.get(`${Endpoint}/${uuid}`)
	},

	async restoreCustomer(uuid: string) {
		return await API.patch(`${Endpoint}/${uuid}/restore`).then((response) => response)
	},

	async getClientsLaboratories(token: string, organizacao: string) {
		return await clientAPI.get(Endpoint, {
			headers: {
				Authorization: 'Bearer ' + token,
				'organizacao-id': organizacao,
			},
		})
	},

	async importarClientes(titulo: string, importar: any, erros: any) {
		return await API.post(Endpoint + 'importar', { titulo, importar, erros }).then(
			(response) => response.data
		)
	},

	async createCustomer(payload: any): Promise<AxiosResponse<ICustomer>> {
		return await API.post(Endpoint, payload)
	},

	async updateCustomer(payload: any, uuid: string): Promise<AxiosResponse<ICustomer>> {
		return await API.put(Endpoint + uuid, payload)
	},

	async updateCustomerType({
		uuid,
		typeId,
	}: {
		uuid: string
		typeId: number
	}): Promise<AxiosResponse<ICustomer>> {
		return await API.patch(Endpoint + uuid + '/customer-type', {
			customer_type_id: typeId,
		})
	},

	async updateClientImage(payload: any, uuid: string) {
		return await API.post(Endpoint + uuid + '/update-image', payload).then(
			(response) => response.data
		)
	},

	async deleteCustomer(uuid: string) {
		return await API.delete(Endpoint + uuid)
	},

	async getHistoricoImportacao() {
		return await API.get(Endpoint + 'importar')
	},

	async visualizaHistorico(id: string) {
		return await API.get(Endpoint + 'importar/' + id)
	},

	async excluirHistoricoImportacao(id: string) {
		return await API.delete(Endpoint + /importar/ + id)
	},

	async validarCPF(cpf: string) {
		const novo_cpf = cpf.replace(/[^\d]/g, '')

		return await API.post(Endpoint + 'validate/cpf', { cpf: novo_cpf }).then(
			(response) => response.data
		)
	},

	async validarCNPJ(cnpj: string) {
		const novo_cnpj = cnpj.replace(/[^\d]/g, '')

		return await API.post(Endpoint + 'validate/cnpj', { cnpj: novo_cnpj }).then(
			(response) => response.data
		)
	},

	async validarEmail(email: string) {
		return await API.post(Endpoint + 'validate/email', { email: email }).then(
			(response) => response.data
		)
	},

	async selectTag(id: string, idCustomer: string) {
		return await API.post(EndpointRotulo + id + '/clientes', { id: idCustomer }).then(
			(response) => response.data
		)
	},

	async deselectTag(id: string, idCustomer: string) {
		return await API.delete(EndpointRotulo + id + '/clientes', {
			data: { id: idCustomer },
		})
	},

	async getAddressZipCode(cep: string) {
		return await ZipcodeApi.get(cep + '/json/')
	},

	async createLinkedCustomer(payload: any) {
		return await API.post(Endpoint + 'link-to/', payload).then((response) => response)
	},
}
