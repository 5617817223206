import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { SearchExamResultsState } from './types'
import { mutations } from './mutations'

export const state: SearchExamResultsState = {
	selectedSearchResultType: 'annotations',
	searchNotesIteratorSettings: null,
	searchResultsIteratorSettings: null,
	selectedResultText: null,
}

export default {
	namespaced: true,
	state,
	mutations,
} as Module<SearchExamResultsState, RootState>
