<template>
	<div
		ref="carousel"
		class="d-flex align-center overflow-auto carousel py-3"
		@wheel="scrollCarousel"
		@mousedown="handleMouseDown"
		@mouseleave="handleMouveLeave"
		@mouseup="handleMouseUp"
		@mousemove="handleMouseMove"
	>
		<slot name="items"></slot>
	</div>
</template>

<script>
export default {
	name: 'DragCarousel',

	data() {
		return {
			isDown: false,
			startX: 0,
			scrollLeft: 0,
		}
	},

	computed: {
		carousel() {
			return this.$refs['carousel']
		},
	},

	methods: {
		getDeltaY(delta) {
			if (delta >= 0) {
				return 335
			}

			return -335
		},

		scrollCarousel(e) {
			this.carousel.scrollLeft += this.getDeltaY(e.deltaY)
			e.preventDefault()
		},

		handleMouseDown(e) {
			this.isDown = true
			this.startX = e.pageX - this.carousel.offsetLeft
			this.scrollLeft = this.carousel.scrollLeft
		},

		handleMouveLeave(e) {
			this.isDown = false
		},

		handleMouseUp() {
			this.isDown = false
		},

		getWalk(walk) {
			if (walk >= 0) {
				return 670
			}

			return -670
		},

		handleMouseMove(e) {
			e.preventDefault()

			if (!this.isDown) return
			const x = e.pageX - this.carousel.offsetLeft
			const walk = (x - this.startX) * 3

			this.carousel.scrollLeft = this.scrollLeft - this.getWalk(walk)
		},
	},
}
</script>

<style scoped lang="scss">
.carousel {
	cursor: grab;
	gap: 14px;
	scroll-behavior: smooth;
	padding-left: 12px;
	padding-right: 6px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-snap-type: x mandatory !important;
}

.carousel::-webkit-scrollbar {
	display: none;
}

.items {
	display: flex;
}
</style>
