import { RootState } from '@/store/types'
import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'
import { Actions } from './actions/action-types'
import { actions } from './actions/actions'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { mutations } from './mutations/mutations'
import { BreedState } from './types'

export const state: BreedState = {
	breeds: {
		data: [],
		meta: {
			current_page: 1,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
		filters: {
			page: 1,
			limit: 10,
			search: undefined,
			speciesId: undefined,
		},
		loading: false,
	},
	openModalCreateBreed: false,
	openModalUpdateBreed: false,
	breedSelected: undefined,
	specieSelected: undefined,
}

export type Store<S = BreedState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}

export const BREED_NAMESPACE = 'Breeds'

export const breed: Module<BreedState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: actions,
	mutations: mutations,
}
