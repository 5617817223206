





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	name: 'Chip',
})
export default class Chip extends Vue {
	@Prop({ type: Boolean, default: true }) formatBullet!: boolean
	@Prop({ type: Boolean, default: false }) large!: boolean
	@Prop({ type: Boolean, default: true }) small!: boolean
	@Prop({ type: String, default: 'red' }) color!: string
	@Prop({ type: String, default: 'white' }) textColor!: string
	@Prop({ type: Boolean, default: true }) close!: boolean
	@Prop({ type: Boolean, default: false }) interactiveTag!: boolean
	@Prop({ type: String, default: 'ma-2' }) contentClass?: string
	@Prop({ type: Boolean, default: undefined }) handleClickEvent?: boolean
	@Prop({ type: Boolean, default: false }) isActive!: boolean
}
