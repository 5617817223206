import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
	{
		name: 'animais.index',
		path: '/animals',
		component: () => import(/* webpackChunkName: 'animals' */ './views/Animals.vue'),
	},

	{
		name: 'animais.perfil',
		path: '/animals/:id/details',
		component: () =>
			import(/* webpackChunkName: 'animals' */ '@/modules/animais/views/DetalhesAnimal.vue'),
	},
]

export default routes
