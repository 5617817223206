import { RootState } from '@/store/types'
import { unitsMeasurement } from '@/utils/constants/examConstants'
import { Module } from 'vuex'
import { actions } from './actions'
import { actions as attributeActions } from './attributes/actions'
import { getters as attributeGetters } from './attributes/getters'
import { state as attributeState } from './attributes/index'
import { mutations as attributeMutations } from './attributes/mutations'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions as referenceActions } from './references/actions'
import { getters as referenceGetters } from './references/getters'
import { state as referenceState } from './references/index'
import { mutations as referenceMutations } from './references/mutations'
import { AllExamState, ExamState } from './types'

export const state: ExamState = {
	examIsLoading: false,
	examIsSaving: false,
	exams: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},

	examModels: [],

	filters: {
		page: 1,
		limit: 10,
		search: '',
	},

	selectedExam: {
		id: 0,
		name: '',
		status: false,
		created_at: '',
		updated_at: '',
		forms: [],
		equipments: [],
		methods: [],
		organization_id: 0,
		abbreviation: '',
		category_id: 0,
		collection_instructions: '',
		due_date: 0,
		due_date_type: 'Days',
		price: 0,
		sku: '',
		labels: [],
	},
	examLayoutSheetModalOptions: {
		asideContract: false,
		rightSideCollapsed: true,
		selectedTab: 0,
		simpleAttributeMode: false,
	},
	examTemplateSheetModalOptions: {
		isOpen: false,
	},
	unitsMeasurement: unitsMeasurement,
}

export const exams: Module<AllExamState, RootState> = {
	namespaced: true,
	state: { ...state, ...referenceState, ...attributeState },
	actions: { ...actions, ...referenceActions, ...attributeActions },
	mutations: { ...mutations, ...referenceMutations, ...attributeMutations },
	getters: { ...getters, ...referenceGetters, ...attributeGetters },
}
