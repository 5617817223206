import { OrderState } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_ORDER_PER_STATUS_INDEX = 'getOrderPerStatusIndex',
	GET_ORDER_INDEX = 'getOrderIndex',

	GET_ORDER_INDEX_FILTER = 'getOrderIndexFilter',
	GET_ORDER_BY_ID = 'getOrderByID',
	GET_ORDER_FILTERED_BY_ID = 'getOrderFilteredByID',
	GET_INDEX_ATTACHMENT = 'getIndexAttachment',
	GET_UUID_ATTACHMENT = 'getUuidAttachment',
	GET_FILTERS_WITHOUT_NULL = 'getFiltersWithoutNull',
}

export type Getters<S = OrderState> = {
	[getterTypes.GET_ORDER_PER_STATUS_INDEX]: (
		state: S
	) => (data: { id: number | string; statusId: number }) => number

	[getterTypes.GET_ORDER_INDEX]: (state: S) => (id: number | string) => number

	[getterTypes.GET_ORDER_INDEX_FILTER]: (state: S) => (id: number | string) => number

	[getterTypes.GET_ORDER_BY_ID]: (state: S) => (id: number | string) => any

	[getterTypes.GET_ORDER_FILTERED_BY_ID]: (state: S) => (id: number | string) => any

	[getterTypes.GET_INDEX_ATTACHMENT]: (state: S) => (uuid: string) => number

	[getterTypes.GET_UUID_ATTACHMENT]: (state: S) => (uuid: string) => any

	[getterTypes.GET_FILTERS_WITHOUT_NULL]: (state: S) => any
}
