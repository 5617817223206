













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	name: 'AttributeReferenceTabButton',
})
export default class AttributeReferenceTabButton extends Vue {
	@Prop({ type: Boolean, default: false }) readonly isNewGroup!: boolean
	@Prop({ type: Boolean, default: false }) readonly loading!: boolean
	@Prop({ type: String, default: 'Valores de Referência' }) readonly groupName!: string
}
