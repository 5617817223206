/* eslint-disable no-unused-vars */
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'
import { Getters } from '../getters/getter-types'
import { ExamsGroupState, IExamsGroupPayload, IExamsGroupResponse } from '../types'
import { RootState } from '@/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IExamFilters } from '@/modules/exams/store/types'
import { IResponseExam } from '@/modules/exams/services/types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'

export enum actionTypes {
	GET_EXAMS_GROUPS = 'GET_EXAMS_GROUPS',
	GET_EXAMS_GROUPS_BY_SEARCH = 'GET_EXAMS_GROUPS_BY_SEARCH',

	GET_EXAMS = 'GET_EXAMS',

	CREATE_EXAMS_GROUP = 'CREATE_EXAMS_GROUP',
	UPDATE_EXAMS_GROUP = 'UPDATE_EXAMS_GROUP',
	REMOVE_EXAMS_GROUP = 'REMOVE_EXAMS_GROUP',

	UNLINK_EXAM = 'UNLINK_EXAM',

	CHANGE_STATUS_EXAMS_GROUP = 'CHANGE_STATUS_EXAMS_GROUP',
}

export interface Actions {
	[actionTypes.GET_EXAMS_GROUPS]({
		commit,
	}: AugmentedActionContext): Promise<ITypeOrmPaginationTemplate<IExamsGroupResponse> | boolean>

	[actionTypes.GET_EXAMS_GROUPS_BY_SEARCH](
		{ commit }: AugmentedActionContext,
		search?: string
	): Promise<ITypeOrmPaginationTemplate<IExamsGroupResponse> | boolean>

	[actionTypes.CREATE_EXAMS_GROUP](
		{ getters, commit }: AugmentedActionContext,
		payload: IExamsGroupPayload
	): Promise<IExamsGroupResponse | boolean>

	[actionTypes.UPDATE_EXAMS_GROUP](
		{ getters, commit }: AugmentedActionContext,
		{ examsGroupId, payload }: { examsGroupId: number; payload: IExamsGroupPayload }
	): Promise<IExamsGroupResponse | boolean>

	[actionTypes.REMOVE_EXAMS_GROUP](
		{ getters, commit }: AugmentedActionContext,
		examsGroupId: number
	): Promise<boolean>

	[actionTypes.GET_EXAMS](
		{ commit }: AugmentedActionContext,
		filters: IExamFilters
	): Promise<ITypeOrmPaginationTemplate<IResponseExam> | boolean>

	[actionTypes.UNLINK_EXAM](
		{ getters, commit }: AugmentedActionContext,
		{
			examsGroupId,
			examId,
		}: {
			examsGroupId: number
			examId: number
		}
	): Promise<IExamsGroupResponse | boolean>

	[actionTypes.CHANGE_STATUS_EXAMS_GROUP](
		{ getters, commit }: AugmentedActionContext,
		{
			examsGroupId,
			status,
		}: {
			examsGroupId: number
			status: boolean
		}
	): Promise<IExamsGroupResponse | boolean>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<ExamsGroupState, RootState>, 'getters' | 'commit' | 'dispatch'>
