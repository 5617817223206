






























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import InputSwitch from '@/components/atomic/atoms/Inputs/InputSwitch.vue'
import InputCheckBox from '@/components/atomic/atoms/Inputs/InputCheckBox.vue'
import { Action } from 'vuex-class'
import { actionTypes, IAttributeGroup } from '@/modules/exams/store/attributes/types'
import AlertConfirmation from '@/components/global/AlertConfirmation.vue'

@Component({
	name: 'ExamAttributeGroupDetailsAction',
	components: {
		AlertConfirmation,
		ButtonIcon,
		InputSwitch,
		InputCheckBox,
	},
})
export default class ExamAttributeGroupDetailsAction extends Vue {
	@Prop({ type: [Object, String], default: null }) contentClass?: string | unknown
	@Prop({ type: Object, required: true }) group!: IAttributeGroup
	@Prop({ type: Boolean, default: undefined }) loadingAttributeDuplicate?: boolean
	@PropSync('hideTitle', { type: Boolean, required: true }) hideTitleState!: boolean

	loadingAttributeRemove = false
	openAlertConfirmation = false
	alertConfirmationText = {
		title: 'Remover grupo',
		subtitle: 'Você tem certeza que deseja remover o grupo selecionado?',
	}

	@Action(`Exams/${actionTypes.DELETE_ATTRIBUTE_GROUP}`) removeAttributeGroup!: (
		id: number
	) => Promise<void>

	async handleRemoveAttributeGroup() {
		this.loadingAttributeRemove = true
		await this.removeAttributeGroup(this.group.id).catch(() => {
			this.loadingAttributeRemove = false
		})
		this.loadingAttributeRemove = false
	}
}
