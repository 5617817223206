import API from '@/axios'
const Endpoint = '/auth'
const PermissionEndpoint = '/permissions/auth/'

import { AxiosResponse } from 'axios'
import {
	IAuthGooglePayload,
	IAuthPayload,
	ILogInResponse,
	ILogInResponseByGoogle,
} from '../store/types'

export default {
	async logIn(payload: IAuthPayload): Promise<AxiosResponse<ILogInResponse>> {
		return await API.post(Endpoint + '/login', payload)
	},

	async logInByGoogle(
		payload: IAuthGooglePayload
	): Promise<AxiosResponse<ILogInResponseByGoogle>> {
		return await API.post(Endpoint + '/login/google', payload)
	},

	async logInByRefreshToken(refresh_token: string): Promise<AxiosResponse<ILogInResponse>> {
		return await API.post(Endpoint + '/login', { refresh_token: refresh_token })
	},

	async getPermissions() {
		return await API.get(PermissionEndpoint).then((response) => response)
	},
}
