import Vue from 'vue'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'

Vue.filter('dueDateFormat', (value) => {
	if (!value) return ''

	const dateISOString = new Date(value).toISOString()

	const dueDate = new Date(dateISOString)
	let todayDate = new Date()

	if (value.length <= 20) {
		todayDate = new Date(todayDate.getTime() + todayDate.getTimezoneOffset() * 60000)
	}

	let days = differenceInDays(dueDate, todayDate)
	let hours = differenceInHours(dueDate, todayDate) % 24
	let minutes = differenceInMinutes(dueDate, todayDate) % 60

	days = typeof days === 'number' ? (days === 0 ? '' : days + 'd ') : ''
	hours = typeof hours === 'number' ? (hours === 0 ? '' : hours + 'h ') : ''
	minutes = typeof minutes === 'number' ? (minutes === 0 ? '' : minutes + 'm ') : ''

	return days + hours + minutes
})
