import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import { STATUS_CREATED } from '@/utils/constants/statusConstants'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import i18n from '@/plugins/vue-i18n'

const statusSuccess = 200

let getMethodsDebounce = null

export default {
	namespaced: true,
	state: {
		loading: false,
		methods: [],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.methods.findIndex((method) => method.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.methods.find((method) => method.id === id)
			}
		},
	},

	mutations: {
		setMethods: (state, methods) => (state.methods = methods),

		addMethod: (state, method) => state.methods.push(method),

		updateMethod: (state, { index, method }) => state.methods.splice(index, 1, method),

		deleteMethod: (state, index) => state.methods.splice(index, 1),

		setLoading: (state, stateModal) => (state.loading = stateModal),
	},

	actions: {
		async getAll({ commit }) {
			try {
				if (getMethodsDebounce) return
				else {
					commit('setLoading', ENABLE_LOADING)
					const { status, data: methods } = await API.getMethods()

					if (status === statusSuccess) {
						commit('setMethods', methods)
					}

					commit('setLoading', DISABLE_LOADING)

					getMethodsDebounce = setTimeout(() => {
						getMethodsDebounce = null
					}, 60000)
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createMethod({ commit }, payload) {
			try {
				const { status, data: method } = await API.createMethod(payload)

				if (status === STATUS_CREATED) {
					commit('addMethod', method)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.methods.feedback.success.create.method') },
						{ root: true }
					)
				}

				return status === STATUS_CREATED
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateMethod({ commit, getters }, { id, payload }) {
			try {
				const { status, data: method } = await API.updateMethod(id, payload)

				if (status === statusSuccess) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateMethod', { index, method })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.methods.feedback.success.update.method') },
							{ root: true }
						)
					}
				}

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteMethod({ commit, getters }, id) {
			try {
				const { status } = await API.deleteMethod(id)

				if (status === statusSuccess) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteMethod', index)
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.methods.feedback.success.remove.method') },
							{ root: true }
						)
					}
				}

				return status === statusSuccess
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
