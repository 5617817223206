import Vue from 'vue'
import Portugues from '../i18n/modules/pt'
import English from '../i18n/modules/en'
import Spanish from '../i18n/modules/es'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
	en: {
		...English,
	},

	pt: {
		...Portugues,
	},

	es: {
		...Spanish,
	},
}

const i18n = new VueI18n({
	locale: 'pt',
	messages,
	silentFallbackWarn: true,
})

export default i18n
