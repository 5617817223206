import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { actionTypes, IMappedExamField } from '@/modules/exams/store/attributes/types'
import { ICreateAttributeMappedFieldPayload } from '@/modules/exams/services/attributes/types'
import { AxiosResponse } from 'axios'
import { IResponseExam } from '../services/types'

@Component
export default class CreateAttributeMappedFieldMixin extends Vue {
	@State((state) => state.Exams.selectedExam) selectedExam!: IResponseExam

	createAttributeMappedFieldDebouncing: NodeJS.Timeout | null = null

	@Action(`Exams/${actionTypes.CREATE_ATTRIBUTE_MAPPED_FIELD}`)
	createAttributeMappedField!: ({
		examId,
		payload,
	}: {
		examId: number
		payload: ICreateAttributeMappedFieldPayload
	}) => Promise<AxiosResponse<IMappedExamField | boolean>>

	async handleCreateAttributeMappedField({
		payload,
		examId,
	}: {
		payload: ICreateAttributeMappedFieldPayload
		examId: number
	}) {
		if (!this.selectedExam) throw new Error('Exam not loaded')

		try {
			if (this.createAttributeMappedFieldDebouncing)
				clearTimeout(this.createAttributeMappedFieldDebouncing)
			this.createAttributeMappedFieldDebouncing = setTimeout(() => {
				this.createAttributeMappedField({ examId, payload })
			}, 2000)
		} catch {
			throw new Error('Error on update attribute')
		}
	}
}
