import { IReferenceEditMode, ReferenceState } from './types'

export const state: ReferenceState = {
	references: [],
	referenceIsLoading: false,
	referenceIsSaving: false,
	selectedReference: null,
	referenceFilters: {
		limit: 10,
		page: 1,
		search: '',
	},
	referenceGroups: [],
	referencedFields: [],
	referenceEditMode: IReferenceEditMode.CURRENT_GROUP,
}
