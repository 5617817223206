import API from '@/axios'
const EndpointExam = '/models'
const EndpointEquipment = '/equipments'
const EndpointStatus = '/status'

export default {
	async getEquipments() {
		return await API.get(EndpointExam + EndpointEquipment).then((response) => response)
	},

	async createEquipment(payload) {
		return await API.post(EndpointExam + EndpointEquipment, payload).then(
			(response) => response
		)
	},

	async updateEquipment(id, payload) {
		return await API.put(EndpointExam + EndpointEquipment + '/' + id, payload).then(
			(response) => response
		)
	},

	async deleteEquipment(id) {
		return await API.delete(EndpointExam + EndpointEquipment + '/' + id).then(
			(response) => response
		)
	},

	async updateEquipmentStatus(id, payload) {
		return await API.patch(
			EndpointExam + EndpointEquipment + '/' + id + EndpointStatus,
			payload
		).then((response) => response)
	},

	async getEquipmentStatusList() {
		return await API.get(EndpointExam + EndpointEquipment + EndpointStatus).then(
			(response) => response
		)
	},
}
