import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { ENABLE_LOADING, DISABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { STATUS_SUCCESS, STATUS_CREATED } from '@/utils/constants/statusConstants'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		loading: false,
		carregado: false,
		racas: [],
		selecionado: {},
		paginas: 0,
		paginas_lidas: [],
		pagina_atual: 1,
		openModalCreateBreed: false,
		openModalUpdateBreed: false,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.racas.findIndex((raca) => raca.id === id)
			}
		},

		getID: (state) => {
			return (id) => {
				return state.racas.find((raca) => raca.id === id)
			}
		},
		// getEspecie: (state, rootState) => {
		// 	return (id) => {
		// 		return rootState['Especies'].especies.find(especie => especie.id === id)
		// 	}
		// }
	},

	mutations: {
		setRacas: (state, payload) => {
			state.racas = payload
			state.carregado = true
		},

		setSelecionado: (state, payload) => (state.selecionado = payload),
		resetBreedSelected: (state) => (state.selecionado = {}),
		setCarregado: (state, bool) => (state.carregado = bool),
		backPage: (state, payload) => (state.pagina_atual = payload),
		addBreed: (state, payload) => state.racas.push(payload),
		updateBreed: (state, { index, breed }) => state.racas.splice(index, 1, breed),
		deleteRaca: (state, index) => state.racas.splice(index, 1),
		resetSelecionado: (state) => (state.selecionado = {}),
		clearRacas: (state) => (state.racas = []),

		setOpenModalCreateBreed: (state, boolean) => (state.openModalCreateBreed = boolean),
		setOpenModalUpdateBreed: (state, boolean) => (state.openModalUpdateBreed = boolean),

		setLoading: (state, modalState) => (state.loading = modalState),
	},

	actions: {
		selecionar({ commit }, payload) {
			const raca = Object.assign({}, payload)

			commit('setSelecionado', raca)
		},

		async getAll({ state, commit }) {
			try {
				commit('setLoading', ENABLE_LOADING)

				if (!state.racas.length) {
					const response = await API.getRaca()

					if (response) {
						commit('setRacas', response)
					}
				}

				commit('setLoading', DISABLE_LOADING)

				return state.racas
			} catch (error) {
				commit('setLoading', DISABLE_LOADING)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createBread({ commit }, payload) {
			try {
				const { status, data: breed } = await API.createBread(payload)

				if (status === STATUS_CREATED) {
					commit('addBreed', breed)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.races.feedback.success.create.breed'),
						},
						{ root: true }
					)
				}

				return status === STATUS_CREATED ? breed : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateBreed({ commit, getters }, { payload, id }) {
			try {
				const { status, data: breed } = await API.updateBreed(payload, id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateBreed', { index, breed })

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.races.feedback.success.update.breed'),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS ? breed : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteRaca({ commit, getters }, id) {
			try {
				const { status } = await API.excluirRaca(id)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('deleteRaca', index)

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.races.feedback.success.remove.breed'),
							},
							{ root: true }
						)
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
