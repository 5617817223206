import { MutationTree } from 'vuex'
import { ErrorsState, types } from './types'

interface CustomError {
	response?: {
		message: string
	}
	data: {
		message: string
	}
	message: string
	status: number
}

const state: ErrorsState = {
	errors: [],
}

const mutations: MutationTree<ErrorsState> = {
	[types.ADD_ERROR](state, error: CustomError) {
		if (process.env.NODE === 'development') {
			console.log(error)
		}

		if (error.status === 503) {
			state.errors.push('Serviço indisponivel')
		} else {
			if (error.data?.message) {
				state.errors.push(error.data.message)
			} else {
				if (error.message) {
					state.errors.push(error.message)
				} else if (Object.keys(error.data).length) {
					state.errors.push(
						'Ops! Parece que algo está errado. Por favor, verifique seus campos no formulário e tente novamente.'
					)
				} else {
					state.errors.push('Erro Inesperado.')
				}
			}
		}
	},
	[types.REMOVE_ERROR](state, index: number) {
		state.errors.splice(index, 1)
	},
	[types.REMOVE_ALL_ERRORS](state) {
		state.errors = []
	},
}

export default {
	namespaced: true,
	state,
	mutations,
}
