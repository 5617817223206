/* eslint-disable no-unused-vars */
import { IAttributeType } from '@/modules/exams/store/attributes/types'

// Attributes Constants
export enum AttributeType {
	INTEGER_TYPE = 'integer',
	DECIMAL_TYPE = 'decimal',
	PERCENTAGE_TYPE = 'percentage',
	MULTIPLE_CHOICE_TYPE = 'select',
	TEXT_TYPE = 'text',
	LONG_TEXT_TYPE = 'textarea',
	FORMULA_TYPE = 'formula',
	IMAGE_TYPE = 'image',
}

export const INTEGER_TYPE = 'integer'
export const DECIMAL_TYPE = 'decimal'
export const PERCENTAGE_TYPE = 'percentage'
export const MULTIPLE_CHOICE_TYPE = 'select'
export const TEXT_TYPE = 'text'
export const LONG_TEXT_TYPE = 'textarea'
export const FORMULA_TYPE = 'formula'
export const IMAGE_TYPE = 'image'

export const ATTRIBUTE_TEXT_ID = 1
export const ATTRIBUTE_LONG_TEXT_ID = 2
export const ATTRIBUTE_DATE_ID = 3
export const ATTRIBUTE_INTEGER_ID = 4
export const ATTRIBUTE_DECIMAL_ID = 5
export const ATTRIBUTE_BOOLEAN_ID = 6
export const ATTRIBUTE_IMAGE_ID = 7
export const ATTRIBUTE_FORMULA_ID = 8
export const ATTRIBUTE_MULTIPLE_CHOICE_ID = 9
export const ATTRIBUTE_PERCENTAGE_ID = 10

export const attributesType: IAttributeType[] = [
	{
		id: ATTRIBUTE_LONG_TEXT_ID,
		type: AttributeType.LONG_TEXT_TYPE,
		slug: 'textarea',
		icon: 'mdi-text-long',
	},

	{
		id: ATTRIBUTE_INTEGER_ID,
		type: AttributeType.INTEGER_TYPE,
		slug: 'integer',
		icon: 'mdi-numeric',
	},

	{
		id: ATTRIBUTE_DECIMAL_ID,
		type: AttributeType.DECIMAL_TYPE,
		slug: 'decimal',
		icon: 'mdi-decimal-comma',
	},

	{
		id: ATTRIBUTE_MULTIPLE_CHOICE_ID,
		type: AttributeType.MULTIPLE_CHOICE_TYPE,
		slug: 'select',
		icon: 'mdi-circle-slice-8',
	},

	{
		id: ATTRIBUTE_PERCENTAGE_ID,
		type: AttributeType.PERCENTAGE_TYPE,
		slug: 'percentage',
		icon: 'mdi-percent-outline',
	},

	{
		id: ATTRIBUTE_FORMULA_ID,
		type: AttributeType.FORMULA_TYPE,
		slug: 'formula',
		icon: 'mdi-calculator-variant-outline',
	},

	{
		id: ATTRIBUTE_IMAGE_ID,
		type: AttributeType.IMAGE_TYPE,
		slug: 'image',
		icon: 'mdi-image',
	},
]

export const unitsMeasurement = [
	'mm3',
	'/mm3',
	'milhões/mm3',
	'fL',
	'pg',
	'g/dL',
	'U/L',
	'mg/dL',
	'mL',
	'ng/mL',
	'ng/dL',
	'mL/min',
	'mEq/L',
	'ug/dL',
	'uU/mL',
	'mOsm/kg',
]
