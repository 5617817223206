// import exames from '@/modules/requisicoes/json/exames.json'
import API from '../service'
import APIOrganization from '../../organizacoes/service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { ORDER_DRAFT_STATUS_ID } from '@/utils/constants/orderConstants'

export default {
	namespaced: true,

	state: {
		requests: {
			data: [],
			links: {},
			meta: {},
		},
		registers: {
			data: [],
			links: {},
			meta: {},
		},
		selectedRequest: {},
		laboratoriesSelected: {
			loading: true,
			data: [],
		},
		loaderLaboratories: false,
		carregado: false,
		examesSolicitacao: [],
		requestForm: null,
		examsProfilesRequest: [],
		criterios: [
			{
				id: 1,
				nome: 'modules.requests.table.criters.examProgress',
				icone: 'mdi-clock-outline',
			},
			{
				id: 2,
				nome: 'modules.requests.table.criters.RequestProgress',
				icone: 'mdi-autorenew',
			},
			{
				id: 3,
				nome: 'modules.requests.table.criters.examLabels',
				icone: 'mdi-label-outline',
			},
			{
				id: 4,
				nome: 'modules.requests.table.criters.urgentRequests',
				icone: 'mdi-alert-circle-outline',
			},
		],
		andamentos: [
			{ id: 1, nome: 'modules.requests.table.andaments.draft' },
			{ id: 2, nome: 'modules.requests.table.andaments.newItem' },
			{ id: 3, nome: 'modules.requests.table.andaments.waitingForRoute' },
			{ id: 4, nome: 'modules.requests.table.andaments.inTransit' },
			{ id: 5, nome: 'modules.requests.table.andaments.noncompliance' },
			{ id: 6, nome: 'modules.requests.table.andaments.inProgress' },
			{ id: 7, nome: 'modules.requests.table.andaments.finished' },
			{ id: 8, nome: 'modules.requests.table.andaments.canceled' },
			{ id: 0, nome: 'default.none' },
		],
		progressos: [
			{
				id: 1,
				nome: 'modules.requests.table.progress.canceled',
				cor: '#FF595D',
				icone: 'mdi-close-circle-outline',
			},
			{
				id: 2,
				nome: 'modules.requests.table.progress.waitingForSample',
				cor: '#F2994A',
				icone: 'mdi-map-clock-outline',
			},
			{
				id: 3,
				nome: 'modules.requests.table.progress.prevented',
				cor: '#FFD34D',
				icone: 'mdi-clock-alert-outline',
			},
			{
				id: 4,
				nome: 'modules.requests.table.progress.processing',
				cor: '#4AA8FF',
				icone: 'mdi-progress-clock',
			},
			{
				id: 5,
				nome: 'modules.requests.table.progress.concluded',
				cor: '#44BBA4',
				icone: 'mdi-clock-check-outline',
			},
		],
		customer: {
			checkout: {
				openDialogCheckout: false,
			},
			counter: {
				openSheetCounter: false,
			},
		},
		orders: [],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.requests.data.findIndex((request) => request.id === id)
			}
		},
		getParsedRequests: (state) => {
			const requests = []

			state.requests.data.forEach((request) => {
				let requesting_customer = null

				if (request['cliente_requisitante']) {
					requesting_customer = {
						id: request['cliente_requisitante']['id'],
						corporate_name: request['cliente_requisitante']['razao_social'] || null,
						fantasy_name: request['cliente_requisitante']['nome_fantasia'],
						avatar: request['cliente_requisitante']['avatar'],
					}
				}

				let intermediary_customer = null

				if (request['cliente_intermediario']) {
					intermediary_customer = {
						id: request['cliente_intermediario']['id'],
						corporate_name: request['cliente_intermediario']['razao_social'] || null,
						fantasy_name: request['cliente_intermediario']['nome_fantasia'],
						avatar: request['cliente_intermediario']['avatar'],
					}
				}

				let tutor = null

				if (request['tutor']) {
					tutor = {
						id: request['tutor']['id'],
						corporate_name: request['tutor']['razao_social'] || null,
						fantasy_name: request['tutor']['nome_fantasia'],
						avatar: request['tutor']['avatar'],
					}
				}

				let patient = null

				if (request['paciente']) {
					patient = {
						id: request['paciente']['id'],
						auid: request['paciente']['auid'],
						name: request['paciente']['nome'],
						avatar: request['paciente']['avatar'],
					}
				}

				let status = null

				if (request['status']) {
					status = {
						id: request['status']['id'],
						name: request['status']['nome'],
					}
				}

				let exams = null

				if (request['exames']) {
					exams = []
					request.exames.forEach((exam) => {
						const responsible_technicians = []

						if (exam.responsaveis_tecnicos) {
							exam.responsaveis_tecnicos.forEach((responsible_technician) => {
								responsible_technicians.push({
									id: responsible_technician['id'],
									nome: responsible_technician['nome'],
									avatar: responsible_technician['avatar'],
								})
							})
						}

						const containers = []

						if (exam.recipientes) {
							exam.recipientes.forEach((container) => {
								containers.push({
									id: container['id'],
									name: container['nome'],
									subtitle: container['legenda'],
									icon: container['icone'],
									color: container['cor'],
								})
							})
						}

						let examStatus = null

						if (exam['status']) {
							examStatus = {
								id: exam['status']['id'],
								name: exam['status']['nome'],
							}
						}

						exams.push({
							id: exam['id'],
							name: exam['nome'],
							initials: exam['sigla'],
							deadline: exam['prazo'],
							deadline_type: exam['tipo_de_prazo'],
							number: exam['numer'],
							category: {
								id: exam['categoria']['id'],
								name: exam['categoria']['nome'],
							},
							responsible_technicians,
							status: examStatus,
							containers,
						})
					})
				}

				let samples = null

				if (request['amostras']) {
					samples = []
					request.amostras.forEach((sample) => {
						samples.push({
							id: sample['id'],
							number: sample['numero'],
							collection_date: sample['data_de_coleta'],
							entry_date: sample['data_de_entrada'],
							container_id: sample['recipiente_id'],
							created_at: sample['created_at'],
							updated_at: sample['updated_at'],
							type: {
								id: sample['tipo']['id'],
								name: sample['tipo']['nome'],
								expiration_date: sample['tipo']['prazo_de_validade'],
								deadline_type: sample['tipo']['tipe_de_prazo'],
							},
						})
					})
				}

				requests.push({
					id: request['id'],
					organization_id: request['organizacao_id'],
					number: request['numero'],
					statusId: request['status_id'],
					requesting_custumer_id: request['cliente_requisitante_id'],
					tutor_id: request['tutor_id'],
					patient_id: request['paciente_id'],
					intermediary_customer_id: request['cliente_intermediario_id'],
					requested_in: request['solicitado_em'],
					started_in: request['iniciado_em'],
					finished_in: request['concluido_em'],
					created_at: request['created_at'],
					update_at: request['updated_at'],
					deleted_at: request['deleted_at'],
					exams_count: request['exames_count'],
					exams,
					samples,
					requesting_customer,
					intermediary_customer,
					tutor,
					patient,
					status,
				})
			})

			return requests
		},

		getIndexExamChildrenRequest: (state) => {
			return ({ indexRequest, idExam }) => {
				return state.requests.data[indexRequest]?.requested_exams?.findIndex(
					(exam) => exam.id === idExam
				)
			}
		},

		getIndexLaboratories: (state) => {
			return (uuid) => {
				return state.laboratoriesSelected.data.findIndex(
					(laboratory) => laboratory.uuid === uuid
				)
			}
		},

		getIndexOrders: (state) => {
			return (id) => {
				return state.orders.findIndex((order) => order.id === id)
			}
		},

		getIndexExam: (state) => {
			return (id) => {
				return state.examsProfilesRequest.findIndex((exam) => exam.id === id)
			}
		},

		getIndexProfile: (state) => {
			return (id) => {
				return state.examsProfilesRequest.findIndex((profile) => profile.id === id)
			}
		},

		hasActiveRequests: (state) => {
			return !!state.requests.data.filter(
				(request) => request.status.id !== ORDER_DRAFT_STATUS_ID
			).length
		},
	},

	mutations: {
		setRequests: (state, payload) => {
			state.requests = Object.assign({}, payload)
		},

		setRegisters: (state, payload) => {
			const registers = [...state.registers.data, ...payload.data]

			const uniq = [...new Set(registers.map(({ id }) => id))].map((requestId) =>
				registers.find(({ id }) => id == requestId)
			)

			state.registers = Object.assign({}, payload)
			state.registers.data = [...uniq]
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.requests = {
				data: [],
				links: {},
				meta: {},
			}
			state.registers = {
				data: [],
				links: {},
				meta: {},
			}
		},

		setSelected: (state, payload) => (state.selectedRequest = payload),

		addRequest: (state, payload) => state.requests.data.push(payload),

		addRegister: (state, payload) => state.registers.data.push(payload),

		updateRequest: (state, { index, payload }) => {
			state.requests.data.splice(index, 1, { ...state.requests.data[index], ...payload })
		},

		updateStatusRequest: (state, { index, status }) => {
			state.requests.data[index].status = status
		},

		resetExamCountRequest: (state, { index }) => {
			state.requests.data[index].requested_exams_count = 0
			state.requests.data[index].requested_exams = []
		},

		updatePriorityRequest: (state, { index, priority }) => {
			state.requests.data[index].priority = priority
		},

		setRequestCanceled: (state, { indexRequest, cancellation_reason }) => {
			state.requests.data[indexRequest].cancellation_reason = cancellation_reason
		},

		updateStatusExamChildrenRequest: (state, { indexRequest, indexExam, status }) => {
			state.requests.data[indexRequest].requested_exams[indexExam].status = status
		},

		updatePriorityExamChildrenRequest: (state, { indexRequest, indexExam, priority }) => {
			state.requests.data[indexRequest].requested_exams[indexExam].priority = priority
		},

		updateRegister: (state, { index, payload }) => {
			state.requests.data.splice(index, 1, payload)
		},

		removeRequest: (state, index) => state.requests.data.splice(index, 1),

		removeRegister: (state, { index, payload }) => {
			state.requests.data.splice(index, 1, payload)
		},

		setOpenDialog: (state, customer) => {
			if (customer === 'checkout') {
				state.customer.checkout.openDialogCheckout = true
			} else {
				state.customer.counter.openSheetCounter = true
			}
		},

		setCloseDialog: (state, customer) => {
			if (customer === 'checkout') {
				state.customer.checkout.openDialogCheckout = false
			} else {
				state.customer.counter.openSheetCounter = false
			}
		},

		setRequestForm: (state, payload) => {
			state.requestForm = Object.assign({}, payload)
		},

		setResetRequestForm: (state) => {
			state.requestForm = null
		},

		setLaboratories: (state, payload) => {
			state.laboratoriesSelected.data = Array.from(payload)
			state.loaderLaboratories = true
			state.laboratoriesSelected.loading = false
		},

		updateLaboratory: (state, { index, payload }) => {
			state.laboratoriesSelected.data.splice(index, 1, payload)
		},

		setExamsLaboratory: (state, { exames, perfis }) => {
			exames = exames.filter((exam) => exam.id)
			perfis = perfis.filter((profile) => profile.id)

			const exams = []
			const profiles = []

			exames.forEach((exam) => {
				exams.push(Object.assign(exam, { check: false }))
			})

			perfis.forEach((profile) => {
				exams.push(Object.assign(profile, { check: false }))
			})

			state.examsProfilesRequest = [...exams, ...profiles]
		},

		changeCheckExamProfile: (state, { index, check }) => {
			state.examsProfilesRequest[index].check = check
		},

		setResetExamsLaboratory: (state) => (state.examsProfilesRequest = []),

		setAddOrders: (state, payload) => {
			const itemOrder = Object.assign({}, payload)

			state.orders.push(itemOrder)
		},

		setRemoveOrders: (state, index) => {
			state.orders.splice(index, 1)
		},

		setResetOrders: (state) => (state.orders = []),

		setStatusReceiving: (state, { index, status }) => {
			const request = Object.assign(state.requests.data[index], { status: status })

			state.requests.data.splice(index, 1, request)
		},

		updateExamChildrenRequestDeletedAt: (state, { indexRequest, indexExam, deleted_at }) => {
			state.requests.data[indexRequest].requested_exams[indexExam].deleted_at = deleted_at
		},

		updateExamChildrenRequestStatus: (state, { indexRequest, indexExam, status }) => {
			state.requests.data[indexRequest].requested_exams[indexExam].status = status
		},

		addExamChildrenRequest: (state, { indexRequest, exams }) => {
			for (const exam of exams) {
				state.requests.data[indexRequest].requested_exams.push(exam)
				state.requests.data[indexRequest].requested_exams_count++
			}
		},

		removeExamChildrenRequest: (state, { indexRequest, indexExam }) => {
			state.requests.data[indexRequest].requested_exams.splice(indexExam, 1)
			state.requests.data[indexRequest].requested_exams_count =
				state.requests.data[indexRequest].requested_exams.length
		},
	},

	actions: {
		selecionar({ commit }, payload) {
			commit('setSelected', Object.assign({}, payload))
		},

		async getAll({ commit, state }, { page, perPage, status, order }) {
			try {
				const response = await API.getRequests(page, perPage, status, order)

				if (response) {
					commit('setRequests', response)
				}

				return state.requests
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllRegisters(
			{ commit },
			{
				page = 1,
				perPage = 25,
				search,
				requestExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
				saveToStore = true,
			}
		) {
			try {
				const response = await API.searchRequests(
					page,
					perPage,
					search,
					requestExamStatus,
					requestedExamPriority,
					orderDirection,
					examRequestStatus,
					examRequestPriority,
					orderBy,
					createdAfter,
					createdBefore,
					orderId
				)

				if (response && saveToStore) {
					commit('setRequests', response)
					commit('setRegisters', response)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllLaboratories({ commit, state }) {
			try {
				if (!state.loaderLaboratories) {
					const laboratories = await APIOrganization.getOrganizacoesLaboratories()

					commit('setLaboratories', laboratories)
				}

				return state.laboratoriesSelected.data
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateRequestStatus({ commit }, payload) {
			try {
				const id = payload.id

				delete payload[id]
				return await API.updateRequestStatus(payload.id, payload)
				// const index = getters.getIndex(id)

				// if (request.id) {
				// 	commit('updateRequest', { index, payload: request })
				// 	return request
				// }
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeRequest({ commit, getters }, { id }) {
			try {
				const request = await API.visualizeRequest(id)

				const index = getters.getIndex(id)

				if (request.id) {
					commit('updateRequest', { index, payload: request })
				}

				return request
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeLaboratory({ commit, getters }, uuid) {
			try {
				const laboratory = await APIOrganization.visualizarOrganizacao(uuid)

				const index = getters.getIndexLaboratories(uuid)

				if (laboratory.id) {
					commit('updateLaboratory', { index, payload: laboratory })
					commit('setExamsLaboratory', laboratory)
					return laboratory
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createRequestCheckout({ commit }, payload) {
			try {
				const request = await API.createRequestCheckout(payload)

				if (request) {
					commit('addRequest', request)
					return request
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createRequest({ commit }, payload) {
			try {
				const request = await API.createRequest(payload)

				if (request) {
					commit('addRequest', request)
					commit('setSelected', request)
				}

				return request
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateRequest({ commit }, payload) {
			try {
				const id = payload.id
				const response = await API.updateRequest(payload, id)

				// const index = getters.getIndex(id)

				// commit('updateRequest', { index, payload: response })
				commit('setSelected', response)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeRequest({ commit, getters }, { id, removeFromList = false }) {
			try {
				const response = await API.removeRequest(id)

				if (removeFromList) {
					const index = getters.getIndex(id)

					commit('removeRequest', index)
				}

				return response ? response : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async getExamResult({ commit }, { requestId, examId }) {
			try {
				console.log(await API.getResult(requestId, examId))
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async addExamResult({ commit }, { requestId, examId, payload }) {
			try {
				await API.addExamResult({ examId, payload, requestId })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async assignExamResult({ commit }, { requestId, examId, payload }) {
			try {
				await API.assignExamResult({ examId, payload, requestId })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		setRemoveOrders({ commit, getters }, payload) {
			const id = payload.id
			const index = getters.getIndexOrders(id)

			commit('setRemoveOrders', index)

			// if(payload.exames && payload.exames.length) {
			// 	payload.exames.forEach(exam => {
			// 		const indexExam = state.orders.findIndex(order => order.id === exam.id && order.perfil === exam.perfil)
			// 		console.log(state.orders)
			// 		if(indexExam > -1) {
			// 			commit('setRemoveOrders', indexExam)
			// 		}
			// 	})
			// }
		},

		async updateRequestStatusModal({ commit, getters }, payload) {
			try {
				const id = payload.id
				const status = Object.assign(payload.status, { status_id: payload.status.id })

				const response = await API.updateRequestStatus(payload.id, status)

				if (response) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('setStatusReceiving', { index, status: status })
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{ title: i18n.t('modules.receiving.alerts.success.requestUpdated') },
							{ root: true }
						)
					}
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
