import { IBreedQueryFilters } from './../store/types'
import API from '@/axios'
import { IBreedPayload } from '../store/types'
const EndpointAnimalV1 = '/animals/v1'
const EndpointAnimalV2 = '/animals/v2'
const BreedsEndpoint = '/breeds'

export default {
	async getBreeds(filters: IBreedQueryFilters) {
		return await API.get(EndpointAnimalV2 + BreedsEndpoint, { params: filters })
	},

	async createBreed(payload: IBreedPayload) {
		return await API.post(EndpointAnimalV1 + BreedsEndpoint, payload)
	},

	async updateBreed({ payload, id }: { payload: IBreedPayload; id: number }) {
		return await API.put(EndpointAnimalV1 + BreedsEndpoint + `/${id}`, payload)
	},

	async deleteBreed(id: number) {
		return await API.delete(EndpointAnimalV1 + BreedsEndpoint + `/${id}`)
	},
}
