import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { CustomersState, ICustomer, ICustomerFilters } from './types'

export const getters: GetterTree<CustomersState, RootState> = {
	getCustomerIndexByUuid:
		(state) =>
		(uuid: string): number => {
			return state.customers.data.findIndex((customer) => customer.uuid === uuid)
		},
	getCustomerIndexById:
		(state) =>
		(id: number): number => {
			return state.customers.data.findIndex((customer) => customer.id === id)
		},

	getCustomerIndexByAgreementCustomerId:
		(state) =>
		(agreementCustomerId: number): number => {
			return state.customers.data.findIndex(
				(customer) => customer.agreement?.id === agreementCustomerId
			)
		},

	getCustomerByUuid:
		(state) =>
		(uuid: string): ICustomer | undefined => {
			return state.customers.data.find((customer) => customer.uuid === uuid)
		},

	getFormattedFiltersWithoutVoids(state) {
		const filteredData: Partial<ICustomerFilters> = Object.entries(state.filters).reduce(
			(acc, [key, value]) => {
				if (value !== undefined && value !== null && value !== '') {
					// eslint-disable-next-line no-param-reassign
					acc = { ...acc, [key]: value }
				}

				return acc
			},
			{}
		)

		return filteredData
	},

	getAllLoadings(state: any) {
		return state.customerIsLoading || state.referenceIsLoading || state.attributeIsLoading
	},
	getAllSavings(state: any) {
		return state.customerIsSaving || state.referenceIsSaving || state.attributeIsSaving
	},
}

export default getters
