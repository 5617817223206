import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import { mutationTypes as examMutationTypes } from '@/modules/exams/store/types'
import { mutationTypes as searchExamResultsMutationTypes } from '@/components/global/SearchExamResults/store/types'

@Component
export default class HandleWithSearchResultsMixin extends Vue {
	@State(({ SearchExamResults }) => SearchExamResults.selectedResultText)
	selectedResultText!: string

	@Mutation(`Exams/${examMutationTypes.UPDATE_RIGHT_SIDE_COLLAPSED}`)
	updateRightSideCollapsed!: (value: boolean) => void

	@Mutation(`SearchExamResults/${searchExamResultsMutationTypes.SET_SELETED_SEARCH_RESULT_TYPE}`)
	setSeletedSearchResultType!: (value: 'annotations' | 'previousResults') => void

	@Mutation(`SearchExamResults/${searchExamResultsMutationTypes.SET_SELETED_RESULT_TEXT}`)
	setSeletedResultText!: (value: string) => void

	handleOpenSearchAnnotations() {
		this.updateRightSideCollapsed(false)
		this.setSeletedSearchResultType('annotations')
	}

	handleOpenSearchPreviousResults() {
		this.updateRightSideCollapsed(false)
		this.setSeletedSearchResultType('previousResults')
	}
}
