import { ITypeOrmPaginationTemplate } from './../../../../utils/interfaces/typeormPagination'
/* eslint-disable no-unused-vars */
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'
import { AnimalState, IAnimalFilters, IAnimalFiltersCustomerId, IAnimalResponse } from '../types'
import { RootState } from '@/store/types'
import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
import { Getters } from '../getters/getter-types'

export enum actionTypes {
	GET_ANIMALS = 'GET_ANIMALS',

	GET_ANIMALS_BY_PET_OWNER = 'GET_ANIMALS_BY_PET_OWNER',

	CREATE_ANIMAL = 'CREATE_ANIMAL',
	UPDATE_ANIMAL = 'UPDATE_ANIMAL',
	REMOVE_ANIMAL = 'REMOVE_ANIMAL',
	UNLINK_ANIMAL = 'UNLINK_ANIMAL',
}

export interface Actions {
	[actionTypes.GET_ANIMALS](
		{ getters, commit }: AugmentedActionContext,
		filters: IAnimalFilters
	): Promise<ITypeOrmPaginationTemplate<IAnimalResponse> | boolean>

	[actionTypes.GET_ANIMALS_BY_PET_OWNER](
		{ getters, commit }: AugmentedActionContext,
		filters: IAnimalFiltersCustomerId
	): Promise<ITypeOrmPaginationTemplate<IAnimalResponse> | boolean>

	[actionTypes.CREATE_ANIMAL](
		{ state, getters, commit }: AugmentedActionContext,
		payload: FormData
	): Promise<IAnimalResponse | boolean>

	[actionTypes.UPDATE_ANIMAL](
		{ state, getters, commit }: AugmentedActionContext,
		{ animalId, payload }: { animalId: number; payload: FormData }
	): Promise<IAnimalResponse | boolean>

	[actionTypes.REMOVE_ANIMAL](
		{ getters, commit }: AugmentedActionContext,
		animalId: number
	): Promise<unknown | boolean>

	[actionTypes.UNLINK_ANIMAL](
		{ getters, commit }: AugmentedActionContext,
		data: { animalId: number; actorId: number }
	): Promise<unknown | boolean>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof any>(
		key: string,
		payload?: Parameters<any>[1],
		root?: { root: boolean }
	): ReturnType<any>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<AnimalState, RootState>, 'getters' | 'commit' | 'dispatch'>
