import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { SpecieState } from '../types'
import { Actions, actionTypes as types } from './action-types'
import { mutationTypes } from '../mutations/mutation-types'
import { getterTypes } from '../getters/getter-types'
import API from '../../service'
import { STATUS_CREATED, STATUS_NOT_FOUND, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'

export const actions: ActionTree<SpecieState, RootState> & Actions = {
	async [types.GET_SPECIES]({ commit }, filters) {
		try {
			commit(mutationTypes.SET_LOADING_SPECIES, ENABLE_LOADING)

			const { status, data: species } = await API.getSpecies(filters)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_SPECIES, species)
			}

			commit(mutationTypes.SET_LOADING_SPECIES, DISABLE_LOADING)

			return status === STATUS_SUCCESS ? species : false
		} catch (error: any) {
			commit(mutationTypes.SET_LOADING_SPECIES, DISABLE_LOADING)

			if (error.response.status === STATUS_NOT_FOUND) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}

			return false
		}
	},

	async [types.CREATE_SPECIE]({ commit }, payload) {
		const { status, data: specie } = await API.createSpecie(payload)

		if (status === STATUS_CREATED) {
			commit(mutationTypes.CREATE_SPECIE, { specie })

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{
					title: i18n.t('modules.species.feedback.success.create.specie'),
				},
				{ root: true }
			)
		}

		return status === STATUS_CREATED ? specie : false
	},

	async [types.UPDATE_SPECIE]({ getters, commit }, { specieId, payload }) {
		const { status, data: specie } = await API.updateSpecie({
			id: specieId,
			payload: payload,
		})

		if (status === STATUS_SUCCESS) {
			const indexSpecie = getters[getterTypes.GET_INDEX_SPECIE](specieId)

			if (indexSpecie > -1) {
				commit(mutationTypes.UPDATE_SPECIE, { index: indexSpecie, specie: specie })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.species.feedback.success.update.specie'),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS ? specie : false
	},

	async [types.REMOVE_SPECIE]({ getters, commit }, specieId) {
		const { status } = await API.deleteSpecie(specieId)

		if (status === STATUS_SUCCESS) {
			const indexSpecie = getters[getterTypes.GET_INDEX_SPECIE](specieId)

			if (indexSpecie > -1) {
				commit(mutationTypes.REMOVE_SPECIE, { index: indexSpecie })

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.species.feedback.success.remove.specie'),
					},
					{ root: true }
				)
			}
		}

		return status === STATUS_SUCCESS
	},
}

export default { actions }
