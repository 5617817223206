import API from '../service'
import moment from 'moment'
import Anos from '../json/anos.json'
import Meses from '../json/meses.json'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		carregado: false,
		selecionado: {},
		animais: [],
		animal: {},
		animalHistory: {},
		especies: [],
		racas: [],
		sexos: [
			{ id: 1, nome: 'Macho', value: 'M' },
			{ id: 2, nome: 'Fêmea', value: 'F' },
			{ id: 3, nome: 'Indefinido', value: 'I' },
		],
		fertilidades: [
			{ id: 1, nome: 'Fértil', value: false },
			{ id: 2, nome: 'Castrado', value: true },
		],
		anos: Anos,
		meses: Meses,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.animais.findIndex((animal) => animal.id === id)
			}
		},

		getIndexTag: (state) => {
			return ({ indexAnimal, idTag }) => {
				return state.animais[indexAnimal].rotulos.findIndex((tag) => tag.id === idTag)
			}
		},

		getAvatar: () => {
			return (logo) => {
				if (!logo) {
					return 'images/pet_default.svg'
				}

				if (logo !== '' && logo !== null) {
					if (logo === '/images/avatar_juridica.png') {
						return 'images/pet_default.svg'
					}

					return logo
				}

				return '/images/animal-image-register.svg'
			}
		},

		getID: (state) => {
			return (id) => {
				return state.animais.find((animal) => animal.id == id)
			}
		},
	},

	mutations: {
		getIdade: (state, data) => {
			const dataMoment = moment(data, 'DD/MM/YYYY')
			let ano = moment().diff(dataMoment, 'years', false)
			const mes = moment().diff(dataMoment.add(ano, 'years'), 'month', false)

			ano = ano > 1 ? ano + ' anos, ' : ano + ' ano'
			const idade = ano + mes + ' meses'

			state.animal.idade = idade // Passa a idade para o animal
		},

		setAnimais: (state, payload) => {
			state.animais = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.animais = []
		},

		setAnimalHistory: (state, payload) => (state.animalHistory = payload),
		setSelecionado: (state, payload) => (state.selecionado = payload),
		deselecionar: (state) => (state.selecionado = {}),
		addAnimal: (state, payload) => state.animais.push(payload),
		updateAnimal: (state, { index, animal }) => state.animais.splice(index, 1, animal),
		deleteAnimal: (state, index) => state.animais.splice(index, 1),
		clearIdade: (state) => (state.animal = {}), // Limpa a idade do animal

		addAnimalTag: (state, { indexAnimal, tag }) => {
			state.animais[indexAnimal].rotulos.push(tag)
		},

		deselectAnimalTag: (state, { indexAnimal, indexTag }) => {
			state.animais[indexAnimal].rotulos.splice(indexTag, 1)
		},

		deselectAllAnimalTag: (state, idTag) => {
			state.animais.forEach((animal) => {
				const index = animal.rotulos.findIndex((tag) => tag.id === idTag)

				if (index > -1) {
					animal.rotulos.splice(index, 1)
				}
			})
		},

		updateAllAnimalTag: (state, { tag }) => {
			state.animais.forEach((animal) => {
				const indexTag = animal.rotulos.findIndex((tagOld) => tagOld.id === tag.id)

				if (indexTag > -1) {
					animal.rotulos.splice(indexTag, 1, tag)
				}
			})
		},
	},

	actions: {
		async visualizaAnimal({ commit }, id) {
			try {
				const animal = await API.visualizarAnimal(id)

				commit('setSelecionado', animal)
				return animal
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeHistory({ commit }, id) {
			try {
				const animalHistory = await API.visualizeHistory(id)

				commit('setAnimalHistory', animalHistory)

				return animalHistory
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAll({ state, commit }) {
			try {
				if (!state.carregado) {
					const response = await API.getAnimais()

					commit('setAnimais', response)
				}

				return state.animais
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeAnimal({ commit }, id) {
			try {
				const animal = await API.visualizarAnimal(id)

				commit('setSelecionado', Object.assign({}, animal))
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createAnimal({ commit }, payload) {
			try {
				const animal = await API.criarAnimal(payload)

				if (animal) {
					commit('addAnimal', animal)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.animals.feedback.success.create.animal') },
						{ root: true }
					)
					return animal
				}

				return animal
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				throw error
			}
		},

		async deleteAnimal({ commit, getters }, id) {
			try {
				const response = await API.excluirAnimal(id)
				const index = getters.getIndex(id)

				commit('deleteAnimal', index)
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.animals.feedback.success.remove.animal') },
					{ root: true }
				)
				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateAnimal({ commit, getters }, { id, payload }) {
			try {
				const animal = await API.editarAnimal(id, payload)
				const index = getters.getIndex(id)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.animals.feedback.success.update.animal') },
					{ root: true }
				)
				commit('updateAnimal', { index, animal: animal })

				return animal
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async generateAUID({ commit }) {
			try {
				const { status, data: auid } = await API.gerarAUID()

				return status === STATUS_SUCCESS ? auid : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async selectTag({ getters, commit }, { tag, animal }) {
			try {
				const response = await API.selectTag(tag.id, animal.id)

				if (response) {
					const indexAnimal = getters.getIndex(animal.id)

					if (indexAnimal > -1) {
						commit('addAnimalTag', { indexAnimal, tag })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deselectTag({ getters, commit }, { tag, animal }) {
			try {
				const response = await API.deselectTag(tag.id, animal.id)

				if (response) {
					const indexAnimal = getters.getIndex(animal.id)

					if (indexAnimal > -1) {
						const indexTag = getters.getIndexTag({ indexAnimal, idTag: tag.id })

						if (indexTag > -1) {
							commit('deselectAnimalTag', { indexAnimal, indexTag })
						}
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
