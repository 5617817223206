import { MutationTree } from 'vuex'
import {
	IResponseAttribute,
	IResponseAttributes,
	IResponseMappedExamFields,
} from '../../services/attributes/types'
import {
	AttributeState,
	IAttributeFormula,
	IFormula,
	ISelectedAttribute,
	ISelectedAttributeGroup,
	mutationTypes as types,
} from './types'

export const mutations: MutationTree<AttributeState> = {
	[types.SET_ATTRIBUTE_LOADING](state, loading: boolean) {
		state.attributeIsLoading = loading
	},
	[types.SET_ATTRIBUTE_SAVING](state, loading: boolean) {
		state.attributeIsSaving = loading
	},

	[types.SET_ATTRIBUTES](state, attributes: IResponseAttributes[]) {
		state.attributes = attributes
	},

	[types.CREATE_ATTRIBUTE_GROUP](
		state,
		{
			attributeGroupIndex,
			attributeGroup,
		}: { attributeGroupIndex: number; attributeGroup: IResponseAttributes }
	) {
		state.attributes.splice(attributeGroupIndex + 1, 0, attributeGroup)
	},
	[types.UPDATE_ATTRIBUTE_GROUP](
		state,
		{ attributeGroup, index }: { attributeGroup: IResponseAttributes; index: number }
	) {
		state.attributes.splice(index, 1, attributeGroup)
	},
	[types.DELETE_ATTRIBUTE_GROUP](state, index: number) {
		if (state.selectedAttributeGroup.id === state.attributes[index].id) {
			state.selectedAttributeGroup = {
				id: 0,
				position: 0,
			} as ISelectedAttributeGroup
		}

		state.attributes.splice(index, 1)
	},

	[types.CREATE_ATTRIBUTE](
		state,
		{
			indexAttributeGroup,
			attributeIndex,
			attribute,
		}: { indexAttributeGroup: number; attributeIndex: number; attribute: IResponseAttribute }
	) {
		state.attributes[indexAttributeGroup].attributes.splice(attributeIndex + 1, 0, attribute)
	},

	[types.UPDATE_ATTRIBUTE](
		state,
		{
			indexAttributeGroup,
			indexAttribute,
			attribute,
		}: { indexAttributeGroup: number; indexAttribute: number; attribute: IResponseAttribute }
	) {
		const newAttribute = state.attributes.slice()

		newAttribute[indexAttributeGroup].attributes.splice(indexAttribute, 1, attribute)

		state.attributes = newAttribute
	},

	[types.DELETE_ATTRIBUTE](
		state,
		{
			indexAttributeGroup,
			indexAttribute,
		}: { indexAttributeGroup: number; indexAttribute: number }
	) {
		if (
			state.selectedAttribute.id ===
			state.attributes[indexAttributeGroup].attributes[indexAttribute].id
		) {
			state.selectedAttribute = {
				id: 0,
				position: 0,
			} as ISelectedAttribute
		}

		state.attributes[indexAttributeGroup].attributes.splice(indexAttribute, 1)
	},

	[types.UPDATE_SELECTED_ATTRIBUTE](
		state,
		value: {
			selectedAttributeGroup: ISelectedAttributeGroup
			selectedAttribute: ISelectedAttribute
		}
	) {
		state.selectedAttributeGroup = value.selectedAttributeGroup
		state.selectedAttribute = value.selectedAttribute
	},

	[types.SET_FORMULAS](state, formulas: IFormula[]) {
		state.formulas = formulas
	},

	[types.SET_FORMULA](
		state,
		{
			formula,
			indexAttributeGroup,
			indexAttribute,
		}: {
			formula: IAttributeFormula
			indexAttributeGroup: number
			indexAttribute: number
		}
	) {
		state.attributes[indexAttributeGroup].attributes[indexAttribute].formulas.push(formula)
	},

	[types.CREATE_FORMULA](
		state,
		{
			formula,
			indexAttributeGroup,
			indexAttribute,
			indexFormula,
		}: {
			formula: IAttributeFormula
			indexAttributeGroup: number
			indexAttribute: number
			indexFormula: number
		}
	) {
		state.attributes[indexAttributeGroup].attributes[indexAttribute].formulas.splice(
			indexFormula,
			1,
			formula
		)
	},

	[types.UPDATE_FORMULA](
		state,
		{
			formula,
			indexAttributeGroup,
			indexAttribute,
			indexFormula,
		}: {
			formula: IAttributeFormula
			indexAttributeGroup: number
			indexAttribute: number
			indexFormula: number
		}
	) {
		state.attributes[indexAttributeGroup].attributes[indexAttribute].formulas[indexFormula] =
			formula
	},

	[types.DELETE_FORMULA](
		state,
		{
			indexAttributeGroup,
			indexAttribute,
			indexFormula,
		}: {
			indexAttributeGroup: number
			indexAttribute: number
			indexFormula: number
		}
	) {
		state.attributes[indexAttributeGroup].attributes[indexAttribute].formulas.splice(
			indexFormula,
			1
		)
	},

	[types.UPDATE_ATTRIBUTE_POSITIONS](state, attributes: IResponseAttributes[]) {
		state.attributes = attributes
	},
	[types.SET_MAPPED_EXAM_FIELDS](state, mappedFields: IResponseMappedExamFields[]) {
		state.examMappedFields = mappedFields
	},
	[types.CREATE_MAPPED_EXAM_FIELDS](state, mappedField: IResponseMappedExamFields) {
		state.examMappedFields.push(mappedField)
	},
	[types.UPDATE_MAPPED_EXAM_FIELDS](
		state,
		payload: { index: number; mappedField: IResponseMappedExamFields }
	) {
		state.examMappedFields.splice(payload.index, 1, payload.mappedField)
	},
}
