import { MutationTree } from 'vuex'
import { Mutations, mutationTypes as types } from './mutation-types'
import { ReceivingState } from '../../types'
import { state as stateOfReceiving } from '../../index'
import { CustomerType } from '@/modules/customers/store/types'

export const mutations: MutationTree<ReceivingState> & Mutations = {
	[types.SET_CUSTOMERS]: (state, typeormPagination) => {
		state.customers = typeormPagination
	},

	[types.ADD_CUSTOMER]: (state, customer) => {
		const customerTypeIdFilter = state.customerFilters.customerType
		const customerTypeId = customer.tipo_de_cliente.id

		if (!customerTypeIdFilter) {
			state.customers.data.push(customer)
		} else {
			if (+customerTypeIdFilter === +customerTypeId) {
				state.customers.data.push(customer)
			}
		}
	},

	[types.SET_CUSTOMER_FILTERS](state, filters) {
		state.customerFilters = filters
	},

	[types.RESET_CUSTOMERS]: (state) => {
		state.customers.data = stateOfReceiving.customers.data
		state.customers.loading = stateOfReceiving.customers.loading
		state.customers.meta = stateOfReceiving.customers.meta
	},

	[types.RESET_CUSTOMER_FILTERS](state) {
		state.customerFilters = stateOfReceiving.customerFilters
	},

	[types.SET_CUSTOMER_LOADING](state, loading) {
		state.customerIsLoading = loading
	},

	[types.SET_RELATIONSHIPS_CUSTOMER](state, { indexCustomer, relationship }) {
		const customer = {
			...state.customers.data[indexCustomer],
			relationship: relationship,
		}

		state.customers.data.splice(indexCustomer, 1, customer)
	},

	[types.SET_ANIMALS_CUSTOMER](state, { indexCustomer, animals }) {
		const customer = { ...state.customers.data[indexCustomer], animals: animals }

		state.customers.data.splice(indexCustomer, 1, customer)
	},

	[types.SET_SELECTED_ACTOR_CUSTOMER]: (state, actor) => {
		state.selectedActors.customer = actor
	},

	[types.SET_SELECTED_ACTOR_VETERINARIAN]: (state, actor) => {
		state.selectedActors.veterinarian = actor
	},

	[types.SET_SELECTED_ACTOR_PET_OWNER]: (state, actor) => {
		state.selectedActors.petOwner = actor
	},

	[types.SET_SELECTED_ACTOR_PATIENT]: (state, actor) => {
		state.selectedActors.patient = actor
	},

	[types.REMOVE_ACTOR_SELECTED]: (state, key) => {
		state.selectedActors[key] = undefined
	},

	[types.RESET_ACTORS_SELECTED]: (state) => {
		state.selectedActors.customer = stateOfReceiving.selectedActors.customer
		state.selectedActors.veterinarian = stateOfReceiving.selectedActors.veterinarian
		state.selectedActors.patient = stateOfReceiving.selectedActors.patient
	},

	[types.UPDATE_CUSTOMER]: (state, { index, payload }) => {
		state.customers.data.splice(index, 1, payload)
	},

	[types.SET_MODAL_STAKEHOLDER_SELECTION]: (state, value) => {
		state.openStakeholderSelection = value
	},

	[types.SET_IS_MODE_CREATE_RECEIVING]: (state, value) => {
		state.isModeCreateReceiving = value
	},

	[types.RESET_MODE_CREATE_RECEIVING]: (state) => {
		state.isModeCreateReceiving = false
	},

	[types.SET_SELECTION_MODE]: (state, selectionMode) => {
		state.selectionMode = selectionMode
	},

	[types.RESET_SELECTION_MODE]: (state) => {
		state.selectionMode = 'Customer'
	},
}

export default { mutations }
