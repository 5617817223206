import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import {
	IReference,
	IReferenceEditMode,
	IReferenceGroup,
	actionTypes,
} from '../store/references/types'
import { ICreateReferenceGroupParams } from '../services/references/types'
import { IAttribute } from '../store/attributes/types'

@Component({
	name: 'CreateReferenceGroupMixin',
})
export class CreateReferenceGroupMixin extends Vue {
	@Prop({ type: Object, required: true }) attribute!: IAttribute
	@Prop({ type: Array, required: true }) referenceValues!: any[]
	@State(({ Exams }) => Exams.selectedReference) reference!: IReference
	@State(({ Exams }) => Exams.referenceEditMode) referenceEditMode!: IReferenceEditMode
	@State(({ Exams }) => Exams.referenceGroups) referenceGroups!: IReferenceGroup[]

	get newGroupName() {
		return this.referenceGroups.length ? 'Novo Grupo ' : 'Valores de Referência'
	}

	get referenceValueGroups() {
		return this.referenceGroups.filter((group) =>
			this.referenceValues.some((value) => value.group_value.id === group.id)
		)
	}

	get availableReferenceGroups() {
		return this.referenceGroups.filter(
			(group) => !this.referenceValues.some((value) => value.group_value.id === group.id)
		)
	}

	@Action(`Exams/${actionTypes.CREATE_REFERENCE_GROUP}`) createReferenceGroup!: (
		payload: ICreateReferenceGroupParams
	) => Promise<void>

	handleCreateReferenceGroup(attributeId: number, name: string) {
		this.createReferenceGroup({
			referenceId: this.reference.id,
			payload: {
				nome: name,
				attributeId,
				editMode: this.referenceEditMode,
			},
		})
	}
}
