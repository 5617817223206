import API from '@/axios'
const Endpoint = '/requisicoes/exam-request/'
const EndpointExamGroups = '/exams-groups/'
const EndpointResults = '/results'
const EndpointVersions = '/versions'
const EndpointVersion = '/version'
const EndpointLatest = '/latest'
const EndpointReport = '/report'

export default {
	async visualizeExamGroup(requestId, examGroupId) {
		return await API.get(
			Endpoint +
				requestId +
				EndpointExamGroups +
				examGroupId +
				EndpointResults +
				EndpointVersions +
				EndpointLatest
		).then((response) => response)
	},

	async updateExamVersion({ requestId, examGroupId, examId, version, payload }) {
		return await API.patch(
			Endpoint +
				requestId +
				EndpointExamGroups +
				examGroupId +
				EndpointResults +
				`/${examId}` +
				EndpointVersion +
				`/${version}`,
			payload
		).then((response) => response.data)
	},

	async visualizeExamGroupLatestReport({ requestId, examGroupId }) {
		return await API.get(
			Endpoint +
				requestId +
				EndpointExamGroups +
				examGroupId +
				EndpointResults +
				EndpointReport
		).then((response) => response.data)
	},

	async sendExamGroupRelatory({ requestId, examGroupId }) {
		return await API.post(
			Endpoint +
				requestId +
				EndpointExamGroups +
				examGroupId +
				EndpointResults +
				`/report/send`
		).then((response) => response)
	},
}
