import API from '@/axios'
import store from '@/store/store'
import axios from 'axios'
const Endpoint = '/contatos/'
const telefone = 'telefones/'
const address = 'enderecos/'

const contatosAPI = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 30000,
})

export default {
	async getTelefones(organizacao_id) {
		return await contatosAPI
			.get(Endpoint + telefone, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response.data)
	},

	async criarTelefone(payload, organizacao_id) {
		return await contatosAPI
			.post(Endpoint + telefone, payload, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response.data)
	},

	async editarTelefone(payload, id, organizacao_id) {
		return await contatosAPI
			.put(Endpoint + telefone + id, payload, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response.data)
	},

	async excluirTelefone(id, organizacao_id) {
		return await contatosAPI
			.delete(Endpoint + telefone + id, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response)
	},

	async getAddresses(organizacao_id) {
		return await contatosAPI
			.get(Endpoint + address, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response)
	},

	async createAddress(payload, organizacao_id) {
		return await contatosAPI
			.post(Endpoint + address, payload, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response)
	},

	async updateAddress(payload, id, organizacao_id) {
		return await contatosAPI
			.put(Endpoint + address + id, payload, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response)
	},

	async updateMainAddress(id, organizacao_id) {
		return await contatosAPI
			.patch(
				Endpoint + address + 'main-address/' + id,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + store.state.Auth.token.access_token,
						'organizacao-id': organizacao_id ? organizacao_id : null,
					},
				}
			)
			.then((response) => response)
	},

	async removeAddress(id, organizacao_id) {
		return await contatosAPI
			.delete(Endpoint + address + id, {
				headers: {
					Authorization: 'Bearer ' + store.state.Auth.token.access_token,
					'organizacao-id': organizacao_id ? organizacao_id : null,
				},
			})
			.then((response) => response)
	},
}
