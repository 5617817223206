
















































































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import ButtonIcon from '../Buttons/ButtonIcon.vue'

@Component({
	name: 'InputTextField',
	components: {
		ButtonIcon,
	},
})
export default class InputTextField extends Vue {
	@VModel({ type: [String, Number], default: null }) valueTextField!: null | string | number

	@Prop({ type: Number, default: undefined }) readonly width?: number
	@Prop({ type: Number, default: undefined }) readonly height?: number
	@Prop({ type: String, default: undefined }) readonly error?: string
	@Prop({ type: Boolean, default: false }) readonly outlined!: boolean
	@Prop({ type: Boolean, default: false }) readonly solo!: boolean
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean
	@Prop({ type: String, default: undefined }) readonly successMessage?: string
	@Prop({ type: String, default: undefined }) readonly suffix?: string
	@Prop({ type: String, default: undefined }) readonly prefix?: string
	@Prop({ type: [String, Number], default: undefined }) readonly minHeight!: string | number
	@Prop({ type: String, default: '' }) readonly rules!: string
	@Prop({ type: String, default: undefined }) readonly label?: string
	@Prop({ type: String, default: undefined }) readonly icon?: string
	@Prop({ type: [String, Number], default: 16 }) readonly sizeIcon?: string | number
	@Prop({ type: [String, Number], default: 14 }) readonly sizeIconAppend?: string | number
	@Prop({ type: String, default: undefined }) readonly textIcon?: string
	@Prop({ type: String, default: undefined }) readonly placeholder?: string
	@Prop({ type: String, default: undefined }) readonly appendIcon?: string
	@Prop({ type: String, default: undefined }) readonly appendOuterIcon?: string
	@Prop({ type: String, default: undefined }) readonly prependIcon?: string
	@Prop({ type: String, default: undefined }) readonly prependInnerIcon?: string
	@Prop({ type: String, default: undefined }) readonly contentClass?: string
	@Prop({ type: Boolean, default: undefined }) readonly readonly?: boolean
	@Prop({ type: Boolean, default: undefined }) readonly disabled?: boolean
	@Prop({ type: String, default: undefined }) readonly type?: string
	@Prop({ type: String, default: undefined }) readonly tooltipMessageAppend?: string
	@Prop({ type: String, default: undefined }) readonly vMask?: string
	@Prop({ type: Number, default: 2 }) readonly decimalLength!: number
	@Prop({ type: Boolean, default: undefined }) readonly isCurrencyField?: boolean
	@Prop({ type: Boolean, default: undefined }) readonly valueAsInteger?: boolean
	@Prop({ type: Boolean, default: undefined }) readonly filled?: boolean
	@Prop({ type: String, default: undefined }) clearIcon!: string
	@Prop({ type: Boolean, default: undefined }) readonly hideDetails?: boolean
	@Prop({ type: Number, default: undefined }) readonly minCurrencyField?: number
	@Prop({ type: Number, default: undefined }) readonly maxCurrencyField?: number
	@Prop({ type: Number, default: undefined }) readonly debounceValidation?: number
	@Prop({ type: String, default: undefined }) readonly messages?: string
	@Prop({ type: Boolean, default: undefined }) readonly loading?: boolean

	get dynamicMinHeight(): string {
		if (this.height && !isNaN(this.height)) {
			return `${this.height}px !important;`
		}

		return `${this.minHeight}px`
	}

	get dynamicMaxWidth(): string {
		if (this.width && !isNaN(this.width)) {
			return `${this.width}px`
		}

		return 'inherit'
	}

	get getHideDetails(): (errorProvider?: string[]) => boolean {
		return (errorProvider?: string[]) => {
			const noErrorsDetected = !(this.error || (errorProvider && !!errorProvider.length))

			return noErrorsDetected && !!this.hideDetails
		}
	}
}
