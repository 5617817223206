














import { Component, Mixins, VModel } from 'vue-property-decorator'
import InputComboBox from '@/components/atomic/atoms/Inputs/InputComboBox.vue'
import UnitsMeasurementMixin from '@/modules/exams/mixins/UnitsMeasurementMixin'
import { IAttribute } from '@/modules/exams/store/attributes/types'
import InputAutoComplete from '@/components/atomic/atoms/Inputs/InputAutoComplete.vue'

@Component({
	name: 'ExamAttributeTypeNumeric',
	components: {
		InputComboBox,
		InputAutoComplete,
	},
	mixins: [UnitsMeasurementMixin],
})
export default class ExamAttributeTypeNumeric extends Mixins(UnitsMeasurementMixin) {
	@VModel({ type: Object, required: true }) attribute!: IAttribute
}
