




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AttributeReferenceValue from '@/modules/exams/components/ExamLayoutSheet/Molecules/Main/References/Cards/AttributeReference/AttributeReferenceValue.vue'
import { Getter, State } from 'vuex-class'
import { IResponseExam } from '@/modules/exams/services/types'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import ReferenceTabs from './Tabs/ReferenceTabs.vue'
import { attributesType } from '@/utils/constants/examConstants'
import { IAttribute } from '@/modules/exams/store/attributes/types'
import AddAttributeGroupMenu from './AddAttributeGroupMenu.vue'

@Component({
	name: 'AttributeReference',
	components: {
		AttributeReferenceValue,
		ButtonIcon,
		ReferenceTabs,
		AddAttributeGroupMenu,
	},
})
export default class AttributeReference extends Vue {
	@Prop({ type: Object, required: true }) attribute!: IAttribute

	selectedTab = 0
	referencedField: {
		id: number | null
		campo_id: number | null
		complemento: string | null
		exibir_conclusao?: boolean
		reference_values: any[]
	} = {
		id: null,
		campo_id: null,
		complemento: null,
		exibir_conclusao: false,
		reference_values: [],
	}

	@State(({ Exams }) => Exams.selectedExam) exam!: IResponseExam
	@State(({ Exams }) => Exams.selectedReference) reference!: any
	@State(({ Exams }) => Exams.referencedFields) referencedFields!: any[]
	@State(({ Exams }) => Exams.referenceGroups) referenceGroups!: any[]
	@State(({ Exams }) => Exams.isAddGroupAllAttributes) isAddGroupAllAttributes!: any
	@State(({ Exams }) => Exams.isRemoveGroupAllAttributes)
	isRemoveGroupAllAttributes!: any

	@Getter('Referencia/getIndexReferencedFields') getIndexReferencedFields!: any

	get hasMaxReferenceValues() {
		return this.referencedField.reference_values.length >= 3
	}

	get selectedTabHasReferenceValues() {
		return (
			this.referencedField &&
			this.referencedField.reference_values.length &&
			this.referencedField.reference_values[this.selectedTab] &&
			this.referencedField.reference_values[this.selectedTab].group_value
		)
	}

	get getIcon() {
		const attributeItem = attributesType.find(
			(attribute: any) => attribute.type === this.attribute.type
		)

		return attributeItem ? attributeItem.icon : null
	}

	get getGroupReferenceCreatedByName() {
		return (referenceGroupName: any) => {
			return this.referenceGroups.find((reference) => {
				const searchValues = this.referencedField.reference_values.find(
					(referenceField) => +referenceField.group_value.id === +reference.id
				)

				return reference.name === referenceGroupName && !searchValues
			})
		}
	}

	@Watch('referencedFields', { immediate: true })
	onReferencedFieldsChange() {
		this.loadReferenceValues()
	}

	async created() {
		this.loadFormData()
	}

	loadFormData() {
		this.referencedField.campo_id = this.attribute.id
	}

	loadReferenceValues() {
		const referencedField = this.referencedFields.find(
			(reference) => +reference.campo_id === this.attribute.id
		)

		if (referencedField) {
			this.referencedField = Object.assign({}, referencedField)
		}
	}
}
