












import RadioSelectGroup, {
	RadioSelectGroupOption,
} from '@/components/atomic/molecules/RadioSelectGroup.vue'
import { IReferenceEditMode, mutationTypes } from '@/modules/exams/store/references/types'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

@Component({
	name: 'ReferenceToolbar',
	components: {
		RadioSelectGroup,
	},
})
export default class ReferenceToolbar extends Vue {
	@State(({ Exams }) => Exams.referenceEditMode) referenceEditMode!: IReferenceEditMode

	@Mutation(`Exams/${mutationTypes.SET_REFERENCE_EDIT_MODE}`) setReferenceEditMode!: (
		payload: IReferenceEditMode
	) => void

	editOptions: RadioSelectGroupOption[] = [
		{
			value: IReferenceEditMode.CURRENT_GROUP,
			title: 'Grupo atual',
		},
		{
			value: IReferenceEditMode.ALL_GROUPS,
			title: 'Todos os grupos',
		},
	]
}
