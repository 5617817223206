const Index = () => import('./views/index')

const routes = [
	{
		path: '/perfil',
		component: Index,
		children: [
			{
				path: '/perfil/cadastro',
				component: () =>
					import(
						/* webpackChunkName: 'profile' */ '@/modules/perfil/views/MeuPerfil.vue'
					),
				name: 'perfil.index',
				meta: {
					subtitle: 'Perfil',
				},
			},
			{
				path: '/perfil/alterar-senha',
				component: () =>
					import(
						/* webpackChunkName: 'profile' */ '@/modules/perfil/views/AlterarSenha.vue'
					),
				name: 'perfil.senha',
				meta: {
					subtitle: 'Alterar Senha',
				},
			},
			{
				path: '/perfil/enderecos',
				component: () =>
					import(
						/* webpackChunkName: 'profile' */ '@/modules/perfil/views/Enderecos.vue'
					),
				name: 'perfil.enderecos',
				meta: {
					subtitle: 'Endereços',
				},
			},
			{
				path: '/perfil/contatos',
				component: () =>
					import(/* webpackChunkName: 'profile' */ '@/modules/perfil/views/teste.vue'),
				name: 'perfil.contatos',
				meta: {
					subtitle: 'Contatos',
				},
			},
			{
				path: '/perfil/outras-informacoes',
				component: () =>
					import(
						/* webpackChunkName: 'profile' */ '@/modules/perfil/views/OutrasInformacoes.vue'
					),
				name: 'perfil.informacoes',
				meta: {
					subtitle: 'Outras informações',
				},
			},
			{
				path: '/perfil/permissoes',
				component: () =>
					import(/* webpackChunkName: 'profile' */ '@/modules/perfil/views/teste.vue'),
				name: 'perfil.permissoes',
				meta: {
					subtitle: 'Papéis & Permissões',
				},
			},
			{
				path: '/perfil/conexoes',
				component: () =>
					import(/* webpackChunkName: 'profile' */ '@/modules/perfil/views/Conexoes.vue'),
				name: 'perfil.conexoes',
				meta: {
					subtitle: 'Conexões',
				},
			},
			{
				path: '/perfil/notificacoes',
				component: () =>
					import(
						/* webpackChunkName: 'profile' */ '@/modules/perfil/views/Notificacoes.vue'
					),
				name: 'perfil.notificacoes',
				meta: {
					subtitle: 'Notificações',
				},
			},
		],
	},
]

export default routes
