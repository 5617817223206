import { CommitOptions, DispatchOptions, Module } from 'vuex'
import { RootState } from '@/store/types'
import { SpecieState } from './types'
import { actions } from './actions/actions'
import { mutations } from './mutations/mutations'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { Actions } from './actions/action-types'
import { Store as VuexStore } from 'vuex'

export const state: SpecieState = {
	species: {
		data: [],
		meta: {
			current_page: 1,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
		loading: false,
	},
	openModalCreateSpecie: false,
	openModalUpdateSpecie: false,
	specieSelected: undefined,
}

export type Store<S = SpecieState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}

export const SPECIE_NAMESPACE = 'Species'

export const specie: Module<SpecieState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: actions,
	mutations: mutations,
}
