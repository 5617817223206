import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { OrderState } from '../types'
import { Getters, getterTypes as types } from './getter-types'

export const getters: GetterTree<OrderState, RootState> & Getters = {
	// Order per status
	[types.GET_ORDER_PER_STATUS_INDEX]: (state) => {
		return ({ id, statusId }) => {
			const status = statusId - 1

			return state.ordersPerStatus[status].data.findIndex((order) => order.id === id)
		}
	},

	[types.GET_ORDER_INDEX]: (state) => {
		return (id) => {
			return state.orders.data.findIndex((order) => order.id === id)
		}
	},

	[types.GET_ORDER_INDEX_FILTER]: (state) => {
		return (id) => {
			return state.ordersFiltered.data.findIndex((order) => order.id === id)
		}
	},

	[types.GET_ORDER_BY_ID]: (state) => {
		return (id) => {
			return state.orders.data.find((order) => order.id === id)
		}
	},

	[types.GET_ORDER_FILTERED_BY_ID]: (state) => {
		return (id) => {
			return state.ordersFiltered.data.find((order) => order.id === id)
		}
	},

	[types.GET_INDEX_ATTACHMENT]: (state) => {
		return (uuid) => {
			return state.attachments.findIndex((attachment) => attachment.uuid === uuid)
		}
	},

	[types.GET_UUID_ATTACHMENT]: (state) => {
		return (uuid) => {
			return state.attachments.find((attachment) => attachment.uuid === uuid)
		}
	},

	[types.GET_FILTERS_WITHOUT_NULL]: (state) => {
		const filtersWithoutNull = Object.fromEntries(
			Object.entries(state.filter).filter(([key, value]) => {
				// Filtra as entradas que possuem valor null ou array vazio
				return value && !(Array.isArray(value) && value.length === 0)
			})
		)

		const payload: any = {}

		Object.entries(filtersWithoutNull).forEach((item) => {
			const key = item[0]
			const value = item[1]

			if (Array.isArray(value)) {
				if (!value.length) return
				payload[key] = value.join(',')
			} else {
				if (value !== null || value !== '') {
					payload[key] = value
				}
			}
		})

		return payload
	},
}

export default getters
