import { DEBOUNCE_TIMEOUT } from '@/utils/constants/generalizeConstants'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import {
	IResponseAttributes,
	IUpdateAttributePositionsParams,
	IUpdateAttributePositionsPayload,
} from '../services/attributes/types'
import { actionTypes } from '../store/attributes/types'

@Component
export default class UpdateAttributePositionsMixin extends Vue {
	@State((state) => state.Exams.attributes) attributes!: IResponseAttributes[]

	updatePositionsDebouncing: NodeJS.Timeout | null = null

	@Action(`Exams/${actionTypes.UPDATE_ATTRIBUTE_POSITIONS}`)
	updateAttributePositions!: (payload: IUpdateAttributePositionsParams) => Promise<void>

	get attributesMap(): IUpdateAttributePositionsPayload {
		return {
			groups: this.attributes.map((attributeGroup) => ({
				id: attributeGroup.id,
				attributes: attributeGroup.attributes.map((attribute) => ({
					id: attribute.id,
				})),
			})),
		}
	}

	handleDraggableChange(event: any) {
		if (this.updatePositionsDebouncing) clearTimeout(this.updatePositionsDebouncing)
		this.updatePositionsDebouncing = setTimeout(() => {
			this.updateAttributePositions({
				examId: +this.$route.params.id,
				payload: this.attributesMap,
			})
		}, DEBOUNCE_TIMEOUT)
	}
}
