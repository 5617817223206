import API from '@/axios'
const Endpoint = '/categorias/'

export default {
	async getCategorias() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async criarCategoria(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async editarCategoria(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async excluirCategoria(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},
}
