









































































































import { Component, InjectReactive, Mixins, Watch } from 'vue-property-decorator'
import InputTextField from '@/components/atomic/atoms/Inputs/InputTextField.vue'
import InputAutoComplete from '@/components/atomic/atoms/Inputs/InputAutoComplete.vue'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import ExamAttributeTypeText from './ExamAttributeTypeText.vue'
import ExamAttributeTypeNumeric from './ExamAttributeTypeNumeric.vue'
import ExamAttributeTypeMultipleChoice from './ExamAttributeTypeMultipleChoice.vue'
import ExamAttributeTypePercentage from './ExamAttributeTypePercentage.vue'
import ExamAttributeTypeImage from './ExamAttributeTypeImage.vue'
import ExamAttributeTypeFormula from './ExamAttributeTypeFormula.vue'
import ExamAttributeDetailsAction from './ExamAttributeDetailsAction.vue'
import {
	IAttribute,
	IAttributeGroup,
	IAttributeType,
	IMappedExamField,
} from '@/modules/exams/store/attributes/types'
import {
	attributesType,
	ATTRIBUTE_LONG_TEXT_ID,
	DECIMAL_TYPE,
	FORMULA_TYPE,
	IMAGE_TYPE,
	INTEGER_TYPE,
	LONG_TEXT_TYPE,
	MULTIPLE_CHOICE_TYPE,
	PERCENTAGE_TYPE,
} from '@/utils/constants/examConstants'
import ValidationAttributeTypeMixin from '@/modules/exams/mixins/ValidationAttributeTypeMixin'
import UpdateAttributeMixin from '@/modules/exams/mixins/UpdateAttributeMixin'
import CreateAttributeAndAttributeGroupMixin from '@/modules/exams/mixins/CreateAttributeAndAttributeGroupMixin'
import { IUpdateAttributePayload } from '@/modules/exams/services/attributes/types'
import { IResponseMethod } from '@/modules/methods/store/types'
import { State } from 'vuex-class'
import HandleWithSearchResultsMixin from '@/modules/exams/mixins/HandleWithSearchResultsMixin'
import CreateAttributeMappedFieldMixin from '@/modules/exams/mixins/CreateAttributeMappedFieldMixin'
import UpdateAttributeMappedFieldMixin from '@/modules/exams/mixins/UpdateAttributeMappedFieldMixin'
import ClassicExamTextEditor from '@/components/global/TextEditorV2/ClassicExamTextEditor.vue'
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
@Component({
	name: 'ExamAttributeDetails',
	components: {
		InputTextField,
		InputAutoComplete,
		ButtonIcon,
		ExamAttributeTypeText,
		ExamAttributeTypeNumeric,
		ExamAttributeTypeMultipleChoice,
		ExamAttributeTypePercentage,
		ExamAttributeTypeFormula,
		ExamAttributeTypeImage,
		ExamAttributeDetailsAction,
		ClassicExamTextEditor,
	},
	mixins: [
		ValidationAttributeTypeMixin,
		CreateAttributeAndAttributeGroupMixin,
		UpdateAttributeMixin,
	],
})
export default class ExamAttributeDetails extends Mixins(
	ValidationAttributeTypeMixin,
	CreateAttributeAndAttributeGroupMixin,
	UpdateAttributeMixin,
	CreateAttributeMappedFieldMixin,
	UpdateAttributeMappedFieldMixin,
	HandleWithSearchResultsMixin
) {
	@InjectReactive('attribute') attribute!: IAttribute<unknown>
	@InjectReactive('attributeGroup') attributeGroup!: IAttributeGroup

	@State((state) => state.Methods.methods) methods!: IResponseMethod[]
	@State((state) => state.Exams.examMappedFields) mappedFields!: IMappedExamField[]

	editor: ClassicEditor | null = null

	typeAttributeId: null | number = ATTRIBUTE_LONG_TEXT_ID

	attributeTypes: IAttributeType[] = attributesType

	searchInput: null | string = null

	form: IUpdateAttributePayload = {
		name: '',
		description: '',
		note: '',
	}

	idEditor = Math.floor(Math.random() * (999 - 10)) + 50

	showAnnotation = false
	showDescription = true
	showMappedAttributeField = false
	mappedField: string | undefined | null = undefined

	get isFormValid() {
		return !!this.form.name
	}

	get getSelectedAttributeTypeComponent() {
		switch (this.form.type) {
			case LONG_TEXT_TYPE:
				return ExamAttributeTypeText
			case INTEGER_TYPE:
				return ExamAttributeTypeNumeric
			case DECIMAL_TYPE:
				return ExamAttributeTypeNumeric
			case PERCENTAGE_TYPE:
				return ExamAttributeTypePercentage
			case IMAGE_TYPE:
				return ExamAttributeTypeImage
			case FORMULA_TYPE:
				return ExamAttributeTypeFormula
			case MULTIPLE_CHOICE_TYPE:
				return ExamAttributeTypeMultipleChoice
			default:
				return ExamAttributeTypeText
		}
	}

	get getItems(): (item: IAttributeType) => boolean {
		return (item) => {
			const translate = `${this.$t(
				`modules.exams.attributeTypes.${item.slug}`
			)}`.toLowerCase()
			const search = this.searchInput?.toLowerCase()

			if (translate) {
				if (search) {
					return translate.includes(search)
				} else {
					return true
				}
			} else {
				return false
			}
		}
	}

	get getMappedExamField() {
		return this.mappedFields.find(
			(mf) => Number.parseInt(mf.exam_field_id) === this.attribute.id
		)
	}

	@Watch('selectedResultText', { immediate: true })
	handleSelectedResultTextChange(text: string) {
		if (this.selectedResultText && this.selectedAttribute?.id === this.attribute.id) {
			const content = `${this.attribute.note || ''} ${text || ''}`

			console.log('content', this.attribute.id, text)

			if (this.editor) {
				this.editor.setData(content)
				this.setSeletedResultText('')
			}
		}
	}

	@Watch('attribute', { immediate: true, deep: true }) onAttributeChanged() {
		this.form = JSON.parse(JSON.stringify(this.attribute))

		if (this.attribute.note) {
			this.showAnnotation = true
		}
	}

	created() {
		const attributeType = this.getAttributeType({
			keyComparation: 'type',
			value: this.attribute.type,
		})

		this.typeAttributeId = attributeType?.id ?? null
	}

	mounted() {
		this.mappedField = this.getMappedExamField?.interfacing_field

		if (this.mappedField) {
			this.showMappedAttributeField = true
		}
	}

	onReady(editor: ClassicEditor): void {
		this.editor = editor
	}

	async handleUpdateAttributeForm() {
		if (!this.isFormValid) return
		// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
		const { id, formulas, attribute_id, position, ...payload } = this.form

		this.handleUpdateAttribute({
			payload,
			attributeGroupId: this.attributeGroup.id,
			attributeId: this.attribute.id,
		})
	}

	async handleUpdateAttributeType(attributeTypeId: number) {
		const attributePayload = this.getDefaultAttributePayload({
			keyComparation: 'id',
			value: attributeTypeId,
		})

		if (attributePayload) {
			const nameBackup = this.form.name

			this.form = Object.assign(this.form, {
				...attributePayload,
				name: nameBackup,
			})

			if (nameBackup) {
				attributePayload.name = nameBackup
			}

			delete attributePayload.formulas
			this.handleUpdateAttribute({
				payload: attributePayload,
				attributeGroupId: this.attributeGroup.id,
				attributeId: this.attribute.id,
			})
		}
	}

	handleUpdateMappedField() {
		if (!this.selectedExam.equipments?.length) return

		if (!this.mappedField) return

		if (this.getMappedExamField?.interfacing_field) {
			this.handleUpdateAttributeMappedField({
				payload: {
					interfacing_field: this.mappedField,
				},
				mappedFieldId: this.getMappedExamField.id,
			})
		} else {
			this.handleCreateAttributeMappedField({
				payload: {
					exam_field_id: this.attribute.id,
					interfacing_field: this.mappedField,
					equipment_id: this.selectedExam.equipments[0].id,
				},
				examId: this.selectedExam.id,
			})
		}
	}
}
