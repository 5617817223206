import { MutationTree } from 'vuex'
import { Mutations, mutationTypes as types } from './mutation-types'
import { OrderState } from '../../types'
import { state as stateOfOrders } from '../../index'

export const mutations: MutationTree<OrderState> & Mutations = {
	[types.SET_CUSTOMERS]: (state, typeormPagination) => {
		state.customers = typeormPagination
	},

	[types.SET_CUSTOMER_FILTERS](state, filters) {
		state.customerFilters = filters
	},

	[types.RESET_CUSTOMERS]: (state) => {
		state.customers.data = stateOfOrders.customers.data
		state.customers.loading = stateOfOrders.customers.loading
		state.customers.meta = stateOfOrders.customers.meta
	},

	[types.RESET_CUSTOMER_FILTERS](state) {
		state.customerFilters = stateOfOrders.customerFilters
	},

	[types.SET_CUSTOMER_LOADING](state, loading) {
		state.customerIsLoading = loading
	},

	[types.SET_RELATIONSHIPS_CUSTOMER](state, { indexCustomer, relationship }) {
		const customer = {
			...state.customers.data[indexCustomer],
			relationship: relationship,
		}

		state.customers.data.splice(indexCustomer, 1, customer)
	},

	[types.SET_ANIMALS_CUSTOMER](state, { indexCustomer, animals }) {
		const customer = { ...state.customers.data[indexCustomer], animals: animals }

		state.customers.data.splice(indexCustomer, 1, customer)
	},

	[types.SET_ACTORS_SELECTED]: (state, actors) => {
		state.actorsSelected = actors
	},

	[types.RESET_ACTORS_SELECTED]: (state) => {
		state.actorsSelected = []
	},

	[types.REMOVE_ACTOR_SELECTED]: (state, indexActorSelected) => {
		state.actorsSelected.splice(indexActorSelected, 1)
	},

	[types.UPDATE_CUSTOMER]: (state, { index, payload }) => {
		state.customers.data.splice(index, 1, payload)
	},
}

export default { mutations }
