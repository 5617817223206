import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'
import {
	ATTRIBUTE_DECIMAL_ID,
	ATTRIBUTE_FORMULA_ID,
	ATTRIBUTE_IMAGE_ID,
	ATTRIBUTE_INTEGER_ID,
	ATTRIBUTE_LONG_TEXT_ID,
	ATTRIBUTE_MULTIPLE_CHOICE_ID,
	ATTRIBUTE_PERCENTAGE_ID,
	ATTRIBUTE_TEXT_ID,
} from '@/utils/constants/examConstants'
import { STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		saveLoading: false,
		carregado: false,
		exames: [],
		selecionado: {},
		atributos: [],
		backupsAtributos: [],
		busca: null,
		currentPage: 1,
		registers: [],
		buscando: [],
		metodos: [
			{ id: 1, nome: 'Arsenazo' },
			{ id: 2, nome: 'Automatizado cinético' },
			{ id: 3, nome: 'Automatizado cinético UV' },
			{ id: 4, nome: 'Automatizado colorimétrico' },
			{ id: 5, nome: 'Azul de Xilidil' },
			{ id: 6, nome: 'Bicromático Endpoint' },
			{ id: 7, nome: 'Bicromático Rate' },
			{ id: 8, nome: 'Biureto' },
			{ id: 9, nome: 'Cálculo' },
			{ id: 10, nome: 'Cinético' },
			{ id: 11, nome: 'Cinético UV' },
			{ id: 12, nome: 'Colorimétrico' },
			{ id: 13, nome: 'Colorimétrico de Ponto Final' },
			{ id: 14, nome: 'Cromatografia' },
			{ id: 15, nome: 'Cromatografia Líquida Alta Performance (HPLC)' },
			{ id: 16, nome: 'Coloração de Gram' },
			{ id: 17, nome: 'Difusão do disco' },
			{ id: 18, nome: 'Efusão torácica' },
			{ id: 19, nome: 'Eletroforese' },
			{ id: 20, nome: 'Cinético' },
			{ id: 21, nome: 'Eletroquimioluminescência' },
			{ id: 22, nome: 'Elisa' },
			{ id: 23, nome: 'Elisa Qualitativo' },
			{ id: 24, nome: 'Enzimático' },
			{ id: 25, nome: 'Enzimático Colorimétrico' },
			{ id: 26, nome: 'Esfregaço sanguíneo' },
			{ id: 27, nome: 'Espectrofotometrico Automatizado' },
			{ id: 28, nome: 'Espectrofotometria de Chama' },
			{ id: 29, nome: 'Fosfomolibdato UV' },
			{ id: 30, nome: 'Glicose Oxidase' },
			{ id: 31, nome: 'Goodwin modificado' },
			{ id: 32, nome: 'IDGA' },
			{ id: 33, nome: 'Imunocromatografia' },
			{ id: 34, nome: 'Imunoensaio' },
			{ id: 35, nome: 'Imunoensaio de fluorescência quantitativa' },
			{ id: 36, nome: 'Imunoensaio Enzimático' },
			{ id: 37, nome: 'Imunoturbidimetria' },
			{ id: 38, nome: 'Osmometria' },
			{ id: 39, nome: 'Quimioluminescência' },
			{ id: 40, nome: 'Quimioluminescência DPC' },
			{ id: 41, nome: 'Quimíca Seca' },
			{ id: 42, nome: 'Radioimunoensaio' },
			{ id: 43, nome: 'Refratometria' },
			{ id: 44, nome: 'RIFI (Imunofluorescência Indireta)' },
			{ id: 45, nome: 'Sims-Horn (Diazo)' },
			{ id: 46, nome: 'Verde de Bromocresol' },
			{ id: 47, nome: 'Vermelho de Pirogalol' },
		],
		equipamentos: [
			{ id: 1, nome: 'Nihon Kohden' },
			{ id: 2, nome: 'Vitros 250 (ortho clinical diagnostics)' },
			{ id: 3, nome: 'BS200 (mindray)' },
		],
		campos: [
			{ id: 1, nome: 'Campo Personalizado 1' },
			{ id: 2, nome: 'Campo Personalizado 2' },
		],
		loading: false,
		rts: [
			{ id: 1, nome: 'Leonel Alfredo Landskron', avatar: null },
			{ id: 2, nome: 'João da Silva', avatar: null },
		],
		operadores: [
			{ id: 1, nome: 'Operador (Adição)', icon: 'mdi-plus' },
			{ id: 2, nome: 'Operador (Subtração)', icon: 'mdi-minus' },
			{ id: 3, nome: 'Operador (Multiplicação)', icon: 'mdi-multiplication' },
			{ id: 4, nome: 'Operador (Divisão)', icon: 'mdi-division' },
		],
		typesAttributes: [
			{
				type: 'text',
				translate: 'simpleText',
				nome: 'Texto simples',
				icon: 'mdi-format-text',
				tipo_atributo_id: ATTRIBUTE_TEXT_ID,
			},
			{
				type: 'textarea',
				translate: 'textarea',
				nome: 'Texto longo',
				icon: 'mdi-text',
				tipo_atributo_id: ATTRIBUTE_LONG_TEXT_ID,
			},
			{
				type: 'integer',
				translate: 'integer',
				nome: 'Inteiro',
				icon: 'mdi-numeric',
				tipo_atributo_id: ATTRIBUTE_INTEGER_ID,
			},
			{
				type: 'decimal',
				translate: 'decimal',
				nome: 'Decimal',
				icon: 'mdi-decimal-comma',
				tipo_atributo_id: ATTRIBUTE_DECIMAL_ID,
			},
			{
				type: 'formula',
				translate: 'formula',
				nome: 'Fórmula',
				icon: 'mdi-calculator-variant-outline',
				tipo_atributo_id: ATTRIBUTE_FORMULA_ID,
			},
			{
				type: 'select',
				translate: 'select',
				nome: 'Multipla Escolha',
				icon: 'mdi-circle-slice-8',
				tipo_atributo_id: ATTRIBUTE_MULTIPLE_CHOICE_ID,
			},
			{
				type: 'percentage',
				translate: 'percentage',
				nome: 'Porcentagem',
				icon: 'mdi-percent-outline',
				tipo_atributo_id: ATTRIBUTE_PERCENTAGE_ID,
			},
			// {
			// 	type: 'date',
			// 	translate: 'date',
			// 	nome: 'Data',
			// 	icon: 'mdi-calendar',
			// 	tipo_atributo_id: ATTRIBUTE_DATE_ID,
			// },
			// {
			// 	type: 'boolean',
			// 	translate: 'positiveNegative',
			// 	nome: 'Positivo/Negativo',
			// 	icon: 'mdi-checkbox-marked-outline',
			// 	tipo_atributo_id: ATTRIBUTE_BOOLEAN_ID,
			// },
			{
				type: 'image',
				translate: 'image',
				nome: 'Imagem',
				icon: 'mdi-image',
				tipo_atributo_id: ATTRIBUTE_IMAGE_ID,
			},
		],
		formulas: [],
		units: [
			'mm3',
			'/mm3',
			'milhões/mm3',
			'fL',
			'pg',
			'g/dL',
			'U/L',
			'mg/dL',
			'mL',
			'ng/mL',
			'ng/dL',
			'mL/min',
			'mEq/L',
			'ug/dL',
			'uU/mL',
			'mOsm/kg',
		],
		examModels: [],
		openTemplatesExams: false,
		pageExam: 1,
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.exames.findIndex((exame) => exame.id === id)
			}
		},

		getIndexTag: (state) => {
			return ({ indexExam, idTag }) => {
				return state.exames[indexExam].rotulos.findIndex((rotulo) => rotulo.id === idTag)
			}
		},

		getId: (state) => {
			return (id) => {
				return state.exames.find((exame) => exame.id === id)
			}
		},

		getSigla: (state) => {
			return (sigla) => {
				return state.exames.find((exame) => exame.sigla === sigla)
			}
		},

		getIdAtributo: (state) => {
			return (id) => {
				const atributo = state.atributos.find((atributo) => atributo.id === id)

				return Object.assign({}, atributo)
			}
		},

		getIndexAtributo: (state) => {
			return (id) => {
				return state.atributos.findIndex((atributo) => atributo.id === id)
			}
		},

		getIdCampo: (state, getters) => {
			return (id_campo, id_atributo) => {
				const index_atributo = getters.getIndexAtributo(id_atributo)
				const campo = state.atributos[index_atributo].campos.find(
					(campo) => campo.id === id_campo
				)

				return Object.assign({}, campo)
			}
		},

		getIndexCampo: (state, getters) => {
			return (id_campo, id_atributo) => {
				const index_atributo = getters.getIndexAtributo(id_atributo)

				return state.atributos[index_atributo].campos.findIndex(
					(campo) => campo.id === id_campo
				)
			}
		},

		checkAtributos: (state) => {
			if (!state.atributos.length) {
				return false
			}

			let atributos_validos_formula = []

			state.atributos.forEach((atributo) => {
				const campos = atributo.campos.filter(
					(campo) => campo.tipo === 'integer' || campo.tipo === 'decimal'
				)

				atributos_validos_formula = [...atributos_validos_formula, ...campos]
			})

			if (!atributos_validos_formula.length || atributos_validos_formula.length < 2) {
				return false
			}

			return true
		},

		getAtributoFormula: (state) => {
			return () => {
				return state.typesAttributes.findIndex((atributo) => atributo.type === 'formula')
			}
		},

		randomKey: (state) => {
			return (ammount) => {
				let key = ''
				const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

				for (let i = 0; i < ammount; i++) {
					key += caracteres.charAt(Math.floor(Math.random() * caracteres.length))
				}

				return key
			}
		},
	},

	mutations: {
		setBusca: (state, payload) => (state.busca = payload),

		setExames: (state, payload) => {
			state.exames = payload
			state.carregado = true
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.exames = []
		},

		selecionar: (state, payload) => {
			state.selecionado = payload
		}, // index
		deselecionar: (state) => {
			state.selecionado = {}
			state.atributos = []
		},

		addExame: (state, payload) => {
			state.exames.push(payload)
		},

		updateExame: (state, { index, payload }) => {
			state.exames.splice(index, 1, payload)
		},

		updateExamStatus: (state, { index, status }) => (state.exames[index].status = status),

		deleteExame: (state, { index }) => {
			state.exames.splice(index, 1)
		},

		updateRotuloSelected: (state, { index_exame, index_exame_rotulo, payload }) => {
			state.exames[index_exame].rotulos.splice(index_exame_rotulo, 1, payload)
		},

		deleteRotuloSelected: (state, { index_exame, index_exame_rotulo }) => {
			state.exames[index_exame].rotulos.splice(index_exame_rotulo, 1)
		},

		setAtributos: (state, payload) => {
			state.atributos = payload
			state.atributos.forEach((atributo) => {
				atributo = Object.assign(atributo, { excluido: false })
			})

			state.backupsAtributos = Array.from(payload)
		},

		setDisabledFormula: (state, { index, boolean }) =>
			(state.typesAttributes[index].$isDisabled = boolean),

		setFormulas: (state, payload) => (state.formulas = payload),

		setBackupAtributos: (state, atributos) => {
			// code his
			state.backupsAtributos = atributos
		},

		addGroupAttribute: (state, atributo) => {
			state.atributos.push(atributo)
		},

		updateGroupAttribute: (state, { groupAttributeIndex, payload }) => {
			state.atributos.splice(groupAttributeIndex, 1, payload)
		},

		deleteGroupAttribute: (state, index) => {
			state.atributos.splice(index, 1)
		},

		deleteGroupAttributeEdit: (state, index) => {
			const payload = Object.assign(state.atributos[index], { excluido: true })

			state.atributos.splice(index, 1, payload)
		},

		addAttribute: (state, { groupAttributeIndex, attribute }) => {
			state.atributos[groupAttributeIndex].campos.push(attribute)
		},

		updateAttribute: (state, { groupAttributeIndex, attributeIndex, attribute }) => {
			state.atributos[groupAttributeIndex].campos.splice(attributeIndex, 1, attribute)
		},

		removeAttribute: (state, { groupAttributeIndex, attributeIndex }) => {
			state.atributos[groupAttributeIndex].campos.splice(attributeIndex, 1)
		},

		removeAttributeEdit: (state, { groupAttributeIndex, attributeIndex }) => {
			const payload = Object.assign(
				state.atributos[groupAttributeIndex].campos[attributeIndex],
				{
					excluido: true,
				}
			)

			state.atributos[groupAttributeIndex].campos.splice(attributeIndex, 1, payload)
		},

		showTitleGroupAttribute: (state, groupAttributeIndex) => {
			state.atributos[groupAttributeIndex].exibir_titulo =
				!state.atributos[groupAttributeIndex].exibir_titulo
		},

		changeStatusGroupAttribute: (state, index_atributo) => {
			state.atributos[index_atributo].status = !state.atributos[index_atributo].status
		},

		updatePage: (state, page) => {
			state.currentPage = page
		},

		updateRegisters: (state, registers) => {
			state.registers = registers
		},

		resetPage: (state) => (state.currentPage = 1),

		resetRegisters: (state) => (state.registers = []),

		addExamTag: (state, { indexExam, tag }) => {
			state.exames[indexExam].rotulos.push(tag)
		},

		deselectExamTag: (state, { indexExam, indexTag }) => {
			state.exames[indexExam].rotulos.splice(indexTag, 1)
		},

		deselectAllExamTag: (state, idTag) => {
			state.exames.forEach((exam) => {
				const index = exam.rotulos.findIndex((tag) => tag.id === idTag)

				if (index > -1) {
					exam.rotulos.splice(index, 1)
				}
			})
		},

		updateAllExamTag: (state, { tag }) => {
			state.exames.forEach((exam) => {
				const indexTag = exam.rotulos.findIndex((tagOld) => tagOld.id === tag.id)

				if (indexTag > -1) {
					exam.rotulos.splice(indexTag, 1, tag)
				}
			})
		},

		setExamModels: (state, examModels) => (state.examModels = examModels),

		updateStateSaveLoading: (state, boolean) => (state.saveLoading = boolean),

		setStateTemplatesExams: (state, boolean) => (state.openTemplatesExams = boolean),

		updatePageExam: (state, boolean) => (state.pageExam = boolean),

		updateAttributesSort: (state, { index, payload }) =>
			(state.atributos[index].campos = payload),

		updateAttributeGroupsSort: (state, payload) => (state.atributos = payload),
	},

	actions: {
		async getAll({ state, commit }) {
			try {
				if (!state.carregado) {
					const response = await API.getExames()

					commit('setExames', response)
				}

				return state.exames
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async switchStatus({ state, commit, getters }, bool) {
			try {
				const exame = state.selecionado
				const index = getters.getIndex(exame.id)
				const novoStatus = Object.assign(state.selecionado, { status: bool })

				await API.changeStatusExam(bool, exame.id)

				commit('updateExame', { index, payload: novoStatus })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getId({ commit, getters }, id) {
			try {
				const exame = await API.getExame(id)

				const index = getters.getIndex(id)

				if (index > -1) {
					commit('updateExame', { index, payload: exame })
				}

				commit('selecionar', Object.assign({}, exame))
				commit('setAtributos', Array.from(exame.atributos))

				return exame ? exame : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async getIdExameReferencia({ commit }, id) {
			try {
				return await API.getExame(id)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createExame({ commit }, payload) {
			try {
				const exame = await API.criarExame(payload)

				if (exame) {
					commit('addExame', exame)
					commit('deselecionar')

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.exams.feedback.success.create.exam'),
						},
						{ root: true }
					)
				} else {
					commit('deselecionar')
				}

				return exame
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateExame({ state, commit, getters }, payload) {
			try {
				const id = state.selecionado.id

				const exame = await API.updateExam(payload, id)

				if (exame) {
					const index = getters.getIndex(id)

					if (index > -1) {
						commit('updateExame', {
							index,
							payload: Object.assign(state.exames[index], exame),
						})
						commit('setAtributos', Array.from(exame.atributos))
					} else {
						commit('deselecionar')
					}

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.exams.feedback.success.update.exam'),
						},
						{ root: true }
					)
				} else {
					commit('deselecionar')
				}

				return exame ? exame : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateStatusExam({ state, commit, getters }, { id, status }) {
			try {
				const response = await API.changeStatusExam(status, id)

				if (response.status === 200) {
					const index = getters.getIndex(id)

					const payload = Object.assign(state.exames[index], { status: status })

					commit('updateExame', { index, payload: payload })
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async deleteExame({ commit, getters }, examId) {
			try {
				const index = getters.getIndex(examId)

				const response = await API.deleteExam(examId)

				if (response.status === 200) {
					commit('deleteExame', { index })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.exams.feedback.success.remove.exam'),
						},
						{ root: true }
					)
				}

				return response
			} catch (error) {
				const errorId = error.response.data.id

				error.response.data.message = i18n.t(
					`modules.exams.feedback.errors.${errorId}.message`
				)

				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		createGroupAttribute({ commit }, { attributeName }) {
			let groupAttributePayload = {}

			const id = Math.floor(Math.random() * (999 - 10)) + 50
			const payload = { nome: attributeName, id }

			groupAttributePayload = Object.assign(payload, {
				exibir_titulo: true,
				status: true,
				campos: [],
				novo: true,
			})

			commit('addGroupAttribute', groupAttributePayload)
		},

		createGroupEmptyAttribute({ commit, getters }, { attribute }) {
			const groupAttributeId = Math.floor(Math.random() * (999 - 10)) + 50

			const groupAttributePayload = {
				nome: 'Sem Nome',
				id: groupAttributeId,
				exibir_titulo: false,
				status: true,
				novo: true,
			}

			const groupAttribute = Object.assign(groupAttributePayload, { campos: [] })
			const key = getters.randomKey(8)
			const attributeId = Math.floor(Math.random() * (999 - 10)) + 45
			const attributePayload = Object.assign(attribute, {
				id: attributeId,
				novo: true,
				key: key,
			})

			groupAttribute.campos.push(attributePayload)
			commit('addGroupAttribute', groupAttribute)
		},

		updateGroupAttribute({ commit, getters }, payload) {
			const groupAttributeIndex = getters.getIndexAtributo(payload.id)

			if (groupAttributeIndex > -1) {
				commit('updateGroupAttribute', {
					groupAttributeIndex: groupAttributeIndex,
					payload: payload,
				})
			}
		},

		deleteGroupAttribute({ commit, getters }, { groupAttributeId, isNewGroupAttribute }) {
			const groupAttributeIndex = getters.getIndexAtributo(groupAttributeId)

			if (groupAttributeIndex > -1) {
				if (isNewGroupAttribute) {
					commit('deleteGroupAttribute', groupAttributeIndex)
				} else {
					commit('deleteGroupAttributeEdit', groupAttributeIndex)
				}
			}
		},

		createAttribute({ commit, getters }, { groupAttribute, attribute }) {
			const key = getters.randomKey(8)
			const id = Math.floor(Math.random() * (999 - 10)) + 50
			const attributePayload = Object.assign(attribute, { id, novo: true, key: key })

			const groupAttributeIndex = getters.getIndexAtributo(groupAttribute.id)

			if (groupAttributeIndex > -1) {
				commit('addAttribute', {
					groupAttributeIndex: groupAttributeIndex,
					attribute: attributePayload,
				})
			}
		},

		updateAttribute({ commit, getters }, { groupAttribute, attribute }) {
			const groupAttributeIndex = getters.getIndexAtributo(groupAttribute.id)

			if (groupAttributeIndex > -1) {
				const attributeIndex = getters.getIndexCampo(attribute.id, groupAttribute.id)

				if (attributeIndex > -1) {
					commit('updateAttribute', {
						groupAttributeIndex: groupAttributeIndex,
						attributeIndex: attributeIndex,
						attribute: attribute,
					})
				}
			}
		},

		removeAttribute({ commit, getters }, { groupAttributeId, attribute }) {
			const groupAttributeIndex = getters.getIndexAtributo(groupAttributeId)

			if (groupAttributeIndex > -1) {
				const attributeIndex = getters.getIndexCampo(attribute.id, groupAttributeId)

				if (attributeIndex > -1) {
					if (attribute.novo) {
						commit('removeAttribute', {
							groupAttributeIndex: groupAttributeIndex,
							attributeIndex: attributeIndex,
						})
					} else {
						commit('removeAttributeEdit', {
							groupAttributeIndex: groupAttributeIndex,
							attributeIndex: attributeIndex,
						})
					}
				}
			}
		},

		showTitleGroupAttribute({ commit, getters }, groupAttribute) {
			const groupAttributeIndex = getters.getIndexAtributo(groupAttribute.id)

			commit('showTitleGroupAttribute', groupAttributeIndex)
		},

		changeStatusGroupAttribute({ commit, getters }, { groupAttributeId }) {
			const groupAttributeIndex = getters.getIndexAtributo(groupAttributeId)

			commit('changeStatusGroupAttribute', groupAttributeIndex)
		},

		async validarSigla(_, sigla) {
			try {
				const response = await API.validarSigla(sigla)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},

		removeIdsAtributos({ state }) {
			const attributes = state.atributos.map((atributo) => {
				const fields = atributo.campos.map((campo) => {
					if (campo.novo) {
						delete campo.id
					} else {
						if (typeof campo.reference_attribute_id === 'number') {
							const attributeReferenced = atributo.campos.find(
								(attribute) => attribute.id === campo.reference_attribute_id
							)

							campo.reference_attribute_id = attributeReferenced.key
						}
					}

					return campo
				})

				atributo.campos = fields

				if (atributo.novo) {
					delete atributo.id
				}

				return atributo
			})

			return attributes
		},

		async selectTag({ getters, commit }, { tag, exam }) {
			try {
				const response = await API.selectTag(tag.id, exam.id)

				if (response) {
					const indexExam = getters.getIndex(exam.id)

					if (indexExam > -1) {
						commit('addExamTag', { indexExam, tag })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deselectTag({ getters, commit }, { tag, exam }) {
			try {
				const response = await API.deselectTag(tag.id, exam.id)

				if (response) {
					const indexExam = getters.getIndex(exam.id)

					if (indexExam > -1) {
						const indexTag = getters.getIndexTag({ indexExam, idTag: tag.id })

						if (indexTag > -1) {
							commit('deselectExamTag', { indexExam, indexTag })
						}
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllFormulas({ state, commit }) {
			try {
				const formulas = await API.getFormulas()

				commit('setFormulas', formulas)

				return state.formulas
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getExamModels({ commit }) {
			try {
				const { status, data: examModels } = await API.getExamModels()

				if (status === STATUS_SUCCESS) {
					commit('setExamModels', examModels)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async useExamModel({ commit }, { id }) {
			try {
				const { status, data: exam } = await API.useExamModel({ id })

				if (status === STATUS_SUCCESS) {
					if (exam) {
						commit('addExame', exam)
						commit('selecionar', exam)

						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: i18n.t('modules.exams.feedback.success.create.exam'),
							},
							{ root: true }
						)
					}
				}

				return { status: status === STATUS_SUCCESS, exam }
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		checkAtributos({ commit, getters }) {
			const valido = getters.checkAtributos

			const index = getters.getAtributoFormula()

			if (!valido) {
				if (index > -1) {
					commit('setDisabledFormula', { index, boolean: true })
				}
			} else {
				if (index > -1) {
					commit('setDisabledFormula', { index, boolean: false })
				}
			}
		},
	},
}
