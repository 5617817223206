




























































































import { Component, Prop, PropSync, VModel, Vue } from 'vue-property-decorator'
import { isAttributeSelectPerType } from '@/utils/functions/isAttributeSelect'
import { isAttributeIntegerOrDecimalPerType } from '@/utils/functions/isAttributeIntegerOrDecimal'
import { isAttributePercentagePerType } from '@/utils/functions/isAttributePercentage'
import { isAttributeFormulaPerType } from '@/utils/functions/isAttributeFormula'
import { Action } from 'vuex-class'
import { actionTypes } from '@/modules/exams/store/references/types'
import { IAttribute } from '@/modules/exams/store/attributes/types'

@Component({
	name: 'AttributeReferenceValue',
})
export default class AttributeReferenceValue extends Vue {
	@VModel({ type: Object, required: true }) attributeReferenceValues!: any
	@Prop({ type: Object, required: true }) attribute!: IAttribute
	@PropSync('complemento', { type: String, default: null }) getComplemento!: string | null
	@Prop({ type: Number, default: null }) referencedFieldId!: number | null

	selected = null
	items: string[] = []
	// eslint-disable-next-line no-undef
	timeout: NodeJS.Timeout | null = null

	get isAttributeTypeNumber() {
		return (
			isAttributeIntegerOrDecimalPerType(this.attribute.type) ||
			isAttributePercentagePerType(this.attribute.type) ||
			isAttributeFormulaPerType(this.attribute.type)
		)
	}

	get isAttributeTypeSelect() {
		return isAttributeSelectPerType(this.attribute.type)
	}

	created() {
		this.setFields()
	}

	@Action(`Exams/${actionTypes.UPDATE_REFERENCE_VALUE}`) updateReferenceValue!: (payload: {
		referencedAttributeId: number
		payload: any
	}) => Promise<any>

	async handleUpdateReferenceValue() {
		if (this.timeout) clearTimeout(this.timeout)

		const payload = {
			grupo_id: this.attributeReferenceValues.group_value.id,
			minimo: this.attributeReferenceValues.minimo,
			maximo: this.attributeReferenceValues.maximo,
			unidade: this.attributeReferenceValues.unidade,
		}

		this.timeout = setTimeout(async () => {
			await this.updateReferenceValue({
				referencedAttributeId: this.attributeReferenceValues.id,
				payload: payload,
			})
		}, 2000)
	}

	setFields() {
		if (this.isAttributeTypeSelect) {
			this.items = this.attribute.default_value?.split(';') || []
		}
	}
}
