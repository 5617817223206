import { SpecieState } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_INDEX_SPECIE = 'GET_INDEX_SPECIE',
}

export type Getters<S = SpecieState> = {
	[getterTypes.GET_INDEX_SPECIE]: (state: S) => (specieId: number) => number
}
