<template>
	<div class="d-block mb-3">
		<router-link :to="to" class="btn-back">
			<v-icon color="#FF9F46"> mdi-chevron-left </v-icon>
			<span class="text-uppercase">{{ text }}</span>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'BackButton',
	props: {
		to: {
			type: Object,
			required: true,
		},

		text: {
			type: String,
		},
	},

	data() {
		return {
			router: this.to,
		}
	},
}
</script>

<style scoped lang="scss">
.btn-back {
	text-decoration: none;
	text-transform: capitalize;

	span {
		color: var(--v-secondary-base);
	}
}
</style>
