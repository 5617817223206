import API from '@/axios'
import { ISpeciePayload } from '../store/types'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
const EndpointAnimalV1 = '/animals/v1'
const EndpointAnimalV2 = '/animals/v2'
const SpeciesEndpoint = '/species'

export default {
	async getSpecies(filters: IFiltersTemplate) {
		return await API.get(EndpointAnimalV2 + SpeciesEndpoint, { params: filters })
	},

	async createSpecie(payload: ISpeciePayload) {
		return await API.post(EndpointAnimalV1 + SpeciesEndpoint, payload)
	},

	async updateSpecie({ payload, id }: { payload: ISpeciePayload; id: number }) {
		return await API.put(EndpointAnimalV1 + SpeciesEndpoint + `/${id}`, payload)
	},

	async deleteSpecie(id: number) {
		return await API.delete(EndpointAnimalV1 + SpeciesEndpoint + `/${id}`)
	},
}
