import { ReceivingState } from '../types'

/* eslint-disable no-unused-vars */
export enum mutationTypes {
	ADD_REQUEST = 'addRequest',
	SET_REQUESTS = 'setRequests',
	SET_FILTER = 'setFilter',
	UPDATE_REQUESTS = 'updateRequests',
	SET_PAYERS = 'setPayers',
	SET_SELECTED_REQUEST = 'setSelectedRequest',
	SET_REQUEST_STATUS = 'setRequestStatus',
	SET_EXAM_STATUS = 'setExamStatus',
	SET_SELECTED_CLINIC = 'setSelectedClinic',
	SET_SELECTED_DOCTOR = 'setSelectedDoctor',
	SET_SELECTED_PET_OWNER = 'setSelectedPetOwner',
	SET_SELECTED_PATIENT = 'setSelectedPatient',
	SET_OBSERVATIONS = 'setObservations',
	ADD_OBSERVATION = 'addObservation',
	UPDATE_OBSERVATION = 'updateObservation',
	DELETE_OBSERVATION = 'deleteObservation',
	SET_AVAILABLE_EXAMS = 'setAvailableExams',
	UPDATE_LOADING_AVAILABLE_EXAMS = 'updateLoadingAvailableExams',
	SET_AVAILABLE_EXAM_GROUPS = 'setAvailableExamGroups',
	SET_REQUESTED_EXAMS = 'setRequestedExams',
	ADD_REQUESTED_EXAMS = 'addRequestedExams',
	UPDATE_REQUESTED_EXAM = 'updateRequestedExam',
	DELETE_REQUESTED_EXAM = 'deleteRequestedExam',
	SET_REQUESTED_EXAM_GROUPS = 'setRequestedExamGroups',
	ADD_REQUESTED_EXAM_GROUPS = 'addRequestedExamGroups',
	DELETE_REQUESTED_EXAM_GROUP = 'deleteRequestedExamGroup',
	RESET_ALL = 'resetAll',
	RESET_SELECTED = 'resetSelected',
	RESET_REQUESTS = 'resetRequests',
	RESET_EXAMS = 'resetExams',
	SET_EXAMS = 'setExams',
	UPDATE_EXAMS = 'updateExams',
	SET_ATTACHMENTS = 'setAttachments',
	ADD_ATTACHMENT = 'addAttachment',
	REMOVE_ATTACHMENT = 'removeAttachment',
	SET_FORMS = 'setForms',
	SET_SAMPLE_STATUS_LIST = 'setSampleStatusList',
	SET_SAMPLES = 'setSamples',
	ADD_SAMPLE = 'addSample',
	UPDATE_SAMPLE = 'editSample',
	REMOVE_SAMPLE = 'removeSample',
	SET_EXAM_DELETED_AT = 'setExamDeletedAt',
	SET_EXAM_PRIORITY = 'setExamPriority',
	SET_EXAM_STATUS_CANCELED = 'setExamStatusCanceled',
	UPDATE_REQUESTED_EXAM_STATUS = 'updateRequestedExamStatus',
	UPDATE_REQUESTED_EXAM_PRIORITY = 'updateRequestedExamPriority',
	UPDATE_REQUESTED_EXAM_DELETED_AT = 'updateRequestedExamDeletedAt',
	SET_LOADING_RECEIVING_SUBHEADER = 'setLoadingReceivingSubHeader',
	SET_REQUEST_CANCELED = 'setRequestCanceled',
	RESET_ALL_REQUESTED_EXAMS = 'resetAllRequestedExams',
	UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM = 'updateStatusRequestedExamGroupsExam',
	UPDATE_PRIORITY_REQUESTED_EXAM_GROUPS_EXAM = 'updatePriorityRequestedExamGroupsExam',
	SET_ALL_REQUESTEDS_EXAMS = 'setAllRequestedsExams',
	UPDATE_PAGE_REQUESTED_EXAMS = 'updatePageRequestedExams',
	ADD_CART_SELECT_ITEM = 'addCartSelectItem',
	ADD_CART_ALL_SELECT_ITEMS = 'addCartAllSelectItems',
	REMOVE_CART_ALL_ITEMS = 'removeCartAllItems',
	REMOVE_CART_SELECT_ITEM = 'removeCartSelectItem',
	SET_SAMPLE_PRINT_CONFIG = 'setSamplePrintConfig',
	UPDATE_IS_ERROR_GENERATE_BARCODE = 'updateIsErrorGenerateBarcode',
	UPDATE_LOADING_REQUESTED_EXAMS = 'updateLoadingRequestedExams',
	UPDATE_LOADING_STAKEHOLDER_SELECTION = 'UPDATE_LOADING_STAKEHOLDER_SELECTION',
}

export type Mutations<S = ReceivingState> = {
	[mutationTypes.ADD_REQUEST](state: S, payload: any): void

	[mutationTypes.SET_REQUESTS](state: S, payload: any): void

	[mutationTypes.SET_FILTER](state: S, data: { type: any; payload: any }): void

	[mutationTypes.UPDATE_REQUESTS](state: S, data: { index: number; request: any }): void

	[mutationTypes.SET_PAYERS](state: S, payload: any): void

	[mutationTypes.SET_SELECTED_REQUEST](state: S, payload: any): void

	[mutationTypes.SET_REQUEST_STATUS](state: S, payload: any): void

	[mutationTypes.SET_EXAM_STATUS](state: S, payload: any): void

	[mutationTypes.SET_SELECTED_CLINIC](state: S, payload: any): void

	[mutationTypes.SET_SELECTED_DOCTOR](state: S, payload: any): void

	[mutationTypes.SET_SELECTED_PET_OWNER](state: S, payload: any): void

	[mutationTypes.SET_SELECTED_PATIENT](state: S, payload: any): void

	[mutationTypes.SET_OBSERVATIONS](state: S, payload: any): void

	[mutationTypes.ADD_OBSERVATION](state: S, payload: any): void

	[mutationTypes.UPDATE_OBSERVATION](state: S, data: { index: number; observation: any }): void

	[mutationTypes.DELETE_OBSERVATION](state: S, index: number): void

	[mutationTypes.SET_AVAILABLE_EXAMS](state: S, payload: any): void

	[mutationTypes.UPDATE_LOADING_AVAILABLE_EXAMS](state: S, loading: boolean): void

	[mutationTypes.SET_AVAILABLE_EXAM_GROUPS](state: S, payload: any): void

	[mutationTypes.SET_REQUESTED_EXAMS](state: S, payload: any): void

	[mutationTypes.ADD_REQUESTED_EXAMS](state: S, exams: any): void

	[mutationTypes.UPDATE_REQUESTED_EXAM](state: S, data: { index: number; exam: any }): void

	[mutationTypes.DELETE_REQUESTED_EXAM](state: S, index: number): void

	[mutationTypes.SET_REQUESTED_EXAM_GROUPS](state: S, payload: any): void

	[mutationTypes.ADD_REQUESTED_EXAM_GROUPS](state: S, exams: any): void

	[mutationTypes.DELETE_REQUESTED_EXAM_GROUP](state: S, index: number): void

	[mutationTypes.RESET_ALL](state: S): void

	[mutationTypes.RESET_SELECTED](state: S): void

	[mutationTypes.RESET_REQUESTS](state: S): void

	[mutationTypes.RESET_EXAMS](state: S): void

	[mutationTypes.SET_EXAMS](state: S, payload: any): void

	[mutationTypes.UPDATE_EXAMS](state: S, data: { index: number; exam: any }): void

	[mutationTypes.SET_ATTACHMENTS](state: S, payload: any): void

	[mutationTypes.ADD_ATTACHMENT](state: S, payload: any): void

	[mutationTypes.REMOVE_ATTACHMENT](state: S, index: number): void

	[mutationTypes.SET_FORMS](state: S, forms: any): void

	[mutationTypes.SET_SAMPLE_STATUS_LIST](state: S, payload: any): void

	[mutationTypes.SET_SAMPLES](state: S, payload: any): void

	[mutationTypes.ADD_SAMPLE](state: S, payload: any): void

	[mutationTypes.UPDATE_SAMPLE](state: S, data: { index: number; sample: any }): void

	[mutationTypes.REMOVE_SAMPLE](state: S, index: number): void

	[mutationTypes.SET_EXAM_DELETED_AT](state: S, index: number): void

	[mutationTypes.SET_EXAM_PRIORITY](state: S, data: { index: number; priority: any }): void

	[mutationTypes.SET_EXAM_STATUS_CANCELED](state: S, data: { index: number; status: any }): void

	[mutationTypes.UPDATE_REQUESTED_EXAM_STATUS](
		state: S,
		data: { index: number; status: any }
	): void

	[mutationTypes.UPDATE_REQUESTED_EXAM_PRIORITY](
		state: S,
		data: { index: number; priority: any }
	): void

	[mutationTypes.UPDATE_REQUESTED_EXAM_DELETED_AT](
		state: S,
		data: { index: number; deleted_at: any }
	): void

	[mutationTypes.SET_LOADING_RECEIVING_SUBHEADER](state: S, loading: boolean): void

	[mutationTypes.SET_REQUEST_CANCELED](state: S, index: number): void

	[mutationTypes.RESET_ALL_REQUESTED_EXAMS](state: S): void

	[mutationTypes.UPDATE_STATUS_REQUESTED_EXAM_GROUPS_EXAM](
		state: S,
		data: { indexExamGroup: number; indexExam: number; status: any }
	): void

	[mutationTypes.UPDATE_PRIORITY_REQUESTED_EXAM_GROUPS_EXAM](
		state: S,
		data: { indexExamGroup: number; indexExam: number; priority: any }
	): void

	[mutationTypes.SET_ALL_REQUESTEDS_EXAMS](state: S, requestedExams: any): void

	[mutationTypes.UPDATE_PAGE_REQUESTED_EXAMS](
		state: S,
		data: { page: number; perPage: number }
	): void

	[mutationTypes.ADD_CART_SELECT_ITEM](state: S, data: { item: any }): void

	[mutationTypes.ADD_CART_ALL_SELECT_ITEMS](state: S, data: { items: any }): void

	[mutationTypes.REMOVE_CART_ALL_ITEMS](state: S): void

	[mutationTypes.REMOVE_CART_SELECT_ITEM](state: S, data: { index: number }): void

	[mutationTypes.SET_SAMPLE_PRINT_CONFIG](state: S, config: any): void

	[mutationTypes.UPDATE_IS_ERROR_GENERATE_BARCODE](state: S, value: boolean): void

	[mutationTypes.UPDATE_LOADING_REQUESTED_EXAMS](state: S, value: boolean): void

	[mutationTypes.UPDATE_LOADING_STAKEHOLDER_SELECTION](state: S, value: boolean): void
}
