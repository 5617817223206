import { CommitOptions, DispatchOptions, Module } from 'vuex'
import { RootState } from '@/store/types'
import { ExamsGroupState } from './types'
import { actions } from './actions/actions'
import { mutations } from './mutations/mutations'
import { getters } from './getters/getters'
import { Mutations } from './mutations/mutation-types'
import { Getters } from './getters/getter-types'
import { Actions } from './actions/action-types'
import { Store as VuexStore } from 'vuex'

export const state: ExamsGroupState = {
	examsGroups: {
		data: [],
		options: {
			isLoading: true,
			activeHeaders: [],
		},
		meta: {
			page: 1,
			limit: 8,
			search: undefined,
			orderBy: 'list',
		},
	},
	examsGroupSelected: null,
	openModalCreateExamsGroup: false,
	openModalUpdateExamsGroup: false,
	exams: {
		loading: false,
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
	},
}

export type Store<S = ExamsGroupState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}

export const EXAM_GROUP_NAMESPACE = 'ExamsGroup'

export const examsGroup: Module<ExamsGroupState, RootState> = {
	namespaced: true,
	state: state,
	getters: getters,
	actions: actions,
	mutations: mutations,
}
