import API from '@/axios'

const Endpoint = '/requisicoes/'
const status = '/status/'
const EndpointSearch = 'search'

export default {
	async getRequests(page, perPage, status = 0, order) {
		return await API.get(
			Endpoint +
				`?page=${page}&perPage=${perPage}${status !== 0 ? '&status_id=' + status : ''} + ${
					order ? '&order_id=' + order : ''
				}`
		).then((response) => response.data)
	},

	async searchRequests(
		page = 1,
		perPage = 25,
		search,
		requestExamStatus,
		requestedExamPriority,
		orderDirection,
		examRequestStatus,
		examRequestPriority,
		orderBy,
		createdAfter,
		createdBefore,
		orderId
	) {
		return await API.get(Endpoint + EndpointSearch, {
			params: {
				page,
				perPage,
				search,
				requestExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
			},
		}).then((response) => response.data)
	},

	async visualizeRequest(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async createRequest(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async createRequestCheckout(payload) {
		return await API.post(Endpoint + 'checkout', payload).then((response) => response.data)
	},

	async updateRequest(payload, id) {
		return await API.patch(Endpoint + id, payload).then((response) => response.data)
	},

	async updateRequestStatus(id, payload) {
		return await API.patch(Endpoint + id + '/' + status, payload).then(
			(response) => response.data
		)
	},

	async removeRequest(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},

	async getResult(requestId, examId) {
		return await API.get(Endpoint + requestId + '/exames/' + examId + '/resultado').then(
			(response) => response.data
		)
	},

	async addExamResult({ requestId, examId, payload }) {
		return await API.post(
			Endpoint + requestId + '/exames/' + examId + '/resultado',
			payload
		).then((response) => response.data)
	},

	async assignExamResult({ requestId, examId, payload }) {
		return await API.put(
			Endpoint + requestId + '/exames/' + examId + '/resultado' + '/assinar',
			payload
		).then((response) => response.data)
	},
}
