// Aged Order Status
export const ORDER_BILLED_STATUS_ID = 6
export const ORDER_DELIVERED_STATUS_ID = 5
export const ORDER_NEW_STATUS_ID = 2

// New Order Status
export const ORDER_CANCELED_STATUS_ID = 9
export const ORDER_FINISHED_STATUS_ID = 8
export const ORDER_PROCESSING_STATUS_ID = 7
export const ORDER_RECEIVED_STATUS_ID = 6
export const ORDER_AWAITING_PAYMENT_STATUS_ID = 5
export const ORDER_IN_LOGISTICS_STATUS_ID = 4
export const ORDER_AWAITING_SAMPLE_STATUS_ID = 3
export const ORDER_BUDGET_STATUS_ID = 2
export const ORDER_DRAFT_STATUS_ID = 1
