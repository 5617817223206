import { RootState } from '@/store/types'
import { Actions, actionTypes as types } from './action-types'
import { mutationTypes } from '../../mutations/customers/mutation-types'
import { OrderState } from '../../types'
import { ICustomerFilters, ICustomerFiltersBelongsTo } from '@/modules/customers/store/types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { ActionTree } from 'vuex'
import { ICustomer } from '@/modules/clientes/store/types'
import { getterTypes } from '../../getters/customers/getter-types'
import CustomerAPI from '@/modules/customers/service'
import { actionTypes as actionTypesOfAnimals } from '@/modules/animals/store/actions/action-types'
import { ANIMAL_NAMESPACE } from '@/modules/animals/store'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'

export const actions: ActionTree<OrderState, RootState> & Actions = {
	async [types.GET_CUSTOMERS](
		{ commit, state, getters },
		{ resetPage = false, filters }: { resetPage?: boolean; filters?: ICustomerFilters } = {}
	): Promise<ITypeOrmPaginationTemplate<ICustomer> | boolean> {
		try {
			commit(mutationTypes.SET_CUSTOMER_LOADING, true)

			if (resetPage) {
				commit(mutationTypes.SET_CUSTOMER_FILTERS, { ...state.customerFilters, page: 1 })
			}

			const filtersTwo: any = getters[getterTypes.GET_FORMATTED_FILTERS_WITHOUT_VOIDS]

			const { data }: any = await CustomerAPI.getCustomers(filters || filtersTwo)

			commit(mutationTypes.SET_CUSTOMERS, data)
			commit(mutationTypes.SET_CUSTOMER_LOADING, false)

			return data
		} catch (error: any) {
			commit(mutationTypes.SET_CUSTOMER_LOADING, false)
			return false
		}
	},

	async [types.GET_CUSTOMERS_BELONGS_TO](
		_,
		{ filters }: { filters: ICustomerFiltersBelongsTo }
	): Promise<ITypeOrmPaginationTemplate<ICustomer> | boolean> {
		try {
			const { data: typeormPagination }: any = await CustomerAPI.getCustomers(filters)

			return typeormPagination ? typeormPagination : false
		} catch (error: any) {
			return false
		}
	},

	async [types.GET_RELATIONSHIPS_CUSTOMER](
		{ dispatch },
		{ customer, filters }
	): Promise<void | boolean> {
		try {
			const responseCustomersBelongsTo = await dispatch(types.GET_CUSTOMERS_BELONGS_TO, {
				filters,
			})

			if (responseCustomersBelongsTo) {
				await dispatch(types.SET_RELATIONSHIPS_CUSTOMER, {
					customerActorUuid: customer.uuid,
					customerCreated: responseCustomersBelongsTo.data,
				})
			}

			const responseAnimalsByPetOwner = await dispatch(
				`${ANIMAL_NAMESPACE}/${actionTypesOfAnimals.GET_ANIMALS_BY_PET_OWNER}`,
				{
					page: 1,
					limit: 20,
					customerId: customer.id,
				},
				{ root: true }
			)

			if (responseAnimalsByPetOwner && typeof responseAnimalsByPetOwner !== 'boolean') {
				await dispatch(types.SET_ANIMALS_CUSTOMER, {
					customerActorUuid: customer.uuid,
					animalCreated: responseAnimalsByPetOwner.data,
				})
			}
		} catch (error: any) {
			return false
		}
	},

	[types.SET_RELATIONSHIPS_CUSTOMER](
		{ commit, getters },
		{ customerActorUuid, customerCreated }
	): void {
		const indexCustomer = getters[getterTypes.GET_CUSTOMER_INDEX_BY_UUID](customerActorUuid)

		if (indexCustomer > -1) {
			const customer = getters[getterTypes.GET_CUSTOMER_BY_UUID](customerActorUuid)

			let relationship = customer?.relationship ? customer.relationship : []

			if (Array.isArray(customerCreated)) {
				relationship = mergeObjectsWithoutDuplicates({
					arrayLoaded: relationship,
					keyToCompare: 'uuid',
					newArray: customerCreated,
				})
			} else {
				if (relationship.length) {
					const payload = [{ ...customerCreated }]

					relationship = mergeObjectsWithoutDuplicates({
						arrayLoaded: relationship,
						keyToCompare: 'uuid',
						newArray: payload,
					})
				} else {
					relationship = [{ ...customerCreated }]
				}
			}

			commit(mutationTypes.SET_RELATIONSHIPS_CUSTOMER, { indexCustomer, relationship })
		}
	},

	[types.SET_ANIMALS_CUSTOMER]({ commit, getters }, { customerActorUuid, animalCreated }): void {
		const indexCustomer = getters[getterTypes.GET_CUSTOMER_INDEX_BY_UUID](customerActorUuid)

		if (indexCustomer > -1) {
			const customer = getters[getterTypes.GET_CUSTOMER_BY_UUID](customerActorUuid)

			let animals = customer?.animals ? customer.animals : []

			if (Array.isArray(animalCreated)) {
				animals = mergeObjectsWithoutDuplicates({
					arrayLoaded: animals,
					keyToCompare: 'id',
					newArray: animalCreated,
				})
			} else {
				if (animals.length) {
					const payload = [{ ...animalCreated }]

					animals = mergeObjectsWithoutDuplicates({
						arrayLoaded: animals,
						keyToCompare: 'id',
						newArray: payload,
					})
				} else {
					animals = [{ ...animalCreated }]
				}
			}

			commit(mutationTypes.SET_ANIMALS_CUSTOMER, { indexCustomer, animals })
		}
	},

	async [types.UPDATE_CUSTOMER_TYPE]({ commit, getters }, customer: ICustomer): Promise<void> {
		const indexCustomer = getters[getterTypes.GET_CUSTOMER_INDEX_BY_UUID](customer.uuid)

		if (indexCustomer > -1) {
			commit(mutationTypes.UPDATE_CUSTOMER, { index: indexCustomer, payload: customer })
		}
	},
}

export default { actions }
