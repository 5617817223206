import { MutationTree } from 'vuex'
import { OrderState } from '../types'
import { Mutations, mutationTypes as types } from './mutation-types'

export const mutations: MutationTree<OrderState> & Mutations = {
	[types.SET_ORDERS_PER_STATUS]: (state, { payload, id }) => {
		state.ordersPerStatus[id - 1] = { ...state.ordersPerStatus[id - 1], ...payload }
	},

	[types.REMOVE_ORDER_PER_STATUS]: (state, { index, status }) => {
		state.ordersPerStatus[status - 1].data.splice(index, 1)
	},

	// Order
	[types.SET_ORDERS]: (state, payload) => {
		state.orders = payload
		state.load = true
	},

	[types.SET_ORDERS_FILTERED]: (state, payload) => {
		const orders = [...state.ordersFiltered.data, ...payload.data]

		const uniq = [...new Set(orders.map(({ id }) => id))].map((requestId) =>
			orders.find(({ id }) => id == requestId)
		)

		state.ordersFiltered = Object.assign({}, payload)
		state.ordersFiltered.data = [...uniq]
	},

	[types.SET_FILTER]: (state, payload) => {
		state.filter = payload
	},

	[types.ADD_ORDERS]: (state, payload) => state.orders.data.push(payload),

	[types.UPDATE_ORDERS]: (state, { index, order }) => state.orders.data.splice(index, 1, order),

	[types.UPDATE_ORDERS_FILTERED]: (state, { index, order }) => {
		state.ordersFiltered.data.splice(index, 1, order)
	},

	[types.SET_ORDER_SELECTED]: (state, payload) => (state.selectedOrder = payload),

	[types.ADD_ORDER]: (state, payload) => state.orders.data.push(payload),

	[types.RESET_ORDER_SELECTED]: (state) => (state.selectedOrder = {}),

	[types.RESET_ALL]: (state) => {
		state.load = false
		state.selectedOrder = {}
		state.orders = { data: [], links: {}, meta: {} }
		state.attachments = []
	},

	[types.RESET_ORDERS_FILTERED]: (state) => {
		state.ordersFiltered = { data: [], links: {}, meta: {} }
	},

	[types.SET_ATTACHMENTS]: (state, payload) => (state.attachments = payload),

	[types.ADD_ATTACHMENT]: (state, payload) => state.attachments.push(payload),

	[types.REMOVE_ATTACHMENT]: (state, index) => state.attachments.splice(index, 1),

	[types.SET_STATUS_ORDER]: (state, payload) => (state.statusOrders = payload),

	[types.SET_LOADING_ORDER_SUB_HEADER]: (state, boolean) => {
		state.loadingOrderSubHeader = boolean
	},

	[types.REMOVE_ORDER]: (state, { index }) => {
		state.orders.data.splice(index, 1)
	},

	[types.SET_MODAL_STAKEHOLDER_SELECTION]: (state, value) => {
		state.openStakeholderSelection = value
	},

	[types.SET_IS_MODE_CREATE_ORDER]: (state, value) => {
		state.isModeCreateOrder = value
	},

	[types.SET_IS_MODE_SELECT_CUSTOMER]: (state, value) => {
		state.isModeSelectCustomer.value = value
	},

	[types.SET_CUSTOMER_SELECTED]: (state, customer) => {
		state.isModeSelectCustomer.customer = customer
	},

	[types.RESET_MODE_CREATE_ORDER]: (state) => {
		state.isModeCreateOrder = false
	},

	[types.RESET_MODE_SELECT_CUSTOMER]: (state) => {
		state.isModeSelectCustomer.value = false
		state.isModeSelectCustomer.customer = undefined
	},
}

export default { mutations }
