/* eslint-disable no-unused-vars */
import { ActionContext, DispatchOptions } from 'vuex'
import { Mutations } from '../mutations/mutation-types'
import { BreedState, IBreedResponse, IBreedPayload, IBreedQueryFilters } from '../types'
import { RootState } from '@/store/types'

import { Mutations as MutationsByAlertSuccess } from '@/modules/successAlert/store/mutations/mutation-types'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { IFiltersTemplate } from '@/utils/interfaces/filtersTemplate'
import { Getters } from '../getters/getter-types'

export enum actionTypes {
	GET_BREEDS = 'GET_BREEDS',

	CREATE_BREED = 'CREATE_BREED',
	UPDATE_BREED = 'UPDATE_BREED',
	REMOVE_BREED = 'REMOVE_BREED',
}

export interface Actions {
	[actionTypes.GET_BREEDS](
		{ commit }: AugmentedActionContext,
		{ filters, isInfiniteScroll }: { filters: IBreedQueryFilters; isInfiniteScroll: boolean }
	): Promise<ITypeOrmPaginationTemplate<IBreedResponse> | boolean>

	[actionTypes.CREATE_BREED](
		{ commit }: AugmentedActionContext,
		payload: IBreedPayload
	): Promise<IBreedResponse | boolean>

	[actionTypes.UPDATE_BREED](
		{ getters, commit }: AugmentedActionContext,
		{ breedId, payload }: { breedId: number; payload: IBreedPayload }
	): Promise<IBreedResponse | boolean>

	[actionTypes.REMOVE_BREED](
		{ getters, commit }: AugmentedActionContext,
		breedId: number
	): Promise<boolean>
}

// Type [intellisense] { getters, commit, dispatch }
type AugmentedActionContext = {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}

	commit<K extends keyof Mutations>(
		key: K,
		payload?: Parameters<Mutations[K]>[1]
	): ReturnType<Mutations[K]>

	commit<S extends keyof MutationsByAlertSuccess>(
		key: string,
		payload?: Parameters<MutationsByAlertSuccess[S]>[1],
		root?: { root: boolean }
	): ReturnType<MutationsByAlertSuccess[S]>

	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & Omit<ActionContext<BreedState, RootState>, 'getters' | 'commit' | 'dispatch'>
