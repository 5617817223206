const routes = [
	{
		name: 'orders.index',
		path: '/orders/:id',
		component: () => import(/* webpackChunkName: 'orders' */ './views/OrderIndex'),
	},
	{
		name: 'orders.new',
		path: '/orders/:id',
		props: { isNew: true },
		component: () => import(/* webpackChunkName: 'orders' */ './views/OrderIndex'),
	},
]

export default routes
