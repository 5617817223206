









































import { Component, Mixins, VModel } from 'vue-property-decorator'
import InputComboBox from '@/components/atomic/atoms/Inputs/InputComboBox.vue'
import InputAutoComplete from '@/components/atomic/atoms/Inputs/InputAutoComplete.vue'
import UnitsMeasurementMixin from '@/modules/exams/mixins/UnitsMeasurementMixin'
import { IAttribute } from '@/modules/exams/store/attributes/types'
import { attributesType } from '@/utils/constants/examConstants'
import GetAttributeNumbersToSelectMixin from '@/modules/exams/mixins/GetAttributeNumbersToSelectMixin'

@Component({
	name: 'ExamAttributeTypePercentage',
	components: {
		InputComboBox,
		InputAutoComplete,
	},
	mixins: [UnitsMeasurementMixin],
})
export default class ExamAttributeTypePercentage extends Mixins(
	UnitsMeasurementMixin,
	GetAttributeNumbersToSelectMixin
) {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	get getIcon() {
		return (type: string) =>
			attributesType.find((attributeType) => attributeType.type === type)?.icon || null
	}
}
