




























































import { Component, Prop, VModel, Watch, Vue } from 'vue-property-decorator'
import Chip from '../Chip.vue'

@Component({
	name: 'InputComboBox',
	components: {
		Chip,
	},
})
export default class InputComboBox extends Vue {
	@VModel({ type: [String, Number, Array], default: null }) valueComboBox!:
		| null
		| string
		| number
		| unknown[]

	@Prop({ type: Number, default: undefined }) readonly width?: number
	@Prop({ type: Number, default: undefined }) readonly height?: number
	@Prop({ type: String, default: undefined }) readonly error?: string
	@Prop({ type: Boolean, default: false }) readonly outlined!: boolean
	@Prop({ type: Boolean, default: false }) readonly dense!: boolean
	@Prop({ type: String, default: undefined }) readonly successMessage?: string
	@Prop({ type: String, default: undefined }) readonly suffix?: string
	@Prop({ type: String, default: undefined }) readonly prefix?: string
	@Prop({ type: Number, default: undefined }) readonly minHeight!: number
	@Prop({ type: String, default: '' }) readonly rules!: string
	@Prop({ type: String, default: undefined }) readonly label?: string
	@Prop({ type: Array, default: () => [] }) items!: unknown[]
	@Prop({ type: String, default: undefined }) readonly itemValue?: string
	@Prop({ type: String, default: undefined }) readonly itemText?: string
	@Prop({ type: Boolean, default: false }) readonly filled?: boolean
	@Prop({ type: Boolean, default: true }) readonly clearable?: boolean
	@Prop({ type: String, default: 'mdi-close' }) readonly clearIcon?: string
	@Prop({ type: Boolean, default: false }) readonly selectionChips?: boolean
	@Prop({ type: Boolean, default: undefined }) readonly multiple?: boolean

	itemsAdded: unknown = []

	get dynamicMinHeight(): string {
		if (this.height && !isNaN(this.height)) {
			return `${this.height}px`
		}

		return `${this.minHeight}px`
	}

	get dynamicMaxWidth(): string {
		if (this.width && !isNaN(this.width)) {
			return `${this.width}px`
		}

		return '100%'
	}

	isSlots(slotName: string) {
		return this.$scopedSlots[slotName]
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getValueItem(item: any) {
		const itemText = this.itemText || 'name'

		return typeof item === 'object' ? item[itemText] : item
	}

	getHiddenDefaultAppend(): boolean {
		if (this.valueComboBox && Array.isArray(this.valueComboBox)) {
			return !!(this.clearable && this.valueComboBox.length)
		}

		return !!(this.clearable && this.valueComboBox)
	}

	@Watch('valueComboBox')
	onChildChanged(item: string | number | null) {
		if (item && typeof item === 'string' && item.length > 0) {
			this.$emit('update:item', item)
		}
	}
}
