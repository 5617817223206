import { AttributeState } from './types'

export const state: AttributeState = {
	attributes: [],
	attributeIsLoading: false,
	attributeIsSaving: false,
	formulas: [],
	selectedAttributeGroup: {
		id: 0,
		position: 0,
	},
	selectedAttribute: {
		id: 0,
		position: 0,
		type: 'attribute',
	},
	examMappedFields: [],
}
