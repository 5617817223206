import API from '@/axios'
const Endpoint = '/models/materials/'

export default {
	async getMaterials() {
		return await API.get(Endpoint).then((response) => response)
	},

	async visualizeMaterial(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async createMaterial(payload) {
		return await API.post(Endpoint, payload).then((response) => response)
	},

	async updateMaterial(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response)
	},

	async deleteMaterial(id) {
		return await API.delete(Endpoint + id).then((response) => response)
	},
}
