import API from '@/axios'

const Endpoint = '/exames/'
const EndpointModel = '/models'
const Validacao = 'validate/'
const EndpointRotulo = '/rotulos/'

export default {
	async getExames() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async getExame(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async criarExame(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async updateExam(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async changeStatusExam(bool, id) {
		return await API.patch(Endpoint + id, { status: bool }).then((response) => response)
	},

	async deleteExam(id) {
		return await API.delete(Endpoint + id).then((response) => response)
	},

	async criarAtributo(payload, id_exame) {
		return await API.post(Endpoint + id_exame + '/atributos', payload).then(
			(response) => response.data
		)
	},

	async editarAtributo(payload, id_exame, id) {
		return await API.put(Endpoint + id_exame + '/atributos/' + id, payload).then(
			(response) => response.data
		)
	},

	async excluirAtributo(id_exame, id) {
		return await API.delete(Endpoint + id_exame + '/atributos/' + id).then(
			(response) => response.data
		)
	},

	async criarCampo(payload, id_exame, id_atributo) {
		return await API.post(
			Endpoint + id_exame + '/atributos/' + id_atributo + '/campos',
			payload
		).then((response) => response.data)
	},

	async editarCampo(payload, id_exame, id_atributo, id) {
		return await API.put(
			Endpoint + id_exame + '/atributos/' + id_atributo + '/campos/' + id,
			payload
		).then((response) => response.data)
	},

	async excluirCampo(id_exame, id_atributo, id) {
		return await API.delete(
			Endpoint + id_exame + '/atributos/' + id_atributo + '/campos/' + id
		).then((response) => response.data)
	},

	async validarSigla(sigla) {
		return await API.post(Endpoint + Validacao + 'sigla', { sigla: sigla }).then(
			(response) => response.data
		)
	},

	async selectTag(id, idExam) {
		return await API.post(EndpointRotulo + id + '/exames', { id: idExam }).then(
			(response) => response.data
		)
	},

	async deselectTag(id, idExam) {
		return await API.delete(EndpointRotulo + id + '/exames', { data: { id: idExam } }).then(
			(response) => response.data
		)
	},

	async getFormulas() {
		return await API.get(Endpoint + '/formulas').then((response) => response.data)
	},

	async getExamModels() {
		return await API.get(EndpointModel + '/exam?default=true').then((response) => response)
	},

	async useExamModel({ id }) {
		return await API.post(EndpointModel + '/exam/create-from-model/' + id).then(
			(response) => response
		)
	},
}
