














import { Component, VModel, Vue } from 'vue-property-decorator'
import InputComboBox from '@/components/atomic/atoms/Inputs/InputComboBox.vue'
import { IAttribute } from '@/modules/exams/store/attributes/types'

@Component({
	name: 'ExamAttributeTypeMultipleChoice',
	components: {
		InputComboBox,
	},
})
export default class ExamAttributeTypeMultipleChoice extends Vue {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	get select(): string[] {
		if (this.attribute.default_value) {
			return (this.attribute.default_value as string).split(';')
		}

		return []
	}

	set select(value: string[]) {
		this.attribute.default_value = value.join(';')
	}

	selects: string[] = []
}
