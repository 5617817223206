import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { AgreementState } from './types'

export const state: AgreementState<unknown> = {
	agreements: {
		data: [],
		meta: {},
	},
	agreementSelected: null,
	loading: false,
	saving: false,
	customers: {
		data: [],
		loading: false,
		saving: false,
	},
	filters: {},
	agreementExamsFilters: {
		page: 1,
		limit: 5,
	},
	exams: {
		data: [],
		meta: {
			current_page: 0,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
		loading: false,
		saving: false,
	},
	examsSelected: [],
	openModalUpdateAgreement: false,
	isModalReadonly: false,
}

export const AGREEMENT_NAMESPACE = 'Agreements'

export const agreements: Module<AgreementState<unknown>, RootState> = {
	namespaced: true,
	state: state,
	actions: actions,
	mutations: mutations,
	getters: getters,
}
