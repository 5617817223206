

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Badge from '../Badge.vue'

@Component({
	name: 'ButtonIcon',
	components: {
		Badge,
	},
})
export default class ButtonIcon extends Vue {
	@Prop({ type: String, default: 'secondary' }) readonly color!: string
	@Prop({ type: Boolean, default: false }) readonly disabled!: boolean
	@Prop({ type: String, required: true }) readonly icon!: string
	@Prop({ type: Boolean, default: false }) readonly menuDisabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly small!: boolean
	@Prop({ type: Boolean, default: false }) readonly loading!: boolean
	@Prop({ type: Boolean, default: false }) readonly badge!: boolean
	@Prop({ type: String, default: '' }) readonly tooltipMessage!: string
	@Prop({ type: Number, default: 150 }) readonly tooltipOpenDelay!: number
	@Prop({ type: String, default: '' }) readonly contentClass!: string
	@Prop({ type: String, default: '' }) readonly menuLeft!: string
	@Prop({ type: String, default: '' }) readonly menuBottom!: string
	@Prop({ type: Boolean, default: false }) readonly readonly!: boolean
	@Prop({ type: [String, Number], default: 0 }) contentBadge?: string | number
	@Prop({ type: Number, default: 10 }) offsetX!: number
	@Prop({ type: Number, default: 10 }) offsetY!: number
	@Prop({ type: Number, default: 24 }) sizeIcon!: number
	@Prop({ type: Boolean, default: true }) bordered!: boolean
	@Prop({ type: Boolean, default: false }) hiddenButtonBorder!: boolean
	@Prop({ type: Boolean, default: true }) topTooltip!: boolean
	@Prop({ type: Boolean, default: undefined }) bottomTooltip?: boolean

	click(): void {
		if (this.readonly) return

		this.$emit('click')
	}
}
