import { MutationTree } from 'vuex'
import { ReferenceState, mutationTypes } from './types'

export const mutations: MutationTree<ReferenceState> = {
	[mutationTypes.SET_REFERENCE_LOADING]: (state, loading: boolean) =>
		(state.referenceIsLoading = loading),

	[mutationTypes.SET_REFERENCE_SAVING]: (state, loading: boolean) =>
		(state.referenceIsSaving = loading),

	[mutationTypes.SET_REFERENCES]: (state, references) => (state.references = references),

	[mutationTypes.RESET_CARREGADO]: (state) => {
		state.references = []
	},

	[mutationTypes.SET_SELECTED_REFERENCE]: (state, payload) => {
		state.selectedReference = payload
		state.referencedFields = payload.referenced_fields
		state.referenceGroups = payload.reference_group_values
	},

	[mutationTypes.RESET_REFERENCE]: (state) => {
		state.selectedReference = null
		state.referenceGroups = []
		state.referencedFields = []
	},

	[mutationTypes.CREATE_REFERENCE]: (state, reference) => state.references.push(reference),

	[mutationTypes.UPDATE_REFERENCE]: (state, { index, payload }) => {
		state.references.splice(index, 1, payload)
	},

	[mutationTypes.DELETE_REFERENCE]: (state, index) => state.references.splice(index, 1),

	[mutationTypes.SET_REFERENCE_GROUPS]: (state, groups) => (state.referenceGroups = groups),

	// Reference Groups
	// ---------------------------------------------------------------------------------------------

	[mutationTypes.CREATE_REFERENCE_GROUPS]: (state, payload) => {
		state.selectedReference = payload
		state.referencedFields = payload.referenced_fields
		state.referenceGroups = payload.reference_group_values
		state.referenceIsLoading = false
	},

	[mutationTypes.DELETE_REFERENCE_GROUP]: (state, payload) => {
		state.selectedReference = payload
		state.referencedFields = payload.referenced_fields
		state.referenceGroups = payload.reference_group_values
		state.referenceIsLoading = false
	},

	[mutationTypes.UPDATE_REFERENCE_GROUP]: (state, payload: { index: number; name: string }) => {
		state.referenceGroups[payload.index].nome = payload.name
	},

	// ---------------------------------------------------------------------------------------------

	[mutationTypes.SET_REFERENCED_FIELDS_VALUES]: (state, { index, referencedValues }) => {
		state.referencedFields[index].reference_values = referencedValues
	},

	[mutationTypes.CREATE_REFERENCE_FIELDS]: (state, referencedField) =>
		state.referencedFields.push(referencedField),

	[mutationTypes.UPDATE_REFERENCE_FIELDS_VALUE]: (
		state,
		{ indexReferencedField, indexReferencedValues, payload }
	) => {
		state.referencedFields[indexReferencedField].reference_values[indexReferencedValues] =
			payload
	},

	[mutationTypes.DELETE_REFERENCE_FIELD]: (state, { indexReferencedField }) =>
		state.referencedFields[indexReferencedField],

	[mutationTypes.DELETE_REFERENCE_FIELD_VALUE]: (
		state,
		{ indexReferencedField, indexReferencedValue }
	) => state.referencedFields[indexReferencedField].reference_values[indexReferencedValue],

	[mutationTypes.SET_REFERENCE_EDIT_MODE]: (state, payload) => {
		state.referenceEditMode = payload
	},
}
