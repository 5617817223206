import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ExamsGroupState } from '../types'
import { getterTypes as types, Getters } from './getter-types'

export const getters: GetterTree<ExamsGroupState, RootState> & Getters = {
	[types.GET_INDEX_CURRENT_PAGE]: (state) => (currentPage: number) => {
		return state.examsGroups.data.findIndex(
			(metaPage) => metaPage.meta.current_page === currentPage
		)
	},

	[types.GET_INDEX_EXAMS_GROUP]:
		(state) =>
		({
			indexCurrentPage,
			examsGroupId,
		}: {
			indexCurrentPage: number
			examsGroupId: number
		}) => {
			return state.examsGroups.data[indexCurrentPage].data.findIndex(
				(examsGroup) => examsGroup.id === examsGroupId
			)
		},

	[types.GET_INDEX_EXAM_GROUP_REACTIVITY]: (state) => {
		return (examsGroupId) => {
			const indexCurrentPage = state.examsGroups.data.findIndex(
				(metaPage) => metaPage.meta.current_page === state.examsGroups.meta.page
			)

			const indexExamsGroup = state.examsGroups.data[indexCurrentPage].data.findIndex(
				(examsGroup) => examsGroup.id === examsGroupId
			)

			return { indexCurrentPage, indexExamsGroup }
		}
	},
}

export default getters
