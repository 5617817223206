



















import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component({
	name: 'InputSwitch',
})
export default class InputSwitch extends Vue {
	@VModel({ type: Boolean, default: false }) valueSwitch!: boolean

	@Prop({ type: String, default: undefined }) label?: string
	@Prop({ type: Boolean, default: undefined }) readonly?: boolean
	@Prop({ type: Boolean, default: undefined }) loading?: boolean
	@Prop({ type: Boolean, default: undefined }) disabled?: boolean

	isSlotLabel() {
		return !this.$slots['default']
	}
}
