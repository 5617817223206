









































































































import { Component, Mixins, VModel } from 'vue-property-decorator'
import ExamCardFormula from '@/modules/exams/components/ExamLayoutSheet/Atoms/ExamCardFormula.vue'
import ButtonIcon from '@/components/atomic/atoms/Buttons/ButtonIcon.vue'
import InputAutoComplete from '@/components/atomic/atoms/Inputs/InputAutoComplete.vue'
import InputComboBox from '@/components/atomic/atoms/Inputs/InputComboBox.vue'
import UnitsMeasurementMixin from '@/modules/exams/mixins/UnitsMeasurementMixin'
import CardInformation from '@/components/atomic/atoms/CardInformation.vue'
import {
	actionTypes,
	IAttribute,
	IAttributeFormula,
	IFormula,
} from '@/modules/exams/store/attributes/types'
import GetAttributeNumbersToSelectMixin from '@/modules/exams/mixins/GetAttributeNumbersToSelectMixin'
import GetSpeciesToSelectMixin from '@/modules/exams/mixins/GetSpeciesToSelectMixin'
import { Action, State } from 'vuex-class'
import {
	ICreateFormulaActionParams,
	IDeleteFormulaActionParams,
	ISetFormulaActionParams,
	ISetRemoveFormulaActionParams,
	IUpdateFormulaActionParams,
} from '@/modules/exams/store/attributes/actions'
import ButtonDefault from '@/components/atomic/atoms/Buttons/ButtonDefault.vue'

@Component({
	name: 'ExamAttributeTypeFormula',
	components: {
		ExamCardFormula,
		ButtonIcon,
		InputAutoComplete,
		InputComboBox,
		CardInformation,
		ButtonDefault,
	},
	mixins: [GetAttributeNumbersToSelectMixin, UnitsMeasurementMixin, GetSpeciesToSelectMixin],
})
export default class ExamAttributeTypeFormula extends Mixins(
	GetAttributeNumbersToSelectMixin,
	UnitsMeasurementMixin,
	GetSpeciesToSelectMixin
) {
	@VModel({ type: Object, required: true }) attribute!: IAttribute

	@State(({ Exams }) => Exams.formulas) formulas!: IFormula[]

	selectedFormula = 0

	get getSelectedFormulaBaseDescription(): string {
		const selectedFormulaBase = this.formulas.find(
			(formula) => formula.id === this.getSelectedFormula?.formula_base_id
		)

		return selectedFormulaBase?.description || ''
	}

	get getSelectedFormula(): IAttributeFormula | null {
		if (this.attribute.formulas?.length > 0 && this.selectedFormula !== null) {
			return this.attribute.formulas[this.selectedFormula]
		}

		return null
	}

	get selectedFormulaFields(): string[] {
		if (
			this.selectedFormula !== null &&
			this.selectedFormula >= 0 &&
			this.attribute.formulas[this.selectedFormula]?.formula
		) {
			return (
				this.attribute.formulas[this.selectedFormula].formula
					?.match(/:\w+/gim)
					?.map((field) => field.replace(':campo', '')) || []
			)
		}

		return []
	}

	set selectedFormulaFields(value: string[]) {
		if (
			this.selectedFormula !== null &&
			this.selectedFormula >= 0 &&
			this.attribute.formulas[this.selectedFormula]?.formula
		) {
			const selectedFormula = this.attribute.formulas[this.selectedFormula].formula

			let index = 0

			const newFormula = selectedFormula.replace(/:\w+/gim, () => `:campo${value[index++]}`)

			this.attribute.formulas[this.selectedFormula].formula = newFormula
		}
	}

	@Action(`Exams/${actionTypes.SET_FORMULA}`)
	setFormula!: (payload: ISetFormulaActionParams) => Promise<void>

	@Action(`Exams/${actionTypes.SET_REMOVE_FORMULA}`)
	setRemoveFormula!: (payload: ISetRemoveFormulaActionParams) => Promise<void>

	@Action(`Exams/${actionTypes.CREATE_FORMULA}`)
	createFormula!: (payload: ICreateFormulaActionParams) => Promise<void>

	@Action(`Exams/${actionTypes.UPDATE_FORMULA}`)
	updateFormula!: (payload: IUpdateFormulaActionParams) => Promise<void>

	@Action(`Exams/${actionTypes.DELETE_FORMULA}`)
	removeFormula!: (payload: IDeleteFormulaActionParams) => Promise<void>

	handleUdpateSelectedFormulaField(value: string, index: number) {
		const newSelectedFormulaFields = this.selectedFormulaFields.slice()

		newSelectedFormulaFields.splice(index, 1, value)
		this.selectedFormulaFields = newSelectedFormulaFields

		this.handleUpsertFormula()
	}

	setFormulaBase(formulaId: number) {
		const formula = this.formulas.find((formula) => formula.id === formulaId)

		if (formula) {
			this.attribute.formulas[this.selectedFormula].formula_base_id = formula?.id
			this.attribute.formulas[this.selectedFormula].formula = formula.formula
		}

		this.handleUpsertFormula()
	}

	handleAddNewFormula() {
		this.setFormula({
			payload: {
				formula_base_id: null,
				specie_id: null,
				formula: '',
			},
			attributeGroupId: this.attributeGroup.id,
			attributeId: this.attribute.id,
		})
	}

	verifyIfFormulaIsFilled() {
		if (this.getSelectedFormula) {
			return this.selectedFormulaFields.every((field) =>
				this.attributesToSelect().some((attribute) => attribute.key === field)
			)
		}

		return false
	}

	handleUpsertFormula() {
		if (this.getSelectedFormula && this.verifyIfFormulaIsFilled()) {
			if (this.getSelectedFormula.id) {
				this.updateFormula({
					formulaId: this.getSelectedFormula.id,
					payload: {
						formula: this.getSelectedFormula.formula,
						formula_base_id: this.getSelectedFormula.formula_base_id,
						specie_id: this.getSelectedFormula.specie_id,
					},
					attributeGroupId: this.attributeGroup.id,
					attributeId: this.attribute.id,
				})
			} else {
				this.createFormula({
					attributeId: this.attribute.id,
					payload: {
						formula: this.getSelectedFormula.formula,
						formula_base_id: this.getSelectedFormula.formula_base_id,
						specie_id: this.getSelectedFormula.specie_id,
					},
					attributeGroupId: this.attributeGroup.id,
					indexFormula: this.selectedFormula,
				})
			}
		}
	}

	handleRemoveFormula(index: number) {
		const formulaToRemove = this.attribute.formulas[index]

		if (formulaToRemove) {
			if (formulaToRemove === this.getSelectedFormula) {
				this.selectedFormula = this.selectedFormula - 1
			}

			if (formulaToRemove.id) {
				this.removeFormula({
					formulaId: formulaToRemove.id,
					attributeGroupId: this.attributeGroup.id,
					attributeId: this.attribute.id,
				})
			} else {
				this.setRemoveFormula({
					indexFormula: index,
					attributeGroupId: this.attributeGroup.id,
					attributeId: this.attribute.id,
				})
			}
		}
	}
}
