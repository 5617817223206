import API from '@/axios'
const Endpoint = '/animals/v1/breeds/'

export default {
	async getRaca() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async visualizaRaca(id) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async createBread(payload) {
		return await API.post(Endpoint, payload).then((response) => response)
	},

	async updateBreed(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response)
	},

	async excluirRaca(id) {
		return await API.delete(Endpoint + id).then((response) => response)
	},
}
