import { ICustomer } from '@/modules/clientes/store/types'
import { types as errorTypes } from '@/modules/errors/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import { RootState } from '@/store/types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ActionTree } from 'vuex'
import agreementCustomersService from '../service/agreementCustomers'
import { ICreateAgreementCustomerParams } from '../service/agreementCustomers/types'
import agreementExamsService from '../service/agreementExams'
import {
	ICreateAgreementExamParams,
	IUpdateAgreementExamParams,
} from '../service/agreementExams/types'
import agreementsService from '../service/agreements'
import {
	AgreementState,
	IAgreementFilters,
	IAgreementPayload,
	IAgreementResponse,
	IExamAgreementResponse,
	actionTypes,
	mutationTypes,
} from './types'

import { mutationTypes as customerMutationTypes } from '@/modules/customers/store/types'

export const actions: ActionTree<AgreementState<unknown>, RootState> = {
	async [actionTypes.GET_AGREEMENTS](
		{ state, commit },
		{ customerId, filters }: { customerId?: number; filters?: IAgreementFilters }
	): Promise<IAgreementResponse[] | boolean> {
		try {
			commit(mutationTypes.SET_AGREEMENT_LOADING, true)

			if (typeof customerId !== 'undefined') {
				const { status, data: agreements } = await agreementsService.getCustomerAgreements(
					customerId
				)

				if (status === STATUS_SUCCESS) {
					commit(mutationTypes.SET_AGREEMENTS, agreements)
				}

				commit(mutationTypes.SET_AGREEMENT_LOADING, false)

				return status === STATUS_SUCCESS ? agreements : false
			} else {
				const { status, data: agreements } = await agreementsService.getAgreements(
					filters || state.filters
				)

				if (status === STATUS_SUCCESS) {
					commit(mutationTypes.SET_AGREEMENTS, agreements)
				}

				commit(mutationTypes.SET_AGREEMENT_LOADING, false)

				return status === STATUS_SUCCESS ? agreements : false
			}
		} catch (error) {
			commit(mutationTypes.SET_AGREEMENT_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.CREATE_AGREEMENT](
		{ commit },
		payload: IAgreementPayload
	): Promise<IAgreementResponse | boolean> {
		try {
			const { status, data: agreement } = await agreementsService.createAgreement(payload)

			if (status === STATUS_CREATED) {
				commit(mutationTypes.ADD_AGREEMENT, agreement)
			}

			return status === STATUS_CREATED ? agreement : false
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.UPDATE_AGREEMENT](
		{ getters, commit },
		{ payload, agreementId }: { payload: IAgreementPayload; agreementId: number }
	): Promise<IAgreementResponse | boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT, true)

			const { status, data: agreement } = await agreementsService.updateAgreement({
				payload: payload,
				agreementId: agreementId,
			})

			if (status === STATUS_SUCCESS) {
				const indexAgreement = getters.getIndexAgreement(agreementId)

				if (indexAgreement > -1) {
					commit(mutationTypes.UPDATE_AGREEMENT, {
						indexAgreement,
						agreement,
					})
				}
			}

			commit(mutationTypes.SET_SAVING_AGREEMENT, false)

			return status === STATUS_SUCCESS ? agreement : false
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.UPDATE_STATUS_AGREEMENT](
		{ getters, commit },
		{ agreementId, payload }: { agreementId: number; payload: { status: boolean } }
	): Promise<IAgreementResponse | boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT, true)

			const { status, data: agreement } = await agreementsService.updateStatusAgreement({
				agreementId,
				payload,
			})

			if (status === STATUS_SUCCESS) {
				const indexAgreement = getters.getIndexAgreement(agreementId)

				if (indexAgreement > -1) {
					commit(mutationTypes.UPDATE_AGREEMENT, {
						indexAgreement: indexAgreement,
						agreement: agreement,
					})
				}
			}

			commit(mutationTypes.SET_SAVING_AGREEMENT, false)

			return status === STATUS_SUCCESS ? agreement : false
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.VISUALIZE_AGREEMENT](
		{ getters, commit },
		agreementId: number
	): Promise<IAgreementResponse | boolean> {
		try {
			const { status, data: agreement } = await agreementsService.visualizeAgreement(
				agreementId
			)

			if (status === STATUS_SUCCESS) {
				const indexAgreement = getters.getIndexAgreement(agreementId)

				if (indexAgreement > -1) {
					commit(mutationTypes.UPDATE_AGREEMENT, {
						indexAgreement: indexAgreement,
						agreement: agreement,
					})
				}

				commit(mutationTypes.SET_AGREEMENT_SELECTED, agreement)
			}

			return status === STATUS_SUCCESS ? agreement : false
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.REMOVE_AGREEMENT](
		{ getters, commit },
		agreementId: number
	): Promise<boolean> {
		try {
			const { status } = await agreementsService.removeAgreement(agreementId)

			if (status === STATUS_SUCCESS) {
				const indexAgreement = getters.getIndexAgreement(agreementId)

				if (indexAgreement > -1) {
					commit(mutationTypes.REMOVE_AGREEMENT, indexAgreement)
				}
			}

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.REMOVE_AGREEMENTS](
		{ dispatch, commit },
		agreementsToRemove: { ids: number[] }
	): Promise<boolean> {
		try {
			const { status } = await agreementsService.removeAgreements(agreementsToRemove)

			if (status === STATUS_SUCCESS) {
				dispatch(`${actionTypes.GET_AGREEMENTS}`)
			}

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.GET_CUSTOMERS]({ commit }): Promise<ICustomer[] | boolean> {
		try {
			commit(mutationTypes.SET_LOADING_CUSTOMERS, true)

			const { status, data: customers } = await agreementsService.getCustomersAgreements()

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_CUSTOMERS, customers)
			}

			commit(mutationTypes.SET_LOADING_CUSTOMERS, false)

			return status === STATUS_SUCCESS ? customers : false
		} catch (error) {
			commit(mutationTypes.SET_LOADING_CUSTOMERS, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.GET_EXAMS](
		{ commit, state },
		{ agreementId, resetPage }: { agreementId: number; resetPage?: boolean }
	): Promise<IExamAgreementResponse[] | boolean> {
		try {
			commit(mutationTypes.SET_LOADING_EXAMS, true)

			if (resetPage) {
				commit(mutationTypes.SET_AGREEMENT_EXAMS_FILTERS, {
					...state.agreementExamsFilters,
					page: 1,
				})
			}

			const { status, data: exams } = await agreementExamsService.getAgreementExams({
				agreementId,
				query: state.agreementExamsFilters,
			})

			commit(mutationTypes.SET_EXAMS, exams)

			commit(mutationTypes.SET_LOADING_EXAMS, false)

			return status === STATUS_SUCCESS ? exams : false
		} catch (error) {
			commit(mutationTypes.SET_LOADING_EXAMS, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.CREATE_AGREEMENT_EXAM](
		{ commit, getters },
		params: ICreateAgreementExamParams
	): Promise<IExamAgreementResponse | boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, true)

			const { status, data: agreementExam } = await agreementExamsService.createAgreementExam(
				params
			)

			if (status === STATUS_CREATED) {
				const indexExam = getters.getAgreementExamIndex(agreementExam.id)

				if (indexExam > -1) {
					commit(mutationTypes.ADD_AGREEMENT_EXAM, {
						agreementExamIndex: indexExam,
						agreementExam,
					})
				}
			}

			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			return status === STATUS_CREATED ? agreementExam : false
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.UPDATE_AGREEMENT_EXAM](
		{ getters, commit },
		params: IUpdateAgreementExamParams
	): Promise<IExamAgreementResponse | boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, true)

			const { status, data: exam } = await agreementExamsService.updateAgreementExam(params)

			// if (status === STATUS_SUCCESS) {
			// 	const indexExam = getters.getAgreementExamIndex(exam.id)

			// 	if (indexExam > -1) {
			// 		commit(mutationTypes.UPDATE_AGREEMENT_EXAM, {
			// 			agreementExamIndex: indexExam,
			// 			agreementExam: exam,
			// 		})
			// 	}
			// }

			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			return status === STATUS_SUCCESS ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.REMOVE_AGREEMENT_EXAM](
		{ getters, commit },
		{ agreementExamId, examId }: { agreementExamId: number; examId: number }
	): Promise<boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, true)

			const { status } = await agreementExamsService.removeAgreementExam(agreementExamId)

			if (status === STATUS_SUCCESS) {
				const agreementExamIndex = getters.getAgreementExamIndex(examId)

				if (agreementExamIndex > -1) {
					commit(mutationTypes.REMOVE_AGREEMENT_EXAM, agreementExamIndex)
				}
			}

			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT_EXAMS, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.CREATE_AGREEMENT_CUSTOMER](
		{ commit, rootGetters },
		params: ICreateAgreementCustomerParams
	): Promise<boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, ENABLE_LOADING)

			const { status, data: agreementCustomer } =
				await agreementCustomersService.createAgreementCustomer(params)

			const customerIndex = rootGetters['Customers/getCustomerIndexById'](
				params.payload.customer_id
			)

			commit(
				`Customers/${customerMutationTypes.UPDATE_CUSTOMER_AGREEMENT}`,
				{ index: customerIndex, payload: agreementCustomer },
				{ root: true }
			)

			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, DISABLE_LOADING)

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: 'O convênio foi vinculado com sucesso!' },
				{ root: true }
			)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, DISABLE_LOADING)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},

	async [actionTypes.REMOVE_AGREEMENT_CUSTOMER](
		{ commit, rootGetters },
		agreementCustomerId: number
	): Promise<boolean> {
		try {
			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, ENABLE_LOADING)

			const { status } = await agreementCustomersService.removeAgreementCustomer(
				agreementCustomerId
			)

			const customerIndex =
				rootGetters['Customers/getCustomerIndexByAgreementCustomerId'](agreementCustomerId)

			commit(`Customers/${customerMutationTypes.DELETE_CUSTOMER_AGREEMENT}`, customerIndex, {
				root: true,
			})

			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, DISABLE_LOADING)

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: 'O convênio foi removido com sucesso!' },
				{ root: true }
			)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_SAVING_AGREEMENT_CUSTOMERS, DISABLE_LOADING)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			return false
		}
	},
}

export default { actions }
