












import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import {
	Bold,
	Code,
	Italic,
	Strikethrough,
	Subscript,
	Superscript,
	Underline,
} from '@ckeditor/ckeditor5-basic-styles'
import { Link } from '@ckeditor/ckeditor5-link'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { Font, FontFamily } from '@ckeditor/ckeditor5-font'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { CodeBlock } from '@ckeditor/ckeditor5-code-block'
import {
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
} from '@ckeditor/ckeditor5-table'
import { List, TodoList } from '@ckeditor/ckeditor5-list'
import { TextTransformation } from '@ckeditor/ckeditor5-typing'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import SearchAnnotations from './plugins/SearchAnnotationsPlugin'
import SearchPreviousResults from './plugins/SearchPreviousResultsPlugin'

@Component({
	name: 'ClassicInputEditor',
})
export default class ClassicInputEditor extends Vue {
	@VModel({ required: true }) data!: string
	@Prop({ default: '' }) placeholder!: string
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ default: () => [] }) toolbarOptions!: string[]
	@Prop({ type: Boolean, default: false }) shouldNotGroupWhenFull!: boolean

	@Prop({ required: true }) id!: string

	editor = ClassicEditor

	get editorConfig() {
		return {
			language: 'pt_BR',
			plugins: [
				Essentials,
				Bold,
				Code,
				Italic,
				Strikethrough,
				Subscript,
				Superscript,
				Underline,
				Link,
				Paragraph,
				Table,
				TableColumnResize,
				TableCaption,
				TableToolbar,
				TableCellProperties,
				TableProperties,
				Font,
				FontFamily,
				List,
				TodoList,
				Highlight,
				BlockQuote,
				TextTransformation,
				Autoformat,
				Heading,
				CodeBlock,
				Alignment,
				SearchPreviousResults,
				SearchAnnotations,
			],

			toolbar: {
				shouldNotGroupWhenFull: this.shouldNotGroupWhenFull,
				items: [
					'undo',
					'redo',
					'|',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'code',
					'subscript',
					'superscript',
					'highlight',
					'blockquote',
					'|',
					'link',
					'codeBlock',
					'insertTable',
					'|',
					'heading',
					'fontSize',
					'fontFamily',
					'fontColor',
					'fontBackgroundColor',
					'alignment',
					'|',
					'bulletedList',
					'numberedList',
					'todoList',
					'|',
					...this.toolbarOptions,
				],
			},

			customEditorPlugin: {
				id: this.id,
			},

			fontSize: {
				options: [9, 11, 12, 13, 'default', 15, 16, 17, 18, 19, 21],
			},

			table: {
				contentToolbar: [
					'tableColumn',
					'tableRow',
					'mergeTableCells',
					'toggleTableCaption',
					'tableProperties',
					'tableCellProperties',
				],
			},
		}
	}

	mounted(): void {
		window.addEventListener(`click:searchAnnotations:${this.id}`, () => {
			this.$emit('click:searchAnnotations')
		})
		window.addEventListener(`click:searchPreviousResults:${this.id}`, () =>
			this.$emit('click:searchPreviousResults')
		)
	}

	destroy(): void {
		window.removeEventListener(`click:searchAnnotations:${this.id}`, () =>
			this.$emit('click:searchAnnotations')
		)
		window.removeEventListener(`click:searchPreviousResults:${this.id}`, () =>
			this.$emit('click:searchPreviousResults')
		)
	}

	onReady(editor: ClassicEditor): void {
		this.$emit('ready', editor)
	}

	onFocus(): void {
		this.$emit('focus')
	}
}
