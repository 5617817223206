import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { AnimalState, IAnimalResponse } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_INDEX_ANIMAL_PAGINATION = 'GET_INDEX_ANIMAL_PAGINATION',
	GET_ANIMAL_PAGINATION = 'GET_ANIMAL_PAGINATION',

	GET_INDEX_ANIMAL = 'GET_INDEX_ANIMAL',
	GET_INDEX_REACTIVITY_ANIMAL = 'GET_INDEX_REACTIVITY_ANIMAL',
}

export type Getters<S = AnimalState> = {
	[getterTypes.GET_INDEX_ANIMAL_PAGINATION]: (state: S) => (currentPage: number) => number

	[getterTypes.GET_ANIMAL_PAGINATION]: (
		state: S
	) => (currentPage: number) => ITypeOrmPaginationTemplate<IAnimalResponse> | undefined

	[getterTypes.GET_INDEX_ANIMAL]: (
		state: S
	) => ({
		indexAnimalPagination,
		animalId,
	}: {
		indexAnimalPagination: number
		animalId: number
	}) => number

	[getterTypes.GET_INDEX_REACTIVITY_ANIMAL]: (state: S) => ({
		currentPage,
		animalId,
	}: {
		currentPage: number
		animalId: number
	}) =>
		| {
				indexAnimalPagination: number
				indexAnimal: number
		  }
		| number
}
