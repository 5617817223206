import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'
import { MutationTree } from 'vuex'
import { state as stateBreed } from '../index'
import { BreedState } from '../types'
import { Mutations, mutationTypes as types } from './mutation-types'

export const mutations: MutationTree<BreedState> & Mutations = {
	[types.SET_MODAL_CREATE_BREED](state, modalState) {
		state.openModalCreateBreed = modalState
	},

	[types.SET_MODAL_UPDATE_BREED](state, modalState) {
		state.openModalUpdateBreed = modalState
	},

	[types.SET_SPECIE_SELECTED](state, specie) {
		state.specieSelected = specie
	},

	[types.SET_BREEDS](state, breeds) {
		state.breeds.data = breeds.data
		state.breeds.meta = breeds.meta
	},

	[types.SET_MERGE_BREEDS](state, breeds) {
		state.breeds.data = mergeObjectsWithoutDuplicates({
			arrayLoaded: state.breeds.data,
			newArray: breeds.data,
			keyToCompare: 'id',
		})
		state.breeds.meta = breeds.meta
	},

	[types.SET_LOADING_BREEDS](state, loading) {
		state.breeds.loading = loading
	},

	[types.CREATE_BREED](state, { breed }) {
		state.breeds.data.push(breed)
	},

	[types.UPDATE_BREED](state, { index, breed }) {
		state.breeds.data.splice(index, 1, breed)
	},

	[types.REMOVE_BREED](state, { index }) {
		state.breeds.data.splice(index, 1)
	},

	[types.SET_BREED_SELECTED](state, breed) {
		state.breedSelected = breed
	},

	[types.RESET_BREED_SELECTED](state) {
		state.breedSelected = undefined
	},

	[types.UPDATE_BREED_FILTERS](state, filters) {
		state.breeds.filters = filters
	},

	[types.RESET_BREED_FILTERS](state) {
		state.breeds.filters = stateBreed.breeds.filters
	},

	[types.RESET_BREEDS](state) {
		const breedsState = Object.assign({}, stateBreed.breeds)

		state.breeds = breedsState
	},
}

export default { mutations }
