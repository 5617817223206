import API from '@/axios'
import axios from 'axios'

const usuariosAPI = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 30000,
})

const Endpoint = '/usuarios/'
const EndpointInvite = '/organizacoes/invites/'

export default {
	async convidarUsuario(payload) {
		return await API.post(Endpoint, { usuarios: payload })
			.then((response) => response.data)
			.catch((error) => error.response)
	},

	async getMembersOrganization(token, id) {
		return await usuariosAPI
			.get(Endpoint, {
				headers: {
					Authorization: 'Bearer ' + token,
					'organizacao-id': id,
				},
			})
			.then((response) => response.data)
	},

	async getUsers() {
		return await API.get(Endpoint).then((response) => response.data)
	},

	async getInvites() {
		return await API.get(EndpointInvite).then((response) => response.data)
	},

	async createUser(payload) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async changeStatus(id) {
		return await API.patch(Endpoint + id).then((response) => response)
	},

	async updateUser(payload, id) {
		return await API.put(Endpoint + id, payload).then((response) => response.data)
	},

	async deleteUser(id) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},

	async deleteMultipleUsers(ids) {
		return await API.delete(Endpoint, { data: { ids: ids } }).then((response) => response.data)
	},

	async searchUsers(search) {
		return await API.get(Endpoint + 'global-search?q=' + search).then(
			(response) => response.data
		)
	},

	async generateToken() {
		return await API.post(EndpointInvite + '/create-token').then((response) => response.data)
	},

	async submitInvites(payload) {
		return await API.post(EndpointInvite, payload).then((response) => response)
	},

	async confirmateInvite(token) {
		return await API.post(EndpointInvite + token + '/accept').then((response) => response)
	},

	async deleteInvite(id) {
		return await API.delete(EndpointInvite + id).then((response) => response.data)
	},

	async deleteMultipleInvites(ids) {
		return await API.delete(EndpointInvite, { data: { ids: ids } }).then(
			(response) => response.data
		)
	},
}
