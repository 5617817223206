import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { AnimalState } from '../types'
import { Actions, actionTypes as types } from './action-types'
import { mutationTypes } from '../mutations/mutation-types'
import API from '../../service'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { getterTypes } from '../getters/getter-types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { CUSTOMER_NAMESPACE } from '@/modules/customers/store'
import { mutationTypes as mutationTypesOfCustomer } from '@/modules/customers/store/types'

export const actions: ActionTree<AnimalState, RootState> & Actions = {
	async [types.GET_ANIMALS]({ getters, commit }, filters) {
		try {
			commit(mutationTypes.SET_PAGINATION_LOADING, ENABLE_LOADING)

			const { status, data: animalTypeOrmPagination } = await API.getAnimals(filters)

			if (status === STATUS_SUCCESS) {
				const indexAnimalPagination = getters[getterTypes.GET_INDEX_ANIMAL_PAGINATION](
					filters.page
				)

				if (indexAnimalPagination > -1) {
					commit(mutationTypes.UPDATE_ANIMALS_PAGINATION, {
						indexAnimalPagination: indexAnimalPagination,
						animalTypeOrmPagination: animalTypeOrmPagination,
					})
				} else {
					commit(mutationTypes.ADD_ANIMALS_PAGINATION, animalTypeOrmPagination)
				}
			}

			commit(mutationTypes.SET_PAGINATION_LOADING, DISABLE_LOADING)

			return status === STATUS_SUCCESS ? animalTypeOrmPagination : false
		} catch (error: unknown) {
			commit(mutationTypes.SET_PAGINATION_LOADING, DISABLE_LOADING)
			return false
		}
	},

	async [types.GET_ANIMALS_BY_PET_OWNER](_, filters) {
		try {
			const { status, data: animalTypeOrmPagination } = await API.getAnimals(filters)

			return status === STATUS_SUCCESS ? animalTypeOrmPagination : false
		} catch (error: unknown) {
			return false
		}
	},

	async [types.CREATE_ANIMAL]({ state, getters, commit, rootState }, payload) {
		const { status, data: animal } = await API.createAnimal(payload)

		if (status === STATUS_CREATED) {
			const indexAnimalPagination = getters[getterTypes.GET_INDEX_ANIMAL_PAGINATION](
				state.animals.meta.filters.page
			)

			if (indexAnimalPagination > -1) {
				commit(mutationTypes.ADD_ANIMAL, {
					indexAnimalPagination: indexAnimalPagination,
					animal: animal,
				})
			}

			// Animals Reactivity by Customer Profile
			if (rootState.Customers.selectedCustomer) {
				commit(
					`${CUSTOMER_NAMESPACE}/${mutationTypesOfCustomer.ADD_ANIMAL_BY_CUSTOMER}`,
					animal,
					{
						root: true,
					}
				)
			}

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.animals.feedback.success.create.animal') },
				{ root: true }
			)
		}

		return status === STATUS_CREATED ? animal : false
	},

	async [types.UPDATE_ANIMAL]({ state, getters, commit, rootState }, { animalId, payload }) {
		const { status, data: animal } = await API.updateAnimal({ animalId, payload })

		if (status === STATUS_SUCCESS) {
			commit(mutationTypes.SET_ANIMAL_SELECTED, animal)

			const indexsReactivity = getters[getterTypes.GET_INDEX_REACTIVITY_ANIMAL]({
				currentPage: state.animals.meta.filters.page,
				animalId: animalId,
			})

			if (typeof indexsReactivity !== 'number') {
				const { indexAnimalPagination, indexAnimal } = indexsReactivity

				commit(mutationTypes.UPDATE_ANIMAL, {
					indexAnimalPagination: indexAnimalPagination,
					indexAnimal: indexAnimal,
					animal: animal,
				})
			}

			// Animals Reactivity by Customer Profile
			if (rootState.Customers.selectedCustomer) {
				const indexAnimalRoot = rootState.Customers.animalsByCustomer.data.findIndex(
					(currentAnimal) => currentAnimal.id === animalId
				)

				if (indexAnimalRoot > -1) {
					commit(
						`${CUSTOMER_NAMESPACE}/${mutationTypesOfCustomer.UPDATE_ANIMAL_BY_CUSTOMER}`,
						{ indexAnimal: indexAnimalRoot, animal },
						{
							root: true,
						}
					)
				}
			}

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.animals.feedback.success.update.animal') },
				{ root: true }
			)
		}

		return status === STATUS_SUCCESS ? animal : false
	},

	async [types.REMOVE_ANIMAL]({ state, getters, commit }, animalId) {
		const { status } = await API.removeAnimal(animalId)

		if (status === STATUS_SUCCESS) {
			const indexsReactivity = getters[getterTypes.GET_INDEX_REACTIVITY_ANIMAL]({
				currentPage: state.animals.meta.filters.page,
				animalId: animalId,
			})

			if (typeof indexsReactivity !== 'number') {
				const { indexAnimalPagination, indexAnimal } = indexsReactivity

				commit(mutationTypes.REMOVE_ANIMAL, {
					indexAnimalPagination: indexAnimalPagination,
					indexAnimal: indexAnimal,
				})
			}

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: i18n.t('modules.animals.feedback.success.remove.animal') },
				{ root: true }
			)
		}

		return status === STATUS_SUCCESS
	},

	async [types.UNLINK_ANIMAL]({ commit, rootState }, { animalId, actorId }) {
		const { status } = await API.unlinkAnimal({ animalId, actorId })

		if (status === STATUS_SUCCESS) {
			// Animals Reactivity by Customer Profile
			if (rootState.Customers.selectedCustomer) {
				const indexAnimalRoot = rootState.Customers.animalsByCustomer.data.findIndex(
					(currentAnimal) => currentAnimal.id === animalId
				)

				if (indexAnimalRoot > -1) {
					commit(
						`${CUSTOMER_NAMESPACE}/${mutationTypesOfCustomer.REMOVE_ANIMAL_BY_CUSTOMER}`,
						indexAnimalRoot,
						{
							root: true,
						}
					)
				}
			}

			commit(
				`Successes/${successTypes.ADD_SUCCESS}`,
				{ title: 'Cliente Desvinculado com sucesso' },
				{ root: true }
			)
		}

		return status === STATUS_SUCCESS
	},
}

export default { actions }
