import { ExamsGroupState } from '../types'

/* eslint-disable no-unused-vars */
export enum getterTypes {
	GET_INDEX_CURRENT_PAGE = 'GET_INDEX_CURRENT_PAGE',
	GET_INDEX_EXAMS_GROUP = 'GET_INDEX_EXAMS_GROUP',
	GET_INDEX_EXAM_GROUP_REACTIVITY = 'GET_INDEX_EXAM_GROUP_REACTIVITY',
}

export type Getters<S = ExamsGroupState> = {
	[getterTypes.GET_INDEX_CURRENT_PAGE]: (state: S) => (currentPage: number) => number

	[getterTypes.GET_INDEX_EXAMS_GROUP]: (
		state: S
	) => ({
		indexCurrentPage,
		examsGroupId,
	}: {
		indexCurrentPage: number
		examsGroupId: number
	}) => number

	[getterTypes.GET_INDEX_EXAM_GROUP_REACTIVITY]: (
		state: S
	) => (examsGroupId: number) => { indexCurrentPage: number; indexExamsGroup: number }
}
